import React from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import EmptyState from '../../../../../common/EmptyState';
import MeetingInfoPrescreenerResponse from './MeetingInfoPrescreenerResponse';

/* =============================================================================
<MeetingInfoPrescreener />
============================================================================= */
function MeetingInfoPrescreener({ prescreenerResponse }) {
  return (
    <div className="my-5 my-2 px-6">
      {prescreenerResponse && Array.isArray(prescreenerResponse) ? prescreenerResponse?.map(
        (response) => <MeetingInfoPrescreenerResponse key={response?.id} response={response} />,
      ) : prescreenerResponse && <MeetingInfoPrescreenerResponse response={prescreenerResponse} />}

      {Array.isArray(prescreenerResponse) && !prescreenerResponse?.length > 0 && (
        <div className="text-center px-5 py-10 mx-auto">
          <EmptyState
            title="No results"
            icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
            description="There is no pre-screener response for this meeting"
        />
        </div>
      )}
      {!Array.isArray(prescreenerResponse) && prescreenerResponse === undefined && (
        <div className="text-center px-5 py-10 mx-auto">
          <EmptyState
            title="No results"
            icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
            description="There is no pre-screener response for this meeting"
        />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingInfoPrescreener;
