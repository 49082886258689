import React, { useContext } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';

/* =============================================================================
<Carousel />
============================================================================= */
function Carousel({ children }) {
  return (
    <ScrollMenu wrapperClassName="relative" scrollContainerClassName="scrollbar-hide relative space-x-1.5" LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {children}
    </ScrollMenu>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  if (isFirstItemVisible) {
    return null;
  }

  return (
    <div className="hidden md:block">
      <div className="flex items-center z-20 absolute top-0 bottom-0 -left-5">
        <button type="button" className="bg-white rounded-full border p-2" disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
          <ArrowNarrowLeftIcon className="w-4 h-4 text-gray-400" />
        </button>
      </div>
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  if (isLastItemVisible) {
    return null;
  }

  return (
    <div className="hidden md:block">
      <div className="flex items-center z-20 absolute top-0 bottom-0 -right-5">
        <button type="button" className="bg-white rounded-full border p-2" disabled={isLastItemVisible} onClick={() => scrollNext()}>
          <ArrowNarrowRightIcon className="w-4 h-4 text-gray-400" />
        </button>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default Carousel;
