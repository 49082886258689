import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import hcpNavLinks from '../../../../../static/hcpNavLinks';
import mslNavLinks from '../../../../../static/mslNavLinks';
import DashboardSideNavAvailabilityStatus from './DashboardSideNavAvailabilityStatus';
import { getUnreadMessagesCount } from '../../../../../chat/redux/selectors';
import { userTypes } from '../../../../../config/constants';
import { hcpPaths, mslPaths } from '../../../../../config/paths';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DashboardSideNav({ user, onClose }) {
  const location = useLocation();
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);

  const isMsl = user?.user_type?.key === userTypes.msl.key;
  const isHcp = user?.user_type?.key === userTypes.hcp.key;

  const unseenMissedCalls = user?.unseen_missed_direct_calls_count;

  return (
    <div className="mt-5 flex flex-1 flex-col h-full justify-between overflow-y-scroll">
      <nav className="flex-1 space-y-5">
        {isHcp ? hcpNavLinks.map((link, i) => (
          <div key={i}>
            <h4 className="px-6 font-[Inter] text-xs font-semibold text-gray-400 mb-2 tracking-wider">
              {link.title}
            </h4>
            {link.items.map((item) => {
              const active = isActive(item.to, location) || ((item.to === '/products/search/hcp' && location.pathname.includes('products') && !location.pathname.includes('trending') && !location.pathname.includes('companies') && !location.pathname.includes('msl'))) || ((item.to === '/events/hcp/live' && location.pathname.includes('events'))) || ((item.to === hcpPaths.meetings('upcoming') && location.pathname.includes('meetings')));
              return (
                <NavLink
                  key={item.title}
                  to={item.to}
                  onClick={onClose && onClose}
                  className="group flex items-center relative px-6 py-2.5 text-base font-medium group"
                >
                  {active ? (
                    <div className="w-1 bg-red-500 rounded-r-full absolute left-0 h-10" />
                  ) : (
                    <div className="w-1 bg-gray-400 rounded-r-full absolute left-0 h-10 hidden group-hover:block" />
                  )}
                  <div className="flex justify-between items-center flex-1 bg">
                    <div className="flex items-center space-x-3">
                      <div className="w-6 bg-white">
                        {active ? item.iconActive : item.iconInActive }
                      </div>
                      <p className={classNames(active ? 'text-gray-700' : 'text-gray-500', 'font-[Inter] font-semibold text-sm ')}>
                        {item.title}
                      </p>
                    </div>
                    {item?.to === '/messaging' && unreadMessagesCount > 0 && (
                      <span
                        className="bg-red-500 text-white group-hover:opacity-80 ml-3 inline-block py-0.5 px-3 text-xs font-semibold rounded-full"
                      >
                        {unreadMessagesCount}
                      </span>
                    )}
                    {item?.to === '/calls/history' && unseenMissedCalls > 0 && (
                      <span
                        className="bg-red-500 text-white group-hover:opacity-80 ml-3 inline-block py-0.5 px-3 text-xs font-semibold rounded-full"
                      >
                        {unseenMissedCalls}
                      </span>
                    )}
                  </div>
                </NavLink>
              );
            })}
          </div>
        )) : isMsl ? mslNavLinks.map((link, i) => (
          <div key={i}>
            <h4 className="px-6 font-[Inter] text-xs font-semibold text-gray-400 mb-2 tracking-wider">
              {link.title}
            </h4>
            {link.items.map((item) => {
              const active = isActive(item.to, location) || ((item.to === mslPaths.meetings('upcoming') && location.pathname.includes('meetings') && !location.pathname.includes('types')));
              return (
                <NavLink
                  key={item.title}
                  to={item.to}
                  onClick={onClose && onClose}
                  className="group flex items-center relative px-6 py-2.5 text-base font-medium group"
                >
                  {active ? (
                    <div className="w-1 bg-red-500 rounded-r-full absolute left-0 h-10" />
                  ) : (
                    <div className="w-1 bg-gray-400 rounded-r-full absolute left-0 h-10 hidden group-hover:block" />
                  )}

                  <div className="flex justify-between items-center flex-1 bg">
                    <div className="flex items-center space-x-3">
                      <div className="w-6 bg-white">
                        {active ? item.iconActive : item.iconInActive }
                      </div>
                      <p className={classNames(active ? 'text-gray-700' : 'text-gray-500', 'font-[Inter] font-semibold text-sm ')}>
                        {item.title}
                      </p>
                    </div>
                    {item?.to === '/messaging' && unreadMessagesCount > 0 && (
                      <span
                        className="bg-red-500 text-white group-hover:opacity-80 ml-3 inline-block py-0.5 px-3 text-xs font-semibold rounded-full"
                      >
                        {unreadMessagesCount}
                      </span>
                    )}
                    {item?.to === '/calls/history' && unseenMissedCalls > 0 && (
                      <span
                        className="bg-red-500 text-white group-hover:opacity-80 ml-3 inline-block py-0.5 px-3 text-xs font-semibold rounded-full"
                      >
                        {unseenMissedCalls}
                      </span>
                    )}
                  </div>
                </NavLink>
              );
            })}
          </div>
        )) : null}
      </nav>
      <DashboardSideNavAvailabilityStatus user={user} />
    </div>
  );
}
const isActive = (link, location) => location.pathname.startsWith(link);

/* Export
============================================================================= */
export default DashboardSideNav;
