import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { useSbCalls } from '../lib/context';
import CallSettingsModal from '../components/CallModals/CallSettingsModal';
import GroupCallMediaContent from '../components/GroupCall/GroupCallMediaContent';
import GroupCallActions from '../components/GroupCall/GroupCallActions';
import GroupCallParticipantsDrawer from '../components/GroupCall/GroupCallParticipantsDrawer';
import { useGetGroupCall } from '../api/queries';
import Spinner from '../../common/Spinner';
import { EmptyState } from '../../common';
import { generalPaths, hcpPaths, mslPaths } from '../../config/paths';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<GroupCallScreen />
============================================================================= */
function GroupCallScreen() {
  const navigate = useNavigate();
  const { groupCallId } = useParams();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const { data: groupCallData, status: groupCallStatus } = useGetGroupCall(groupCallId);
  const sendBirdRoomId = groupCallData?.send_bird_room_id;
  const isVideoCall = groupCallData && groupCallData?.group_call_type?.key === 'video-call';

  const {
    rooms, isAuthenticated, fetchRoomById,
  } = useSbCalls();

  const room = rooms?.find((_room) => _room?.roomId === sendBirdRoomId);
  const { localParticipant } = room || {};
  const discreedlyGroupCallId = room?.group_call_discreedly_id;

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [participantsDrawerOpen, setParticipantsDrawerOpen] = useState(false);

  const toggleSettingsModal = () => setSettingsModalOpen(!settingsModalOpen);
  const toggleParticipantsDrawer = () => setParticipantsDrawerOpen(!participantsDrawerOpen);

  useEffect(() => {
    if (!room && sendBirdRoomId && isAuthenticated) {
      fetchRoomById(sendBirdRoomId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendBirdRoomId, isAuthenticated]);

  useEffect(() => {
    if (room && groupCallStatus === 'success') {
      if (!localParticipant) {
        const redirectTo = groupCallId != null ? generalPaths.joinGroupCall(groupCallId) : (userType === 'hcp' ? hcpPaths.dashboard : mslPaths.dashboard);
        navigate(redirectTo);
      }
    }
  }, [groupCallStatus, room, localParticipant, userType, navigate, groupCallId]);

  if (!groupCallData && groupCallStatus !== 'loading') {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="Group call not found"
          icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
          description="The requested group call was not found"
        />
      </div>
    );
  }

  return (
    <div className="h-full w-full bg-gray-800 flex flex-col space-y-6 sm:space-y-10 p-4 sm:p-12">
      {(groupCallStatus === 'loading' || !room || !localParticipant) ? (
        <div className="text-center items-center h-full justify-center flex">
          <Spinner color="white" size={28} />
        </div>
      ) : (
        <>
          <GroupCallMediaContent room={room} />
          <GroupCallActions
            room={room}
            toggleSettingsModal={toggleSettingsModal}
            toggleParticipantsDrawer={toggleParticipantsDrawer}
          />
          <CallSettingsModal
            showVideoSettings={isVideoCall}
            visible={settingsModalOpen}
            onClose={toggleSettingsModal}
          />
          <GroupCallParticipantsDrawer
            discreedlyGroupCallId={discreedlyGroupCallId}
            open={participantsDrawerOpen}
            onClose={toggleParticipantsDrawer}
          />
        </>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default GroupCallScreen;
