import React from 'react';
import {
  FireIcon,
  SearchIcon,
  BeakerIcon,
  ChatIcon,
  CalendarIcon,
  PhoneIcon,
} from '@heroicons/react/outline';

import { ReactComponent as DashboardIconActive } from '../assets/icons/nav-dashboard-icon-active.svg';
import { ReactComponent as DashboardIconInActive } from '../assets/icons/nav-dashboard-icon-Inactive.svg';
import { generalPaths, hcpPaths } from '../config/paths';

export default [
  {
    title: 'DASHBOARD',
    items: [
      {
        to: hcpPaths.dashboard,
        title: 'Feed',
        iconActive: <DashboardIconActive width={22} />,
        iconInActive: <DashboardIconInActive width={22} />,
      },
      {
        to: hcpPaths.globalSearch.home,
        title: 'Search drugs',
        iconActive: <SearchIcon width={24} color="#374151" />,
        iconInActive: <SearchIcon width={24} color="#9CA3AF" />,
      },
      {
        to: hcpPaths.events.live,
        title: 'Happening now',
        iconActive: <FireIcon width={24} color="#374151" />,
        iconInActive: <FireIcon width={24} color="#9CA3AF" />,
      },
      {
        to: hcpPaths.trendingProducts.home,
        title: 'Therapy area products',
        iconActive: <BeakerIcon width={24} color="#374151" />,
        iconInActive: <BeakerIcon width={24} color="#9CA3AF" />,
      },
    ],
  },
  {
    title: 'CONNECT',
    items: [
      {
        to: hcpPaths.chats(),
        title: 'Messaging',
        iconActive: <ChatIcon width={24} color="#374151" />,
        iconInActive: <ChatIcon width={24} color="#9CA3AF" />,
      },
      {
        to: hcpPaths.meetings('upcoming'),
        title: 'Meetings',
        iconActive: <CalendarIcon width={24} color="#374151" />,
        iconInActive: <CalendarIcon width={24} color="#9CA3AF" />,
      },
      {
        to: generalPaths.callHistory,
        title: 'Call history',
        iconActive: <PhoneIcon width={24} color="#374151" />,
        iconInActive: <PhoneIcon width={24} color="#9CA3AF" />,
      },
    ],
  },
];
