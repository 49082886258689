import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsAuthenticated, getUser } from '../auth/redux/selectors';
import DashboardLayout from '../common/Layout/DashboardLayout';
import { hcpPaths, mslPaths } from '../config/paths';

function AuthenticatedRoute({
  allowHcp,
  allowMsl,
  showMainDashboardLayout,
}) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);

  const isUserAllowed = (allowHcp && user?.user_type?.key === 'hcp') || (allowMsl && user?.user_type?.key === 'msl');
  const authenticatedCheck = user && isUserAllowed;

  const successComponent = showMainDashboardLayout ? <DashboardLayout /> : <Outlet />;
  const redirectPath = user ? (user?.user_type?.key === 'hcp' ? hcpPaths.dashboard : mslPaths.dashboard) : '/';

  return (authenticatedCheck ? successComponent : <Navigate to={redirectPath} />);
}

AuthenticatedRoute.defaultProps = {
  showMainDashboardLayout: true,
  allowMsl: true,
  allowHcp: true,
};

export default AuthenticatedRoute;
