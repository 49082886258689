import React from 'react';

import { Link, Outlet, useLocation } from 'react-router-dom';
import MainLogo from '../../assets/logos/MainLogo.png';
import HCPImage from '../../assets/images/auth/auth-layout-1.jpeg';
import OtherImage from '../../assets/images/auth/auth-layout-2.jpeg';

/* =============================================================================
<AuthLayout />
============================================================================= */
function AuthLayout() {
  const location = useLocation();
  const imageToShow = location.pathname.includes('msl') ? OtherImage : HCPImage;

  return (
    <div className="min-h-full flex bg-white">
      <div className="flex-1 my-auto bg-white mx-10">
        <div className="flex-1 sm:mx-auto py-8 bg-white sm:w-96 lg:w-full xl:px-20">
          <Link to="/">
            <img className="w-40 mb-6" src={MainLogo} alt="logo" />
          </Link>
          <Outlet />
        </div>
      </div>
      <div className="hidden lg:block relative" style={{ flex: 1.4 }}>
        <img
          className="inset-0 h-full w-full object-cover"
          src={imageToShow}
          alt=""
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default AuthLayout;
