import React from 'react';
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/solid';
import Button from '../../common/Button';
import { useGetSubscriptionsProductIds } from '../api/queries';
import { useSubscribeProduct, useUnsubscribeFromProduct } from '../api/mutations';

/* =============================================================================
<ProductSubscriptionButton />
============================================================================= */
function ProductSubscriptionButton({ productId }) {
  const { mutate: subscribeToProduct, status: subscribeStatus } = useSubscribeProduct();
  const { mutate: unsubscribeFromProduct, status: unsubscribeStatus } = useUnsubscribeFromProduct();
  const { data: subscribedProductIds, status: subscribedProductsStatus } = useGetSubscriptionsProductIds();

  const isLoading = subscribeStatus === 'loading' || unsubscribeStatus === 'loading' || subscribedProductsStatus === 'loading';

  const isSubscribed = subscribedProductIds?.find(
    (subscribedProductId) => (subscribedProductId === productId),
  );

  const _handleSubscribe = () => {
    subscribeToProduct(productId);
  };

  const _handleUnsubscribe = () => {
    unsubscribeFromProduct(productId);
  };

  return (
    <Button
      customStyleClasses="w-full"
      title={isSubscribed ? 'Subscribed' : 'Subscribe'}
      size="block"
      variant="secondary"
      disabled={isLoading}
      loading={isLoading}
      icon={isSubscribed ? <CheckCircleIcon className="w-5 h-5 text-green-500" /> : <PlusIcon className="w-5 h-5 text-gray-400" />}
      onClick={(e) => {
        if (isSubscribed) {
          _handleUnsubscribe();
        } else {
          _handleSubscribe();
        }

        // Prevent it clicking on the product card (in product search)
        e.preventDefault();
      }}
    />
  );
}

/* Export
============================================================================= */
export default ProductSubscriptionButton;
