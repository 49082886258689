import React, { useEffect, useRef, useState } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ChatChannelsHeader />
============================================================================= */
function ChatChannelsHeader({ query, onQueryChange }) {
  const [showInput, setShowInput] = useState(false);

  const searchInputRef = useRef(null);

  const _handleOpenSearch = () => {
    setShowInput(true);
  };

  const _handleCloseSearch = () => {
    setShowInput(false);
    onQueryChange('');
  };
  // eslint-disable-next-line no-restricted-globals

  useEffect(() => {
    if (showInput) {
      searchInputRef.current.focus();
    }
  }, [showInput]);

  return (
    <div className="border-b h-16 px-5 flex shrink-0 justify-between">
      <div className={`${showInput && 'hidden'} flex items-center transition-opacity`}>
        <h1 className="text-lg font-semibold text-grey-900">Conversations</h1>
      </div>
      <div className={classNames(showInput && 'transition-all w-full', 'w-5 relative text-gray-400 focus-within:text-gray-600')}>
        <button
          type="button"
          onClick={_handleOpenSearch}
          className="absolute inset-y-0 left-0 flex items-center hover:text-gray-600"
        >
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <input
          className={classNames(!showInput && 'hidden', 'block h-full w-full border-transparent px-8 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm')}
          name="search"
          placeholder="Search conversations"
          value={query}
          onChange={(e) => onQueryChange(e.target.value)}
          autoComplete="off"
          ref={searchInputRef}
        />
        <button
          type="button"
          onClick={_handleCloseSearch}
          className={classNames(!showInput && 'hidden', 'absolute inset-y-0 right-0 flex items-center text-gray-400 hover:text-gray-600')}
        >
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatChannelsHeader;
