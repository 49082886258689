import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, CounterInput, Modal, ProductCard,
} from '../../../../common';
import ProductOrderSampleSelectAddress from './ProductOrderSampleSelectAddress';

import { useGetProduct } from '../../../api/queries';
import { useOrderProductSamples } from '../../../api/mutations';
import TextArea from '../../../../common/TextArea';

/* =============================================================================
<ProductOrderSampleModal />
============================================================================= */
function ProductOrderSampleModal({
  visible, onSuccess, onClose,
}) {
  const { productId } = useParams();

  const { data: product } = useGetProduct(productId);
  const { mutate: useOrderProduct, isLoading } = useOrderProductSamples();

  const [quantity, setQuantity] = useState(0);
  const [hcpAddressId, setHcpAddressId] = useState(null);
  const [comments, setComments] = useState('');

  const resetForm = () => {
    setQuantity(0);
    setHcpAddressId(null);
    setComments('');
  };

  const _handleSubmit = () => {
    useOrderProduct({
      productId: product?.id,
      quantity,
      hcp_address_id: hcpAddressId,
      comments,
    }, {
      onSuccess: () => {
        onSuccess();
        onClose();
        resetForm();
      },
    });
  };

  return (
    <Modal size="xl" visible={visible} heading="Order product samples" onClose={onClose}>
      <div className="divide-y">
        <div className="p-5 rounded-lg border mx-5 my-5">
          <ProductCard product={product} showActions={false} />
        </div>
        <div className="flex items-center border-t p-5 grid sm:grid-cols-3 space-y-2.5 sm:space-y-0">
          <h3 className="font-[Inter] text-sm font-medium text-gray-700 col-span-3 sm:col-span-1">
            Number of samples
          </h3>
          <div className="space-x-2 flex col-span-3 sm:col-span-2">
            <CounterInput
              value={quantity}
              onChange={(newValue) => setQuantity(newValue)}
            />
          </div>
        </div>
        <ProductOrderSampleSelectAddress
          hcpAddressId={hcpAddressId}
          onHcpAddressIdChange={setHcpAddressId}
        />
        <div className="flex items-start border-t p-5 grid sm:grid-cols-3 space-y-2.5 sm:space-y-0">
          <h3 className="font-[Inter] text-sm font-medium text-gray-700 col-span-3 sm:col-span-1">
            Comments
            (optional)
          </h3>
          <div className="space-x-2 flex col-span-3 sm:col-span-2 items-stretch">
            <TextArea
              label={null}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Please enter comments..."
              customContainerStyles="w-full"
            />
          </div>
        </div>
        <div className="flex justify-end items-center sm:space-x-4 p-5">
          <Button
            title="Cancel"
            variant="secondary"
            onClick={onClose}
            customStyleClasses="hidden sm:block w-24 h-10"
          />
          <Button
            type="submit"
            title="Submit order"
            variant="purple"
            loading={isLoading}
            onClick={_handleSubmit}
            customStyleClasses="w-full sm:w-40 h-10"
          />
        </div>
      </div>
    </Modal>
  );
}

const initialValues = {
  comments: '',
  quantity: 0,
  hcp_address_id: '',
};

/* Export
============================================================================= */
export default ProductOrderSampleModal;
