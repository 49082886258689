import React from 'react';
import { PusherProvider as HPusherProvider } from '@harelpls/use-pusher';
import { connect } from 'react-redux';
import { PUSHER_APP_KEY, PUSHER_APP_CLUSTER } from '../config/secrets';
import { getUser } from '../auth/redux/selectors';
import { useBroadcastAuthentication } from '../auth/api/mutations';

function PusherProvider({ children, user }) {
  const { mutateAsync: authenticateWithBroadcasting } = useBroadcastAuthentication();

  if (!user) {
    return children;
  }

  return (
    <HPusherProvider
      clientKey={PUSHER_APP_KEY}
      cluster={PUSHER_APP_CLUSTER}
      channelAuthorization={{
        customHandler: (data, callback) => {
          // Must return a promise (by using mutateAsync as seen above) and use .then and .catch, DO NOT USE react-query's onSuccess and onError (it will only listen to one channel for some reason)
          authenticateWithBroadcasting({
            channel_name: data.channelName,
            socket_id: data.socketId,
          }).then((response) => {
            callback(null, {
              auth: response.data.auth,
            });
          }).catch((error) => {
            callback(error, null);
          });
        },
      }}
    >
      {children}
    </HPusherProvider>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(PusherProvider);
