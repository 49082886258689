import React, { useState } from 'react';
import { ClockIcon } from '@heroicons/react/solid';

import MeetingTypeUpdateModal from './MeetingTypeUpdateModal';

/* =============================================================================
<MeetingTypeItem />
============================================================================= */
function MeetingTypeItem({ meetingType }) {
  const [editModal, setEditModal] = useState(false);

  const _toggleEditModal = () => setEditModal((prev) => !prev);

  return (
    <>
      <button type="button" onClick={_toggleEditModal} className="bg-white px-6 py-6 rounded-lg border hover:shadow-sm transition-all ease-in-out flex items-start flex-col group">
        <div className="flex flex-row justify-between space-x-3 w-full">
          <div className="flex items-center space-x-2 overflow-hidden">
            <div className="w-3 h-3 rounded-full shrink-0" style={{ backgroundColor: meetingType?.color }} />
            <h1 className="font-[Inter] text-xl font-semibold text-gray-700 truncate group-hover:text-gray-900 transition-all ease-in-out">{meetingType?.name}</h1>
          </div>
          <div className="flex items-center space-x-2 shrink-0">
            <ClockIcon className="w-5 h-5 text-gray-300" />
            <p className="font-[Inter] text-sm text-gray-700 font-medium">{`${meetingType?.duration_in_minutes} mins`}</p>
          </div>
        </div>
        <div className="mt-4 text-left space-y-1">
          <h2 className="text-sm text-gray-400 font-medium">Description</h2>
          <p className="text-sm text-gray-700">{meetingType?.description || 'No description'}</p>
        </div>
      </button>
      <MeetingTypeUpdateModal
        visible={editModal}
        onClose={_toggleEditModal}
        meetingType={meetingType}
      />
    </>
  );
}

/* Export
============================================================================= */
export default MeetingTypeItem;
