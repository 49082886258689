import React, { useEffect, useState } from 'react';
import { useGetProductUpdates } from '../../api/queries';
import LiveUpdatesModal from './components/LiveUpdatesModal';
import { client as queryClient } from '../../../config/react-query';
import { queryKeys } from '../../api/query-key-factory';
import Content from './components/Content';
import SeeMoreCollapsed from './components/SeeMoreCollapsed';
import SeeMore from './components/SeeMore';

/* =============================================================================
<ProductLiveUpdates />
============================================================================= */
function ProductLiveUpdates({ productId, showModal, setShowModal }) {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const { data: productUpdates, status: productUpdatesStatus } = useGetProductUpdates(productId);
  const hasAnyUpdates = productUpdatesStatus === 'success' && productUpdates?.length > 0;

  const _handleModalClose = () => {
    const productIdToInvalidate = productId;
    // Invalidate list of products with updates
    setShowModal(false);
    queryClient.invalidateQueries(queryKeys.productsUpdates);
    queryClient.invalidateQueries(queryKeys.productUpdates(productIdToInvalidate));
  };

  const stories = productUpdates?.map((update) => {
    const content = {
      content: ({ isPaused, action, story }) => Content({
        isPaused, action, story, update, onClose: _handleModalClose,
      }),
    };

    const baseStory = {
      url: update?.story_format_key === 'video' ? update?.video_path : update?.story_format_key === 'image' ? update?.image_path : null,
      type: update?.story_format_key === 'video' ? 'video' : null, // Video must be using the package's built-in video rendered, as it handles duration of the story based on the video duration.
      seeMoreCollapsed: ({ action }) => SeeMoreCollapsed({ update, action }),
      seeMore: SeeMore,
      header: {
        companyId: update?.pharma_company?.id,
        companyLogo: update?.pharma_company?.logo_path,
        companyName: update?.pharma_company?.name,
        publishedAt: update?.published_at,
      },
      update,
      updateId: update?.id,
      productId: update?.product?.id,
    };

    // If format is 'text', add content property to baseStory
    return update?.story_format_key === 'text' ? { ...baseStory, ...content } : baseStory;
  });

  useEffect(() => {
    if (productUpdatesStatus === 'success' && productUpdates?.length > 0) {
      const firstUpdateUserHasNotSeen = productUpdates.find((update) => !update?.has_user_viewed);
      const firstUpdateUserHasNotSeenIndex = productUpdates.indexOf(firstUpdateUserHasNotSeen);

      if (!firstUpdateUserHasNotSeen) {
        setCurrentStoryIndex(0);
      } else {
        setCurrentStoryIndex(firstUpdateUserHasNotSeenIndex);
      }
    } else {
      setCurrentStoryIndex(0);
    }
  }, [productUpdatesStatus, productUpdates]);

  if (!hasAnyUpdates && productUpdatesStatus !== 'loading') {
    return null;
  }

  return (
    <LiveUpdatesModal
      productId={productId}
      currentStoryIndex={currentStoryIndex}
      visible={showModal}
      stories={stories}
      onClose={_handleModalClose}
    />
  );
}

/* Export
============================================================================= */
export default ProductLiveUpdates;
