import React, { useState } from 'react';
import { PhoneOutgoingIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../common/Button';
import Drawer from '../../../../common/Drawer';
import PromptModal from '../../../../common/PromptModal';
import Spinner from '../../../../common/Spinner';
import TabsSwitch from '../../../../common/TabsSwitch';
import MeetingInfoOverview from './MeetingInfoOverview';
import MeetingInfoParticipants from './MeetingInfoParticipants';
import MeetingInfoPrescreener from './MeetingInfoPrescreener';
import { useGetMeeting } from '../../../api/queries';
import { useCancelMeeting } from '../../../api/mutations';
import { hcpPaths, mslPaths } from '../../../../config/paths';
import { setSelectedMeetingId, setShowMeetingInfoDrawer } from '../../../redux/actions';
import { getUser } from '../../../../auth/redux/selectors';
import { userTypes } from '../../../../config/constants';

const tabs = [
  { name: 'Participants' },
  { name: 'Pre-screener' },
];

/* =============================================================================
<MeetingInfoDrawer />
============================================================================= */
function MeetingInfoDrawer({
  open, onClose, meetingId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('Participants');
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);

  const { data, status } = useGetMeeting(meetingId);

  const { mutate: cancelMeeting, isLoading: cancelMeetingLoading } = useCancelMeeting();

  const user = useSelector(getUser);
  const meeting = data?.meeting;
  const prescreenerResponse = data?.my_prescreener_response || data?.prescreener_responses;
  const groupCall = meeting?.group_call;
  const isCurrentUserOwner = meeting?.is_current_user_owner;
  const isMeetingCancelled = meeting?.cancelled_at != null;

  const _handleCancelMeeting = () => {
    cancelMeeting(meeting?.id);
    setShowCancelPrompt(false);
  };

  const _handleJoinRoom = () => {
    dispatch(setShowMeetingInfoDrawer(false));
    dispatch(setSelectedMeetingId(null));

    // Join actual group call
    // navigate(generalPaths.joinGroupCall(groupCall?.id));

    // Go to initiate direct call (assuming there is just one other participant)
    const otherParticipant = meeting?.participants?.find((participant) => participant?.user?.id !== user?.id);
    const otherParticipantUser = otherParticipant?.user;

    if (!otherParticipantUser) {
      return;
    }

    if (user?.user_type?.key === userTypes.hcp.key && otherParticipantUser?.user_type?.key === userTypes.msl.key) {
      navigate(hcpPaths.mslProfile(otherParticipantUser?.user_type_specific_data?.msl_id).initiateCall);
    } else if (user?.user_type?.key === userTypes.msl.key && otherParticipantUser?.user_type?.key === userTypes.hcp.key) {
      navigate(mslPaths.hcpProfile(otherParticipantUser?.user_type_specific_data?.hcp_id).initiateCall);
    }
  };

  return (
    <Drawer
      heading={meeting?.title || 'Loading...'}
      open={open}
      onClose={onClose}
      contentContainerStyles=""
      footer={(meetingId && status === 'success') ? (
        <div className="flex flex-col-reverse sm:flex-row p-6 space-y-3 sm:space-y-0 sm:space-x-4 space-y-reverse border-t">
          {(isCurrentUserOwner && !isMeetingCancelled) && (
            <Button
              size="block"
              variant="secondary"
              title="Cancel"
              onClick={() => setShowCancelPrompt(true)}
            />
          )}
          <Button
            size="block"
            variant="purple"
            title="Join meeting"
            iconPosition="right"
            disabled={isMeetingCancelled}
            icon={<PhoneOutgoingIcon className="w-4 h-4 text-white" />}
            onClick={_handleJoinRoom}
          />
        </div>
      ) : null}
    >
      {(meetingId && status === 'success') ? (
        <div className="flex flex-col flex-1 overflow-y-scroll">
          <MeetingInfoOverview
            meeting={meeting}
            onClose={onClose}
          />
          <TabsSwitch
            tabs={tabs}
            isActive={selectedTab}
            onChange={setSelectedTab}
            contentStyles="px-4 sm:px-6"
            switchToDropdownOnSmallScreens={false}
          />
          <div className="flex-1">
            <div className="h-full flex flex-col">
              {selectedTab === 'Participants' && (
                <MeetingInfoParticipants
                  meeting={meeting}
                  onClose={onClose}
                />
              )}
              {selectedTab === 'Pre-screener' && (
                <MeetingInfoPrescreener
                  prescreenerResponse={prescreenerResponse}
                  onClose={onClose}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="justify-center items-center w-full text-center">
          <Spinner />
        </div>
      )}
      <PromptModal
        btnTxt="Cancel the meeting"
        closeBtnText="Go back"
        open={showCancelPrompt}
        onClose={() => setShowCancelPrompt(false)}
        onBtnPress={_handleCancelMeeting}
        btnLoading={cancelMeetingLoading}
        title="Cancel meeting"
        description="Are you sure you would like to cancel this meeting?"
      />
    </Drawer>
  );
}

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.meetingId === nextProps.meetingId
);

/* Export
============================================================================= */
export default React.memo(MeetingInfoDrawer, propsAreEqual);
