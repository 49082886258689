import React from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Button, Input, Modal, TextArea,
} from '../../../common';

import { useCreateMeetingType } from '../../api/mutations';

function MeetingTypeCreateModal({ visible, onClose }) {
  const { mutateAsync: createMeetingType, isLoading } = useCreateMeetingType();

  const initialValues = {
    name: '',
    duration_in_minutes: null,
    description: '',
  };

  const _handleCreateMeetingType = async (values) => {
    await createMeetingType({
      name: values?.name,
      duration_in_minutes: values?.duration_in_minutes,
      description: values?.description,
    });
    onClose();
  };

  return (
    <Modal
      size="sm"
      visible={visible}
      onClose={onClose}
      heading="Create meeting type"
    >
      <div className="px-6 py-6">
        <Formik initialValues={initialValues} onSubmit={_handleCreateMeetingType}>
          {({ values }) => (
            <Form className="space-y-4">
              <Field name="name">
                {({ field }) => (
                  <Input
                    label="Name"
                    {...field}
                  />
                )}
              </Field>
              <Field name="duration_in_minutes">
                {({ field }) => (
                  <Input
                    label="Duration (in minutes)"
                    {...field}
                  />
                )}
              </Field>
              <Field name="description">
                {({ field }) => (
                  <TextArea
                    label="Description (optional)"
                    {...field}
                  />
                )}
              </Field>
              <div className="flex flex-col space-y-3 pt-3 pb-1">
                <Button
                  type="submit"
                  title="Create meeting type"
                  variant="purple"
                  customStyleClasses="w-full"
                  loading={isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default MeetingTypeCreateModal;
