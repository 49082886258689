import React, { Fragment } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { Dialog, Transition } from '@headlessui/react';

function Modal({
  visible,
  heading,
  icon,
  size,
  onClose,
  children,
  showHeader,
  description,
  customStyles,
  closeWhenClickOutside,
  initialFocus,
}) {
  const sizeStyles = size === 'sm' ? 'sm:max-w-sm' : size === 'lg' ? 'sm:max-w-lg' : size === 'xl' ? 'sm:max-w-2xl' : 'sm:max-w-lg';
  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        open={visible}
        onClose={(closeWhenClickOutside && onClose) ? onClose : () => null}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative ${sizeStyles} ${customStyles} bg-white rounded-lg text-left shadow-xl transform transition-all my-8 w-full`}>
                {/* header */}
                {showHeader && (
                  <div className="border-b p-5">
                    <div className="absolute top-0 right-0 pt-4 pr-4 block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-1"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="flex items-center">
                      {icon && icon}
                      <div className={`mt-3 mt-0 text-left ${icon && 'ml-3'}`}>
                        <Dialog.Title as="h3" className="text-lg font-[Inter] font-medium leading-6 text-gray-700">{heading}</Dialog.Title>
                        <p className="text-sm font-[Inter] text-gray-500">{description}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* main content */}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.defaultProps = {
  showHeader: true,
  closeWhenClickOutside: false,
};

export default Modal;
