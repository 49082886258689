import React from 'react';

import { Tabs } from '../../../common';
import { useGetMeetings } from '../../api/queries';
import { localStorageKeys } from '../../../config/constants';

const tabs = [
  { name: 'Upcoming', key: 'upcoming' },
  { name: 'Past', key: 'past' },
];

/* =============================================================================
<MyMeetingsHeader />
============================================================================= */
function MyMeetingsHeader() {
  const userType = localStorage.getItem(localStorageKeys.discreedlyUserType);

  return (
    <div className="">
      <div>
        <h1 className="text-2xl font-bold mb-2 text-gray-900">
          My meetings
        </h1>
        <Tabs
          tabs={tabs}
          link={`/meetings/list/${userType}/`}
          deepLink="/."
          switchToDropdownOnSmallScreens={false}
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MyMeetingsHeader;
