import React from 'react';
import { Field, Form, Formik } from 'formik';

import {
  Button, Input, Modal, SelectDropDown,
} from '../../../common';

import { useCreateSchedule } from '../../api/mutations';
import { useGetFeaturedTimezones } from '../../../auth/api/queries';

function ScheduleCreateModal({ visible, onClose }) {
  const { data: timezones } = useGetFeaturedTimezones();
  const { mutateAsync: createSchedule, isLoading } = useCreateSchedule();

  const _handleCreateSchedule = async (values) => {
    await createSchedule({
      name: values?.name,
      timezone: values?.timezone,
    }).then(() => onClose());
  };

  return (
    <Modal
      size="sm"
      visible={visible}
      onClose={onClose}
      heading="Create a new schedule"
    >
      <div className="px-6 pb-6 ">
        <Formik initialValues={initialValues} onSubmit={_handleCreateSchedule}>
          {({ values }) => (
            <Form className="space-y-4 mt-5">
              <Field name="name">
                {({ field }) => (
                  <Input
                    label="Name"
                    {...field}
                  />
                )}
              </Field>
              <Field name="timezone">
                {({ field }) => (
                  <SelectDropDown
                    label="Timezone"
                    name="timezone"
                    items={timezones}
                    {...field}
                  />
                )}
              </Field>
              <div className="flex pt-2 pb-1">
                <Button
                  type="submit"
                  variant="purple"
                  loading={isLoading}
                  title="Create schedule"
                  customStyleClasses="w-full"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

const initialValues = {
  name: '',
  timezone: '',
};

export default ScheduleCreateModal;
