import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Call History
 */
export const useGetCallHistory = () => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.callHistory(),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`shared/direct-calls/ended?page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};

/**
 * Get Direct Call
 */
export const useGetDirectCall = (callId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.directCall(callId),
    async () => {
      const { data } = await axiosPrivate.get(`shared/direct-calls/${callId}`);
      return data?.data?.direct_call;
    },
    {
      enabled: callId != null,
    },
  );
};

/**
 * Get Group Call
 */
export const useGetGroupCall = (groupCallId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.groupCall(groupCallId),
    async () => {
      const { data } = await axiosPrivate.get(`shared/group-calls/${groupCallId}`);
      return data?.data?.group_call;
    },
    {
      enabled: groupCallId != null,
    },
  );
};

/**
 * Get Group Call Participants
 */
export const useGetGroupCallParticipants = (groupCallId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.groupCallParticipants(groupCallId),
    async () => {
      const { data } = await axiosPrivate.get(`shared/group-calls/${groupCallId}/participants`);
      return data?.data?.participants?.filter((p) => p?.sendbird?.state === 'connected' || p?.sendbird?.state === 'entered');
    },
  );
};
