import { useInfiniteQuery } from '@tanstack/react-query';

import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Events
 */
export const useGetEvents = (filters) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.events(filters),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`hcp/events/targeted?filter[status]=${filters}&sort=start_at&page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};
