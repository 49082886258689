import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import PlaceHolder from '@sendbird/uikit-react/ui/PlaceHolder';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';
import { ChatIcon, SearchIcon } from '@heroicons/react/outline';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import ChatChannelName from '../../ChatChannelName';
import { EmptyState, Spinner } from '../../../../common';
import ChatChannelAvatar from '../../ChatChannelAvatar';
import { getSelectedSendBirdChannelUrl } from '../../../redux/selectors';
import { generalPaths } from '../../../../config/paths';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ChatChannelsList />
============================================================================= */
function ChatChannelsList({ query, onQueryChange }) {
  const { channelUrl } = useParams();
  const navigate = useNavigate();

  const selectedSendBirdChannelUrl = useSelector(getSelectedSendBirdChannelUrl);

  const [channelList, setChannelsList] = useState();
  const {
    allChannels, channelListQuery, initialized, loading,
  } = useChannelListContext();

  useEffect(() => {
    if (allChannels) {
      setChannelsList(allChannels);
    }
  }, [allChannels, channelListQuery]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: channelListQuery?.hasNext,
    onLoadMore: () => {
      channelListQuery?.next(channelListQuery?.token).then(
        (res) => setChannelsList((prev) => [...prev, ...res]),
      );
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !channelListQuery?.hasNext,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 5px 0px',
  });

  const _handleChannelSelect = (selectedChannel) => {
    navigate(generalPaths.chat(selectedChannel?.url));
  };

  if (loading) {
    return (
      <div className="text-center mt-4">
        <Spinner />
      </div>
    );
  }

  if (!initialized && !loading) {
    return <PlaceHolder type="ERROR" />;
  }

  return (
    <>
      {(channelList?.length > 0) && (
        <div className="w-full bg-white lg:space-y-1 lg:py-2 h-full divide-y lg:divide-y-0">
          {channelList?.map((channel) => (
            <button
              key={channel?.url}
              type="button"
              onClick={() => _handleChannelSelect(channel)}
              className={classNames(
                'w-full cursor-pointer block px-6 py-3 lg:px-4 lg:py-3 lg:hover:bg-gray-100 lg:hover:rounded-lg transition-all ease-in-out',
                channel?.url === selectedSendBirdChannelUrl && 'lg:bg-gray-100 lg:rounded-lg',
              )}
            >
              <div className="flex justify-between space-x-2">
                <div className="shrink-0 w-14">
                  <ChatChannelAvatar channel={channel} forChatHeader={false} />
                </div>
                <div className="flex-1 overflow-x-hidden w-full space-y-1">
                  <div className="flex-1 flex space-x-5">
                    <ChatChannelName channel={channel} forChatHeader={false} />
                    {channel?.lastMessage && (
                      <span className="text-xs text-gray-400 leading-4 font-medium shrink-0">
                        {moment(channel?.lastMessage?.createdAt).fromNow()}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between space-x-2">
                    {channel?.lastMessage ? (
                      <span className={classNames(
                        'truncate text-sm leading-5 font-[Inter] ',
                        channel?.unreadMessageCount > 0 ? 'text-gray-700 font-medium' : 'text-gray-500 font-normal',
                      )}>
                        {channel?.lastMessage?.messageType === 'file'
                          ? 'File attachment' : channel?.lastMessage ? channel?.lastMessage?.message : 'Start a conversation'}
                      </span>
                    ) : (
                      <span className={classNames(
                        'truncate text-sm leading-5 font-[Inter] text-gray-500 font-normal',
                      )}>
                        Start a conversation
                      </span>
                    )}
                    {channel?.unreadMessageCount > 0 && (
                      <span className="inline-flex items-center text-right rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-semibold text-blue-800">
                        {channel?.unreadMessageCount}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </button>
          ))}

          {channelListQuery?.hasNext && (
            <div ref={sentryRef} className="text-center py-4">
              <Spinner />
            </div>
          )}
        </div>
      )}

      {(!loading && channelList?.length === 0 && query === '') && (
        <div className="w-full bg-white h-full space-y-1 py-2">
          <div className="text-center justify-center flex h-full">
            <EmptyState
              title="No chats"
              icon={<ChatIcon className="w-9 h-9 text-gray-400" />}
              description="Start a conversation"
            />
          </div>
        </div>
      )}
      {(!loading && channelList?.length === 0 && query !== '') && (
        <div className="w-full bg-white h-full space-y-1 py-2">
          <div className="text-center justify-center flex h-full">
            <EmptyState
              title="No chats found"
              icon={<SearchIcon className="w-9 h-9 text-gray-400" />}
              description="Try searching something else"
            />
          </div>
        </div>
      )}
    </>
  );
}

/* Export
============================================================================= */
export default ChatChannelsList;
