import React, { Fragment, useEffect, useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import Drawer from '../../../Drawer';
import TabsSwitch from '../../../TabsSwitch';
import EmptyState from '../../../EmptyState';
import DashboardNotificationItem from './DashboardNotificationItem';
import { useGetNotifications } from '../../../../notification/api/queries';
import { ReactComponent as NotificationIcon } from '../../../../assets/icons/edit-notification-icon.svg';
import { Spinner } from '../../../index';
import { client as queryClient } from '../../../../config/react-query';
import { queryKeys as authQueryKeys } from '../../../../auth/api/query-key-factory';

function DashboardNotificationSlider({ open, onClose }) {
  const [selectedTab, setSelectedTab] = useState('Unread');

  const {
    data: readNotificationsData,
    isLoading: readNotificationsLoading,
    isSuccess: readNotificationsIsSuccess,
    hasNextPage: readNotificationsHasNextPage,
    fetchNextPage: readNotificationsFetchNextPage,
    isFetchingNextPage: readNotificationsIsFetchingNextPage,
  } = useGetNotifications('read', open);

  const {
    data: unreadNotificationsData,
    isLoading: unreadNotificationsLoading,
    isSuccess: unreadNotificationsIsSuccess,
    hasNextPage: unreadNotificationsHasNextPage,
    fetchNextPage: unreadNotificationsFetchNextPage,
    isFetchingNextPage: unreadNotificationsIsFetchingNextPage,
  } = useGetNotifications('unread', open);

  const tabs = [
    { name: 'Unread', count: unreadNotificationsData?.pages[0]?.pagination?.total },
    { name: 'Read' },
  ];

  useEffect(() => {
    if (!open) { // When the drawer is closed, invalidate the unread notifications count from the user object
      queryClient.invalidateQueries(authQueryKeys.hcpUser());
      queryClient.invalidateQueries(authQueryKeys.mslUser());
    }
  }, [open]);

  const [sentryRef] = useInfiniteScroll({
    loading: selectedTab === 'Unread' ? unreadNotificationsIsFetchingNextPage : readNotificationsIsFetchingNextPage,
    hasNextPage: selectedTab === 'Unread' ? unreadNotificationsHasNextPage : readNotificationsHasNextPage,
    onLoadMore: selectedTab === 'Unread' ? unreadNotificationsFetchNextPage : readNotificationsFetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: selectedTab === 'Unread' ? !unreadNotificationsHasNextPage : !readNotificationsHasNextPage,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 50px 0px',
  });

  return (
    <Drawer heading="Notifications" open={open} onClose={onClose}>
      <div className="flex flex-col w-full overflow-y-scroll">
        <TabsSwitch
          tabs={tabs}
          isActive={selectedTab}
          onChange={setSelectedTab}
          switchToDropdownOnSmallScreens={false}
          contentStyles="px-6"
        />
        {selectedTab === 'Unread' && unreadNotificationsIsSuccess && unreadNotificationsData?.pages?.map((page) => (
          <Fragment key={page?.pagination?.page}>
            {page?.notifications?.map((notification) => (
              <DashboardNotificationItem notification={notification} key={notification.id} />
            ))}
          </Fragment>
        ))}

        {selectedTab === 'Read' && readNotificationsIsSuccess && readNotificationsData?.pages?.map((page) => (
          <Fragment key={page?.pagination?.page}>
            {page?.notifications?.map((notification) => (
              <DashboardNotificationItem notification={notification} key={notification.id} />
            ))}
          </Fragment>
        ))}

        <div className="py-4">
          {(unreadNotificationsLoading || readNotificationsLoading) && (
            <div className="justify-center items-center w-full text-center">
              <Spinner />
            </div>
          )}
          {(selectedTab === 'Unread' ? unreadNotificationsHasNextPage : readNotificationsHasNextPage) && (
            <div ref={sentryRef} className="justify-center items-center w-full text-center">
              <Spinner />
            </div>
          )}
        </div>

        {selectedTab === 'Read' && !readNotificationsLoading && readNotificationsData?.pages[0].pagination.total === 0 && (
          <div className="py-2">
            <EmptyState icon={<NotificationIcon />} title="You have no read notifications" />
          </div>
        )}
        {selectedTab === 'Unread' && !unreadNotificationsLoading && unreadNotificationsData?.pages[0].pagination.total === 0 && (
          <div className="py-2">
            <EmptyState icon={<NotificationIcon />} title="You have no new notifications" />
          </div>
        )}
      </div>
    </Drawer>
  );
}

export default DashboardNotificationSlider;
