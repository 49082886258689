import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChevronRightIcon,
  ClockIcon,
  CalendarIcon,
} from '@heroicons/react/solid';
import moment from 'moment/moment';
import { getUser } from '../../../../../auth/redux/selectors';
import { Avatar } from '../../../../../common';
import MeetingListItemRating from './MeetingListItemRating';
import { setSelectedMeetingId, setShowMeetingInfoDrawer } from '../../../../redux/actions';
import { localStorageKeys, userTypes } from '../../../../../config/constants';
import MeetingRateModal from '../../../MeetingRateModal';

/* =============================================================================
<MeetingsListItem />
============================================================================= */
const MeetingsListItem = ({ meeting, filter, userTimezone }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const [ratingModal, setRatingModal] = useState(false);

  const _toggleRatingModal = () => setRatingModal((prev) => !prev);

  const _handleOpenMeeting = (e) => {
    dispatch(setShowMeetingInfoDrawer(true));
    dispatch(setSelectedMeetingId(meeting?.id));
  };

  const startAt = moment(meeting?.utc_start_at).tz(user?.timezone);
  const endAt = moment(meeting?.utc_end_at).tz(user?.timezone);
  const isUpcoming = moment(meeting?.utc_end_at).isAfter(moment());
  const isOngoing = moment().isBetween(startAt, endAt);
  const isPast = moment(meeting?.utc_end_at).isBefore(moment());
  const isCancelled = meeting?.cancelled_at !== null;

  const startTimeFormatted = moment(meeting?.utc_start_at).tz(user?.timezone).format('h:mma');
  const endTimeFormatted = moment(meeting?.utc_end_at).tz(user?.timezone).format('h:mma');
  const dateFormatted = moment(meeting?.utc_start_at).tz(user?.timezone).format('ddd, MMM Do YYYY');

  return (
    <li key={meeting.id} className="w-full">
      <button type="button" onClick={_handleOpenMeeting} className="block py-6 w-full flex items-center space-y-3 justify-between space-x-5 group z-0">
        <div className="flex flex-col flex-1">
          <div className="flex ml-3">
            {meeting?.participants?.slice(0, 5).map((participant) => (
              <div className="">
                <div className="border border-2 border-white rounded-full -ml-3">
                  <Avatar
                    size="medium"
                    source={participant?.user?.profile_image_path}
                    name={participant?.user?.name}
                    color={participant?.user?.color}
                    tooltipContent={`${participant?.user?.name} (${participant?.user?.user_type?.name})`}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="w-full flex-1 mt-2">
            <div className="flex-1 overflow-hidden w-full">
              <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2.5 sm:space-y-0 overflow-hidden w-full">
                <div className="hover:opacity-75 overflow-hidden max-w-xs sm:max-w-sm">
                  <h3 className="font-semibold text-gray-900 truncate text-left">{meeting?.title}</h3>
                </div>
                <div className="text-left text-sm text-gray-500 font-medium font-[Inter] w-32 sm:w-auto shrink-0">
                  {isCancelled ? (
                    <div className="bg-red-100 text-xs flex justify-center rounded-full text-red-600 py-1.5 sm:py-1 px-3">
                      Canceled
                    </div>
                  ) : isOngoing ? (
                    <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-3">
                      Ongoing
                    </div>
                  ) : isUpcoming ? (
                    <div className="bg-green-100 text-xs flex justify-center rounded-full text-green-600 py-1.5 sm:py-1 px-3">
                      Upcoming
                    </div>
                  ) : isPast ? (
                    <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-3">
                      Ended
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="w-full mt-5 sm:mt-2">
                <dl className="mt-2 text-gray-500 flex sm:flex-row flex-col w-fit sm:space-x-3 space-y-3 sm:space-y-0">
                  <div className="flex space-x-2 overflow-hidden">
                    <dt className="mt-0.5">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <span className="truncate">{dateFormatted}</span>
                  </div>
                  <div className="flex space-x-2">
                    <dt className="mt-0.5">
                      <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>{`${startTimeFormatted} - ${endTimeFormatted}`}</dd>
                  </div>
                </dl>
              </div>
              {(userType === userTypes.hcp.key && isPast && !isCancelled) && (
                <div className="mt-3">
                  <MeetingListItemRating
                    meeting={meeting}
                    toggleRatingModal={_toggleRatingModal}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="shrink-0">
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-700 shrink-0"
            aria-hidden="true"
          />
        </div>
      </button>
      <MeetingRateModal
        meeting={meeting}
        visible={ratingModal}
        onClose={_toggleRatingModal}
      />
    </li>
  );
};

/* Export
============================================================================= */
export default MeetingsListItem;
