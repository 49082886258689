import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import useSbCalls from '../../lib/context/useSbCalls';
import { getIsAuthenticated, getUser } from '../../../auth/redux/selectors';

const SendBirdCallsAuthenticator = ({ user, isAuthenticated }) => {
  const { isAuthenticated: sendBirdIsAuthenticated, initAndAuthSendbirdCalls } = useSbCalls();

  useEffect(() => {
    if (isAuthenticated && user && !sendBirdIsAuthenticated) {
      initAndAuthSendbirdCalls(user?.send_bird_user?.id, user?.send_bird_user?.access_token);
    }

    // TODO: unauthenticate and clear calls on unmount?
  }, [user, isAuthenticated, initAndAuthSendbirdCalls, sendBirdIsAuthenticated]);

  return null;
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

// Don't re-render unless user?.id or isAuthenticated changes
const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
  && prevProps.isAuthenticated === nextProps.isAuthenticated
);

export default connect(mapStateToProps)(React.memo(SendBirdCallsAuthenticator, propsAreEqual));
