import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import GroupCallScreen from './GroupCallScreen';
import DirectCallScreen from './DirectCallScreen';
import CallHistoryScreen from './CallHistoryScreen';
import HcpInitiateCallWithMslScreen from './HcpInitiateCallWithMslScreen';
import CallEndedScreen from './CallEndedScreen';
import MslInitiateCallWithHcpScreen from './MslInitiateCallWithHcpScreen';
import VOIPCallScreen from './VOIPCallScreen';
import GroupCallJoinScreen from './GroupCallJoinScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<CallsScreens />
============================================================================= */
function CallsScreens() {
  return (
    <Routes>
      <Route path="calls">
        <Route element={<AuthenticatedRoute showMainDashboardLayout={false} allowHcp={false} allowMsl />}>
          <Route path="msl/direct/:callId" element={<DirectCallScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute showMainDashboardLayout={false} allowHcp allowMsl={false} />}>
          <Route path="hcp/direct/:callId/:prescreenerResponseId?" element={<DirectCallScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute showMainDashboardLayout={false} />}>
          <Route path=":callId/ended" element={<CallEndedScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute showMainDashboardLayout={false} allowHcp allowMsl={false} />}>
          <Route path=":products/:productId/hotline" element={<VOIPCallScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute />}>
          <Route path="history" element={<CallHistoryScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="hcp/initiate/:mslId" element={<HcpInitiateCallWithMslScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute allowHcp={false} allowMsl />}>
          <Route path="msl/initiate/:hcpId" element={<MslInitiateCallWithHcpScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute showMainDashboardLayout={false} />}>
          <Route path="group/:groupCallId" element={<GroupCallScreen />} />
          <Route path="group/:groupCallId/join" element={<GroupCallJoinScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default CallsScreens;
