import React, { useId } from 'react';

/* =============================================================================
<RadioGroup />
============================================================================= */
function RadioGroup({
  value, options, onChange,
}) {
  const id = useId();

  return (
    <div className="space-y-4 w-full">
      {options.map((option) => (
        <div key={option.id} className="relative flex items-start w-full">
          <div className="flex h-5 items-center shrink-0">
            <input
              id={option.id}
              name={id}
              type="radio"
              checked={value === option.id}
              onChange={onChange}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
          </div>
          <div className="ml-3 text-sm w-full flex-1 overflow-x-hidden">
            <div className="">
              <label htmlFor={option.id} className=" text-sm font-medium text-gray-700 flex-1">
                {option.title}
              </label>
              {option?.description && <p className="text-gray-500 truncate">{option?.description}</p>}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

/* Export
============================================================================= */
export default RadioGroup;
