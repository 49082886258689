import React from 'react';
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

function Toast({
  status, title, description, onClose,
}) {
  return (
    <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {status === 'error' ? (
              <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
            ) : status === 'success' ? (
              <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
            ) : status === 'warn' ? (
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
            ) : status === 'info' ? (
              <ExclamationCircleIcon className="h-6 w-6 text-sky-400" aria-hidden="true" />
            ) : null}
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-[Inter] font-medium text-gray-900">
              {title}
            </p>
            {description && (
            <p className="mt-1 font-[Inter] text-sm text-gray-500">{description}</p>
            )}
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              type="button"
              className="bg-white rounded-md font-[Inter] inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Toast;
