import React from 'react';
import { connect } from 'react-redux';
import { useChannel } from '@harelpls/use-pusher';

import { getUser } from '../../auth/redux/selectors';

/* =============================================================================
<OnlineStatusListener />
============================================================================= */
function OnlineStatusListener({ user }) {
  const userId = user?.id;

  const channelName = userId ? `private-users.${userId}.online-state-listener` : null;

  useChannel(channelName);

  return null;
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(OnlineStatusListener);
