import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { getInitials } from '../util/functions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<Avatar />
============================================================================= */
function Avatar({
  border, color, source, name, size, tooltipContent, tooltipPosition, showFullName, customContainerStyles, customStyles,
}) {
  const id = useId();
  const extraLarge = 'h-24 w-24 sm:h-32 sm:w-32 xs:h-12';
  const large = 'h-16 w-16 sm:h-20 sm:w-20 xs:h-12';
  const extraMedium = 'h-12 w-12 sm:h-14 sm:w-14 xs:h-14';
  const medium = 'h-10 w-10 sm:h-12 sm:w-12 xs:h-12';
  const small = 'h-8 w-8 sm:h-8 sm:w-8 xs:h-12';

  return (
    <div
      className={classNames('flex', customContainerStyles)}
      id={id}
      data-tooltip-content={tooltipContent}
      data-tooltip-place={tooltipPosition}
    >
      {tooltipContent && <Tooltip anchorId={id} />}
      {source ? (
        <div
          className={
            classNames(
              size === 'extraLarge' && extraLarge,
              size === 'large' && large,
              size === 'extraMedium' && extraMedium,
              size === 'medium' && medium,
              size === 'small' && small,
              border && 'ring-4 ring-white',
              'rounded-full overflow-hidden',
              customStyles,
            )
          }
        >
          <img
            src={source}
            alt=""
            className={
              classNames(
                size === 'extraLarge' && extraLarge,
                size === 'large' && large,
                size === 'extraMedium' && extraMedium,
                size === 'medium' && medium,
                size === 'small' && small,
              )
            }
          />
        </div>
      ) : (
        <div
          style={{ backgroundColor: color }}
          className={
            classNames(
              size === 'extraLarge' && extraLarge,
              size === 'large' && large,
              size === 'extraMedium' && extraMedium,
              size === 'medium' && medium,
              size === 'small' && small,
              border && 'ring-4 ring-white',
              'rounded-full flex justify-center items-center',
              customStyles,
            )
          }
        >
          <span
            className={classNames(
              size === 'extraLarge' && 'text-3xl',
              size === 'large' && 'text-2xl',
              size === 'small' && 'text-xs',
              'font-semibold leading-none text-white select-none',
            )}
          >
            {showFullName ? name : getInitials(name)}
          </span>
        </div>
      )}
    </div>
  );
}

Avatar.defaultProps = {
  size: 'large',
  name: '',
  color: '#4F46E5',
  tooltipPosition: 'bottom',
  showFullName: false,
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['extraLarge', 'large', 'extraMedium', 'medium', 'small']),
  name: PropTypes.string,
  color: PropTypes.string,
  tooltipPosition: PropTypes.string,
  showFullName: PropTypes.bool,
};

/* Export
============================================================================= */
export default Avatar;
