import React from 'react';
import { useSelector } from 'react-redux';

import MslDashboardHeader from '../components/MslDashboardHeader';
import MslDashboardOverview from '../components/MslDashboardOverview';
import MslDashboardPerformance from '../components/MslDashboardPerformance';

import { getUser } from '../../auth/redux/selectors';

/* =============================================================================
<MslDashboardScreen />
============================================================================= */
function MslDashboardScreen() {
  const user = useSelector(getUser);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 md:py-8 space-y-8">
      <MslDashboardHeader
        profileImage={user?.profile_image_path}
        name={user?.name}
        color={user?.color}
      />
      <MslDashboardOverview />
      <MslDashboardPerformance />
    </div>
  );
}
/* Export
============================================================================= */
export default MslDashboardScreen;
