import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../auth/redux/reducer';
import chatReducer from '../chat/redux/reducer';
import meetingsReducer from '../meeting/redux/reducer';

const store = configureStore({
  reducer: {
    Auth: authReducer,
    Chat: chatReducer,
    Meetings: meetingsReducer,
  },
});

export default store;
