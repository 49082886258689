import React, { useCallback } from 'react';
import { PhoneIcon, VideoCameraIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import {
  Modal, Avatar, CallActionButton, AnimatedDotDotDot, Spinner,
} from '../../../common';
import { ReactComponent as PhoneEndIcon } from '../../../assets/icons/edit-phone-icon.svg';
import { localStorageKeys, userTypes } from '../../../config/constants';
import { useSbCalls } from '../../lib/context';

/* =============================================================================
<CallRingingModal />
============================================================================= */
function CallRingingModal({ visible, onClose }) {
  const navigate = useNavigate();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const sbCalls = useSbCalls();
  const { calls } = sbCalls;
  const ringingCalls = calls ? calls.filter((callItem) => callItem.myRole === 'dc_callee' && callItem.callState === 'ringing' && callItem.isOngoing === true) : null;
  const ringingCall = ringingCalls ? ringingCalls[ringingCalls.length - 1] : null;

  const {
    callId,
    isVideoCall,
    stopVideo,
    end,
    accept,
    remoteUser,
  } = ringingCall || {};

  const acceptCall = useCallback((videoEnabled) => {
    // TODO: try accepting call if it's ended already, shouldn't be showing this modal, but just in case
    // TODO: or if call was disconnected from the callee in the meantime, but SendBird hasn't yet told the callee that the call was ended (delayed response)

    // This is necessary, as otherwise it shows local video as active, but with a black screen for the video
    if (!videoEnabled) {
      stopVideo();
    }

    accept({
      callOption: {
        audioEnabled: true,
        videoEnabled,
      },
    });

    navigate(userType === userTypes.hcp.key ? `/calls/hcp/direct/${callId}` : `/calls/msl/direct/${callId}`);
  }, [accept, navigate, userType, callId, stopVideo]);

  const endCall = useCallback(() => {
    onClose();
    end();
  }, [end, onClose]);

  return (
    <Modal
      size="sm"
      showHeader={false}
      visible={visible}
      onClose={() => null}
      customStyles="bg-gray-700 p-8"
    >
      {ringingCall ? (
        <div className="flex flex-col items-center space-y-5">
          <div>
            <Avatar
              border
              size="large"
              source={remoteUser?.profileUrl}
              name={remoteUser?.nickname}
            />
          </div>
          <div className="text-center">
            <h1 className="text-2xl text-white font-semibold mb-1">{remoteUser?.nickname}</h1>
            {/* Don't show if it's a video call or audio call, as will always be a video call,
            just video may be off, this means screen share will be an option */}
            <AnimatedDotDotDot customStyles="text-white text-sm" prefixText="Incoming call" />
          </div>
          <div className="flex w-fit space-x-3 bg-gray-600 p-3 rounded-full justify-center items-center m-auto block">
            {isVideoCall ? (
              <>
                <CallActionButton
                  variant="green"
                  icon={<VideoCameraIcon className="w-5 h-5 text-white" />}
                  onClick={() => acceptCall(true)}
                  tabIndex={-1}
                />
                <CallActionButton
                  variant="green"
                  icon={<PhoneIcon className="w-5 h-5 text-white" />}
                  onClick={() => acceptCall(false)}
                  tabIndex={-1}
                />
              </>
            ) : (
              <CallActionButton
                variant="green"
                icon={<PhoneIcon className="w-5 h-5 text-white" />}
                onClick={() => acceptCall(false)}
                tabIndex={-1}
              />
            )}
            <CallActionButton
              variant="red"
              icon={<PhoneEndIcon />}
              onClick={() => endCall()}
              tabIndex={-1}
            />
          </div>
        </div>
      ) : (
        <div className="text-center hereitisfortesting">
          <Spinner color="white" />
        </div>
      )}
    </Modal>
  );
}

/* Export
============================================================================= */
export default CallRingingModal;
