import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChevronLeftIcon, PhoneIcon } from '@heroicons/react/solid';
import SendBirdCall from 'sendbird-calls';
import { EmojiSadIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import Avatar from '../../common/Avatar';
import Button from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import { useSbCalls } from '../lib/context';
import { getUser } from '../../auth/redux/selectors';
import Spinner from '../../common/Spinner';
import { useGetGroupCall } from '../api/queries';
import { EmptyState } from '../../common';
import Toast from '../../common/Toast';

/* =============================================================================
<GroupCallJoinScreen />
============================================================================= */
function GroupCallJoinScreen({ user }) {
  const navigate = useNavigate();
  const { groupCallId } = useParams();
  const { fetchRoomById } = useSbCalls();

  const [isJoiningRoom, setIsJoiningRoom] = useState(false);

  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);

  const videoRef = useRef(null);

  const _toggleVideoEnabled = () => setVideoEnabled((prev) => !prev);
  const _toggleAudioEnabled = () => setAudioEnabled((prev) => !prev);

  const { data: groupCallData, status: groupCallStatus } = useGetGroupCall(groupCallId);

  const discreedlyGroupCallId = groupCallData?.id;
  const sendBirdRoomId = groupCallData?.send_bird_room_id;
  const isVideoCall = groupCallData && groupCallData?.group_call_type?.key === 'video-call';

  const _handleJoinRoom = async () => {
    if (!sendBirdRoomId || !discreedlyGroupCallId) {
      return;
    }

    setIsJoiningRoom(true);

    await fetchRoomById(sendBirdRoomId).then((fetchedRoom) => {
      if (fetchedRoom && fetchedRoom?.roomId) {
        const params = isVideoCall ? {
          audioEnabled,
          videoEnabled,
        } : {
          audioEnabled,
        };

        fetchedRoom.enter(params).then(() => {
          navigate(`/calls/group/${discreedlyGroupCallId}`);
        }).catch((error) => {
          toast.custom((toastProps) => (
            <Toast
              status="error"
              title={error?.message}
              onClose={() => toast.remove(toastProps.id)}
            />
          ));
        });
      }
    }).catch((error) => {
      toast.custom((toastProps) => (
        <Toast
          status="error"
          title={error?.message}
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    }).finally(() => {
      setIsJoiningRoom(false);
    });
  };

  useEffect(() => {
    if (!videoEnabled || !isVideoCall || groupCallStatus !== 'success') return () => {};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mediaAccess = SendBirdCall.useMedia({ audio: true, video: videoEnabled });

    mediaAccess.on('streamChanged', (stream) => {
      if (videoRef.current) videoRef.current.srcObject = stream;
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (videoRef.current) videoRef.current.srcObject = null;
      mediaAccess?.dispose();
    };
  }, [videoEnabled, isVideoCall, groupCallStatus]);

  if (groupCallStatus === 'loading') {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!groupCallData && groupCallStatus !== 'loading') {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="Group call not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="The requested group call was not found"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col items-center p-6">
          <h2 className="text-xs text-gray-400 uppercase font-semibold font-[Inter] tracking-wide mt-2">GROUP CALL</h2>
          <h1 className="text-2xl text-gray-700 font-bold font-[Inter] mt-1 mb-8 text-center">Join a group call</h1>
          <div className="w-full max-w-2xl space-y-4">
            <div className="w-full space-y-6 p-6 bg-white border shadow-sm rounded-lg">
              <div className="relative justify-center items-center flex bg-gray-600 overflow-hidden rounded-lg">
                {(videoEnabled && isVideoCall) && (
                  <div className="h-full w-full">
                    <video
                      ref={videoRef}
                      autoPlay
                      playsInline
                      muted
                      className="h-full w-full"
                    />
                  </div>
                )}
                {(!videoEnabled || !isVideoCall) && (
                  <div className="py-24">
                    <Avatar
                      source={user?.profile_image_path || null}
                      color={user?.color}
                      name={user?.name}
                      border
                      size="extraLarge"
                    />
                  </div>
                )}
              </div>
              <div className="mt-6 space-y-4">
                <Checkbox
                  name="audio-only"
                  checked={audioEnabled}
                  color="indigo"
                  label="Microphone"
                  onChange={_toggleAudioEnabled}
                />
                {isVideoCall && (
                  <Checkbox
                    name="video-call"
                    checked={videoEnabled}
                    color="indigo"
                    label="Video"
                    onChange={_toggleVideoEnabled}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-6 lg:px-8 py-6 bg-white justify-between sm:items-center border-t flex w-full sm:space-x-10">
        <div className="flex-1 flex flex-col items-start hidden sm:flex">
          <Button
            title="Back"
            variant="secondary"
            icon={<ChevronLeftIcon className="w-4 h-4 text-black" />}
            onClick={() => navigate(-1)}
            customStyleClasses="w-full sm:w-24"
          />
        </div>
        <div className="flex-1 flex flex-col sm:items-end">
          <Button
            variant="green"
            iconPosition="left"
            icon={<PhoneIcon className="w-4 h-4 text-white" />}
            title="Join call"
            customStyleClasses="w-full sm:w-48"
            onClick={_handleJoinRoom}
            loading={isJoiningRoom}
            disabled={isJoiningRoom}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(GroupCallJoinScreen);
