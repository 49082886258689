import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/solid';

import ChatChannelAvatar from '../../ChatChannelAvatar';
import ChatChannelName from '../../ChatChannelName';
import { localStorageKeys, userTypes } from '../../../../config/constants';
import { hcpPaths, mslPaths } from '../../../../config/paths';

/* =============================================================================
<ChatChannelHeaderMembersInfo />
============================================================================= */
function ChatChannelHeaderMembersInfo({ channel, userAvailability }) {
  const navigate = useNavigate();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const _handleBackClick = () => {
    navigate(userType === userTypes.hcp.key ? hcpPaths.chats() : mslPaths.chats());
  };

  return (
    <div className="flex px-5 w-full">
      <button type="button" className="pr-3 block lg:hidden" onClick={_handleBackClick}>
        <ChevronLeftIcon className="w-6 h-6 text-gray-500" />
      </button>
      <div className="flex items-center flex-1">
        <div className="shrink-0 w-16">
          <ChatChannelAvatar channel={channel} />
        </div>
        <ChatChannelName channel={channel} forChatHeader userAvailability={userAvailability} />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatChannelHeaderMembersInfo;
