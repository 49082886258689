import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import ProductPlaceholderImg from '../../assets/icons/calendar-icon-large.png';
import {
  Avatar, Button, Modal, Spinner, TextArea,
} from '../../common';
import { useRateMeeting } from '../api/mutations';

/* =============================================================================
<MeetingRateModal />
============================================================================= */
function MeetingRateModal({
  visible, meeting, onClose, onSubmitSuccess,
}) {
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState('');

  const { mutate: rateMeeting, isLoading } = useRateMeeting();

  const meetingId = meeting?.id;

  const _handleSubmit = () => {
    rateMeeting({
      rating,
      comments,
      meetingId,
    }, {
      onSuccess: () => {
        setRating(null);
        setComments('');
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
        onClose();
      },
    });
  };

  return (
    <Modal
      showHeader={false}
      visible={visible}
      onClose={onClose}
      customStyles="flex flex-col overflow-visible px-8 items-center"
    >
      {meeting ? (
        <>
          <div className="-mt-9">
            <Avatar
              border
              size="large"
              source={ProductPlaceholderImg}
              name="Meeting"
            />
          </div>
          <h1 className="text-lg text-center text-gray-700 mt-8 mb-2 font-medium">How was your interaction?</h1>
          <ReactStars
            count={5}
            size={35}
            value={rating}
            onChange={(r) => setRating(r)}
            activeColor="#FBBF24"
            color="#E5E7EB"
          />
          <div className="mt-6 w-full space-y-2 sm:space-y-0">
            <h3 className="font-[Inter] text-sm mb-1 font-medium text-gray-700 col-span-3 sm:col-span-1">
              Comments
              <span className="text-gray-400"> (optional)</span>
            </h3>
            <div className="flex flex-col col-span-3 sm:col-span-2 items-stretch">
              <TextArea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full items-center border-t mt-5 py-5">
            <Button
              type="submit"
              title="Submit feedback"
              variant="purple"
              loading={isLoading}
              onClick={_handleSubmit}
              customStyleClasses="w-full h-10 mb-3"
            />
            <Button
              type="submit"
              title="Cancel"
              variant="secondary"
              onClick={onClose}
              customStyleClasses="w-full h-10 mb-3"
            />
          </div>
        </>
      ) : (
        <div className="py-10">
          <Spinner />
        </div>
      )}
    </Modal>
  );
}

/* Export
============================================================================= */
export default MeetingRateModal;
