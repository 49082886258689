import moment from 'moment-timezone';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avatar, Button, Modal } from '../../common';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/check-circle.svg';
import { getUser } from '../../auth/redux/selectors';
import { localStorageKeys } from '../../config/constants';

function MeetingScheduledModal({
  meeting, visible, onClose, user,
}) {
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  const navigate = useNavigate();

  const _handleDoneClick = () => navigate(`/meetings/list/${userType}/upcoming`);

  const userTimezone = user?.timezone;

  const date = moment(meeting?.utc_start_at).tz(userTimezone).format('dddd, MMM D YYYY');
  const startTime = moment(meeting?.utc_start_at).tz(userTimezone).format('h:mma');
  const endTime = moment(meeting?.utc_end_at).tz(userTimezone).format('h:mma');

  const participants = meeting?.participants;

  return (
    <Modal
      size="sm"
      showHeader={false}
      visible={visible}
      onClose={onClose}
    >
      <div className="p-6 space-y-6">
        <CheckCircleIcon width={50} height={50} />
        <p className="text-lg font-medium leading-6 text-gray-900">Meeting confirmed</p>
        <div className="space-y-5">
          <div className="flex flex-col space-y-3">
            <p className="text-sm text-gray-400">Participants</p>
            <div className="space-y-3 flex-1">
              {participants?.map((participant) => (
                <div className="flex items-center space-x-2.5">
                  <Avatar
                    color={participant?.user?.color}
                    name={participant?.user?.name}
                    size="small"
                    source={participant?.user?.profile_image_path}
                  />
                  <p className="text-sm text-gray-600 font-medium">
                    {participant?.user?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-sm text-gray-400">Date and time</p>
            <p className="text-sm text-gray-600 font-medium">{`${date} • ${startTime} - ${endTime}`}</p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-sm text-gray-400">Title</p>
            <p className="text-sm text-gray-600 font-medium">{meeting.title}</p>
          </div>
        </div>
        <Button title="View upcoming meeting" size="block" variant="purple" onClick={_handleDoneClick} />
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(MeetingScheduledModal);
