import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Alert, Button } from '../../common';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/edit-chevron-left-icon.svg';

/* =============================================================================
<PasswordResetRequestSuccessScreen />
============================================================================= */
function PasswordResetRequestSuccessScreen() {
  const navigate = useNavigate();
  const params = useParams();

  const _handleBackToSignInClick = () => {
    navigate(-2);
  };

  return (
    <div>
      <h2 className="text-3xl mb-3 font-extrabold font-[Inter] text-gray-900">
        You will receive an email shortly.
      </h2>
      <Alert status="info" title={`An email with instructions to reset your password has been sent to ${params?.email}`} />
      <Button icon={<ChevronLeftIcon />} customStyleClasses="mt-3 w-full" onClick={_handleBackToSignInClick} title="Back to sign in" variant="secondary" />
    </div>
  );
}

/* Export
============================================================================= */
export default PasswordResetRequestSuccessScreen;
