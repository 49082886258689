import { EmojiSadIcon } from '@heroicons/react/outline';
import React, { useState, useEffect } from 'react';

import { EmptyState, ProductCard, Spinner } from '../../common';

import { useGetProductKnowledge } from '../../products/api/queries';

/* =============================================================================
<ManageProfileProductKnowledge />
============================================================================= */
function ManageProfileProductKnowledge() {
  const { data: productKnowledgeData, isLoading, isSuccess } = useGetProductKnowledge();

  if (!productKnowledgeData?.length > 0 && isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!productKnowledgeData?.length > 0 && !isLoading) {
    return (
      <div className="text-center py-8">
        <EmptyState
          title="No products found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="No products found"
        />
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h2 className="font-[Inter] text-lg font-semibold text-gray-700">Product knowledge</h2>
      <p className="font-[Inter] text-sm text-gray-500">Products that you can provide advice on for healthcare providers</p>
      <div className="w-full h-1 border-b mt-5" />
      <ul className="divide-y divide-gray-200 w-full">
        {productKnowledgeData?.map((productKnowledge) => (
          <li className="py-5 sm:py-6">
            <ProductCard
              key={productKnowledge?.id}
              product={productKnowledge?.product}
              showCompany={false}
              showActions={false}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

/* Export
============================================================================= */
export default ManageProfileProductKnowledge;
