import React from 'react';
import Linkify from 'react-linkify';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function MultiLineParagraph({
  content, textStyles, containerStyles, linked,
}) {
  const paragraphs = content.split('\n');

  return (
    <div className={classNames(
      containerStyles,
      linked && 'link-container',
      'space-y-2',
    )}>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className={classNames('leading-6', textStyles)}>
          {linked ? (
            <Linkify>
              {paragraph}
            </Linkify>
          ) : paragraph}
        </p>
      ))}
    </div>
  );
}

MultiLineParagraph.defaultProps = {
  linked: true,
};

export default MultiLineParagraph;
