import React, { useState } from 'react';
import ProductPlaceholderImg from '../../../assets/icons/placeholder-product-image-large.png';
import { ReactComponent as NotificationActive } from '../../../assets/icons/edit-notification-unmarked-icon.svg';
import { Avatar } from '../../../common';
import ProductLiveUpdates from '../ProductLiveUpdates';
import { useGetProductUpdates } from '../../api/queries';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<AvatarWithLiveUpdates />
============================================================================= */
function AvatarWithLiveUpdates({ product }) {
  const [showStoriesModal, setShowStoriesModal] = useState(false);
  const { data: productUpdates, status: productUpdatesStatus } = useGetProductUpdates(product?.id);
  const hasUnseenUpdates = productUpdatesStatus === 'success' && productUpdates?.find((update) => !update?.has_user_viewed);
  const hasAnyUpdates = productUpdatesStatus === 'success' && productUpdates?.length > 0;

  return (
    <>
      <button
        type="button"
        className={classNames(
          'flex flex-col items-center group',
          hasAnyUpdates ? 'cursor-pointer' : 'cursor-default',
        )}
        onClick={() => setShowStoriesModal(true)}
      >
        <div
          className={classNames(
            'flex justify-center items-center relative rounded-full transition-all ease-in-out hover:ring-4 border-white ring-4 ring-white',
            hasAnyUpdates ? 'hover:ring-yellow-400' : '',
          )}
        >
          <div className={classNames(hasUnseenUpdates ? '' : '', 'w-full h-full max-w-xs rounded-full overflow-hidden')}>
            <Avatar
              source={product?.main_image_path || ProductPlaceholderImg}
              name={product?.name}
              size="extraLarge"
              border
            />
          </div>
          {hasUnseenUpdates && (
            <span className="absolute top-1 right-1 sm:top-2 sm:right-2">
              <span className="flex h-6 w-6">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75" />
                <NotificationActive className="h-6 w-6" />
              </span>
            </span>
          )}
        </div>
      </button>
      <ProductLiveUpdates
        productId={product?.id}
        showModal={showStoriesModal}
        setShowModal={setShowStoriesModal}
      />
    </>
  );
}

/* Export
============================================================================= */
export default AvatarWithLiveUpdates;
