import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import {
  Button, Input, Alert, Spinner,
} from '../../common';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open-icon.svg';
import { ReactComponent as EyeCloseIcon } from '../../assets/icons/eye-close-icon.svg';
import { setIsAuthenticated } from '../redux/actions';
import { useAcceptMslInvite } from '../api/mutations';
import { useMslInviteDetails } from '../api/queries';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<MslInviteAcceptScreen />
============================================================================= */
function MslInviteAcceptScreen() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  const {
    data: inviteDetails,
    error: inviteDetailsError,
    status: getInviteDetailsStatus,
  } = useMslInviteDetails({ token });

  const {
    mutate: acceptInvite,
    isLoading: acceptInviteLoading,
  } = useAcceptMslInvite();

  const email = inviteDetails?.email;
  const companyName = inviteDetails?.pharma_company?.name;

  const _togglePassword = () => setPasswordToggle((prev) => !prev);
  const _toggleConfirmPassword = () => setConfirmPasswordToggle((prev) => !prev);

  const _handleSubmit = (values) => {
    acceptInvite({
      ...values,
      invite_token: token,
    }, {
      onSuccess: async (payload) => {
        localStorage.setItem(localStorageKeys.discreedlyAccessToken, payload?.data.data?.access_token);
        localStorage.setItem(localStorageKeys.discreedlyUserType, 'msl');
        dispatch(setIsAuthenticated(true));
      },
    });
  };

  return (
    <div>
      {getInviteDetailsStatus === 'error' && (
        <div className="my-5">
          <Alert
            status="error"
            title={inviteDetailsError?.message?.title || 'Error'}
          />
        </div>
      )}

      {getInviteDetailsStatus === 'loading' && (
        <div className="flex my-6">
          <Spinner />
        </div>
      )}
      {getInviteDetailsStatus === 'success' && (
        <>
          <div className="my-5">
            <h2 className="text-md mb-3 font-semibold font-[Inter] text-gray-400">
              YOU HAVE BEEN INVITED TO
            </h2>
            <h2 className="text-3xl mb-5 font-extrabold font-[Inter] text-gray-900">
              {companyName}
            </h2>
            <Alert
              status="info"
              title={`Please create a password for your account: ${email}`}
            />
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={_handleSubmit}
          >
            {() => (
              <Form className="space-y-6">
                <Field name="password">
                  {({ field }) => (
                    <Input
                      label="Create your password"
                      type={!passwordToggle ? 'password' : 'text'}
                      icon={passwordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                      onIconClick={_togglePassword}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="password_confirmation">
                  {({ field }) => (
                    <Input
                      label="Confirm your password"
                      type={!confirmPasswordToggle ? 'password' : 'text'}
                      icon={confirmPasswordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                      onIconClick={_toggleConfirmPassword}
                      {...field}
                    />
                  )}
                </Field>
                <Button
                  type="submit"
                  size="block"
                  title="Accept Invite"
                  disabled={!!inviteDetailsError}
                  loading={acceptInviteLoading}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

const initialValues = {
  password: '',
  password_confirmation: '',
};

/* Export
============================================================================= */
export default MslInviteAcceptScreen;
