import React from 'react';
import Spinner from './Spinner';

function Checkbox({
  name,
  label,
  height,
  width,
  checked,
  color,
  loading,
  onChange,
  disabled,
  spinnerSize,
  description,
}) {
  return (
    <button type="button" className="relative flex items-center">
      <div className="flex items-center">
        {loading ? (
          <div className="-mt-2">
            <Spinner size={spinnerSize} color="#FC6061" />
          </div>
        ) : (
          <input
            type="checkbox"
            name={name}
            id={name}
            onChange={onChange}
            checked={checked}
            disabled={disabled || loading}
            className={`${checked ? `focus:ring-${color}-500` : 'focus:ring-gray-300'} ${height} ${width} text-${color}-600 border-gray-300 rounded ${
              disabled && 'opacity-50 bg-gray-100'
            }`}
          />
        )}
      </div>

      {label && (
        <label
          htmlFor={name}
          className="ml-4 -mt-0.5 block text-sm  font-[Inter] font-medium text-gray-900"
        >
          {label}
        </label>
      )}

      {label && description && (
        <div className="ml-2 text-sm -mt-0.2">
          <label htmlFor={name} className="font-medium text-gray-900">
            {label}
          </label>
          {description && <p className="text-gray-500">{description}</p>}
        </div>
      )}
    </button>
  );
}

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
