import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from './Button';

function Notfound() {
  const navigate = useNavigate();

  const _handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="h-full absolute top-0 left-0 right-0">
      <div className="bg-white min-h-full py-16 sm:py-24 md:grid md:place-items-center">
        <div className="max-w-max mx-auto">
          <p className="text-center tracking-tight font-bold text-primary-600">404</p>
          <main className="sm:flex">
            <div className="">
              <div className="">
                <h1 className="text-4xl text-center font-bold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
                <p className="mt-2 text-center text-base text-gray-500">Sorry we couldn’t find the page you’re looking for.</p>
              </div>
              <div className="mt-5 flex justify-center space-x-3">
                <Button title="Go back home" variant="secondary" onClick={_handleGoBack} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Notfound;
