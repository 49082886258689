import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// THIS TAB SWITCH ON STATE AND THE OTHER ONE SWITCH ON URL

function TabsSwitch({
  tabs, isActive, left, containerStyles, contentStyles, onChange, switchToDropdownOnSmallScreens = true,
}) {
  return (
    <div className={containerStyles}>
      <div className={classNames(switchToDropdownOnSmallScreens ? 'md:hidden' : 'hidden')}>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onClick={(e) => onChange(e.target.value)}
        >
          {tabs.map((tab) => (
            <option value={tab.name} key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className={classNames(switchToDropdownOnSmallScreens ? 'hidden md:block' : 'block')}>
        <div className={classNames('border-b border-gray-200 flex items-center justify-between', contentStyles)}>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div key={tab.name} className="flex items-center">
                <button
                  key={tab.name}
                  type="button"
                  className={classNames(
                    isActive === tab.name
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap flex justify-between py-4 px-1.5 border-b-2 font-[Inter] font-medium text-sm',
                  )}
                  aria-current={isActive === tab.name ? 'page' : undefined}
                  onClick={() => onChange(tab.name)}
                >
                  <p className={tab?.count > 0 ? 'mr-2' : ''}>{tab.name}</p>
                  {tab?.count && tab?.count > 0 ? (
                    <div className={classNames(
                      isActive === tab.name
                        ? 'bg-indigo-100 text-indigo-500 '
                        : 'bg-gray-100 text-gray-500',
                      'font-[Inter] flex justify-center items-center font-medium text-xs ml-1 rounded-full  w-8 h-5',
                    )}>
                      {tab?.count}
                    </div>
                  ) : null}
                </button>
              </div>
            ))}
          </nav>
          {left}
        </div>
      </div>
    </div>
  );
}

export default TabsSwitch;
