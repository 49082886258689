import React from 'react';
import { InformationCircleIcon, XCircleIcon, ExclamationIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Alert({ title, status, description }) {
  return (
    <div
      className={classNames(
        status === 'error' && 'bg-red-50',
        status === 'warning' && 'bg-yellow-50',
        status === 'info' && 'bg-blue-50',
        'rounded-md p-4',
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {status === 'error' && (<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />)}
          {status === 'warning' && (<ExclamationIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />)}
          {status === 'info' && (<InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />)}
        </div>
        <div className="ml-3">
          <h3
            className={classNames(
              status === 'error' && 'text-red-800',
              status === 'warning' && 'text-yellow-800',
              status === 'info' && 'text-blue-700',
              'text-sm font-medium font-[Inter]',
            )}
          >
            {title}
          </h3>
          {description && (
          <div
            className={classNames(
              status === 'error' && 'text-red-800',
              status === 'warning' && 'text-yellow-800',
              status === 'info' && 'text-blue-700',
              'mt-2 text-sm font-[Inter]',
            )}
          >
            {description}
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Alert;
