import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ToggleSwitch } from '../../common';

/* =============================================================================
<EventsHeader />
============================================================================= */
function EventsHeader({ enabled, onChange }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // SET SWITCH TO ENABLED IF USER NAVIGATE DIRECTLY TO ONLINE
  useEffect(() => {
    if (pathname?.includes('live')) {
      onChange(true);
    } else {
      onChange(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const _handleToggleSwitch = (value) => {
    onChange(value);
    if (value === true) {
      navigate('/events/hcp/live');
    } else {
      navigate('/events/hcp');
    }
  };

  return (
    <div className="flex sm:flex-row flex-col sm:space-y-0 space-y-5 justify-between">
      <h1 className="text-2xl font-bold font-[Inter] text-gray-900">
        Happening now
      </h1>
      <div className="flex mt-2 items-center">
        <p className="text-gray-700 font-[Inter] text-sm font-semibold mr-4">Live only</p>
        <ToggleSwitch enabled={enabled} onChange={_handleToggleSwitch} />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default EventsHeader;
