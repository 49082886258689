import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import {
  Stepper, MslInformation, MslPrescreener, EmptyState, Spinner,
} from '../../common';
import MeetingScheduledModal from '../components/MeetingScheduledModal';
import MslMeetingTypeAndTime from '../components/ScheduleMeetingWithMsl/MslMeetingTypeAndTime';
import { useCreateMeeting } from '../api/mutations';
import { useGetMslProfile } from '../../profile/api/queries';
import { useSubmitPrescreenerAnswers } from '../../company/api/mutations';
import Button from '../../common/Button';
import useUserOnlineStatus from '../../util/hooks/useUserOnlineStatus';
import { client as queryClient } from '../../config/react-query';
import { queryKeys } from '../api/query-key-factory';

/* =============================================================================
<ScheduleMeetingWithMslScreen />
============================================================================= */
function ScheduleMeetingWithMslScreen() {
  const navigate = useNavigate();
  const { mslId } = useParams();
  const { data: msl, isLoading } = useGetMslProfile(mslId);
  const companyId = msl?.pharma_company?.id;
  const [currentStep, setCurrentStep] = useState(1);
  const [meetingTypeId, setMeetingTypeId] = useState(null);
  const [date, setDate] = useState(null);
  const [slot, setSlot] = useState(null);
  const [callType, setCallType] = useState('video-call');
  const [prescreenerResponseId, setPrescreenerResponseId] = useState(null);
  const [answers, setAnswers] = useState({});

  const userAvailability = useUserOnlineStatus(msl?.user?.id, msl?.availability, false);

  const {
    data: scheduleMeetingData,
    mutateAsync: scheduleMeeting,
    isLoading: scheduleMeetingLoading,
  } = useCreateMeeting();

  const {
    isLoading: submitPrescreenerAnswersLoading,
    mutate: submitPrescreenerAnswers,
  } = useSubmitPrescreenerAnswers();

  const _onSelectSlot = (value) => {
    setSlot(value);
  };

  if (!msl && !isLoading) {
    return (
      <div className="text-center mt-80">
        <EmptyState
          title="Not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="MSL not found"
        />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  const onCallTypeChange = (e) => {
    setCallType(e?.target?.id);
  };

  const _handleSubmitPrescreener = async () => {
    await submitPrescreenerAnswers({ companyId, answers }, {
      onSuccess: (response) => {
        setPrescreenerResponseId(response?.data?.data?.prescreener_response?.id);
        setCurrentStep(2);
      },
    });
  };

  const _handleScheduleMeeting = async () => {
    await scheduleMeeting({
      start_at: slot?.split('|')[0],
      end_at: slot?.split('|')[1],
      msl_id: mslId,
      msl_meeting_type_id: meetingTypeId,
      group_call_type_key: callType,
      prescreener_response_id: prescreenerResponseId,
    }, {
      onSuccess: () => {
        setCurrentStep(3);
        queryClient.invalidateQueries(queryKeys.meetings('upcoming'));
        queryClient.invalidateQueries(queryKeys.meetings('past'));
      },
    });
  };

  const _handleGoBack = () => {
    if (currentStep === 1) {
      navigate(-1);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col items-center p-6">
          <h2 className="text-xs text-gray-400 uppercase font-semibold font-[Inter] tracking-wide mt-2">Schedule a meeting</h2>
          <h1 className="text-2xl text-gray-700 font-bold font-[Inter] mt-1 mb-8 text-center">{currentStep === 1 ? 'What is your goal for this meeting?' : 'Choose a convenient time'}</h1>
          <div className="w-full max-w-3xl space-y-4">
            <MslInformation mslId={mslId} userAvailability={userAvailability} />
            {currentStep === 1 && (
              <MslPrescreener
                companyId={companyId}
                answers={answers}
                setAnswers={setAnswers}
              />
            )}
            {(currentStep === 2 || currentStep === 3) && (
              <MslMeetingTypeAndTime
                meetingTypeId={meetingTypeId}
                onMeetingTypeIdSelect={setMeetingTypeId}
                onCallTypeChange={onCallTypeChange}
                callType={callType}
                onSlotSelect={_onSelectSlot}
                slot={slot}
                onDateSelect={setDate}
                date={date}
              />
            )}
            {(currentStep === 3 && scheduleMeetingData) && (
              <MeetingScheduledModal
                meeting={scheduleMeetingData?.data?.data?.meeting}
                visible
                onClose={() => null}
              />
            )}
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-6 lg:px-8 py-6 bg-white justify-between sm:items-center border-t flex flex-col-reverse space-y-3 space-y-reverse sm:space-y-0 sm:flex-row space-x-0 sm:space-x-4 sm:space-x-10">
        <div className="flex-1 flex flex-col items-start">
          <Button
            title="Back"
            variant="secondary"
            icon={<ChevronLeftIcon className="w-4 h-4 text-black hidden sm:flex" />}
            onClick={_handleGoBack}
            customStyleClasses="w-full sm:w-24"
          />
        </div>
        <div className="flex-1 shrink-0 hidden md:block">
          <div className="max-w-xs w-full flex items-center m-auto block self-center justify-center">
            <Stepper steps={[1, 2, 3]} currentStep={currentStep} />
          </div>
        </div>
        <div className="flex-1 flex flex-col items-end">
          {(currentStep === 2 || currentStep === 3) && (
            <Button
              variant="green"
              iconPosition="right"
              icon={<ChevronRightIcon className="w-4 h-4 text-white" />}
              title="Confirm meeting"
              customStyleClasses="w-full sm:w-48"
              onClick={_handleScheduleMeeting}
              loading={scheduleMeetingLoading}
              disabled={scheduleMeetingLoading || currentStep !== 2}
            />
          )}
          {currentStep === 1 && (
            <Button
              title="Next"
              variant="purple"
              iconPosition="right"
              icon={<ChevronRightIcon className="w-4 h-4 text-white" />}
              onClick={_handleSubmitPrescreener}
              customStyleClasses="w-full sm:w-24"
              loading={submitPrescreenerAnswersLoading}
              disabled={submitPrescreenerAnswersLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleMeetingWithMslScreen;
