import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getUser } from '../../../auth/redux/selectors';
import {
  Avatar, Button, Modal, Spinner, TextArea,
} from '../../../common';
import { useRateDirectCall } from '../../api/mutations';
import Toast from '../../../common/Toast';
import { useGetDirectCall } from '../../api/queries';

/* =============================================================================
<CallRateModal />
============================================================================= */
function CallRateModal({
  directCallId, visible, onClose, onSubmitSuccess,
}) {
  const user = useSelector(getUser);
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState('');

  /* only pass through directCallId when visible, so on the call history page, it doesn't load every direct call */
  const { data: directCall } = useGetDirectCall(visible ? directCallId : null);
  const { mutate: rateCall, isLoading } = useRateDirectCall();

  const otherUser = directCall?.callee_user?.id === user?.id ? directCall?.caller_user : directCall?.callee_user;

  const _handleSubmit = () => {
    rateCall({
      rating,
      comments,
      callId: directCall?.id,
    }, {
      onSuccess: () => {
        toast.custom((toastProps) => (
          <Toast
            status="success"
            title="Thank you for your feedback!"
            onClose={() => toast.remove(toastProps.id)}
          />
        ));

        setRating(null);
        setComments('');
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
        onClose();
      },
    });
  };

  return (
    <Modal
      showHeader={false}
      visible={visible}
      onClose={onClose}
      customStyles="flex flex-col overflow-visible px-8 items-center"
    >
      {directCall ? (
        <>
          <div className="-mt-9">
            <Avatar
              border
              size="large"
              source={otherUser?.profile_image_path}
              name={otherUser?.name}
            />
          </div>
          <h1 className="text-lg text-center text-gray-700 mt-8 mb-2 font-medium">How was your interaction?</h1>
          <ReactStars
            count={5}
            size={35}
            value={rating}
            onChange={(r) => setRating(r)}
            activeColor="#FBBF24"
            color="#E5E7EB"
          />
          <div className="mt-6 w-full space-y-2 sm:space-y-0">
            <h3 className="font-[Inter] text-sm mb-1 font-medium text-gray-700 col-span-3 sm:col-span-1">
              Comments
              <span className="text-gray-400"> (optional)</span>
            </h3>
            <div className="flex flex-col col-span-3 sm:col-span-2 items-stretch">
              <TextArea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full justify-end items-center border-t mt-5 py-5">
            <Button
              type="submit"
              title="Submit feedback"
              variant="purple"
              loading={isLoading}
              onClick={_handleSubmit}
              customStyleClasses="w-full h-10 mb-3"
            />
            <Button
              type="submit"
              title="Cancel"
              variant="secondary"
              onClick={onClose}
              customStyleClasses="w-full h-10 mb-3"
            />
          </div>
        </>
      ) : (
        <div className="py-10">
          <Spinner />
        </div>
      )}
    </Modal>
  );
}

/* Export
============================================================================= */
export default CallRateModal;
