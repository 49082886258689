import React from 'react';
import { Link } from 'react-router-dom';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import Avatar from './Avatar';
import CompanyNameLinkWithHoverPreview from './CompanyNameLinkWithHoverPreview';
import UserAvailabilityIndicator from './UserAvailabilityIndicator';
import Spinner from './Spinner';
import { ReactComponent as BagIcon } from '../assets/icons/edit-bag-icon.svg';
import { useGetMslProfile } from '../profile/api/queries';
import { hcpPaths } from '../config/paths';

/* =============================================================================
<MslInformation />
============================================================================= */
function MslInformation({ mslId, userAvailability }) {
  const {
    data: msl,
    isLoading,
    isFetching,
    status,
  } = useGetMslProfile(mslId);
  const name = msl?.user?.name;
  const color = msl?.user?.color;
  const avatar = msl?.user?.profile_image_path;
  const location = msl?.us_state?.name;

  const profileLink = hcpPaths.mslProfile(mslId).home;

  return (
    <div className="w-full">
      <div className="p-6 bg-white border shadow-sm rounded-lg">
        {status === 'success' && (
          <div className="flex flex-col sm:pr-20 space-y-6 md:space-y-0 md:flex-row md:items-center justify-between md:space-x-10">
            <div className="flex items-center space-x-5 overflow-x-hidden">
              <Link to={profileLink} className="hover:opacity-75">
                <Avatar
                  border
                  source={avatar}
                  name={name}
                  color={color}
                  size="large"
                />
              </Link>
              <div className="flex min-w-0 flex-1 items-center justify-center space-x-6 pb-1">
                <div className="min-w-0 flex-1 sm:block">
                  <div>
                    <Link to={profileLink}>
                      <h1 className="text-lg font-bold text-gray-700 hover:text-gray-500">{name}</h1>
                    </Link>
                    <UserAvailabilityIndicator variant="pulseWithText" availability={userAvailability} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-8 hidden sm:flex">
              <div className="flex items-center space-x-4">
                <BagIcon className="h-4 w-4 text-gray-300" />
                <div className="flex-1">
                  <h4 className="text-xs text-gray-400 uppercase font-semibold">Company</h4>
                  <CompanyNameLinkWithHoverPreview company={msl?.pharma_company} />
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <LocationMarkerIcon className="h-5 w-5 text-gray-300" />
                <div className="flex-1">
                  <h4 className="text-xs text-gray-400 uppercase font-semibold">Location</h4>
                  <p className="text-sm text-gray-700 mt-1 font-medium">{location}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading && isFetching && (
          <div className="flex justify-center p-5 text-center align-center items-center">
            <Spinner />
          </div>
        )}
        {status === 'error' && (
          <div className="flex justify-center p-5 text-center align-center items-center">
            <p>User not found</p>
          </div>
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslInformation;
