import React, { useCallback } from 'react';
import { MicrophoneIcon } from '@heroicons/react/solid';
import { ArrowsExpandIcon, XIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import Avatar from '../../../../common/Avatar';
import { useSbCalls } from '../../../lib/context';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<DirectCallLocalUserView />
============================================================================= */
function DirectCallLocalUserView({
  mediaView, selectedMedia, onCloseMedia, onExpandMedia,
}) {
  const { callId } = useParams();
  const { calls: allCalls } = useSbCalls();
  const call = allCalls.find((_call) => _call?.callId === callId);

  const {
    localUser,
    isLocalVideoEnabled,
    isLocalAudioEnabled,
    setLocalMediaView,
  } = call;

  const localMediaViewRef = useCallback((node) => {
    setLocalMediaView(node);
  }, [setLocalMediaView]);

  return (
    <div
      className={classNames(
        'relative bg-gray-700 h-full flex-1 flex flex-col justify-center overflow-hidden rounded-lg',
        mediaView === 'full' && selectedMedia !== 'local' ? 'hidden' : 'block',
      )}
    >
      {selectedMedia === 'local' ? (
        <button
          type="button"
          className="absolute z-20 top-0 right-0 m-2 p-2 rounded-full bg-gray-500 hover:bg-gray-400 focus:ring-2 ring-gray-300"
          onClick={onCloseMedia}
        >
          <XIcon className="w-5 h-5 text-gray-200" />
        </button>
      ) : (
        <button
          type="button"
          className="absolute z-20 top-0 right-0 m-2 p-2 rounded-full bg-gray-500 hover:bg-gray-400 focus:ring-2 ring-gray-300"
          onClick={() => onExpandMedia('local')}
        >
          <ArrowsExpandIcon className="w-5 h-5 text-gray-200" />
        </button>
      )}
      {isLocalVideoEnabled ? (
        <video
          ref={localMediaViewRef}
          playsInline
          autoPlay
          muted
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full mirrorVideoView"
        />
      ) : (
        <div className="flex justify-center">
          <Avatar
            border
            size="extraLarge"
            name={localUser?.nickname}
            source={localUser?.profileUrl}
          />
        </div>
      )}
      {isLocalAudioEnabled && !isLocalVideoEnabled && (
        <audio
          autoPlay
          playsInline
          muted
          ref={localMediaViewRef}
        />
      )}
      <div className="flex w-full items-center justify-between absolute left-0 bottom-1 px-6 py-4 text-white">
        <div className="bg-gray-200/20 px-3 py-1 rounded-full">
          <h2 className="text-white text-xs">
            You
          </h2>
        </div>
        <div className="relative rounded-full flex justify-center items-center">
          <MicrophoneIcon className="w-5 h-5 text-white" />
          {!isLocalAudioEnabled && <div className="absolute w-1 h-6 -rotate-45 rounded-full bg-red-500" /> }
        </div>
      </div>
    </div>
  );
}

const propsAreEqual = (prevProps, nextProps) => prevProps.mediaView === nextProps.mediaView
  && prevProps.selectedMedia === nextProps.selectedMedia;

/* Export
============================================================================= */
export default React.memo(DirectCallLocalUserView, propsAreEqual);
