import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import {
  ClockIcon, DotsHorizontalIcon, PhoneIncomingIcon, PhoneOutgoingIcon,
} from '@heroicons/react/solid';
import { getUser } from '../../../../auth/redux/selectors';
import { useCreateChannel } from '../../../../chat/api/mutations';
import { Avatar } from '../../../../common';
import CallHistoryListItemRating from './CallHistoryListItemRating';
import { generalPaths, hcpPaths, mslPaths } from '../../../../config/paths';
import { localStorageKeys, userTypes } from '../../../../config/constants';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<CallHistoryListItem />
============================================================================= */
const CallHistoryListItem = ({ call }) => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const otherUser = call?.callee_user?.id === user?.id ? call?.caller_user : call?.callee_user;
  const status = call?.direct_call_end_result?.key;

  const { mutate: createChannel } = useCreateChannel();

  const _handleChatClick = () => {
    createChannel({
      name: '',
      user_ids: [otherUser?.id],
    }, {
      onSuccess: (response) => {
        navigate(generalPaths.chat(response?.data?.data?.send_bird_channel_url));
      },
    });
  };

  const profileLink = (otherUser?.user_type?.key === userTypes.hcp.key && userType === userTypes.msl.key) ? mslPaths.hcpProfile(otherUser?.user_type_specific_data.hcp_id).home : (otherUser?.user_type?.key === userTypes.msl.key && userType === userTypes.hcp.key) ? hcpPaths.mslProfile(otherUser?.user_type_specific_data.msl_id).home : 'not-found';
  const callLink = (otherUser?.user_type?.key === userTypes.hcp.key && userType === userTypes.msl.key) ? mslPaths.hcpProfile(otherUser?.user_type_specific_data.hcp_id).initiateCall : (otherUser?.user_type?.key === userTypes.msl.key && userType === userTypes.hcp.key) ? hcpPaths.mslProfile(otherUser?.user_type_specific_data.msl_id).initiateCall : 'not-found';
  const scheduleLink = (otherUser?.user_type?.key === userTypes.hcp.key && userType === userTypes.msl.key) ? mslPaths.hcpProfile(otherUser?.user_type_specific_data.hcp_id).scheduleMeeting : (otherUser?.user_type?.key === userTypes.msl.key && userType === userTypes.hcp.key) ? hcpPaths.mslProfile(otherUser?.user_type_specific_data.msl_id).scheduleMeeting : 'not-found';

  const diff = moment.utc(call?.end_at) - moment.utc(call?.accepted_at);

  return (
    <li key={call.id} className="relative flex flex-col sm:flex-row sm:space-x-5 py-6 xl:static">
      <Link to={profileLink} className="hover:opacity-75 mb-3 sm:mb-0">
        <Avatar
          size="extraMedium"
          source={otherUser?.profile_image_path}
          name={otherUser?.name}
          color={otherUser?.color}
        />
      </Link>
      <div className="flex-auto">
        <div className="pr-10 xl:pr-0 flex flex-col space-y-1.5 sm:flex-row sm:space-x-2.5 sm:space-y-0 sm:mb-0 mb-3">
          <Link to={profileLink} className="hover:opacity-75">
            <h3 className="font-semibold text-gray-900">{otherUser?.name}</h3>
          </Link>
          <div className="text-left text-sm text-gray-500 font-medium font-[Inter] w-32 sm:w-auto">
            {status === 'completed' ? (
              <div className="bg-green-100 text-xs flex justify-center rounded-full text-green-600 py-1.5 sm:py-1 px-3">
                Completed
              </div>
            ) : status === 'canceled' ? (
              <div className="bg-red-100 text-xs flex justify-center rounded-full text-red-600 py-1.5 sm:py-1 px-3">
                Canceled
              </div>
            ) : (status === 'declined' || status === 'no-answer') ? (
              <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-3">
                No answer
              </div>
            ) : status === 'connection-lost' ? (
              <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-3">
                Connection lost
              </div>
            ) : status === 'timed-out' ? (
              <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-3">
                Timed out
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full">
          <dl className="mt-2 text-gray-500 flex sm:flex-row flex-col w-fit sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="flex space-x-2 overflow-hidden">
              <dt className="mt-0.5">
                {call?.caller_user?.id === user?.id ? (
                  <PhoneOutgoingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                ) : (
                  <PhoneIncomingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
              </dt>
              <span className="truncate">{moment(call.started_at).fromNow()}</span>
            </div>
            {(call?.accepted_at && call?.end_at) && (
              <div className="flex space-x-2">
                <dt className="mt-0.5">
                  <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>{moment.utc(diff).format('HH:mm:ss')}</dd>
              </div>
            )}
          </dl>
        </div>
        {(userType === 'hcp' && status === 'completed') && (
          <div className="mt-3">
            <CallHistoryListItemRating call={call} />
          </div>
        )}
      </div>
      <Menu as="div" className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center">
        <div>
          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
            <span className="sr-only">Open options</span>
            <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={callLink}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Call
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={_handleChatClick}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-left',
                    )}
                  >
                    Chat
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={scheduleLink}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Schedule
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={profileLink}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    View Profile
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </li>
  );
};

/* Export
============================================================================= */
export default CallHistoryListItem;
