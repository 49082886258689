/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import Toast from '../../common/Toast';
import { client as queryClient } from '../../config/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError } from '../../util/functions';

/**
 * Subscribe to a product
 */
export const useSubscribeProduct = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((productId) => axiosPrivate.post(`hcp/products/${productId}/subscribe`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['productUpdates']);
      queryClient.invalidateQueries(['events']);
      queryClient.invalidateQueries(queryKeys.productSubscriptions);
      queryClient.invalidateQueries(queryKeys.productSubscriptionsProductIds);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Unsubscribe from a product
 */
export const useUnsubscribeFromProduct = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((productId) => axiosPrivate.post(`hcp/products/${productId}/unsubscribe`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['productUpdates']);
      queryClient.invalidateQueries(['events']);
      queryClient.invalidateQueries(queryKeys.productSubscriptions);
      queryClient.invalidateQueries(queryKeys.productSubscriptionsProductIds);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Prescribe a product
 */
export const usePrescribeProduct = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/products/${data?.productId}/prescribe`, data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Use Product Samples
 */
export const useProductSamples = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/products/${data?.productId}/use-samples`, data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Order Product Samples
 */
export const useOrderProductSamples = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/products/${data?.productId}/order-samples`, data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Product Samples Log Interest
 */
export const useProductSamplesLogInterest = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((productId) => axiosPrivate.post(`hcp/products/${productId}/order-samples/log-interest`), {
    onSuccess: () => {
      toast.custom((toastProps) => (
        <Toast
          status="warn"
          title="This company does not yet support this feature."
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Get Product from barcode
 */
export const useGetProductFromBarcode = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('hcp/products/barcode-search', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Mark Update as Viewed
 */
export const useMarkUpdateAsViewed = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/products/updates/${data?.updateId}/mark-as-viewed`), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Mark Product As Viewed
 */
export const useMarkProductAsViewed = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/products/${data}/mark-as-viewed`, data));
};
