import React from 'react';

import MslMeetingTypeSelect from './MslMeetingTypeSelect';
import MslMeetingTimeSelect from './MslMeetingTimeSelect';
import MeetingCallTypeSelect from '../../MeetingCallTypeSelect';

/* =============================================================================
<MslMeetingTypeAndTime />
============================================================================= */
function MslMeetingTypeAndTime({
  meetingTypeId,
  onMeetingTypeIdSelect,
  onSlotSelect,
  slot,
  onDateSelect,
  date,
  onCallTypeChange,
  callType,
}) {
  return (
    <div className="p-6 bg-white border shadow-sm rounded-lg space-y-8">
      <MslMeetingTypeSelect
        meetingTypeId={meetingTypeId}
        onMeetingTypeIdSelect={onMeetingTypeIdSelect}
      />
      {meetingTypeId && (
        <MslMeetingTimeSelect
          meetingTypeId={meetingTypeId}
          onSlotSelect={onSlotSelect}
          slot={slot}
          onDateSelect={onDateSelect}
          date={date}
        />
      )}
      {/*
      <MeetingCallTypeSelect
        onCallTypeChange={onCallTypeChange}
        value={callType}
      />
      */}
    </div>
  );
}

/* Export
============================================================================= */
export default MslMeetingTypeAndTime;
