import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, Modal, ProductCard, SelectDropDown, Spinner,
} from '../../../../common';
import { useGetProduct, useGetProductDiagnosis } from '../../../api/queries';
import { usePrescribeProduct } from '../../../api/mutations';
import TextArea from '../../../../common/TextArea';

/* =============================================================================
<ProductPrescriptionModal />
============================================================================= */
function ProductPrescriptionModal({
  visible, onSuccess, onClose,
}) {
  const { productId } = useParams();

  const { data: product } = useGetProduct(productId);
  const { data: diagnosisData, isSuccess: diagnosisGetSuccess } = useGetProductDiagnosis(visible ? productId : null);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('');
  const [comments, setComments] = useState('');

  const { mutate: useProductPrescription, isLoading } = usePrescribeProduct();

  const resetForm = () => {
    setSelectedDiagnosis(null);
    setComments('');
  };

  useEffect(() => {
    if (diagnosisGetSuccess) {
      const tempList = diagnosisData.map((diagnosisListItem) => ({
        key: diagnosisListItem?.id,
        name: diagnosisListItem?.name,
      }));

      setDiagnosisList([...tempList, {
        key: 'other',
        name: 'Other',
      }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisGetSuccess]);

  const _handleSubmit = () => {
    useProductPrescription({
      productId: product?.id,
      comments,
      product_preset_diagnosis_id: selectedDiagnosis === 'other' ? null : selectedDiagnosis,
    }, {
      onSuccess: () => {
        onSuccess();
        onClose();
        resetForm();
      },
    });
  };

  return (
    <Modal size="xl" visible={visible} heading="Record prescription" onClose={onClose}>
      <div className="divide-y">
        <div className="p-5 rounded-lg mx-5 my-5 border">
          <ProductCard product={product} showActions={false} />
        </div>
        <div className="flex items-center border-t p-5 grid sm:grid-cols-3 space-y-2.5 sm:space-y-0">
          <h3 className="font-[Inter] text-sm font-medium text-gray-700 col-span-3 sm:col-span-1">
            Select diagnosis
          </h3>
          <div className="space-x-2 flex col-span-3 sm:col-span-2">
            {diagnosisGetSuccess ? (
              <SelectDropDown
                label={null}
                placeholder="Select..."
                items={diagnosisList}
                value={selectedDiagnosis}
                onChange={(e) => setSelectedDiagnosis(e.target.value)}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <div className="flex items-start border-t p-5 grid sm:grid-cols-3 space-y-2.5 sm:space-y-0">
          <h3 className="font-[Inter] text-sm font-medium text-gray-700 col-span-3 sm:col-span-1">
            Comments
          </h3>
          <div className="space-x-2 flex col-span-3 sm:col-span-2 items-stretch">
            <TextArea
              label={null}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Please enter comments..."
              customContainerStyles="w-full"
            />
          </div>
        </div>
        <div className="flex justify-end items-center sm:space-x-4 p-5">
          <Button
            title="Cancel"
            variant="secondary"
            onClick={onClose}
            customStyleClasses="hidden sm:block w-24 h-10"
          />
          <Button
            type="submit"
            title="Record prescription"
            variant="purple"
            loading={isLoading}
            onClick={_handleSubmit}
            customStyleClasses="w-full sm:w-48 h-10"
          />
        </div>
      </div>
    </Modal>
  );
}

/* Export
============================================================================= */
export default ProductPrescriptionModal;
