import React, {
  createContext,
  useMemo,
  useRef,
  useState,
} from 'react';

export const GlobalSearchContext = createContext(undefined);

/* =============================================================================
<GlobalSearchProvider />
============================================================================= */
function GlobalSearchProvider({ children }) {
  const searchInputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');

  const value = useMemo(() => ({
    searchQuery,
    setSearchQuery,
    searchInputRef,
  }), [
    searchQuery,
    setSearchQuery,
    searchInputRef,
  ]);

  return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>;
}

/* Export
============================================================================= */
export default GlobalSearchProvider;
