/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Tabs({
  tabs, left, deepLink, containerStyles, contentStyles, link, switchToDropdownOnSmallScreens = true,
}) {
  const navigate = useNavigate();
  const { tab: paramsTab } = useParams();
  return (
    <div className={containerStyles}>
      <div className={classNames(switchToDropdownOnSmallScreens ? 'md:hidden' : 'hidden')}>
        <select
          id="tabs"
          name="tabs"
          value={paramsTab}
          className="block overflow-hidden w-full rounded-md border-gray-300 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => navigate(`${link}${e.target.value}${deepLink || ''}`)}
        >
          {tabs.map((tab) => (
            <option key={tab.key} value={tab.key}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className={classNames(switchToDropdownOnSmallScreens ? 'hidden md:block' : 'block')}>
        <div className={`border-b border-gray-200 flex items-center justify-between ${contentStyles}`}>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink to={`${link}${tab?.key}${deepLink || ''}`} key={tab.key} className="flex items-center">
                <button
                  type="button"
                  className={classNames(
                    tab?.key === paramsTab
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap flex justify-between py-4 px-1.5 border-b-2 font-[Inter] font-medium text-sm',
                  )}
                  aria-current={tab?.key === paramsTab ? 'page' : undefined}
                  // onClick={() => onChange(tab.key)}
                >
                  <p className={tab?.count != null ? 'mr-2' : ''}>{tab.name}</p>
                  {tab?.count != null ? (
                    <div className={classNames(
                      tab?.key === paramsTab
                        ? 'bg-indigo-100 text-indigo-500 '
                        : 'bg-gray-100 text-gray-500',
                      'font-[Inter] flex justify-center items-center font-medium text-xs ml-1 rounded-full  w-8 h-5',
                    )}
                    >
                      {tab?.count}
                    </div>
                  ) : null}
                </button>
              </NavLink>
            ))}
          </nav>
          {left}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
