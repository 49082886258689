/* eslint-disable jsx-a11y/label-has-associated-control */
import { PlusIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Input, Spinner } from '../../../../common';
import { useGetHcpAddresses } from '../../../../auth/api/queries';
import { useCreateHcpAddress, useDeleteHcpAddress } from '../../../../auth/api/mutations';
import RadioGroup from '../../../../common/RadioGroup';

/* =============================================================================
<ProductOrderSampleSelectAddress />
============================================================================= */
function ProductOrderSampleSelectAddress({ hcpAddressId, onHcpAddressIdChange }) {
  const {
    data: addresses,
    status: getAddressesStatus,
  } = useGetHcpAddresses();
  const {
    mutateAsync: createAddress,
    isLoading: createAddressLoading,
  } = useCreateHcpAddress();
  const {
    mutateAsync: deleteAddress,
    isLoading: deleteAddressLoading,
  } = useDeleteHcpAddress();

  const [showAddNewAddressInput, setShowAddNewAddressInput] = useState(false);
  const [newAddress, setNewAddress] = useState('');

  const _handleCreateAddress = async () => {
    await createAddress({
      full_address: newAddress,
    }, {
      onSuccess: (response, variables, context) => {
        const newAddressId = response?.data?.data?.address?.id;
        if (newAddressId) {
          onHcpAddressIdChange(newAddressId);
        }
        setNewAddress('');
        setShowAddNewAddressInput(false);
      },
    });
  };

  const _handleDeleteAddress = async (id) => {
    if (id) {
      await deleteAddress(id);
    }
  };

  const _handleCloseCreateAddressForm = () => {
    setShowAddNewAddressInput(false);
    setNewAddress('');
  };

  return (
    <div className="border-t p-5 grid grid-cols-3 space-y-2.5 sm:space-y-0">
      <h3 className="font-[Inter] text-sm font-medium text-gray-700 col-span-3 sm:col-span-1">
        Select address
      </h3>
      <div className="col-span-3 sm:col-span-2 w-full">
        {getAddressesStatus === 'success' ? (
          <>
            {addresses?.length !== 0 && (
              <div className="space-y-3 mb-6 w-full">
                <RadioGroup
                  value={hcpAddressId}
                  options={addresses?.map((address) => ({
                    id: address.id,
                    title: (
                      <div className="flex flex-col">
                        <span>{address.full_address}</span>
                        <div>
                          <button type="button" disabled={deleteAddressLoading} className="text-sm text-left mt-1 shrink-0 text-indigo-600 hover:text-indigo-500 font-medium disabled:opacity-75" onClick={() => _handleDeleteAddress(address?.id)}>
                            {deleteAddressLoading ? 'Processing...' : 'Delete'}
                          </button>
                        </div>
                      </div>
                    ),
                  }))}
                  onChange={(e) => onHcpAddressIdChange(e.target.id)}
                />
              </div>
            )}
            {!showAddNewAddressInput && (
              <button type="button" className="flex items-center hover:opacity-75" onClick={() => setShowAddNewAddressInput(true)}>
                <PlusIcon className="w-5 h-5 text-indigo-500" />
                <p className="text-sm text-indigo-600 font-medium ml-1">Add address</p>
              </button>
            )}
            {showAddNewAddressInput && (
              <div className="">
                <Input
                  value={newAddress}
                  placeholder="Enter your address"
                  onChange={(e) => setNewAddress(e.target?.value)}
                />
                <div className="flex space-x-5 mt-5">
                  <button type="button" className="text-sm font-medium text-gray-500 hover:text-gray-400" onClick={_handleCloseCreateAddressForm}>
                    Cancel
                  </button>
                  <button type="button" className="text-sm text-indigo-600 hover:text-indigo-500 font-medium disabled:opacity-75" onClick={_handleCreateAddress} disabled={createAddressLoading}>
                    {createAddressLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <Spinner size={20} />
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductOrderSampleSelectAddress;
