import React, { useState, useEffect } from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { EmptyState, ProductCard, Spinner } from '../../common';
import { useGetSubscriptions } from '../../products/api/queries';

/* =============================================================================
<ManageProfileSubscriptions />
============================================================================= */
function ManageProfileSubscriptions() {
  const { data: subscriptionsData, status } = useGetSubscriptions();

  if (!subscriptionsData?.length > 0 && status === 'loading') {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!subscriptionsData?.length > 0 && status === 'success') {
    return (
      <div className="text-center py-8">
        <EmptyState
          title="You don't have any subscriptions"
          icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
          description="Subscribe to your first product"
        />
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between">
        <div>
          <h2 className="font-[Inter] text-lg font-semibold text-gray-700">Subscriptions</h2>
          <p className="font-[Inter] text-sm text-gray-500">Products you are subscribed to</p>
        </div>
      </div>
      <div className="w-full h-1 border-b mt-5" />
      <ul className="divide-y divide-gray-200 w-full">
        {subscriptionsData?.map((subscription) => (
          <li className="py-5 sm:py-6">
            <ProductCard
              key={subscription?.id}
              product={subscription?.product}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

/* Export
============================================================================= */
export default ManageProfileSubscriptions;
