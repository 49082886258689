/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError } from '../../util/functions';

/**
 * Submit Prescreener Answers
 */
export const useSubmitPrescreenerAnswers = () => {
  const { axiosPrivate } = useAxios();
  return useMutation(({ companyId, answers }) => axiosPrivate.post(`hcp/pharma-companies/${companyId}/prescreener`, answers), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};
