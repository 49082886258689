import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { getIsAuthenticated, getUser } from '../auth/redux/selectors';
import { useGetHcpUser, useGetMslUser } from '../auth/api/queries';
import { setUser } from '../auth/redux/actions';
import Spinner from '../common/Spinner';
import { localStorageKeys } from '../config/constants';

/* =============================================================================
<UserProvider />
============================================================================= */
function UserProvider({ children, isAuthenticated }) {
  const dispatch = useDispatch();
  const userType = localStorage.getItem(localStorageKeys.discreedlyUserType);

  const {
    isLoading: isHcpUserLoading,
    isFetching: isHcpUserFetching,
  } = useGetHcpUser(
    userType === 'hcp' && isAuthenticated,
    (data) => {
      dispatch(setUser(data));
    },
  );

  const {
    isLoading: isMslUserLoading,
    isFetching: isMslUserFetching,
  } = useGetMslUser(
    userType === 'msl' && isAuthenticated,
    (data) => {
      dispatch(setUser(data));
    },
  );

  if ((isHcpUserLoading && isHcpUserFetching) || (isMslUserLoading && isMslUserFetching)) {
    return (
      <div className="text-center h-full justify-center align-center mt-8">
        <Spinner />
      </div>
    );
  }

  return children;
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(UserProvider);
