import React from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../../auth/redux/selectors';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ChatChannelGroupChat />
============================================================================= */
function ChatChannelGroupChat({ channel, forChatHeader, user }) {
  const currentUserSendbirdId = user?.send_bird_user?.id;

  const membersCount = channel?.memberCount;
  const members = channel?.members;
  const filteredMembers = members?.filter((_member) => _member?.userId !== currentUserSendbirdId);
  const channelName = membersCount > 2 ? `${filteredMembers?.map((_member, i) => _member?.nickname).join(', ')}` : user?.name;

  return (
    <div className={classNames('flex flex-col text-left items-start w-full overflow-x-hidden overflow-hidden')}>
      <span className="truncate w-full text-sm leading-5 font-semibold font-[Inter] text-gray-700">
        {channelName}
      </span>
      {forChatHeader && (
        <span className="text-sm leading-5 font-medium font-[Inter] text-gray-700">
          Group chat
        </span>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ChatChannelGroupChat);
