import React, { useRef, Fragment } from 'react';

import { EmojiSadIcon } from '@heroicons/react/outline';
import { useGetCompanyPipelineProducts } from '../api/queries';

import { EmptyState, Spinner } from '../../common';
import PipelineProductCard from '../../common/PipelineProductCard';
import useIntersectionObserver from '../../util/hooks/useIntersectionObserver';

/* =============================================================================
<CompanyPipelineProducts />
============================================================================= */
function CompanyPipelineProducts({ companyId }) {
  const {
    data,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetCompanyPipelineProducts(companyId);

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <div>
      <ul className="max-w-7xl mx-auto divide-y divide-gray-200 px-4 sm:px-6 lg:px-8">
        {isSuccess && data?.pages?.map((page) => (
          <Fragment key={page?.pagination?.page}>
            {page?.pipeline_products?.map((product) => (
              <li className="py-5 sm:py-6" key={product?.id}>
                <PipelineProductCard
                  pipelineProduct={product}
                  showCompany={false}
                />
              </li>
            ))}
          </Fragment>
        ))}

        {/* fetch data on scroll end */}
        <div
          ref={loadMoreRef}
          className={`${!hasNextPage ? 'hidden' : ''} text-2xl flex justify-center`}
        />
      </ul>

      {/* loading spinner */}
      {(isLoading || (hasNextPage && isFetchingNextPage)) && (
        <div className="text-center mt-8">
          <Spinner />
        </div>
      )}

      {/* Empty State */}
      {(isSuccess && data?.pages[0]?.pagination?.total === 0) && (
        <div className="text-center mt-8">
          <EmptyState
            title="No results"
            icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
            description="No pipeline products were found"
            />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default CompanyPipelineProducts;
