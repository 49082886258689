import React, { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/solid';
import { PromptModal } from '../../../../common';
import { useDeleteDateOverride } from '../../../api/mutations';

/* =============================================================================
<ScheduleDateOverridesItem />
============================================================================= */
function ScheduleDateOverridesItem({ override }) {
  const { scheduleId } = useParams();
  const [promptModal, setPromptModal] = useState(false);
  const { mutate: deleteOverride, isLoading } = useDeleteDateOverride();

  const _togglePromptModal = () => setPromptModal((prev) => !prev);

  const _handleDelete = () => {
    setPromptModal(false);
    deleteOverride({
      scheduleId,
      date: override?.date,
    });
  };

  return (
    <div className="flex sm:flex-row flex-col justify-between sm:items-center space-y-3 sm:space-y-0 px-6 py-5 sm:py-4">
      <div>
        <h2 className="font-[Inter] text-gray-500 text-xs font-medium">{moment(override?.date).format('dddd')}</h2>
        <h2 className="font-[Inter] text-gray-700 text-sm font-medium">{moment(override?.date).format('Do MMM YYYY')}</h2>
      </div>
      <div className="flex items-center space-x-3 justify-between">
        <h2 className="font-[Inter] text-gray-700 text-sm normal leading-5">
          {override?.rules?.length === 0 ? 'Unavailable' : (
            <div className="flex flex-col space-y-1">
              {override?.rules?.map((rule) => (
                <span>
                  {`${moment(rule?.start_time, 'HH:mm:ss').format('h:mma')} - ${moment(rule?.end_time, 'HH:mm:ss').format('h:mma')}`}
                </span>
              ))}
            </div>
          )}
        </h2>
        <button type="button" className="px-2 py-2 group self-end" onClick={_togglePromptModal}>
          <TrashIcon className="w-5 h-5 text-gray-400 group-hover:text-red-500 transition-all ease-in-out" />
        </button>
      </div>
      <PromptModal
        btnTxt="Yes, delete"
        open={promptModal}
        onClose={_togglePromptModal}
        onBtnPress={_handleDelete}
        btnLoading={isLoading}
        title="Delete the override"
        description="Are you sure you would like to delete the override?"
      />
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleDateOverridesItem;
