import React from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, AnimatedDotDotDot } from '../../../../common';
import { useSbCalls } from '../../../lib/context';

/* =============================================================================
<DirectCallDialingView />
============================================================================= */
function DirectCallDialingView() {
  const { callId } = useParams();
  const { calls: allCalls } = useSbCalls();
  const call = allCalls.find((_call) => _call?.callId === callId);

  const { remoteUser } = call;

  return (
    <div className="justify-center items-center text-center self-center flex flex-col">
      <div>
        <Avatar
          border
          size="extraLarge"
          name={remoteUser.nickname}
          source={remoteUser.profileUrl}
        />
      </div>
      <h2 className="mt-5 mb-2 text-2xl font-semibold text-white">{remoteUser.nickname}</h2>
      <AnimatedDotDotDot customStyles="text-white text-sm" prefixText="Calling" />
    </div>
  );
}

/* Export
============================================================================= */
export default DirectCallDialingView;
