import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';
import MeetingTypeItem from '../components/MeetingTypes/MeetingTypeItem';
import MeetingTypesHeader from '../components/MeetingTypes/MeetingTypesHeader';

import { useGetMeetingTypes } from '../api/queries';
import { Button, EmptyState, Spinner } from '../../common';
import MeetingTypeCreateModal from '../components/MeetingTypes/MeetingTypeCreateModal';
import MeetingTypeEmptyState from '../../assets/icons/empty-state/MeetingTypeEmptyState.png';

/* =============================================================================
<MeetingTypesScreen />
============================================================================= */
function MeetingTypesScreen() {
  const { data: meetingTypes, isLoading, isSuccess } = useGetMeetingTypes();

  const [createModal, setCreateModal] = useState(false);
  const _toggleCreateModal = () => setCreateModal((prev) => !prev);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-7 space-y-6 flex flex-col h-full">
      <MeetingTypesHeader toggleCreateModal={_toggleCreateModal} />
      {(isSuccess && meetingTypes?.length !== 0) && (
        <div className="flex-1 pb-7">
          <div className="flex flex-wrap justify-between grid grid-cols-1 gap-3 xl:gap-5 xs:grid-cols-1 md:grid-cols-2">
            {meetingTypes?.map((meetingType) => (
              <MeetingTypeItem
                key={meetingType?.id}
                meetingType={meetingType}
              />
            ))}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-full text-center">
          <Spinner />
        </div>
      )}

      {/* if no data */}
      {(isSuccess && meetingTypes?.length === 0) && (
        <div className="items-center justify-center flex-1 flex">
          <div className="text-center max-w-sm py-6">
            <EmptyState
              title="No meeting types"
              description="When a user is scheduling a call with you, they will be able to select from your meeting types."
              icon={<img src={MeetingTypeEmptyState} alt="" className="w-72" />}
            />
            <Button
              onClick={_toggleCreateModal}
              customStyleClasses="mt-5 w-60 mx-auto"
              variant="purple"
              title="Create meeting type"
              icon={<PlusIcon className="text-white w-5 h-5" />}
            />
          </div>
        </div>
      )}

      <MeetingTypeCreateModal visible={createModal} onClose={_toggleCreateModal} />
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingTypesScreen;
