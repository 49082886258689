import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import ProductPlaceholderImg from '../../../assets/icons/placeholder-product-image-small.png';

/* =============================================================================
<ProductTrendingListItemProduct />
============================================================================= */
function ProductTrendingListItemProduct({ therapyProduct }) {
  const isExternalLink = therapyProduct?.is_external;

  if (isExternalLink) {
    const link = therapyProduct?.info_url;
    return (
      <a
        href={link}
        target="blank"
        className="border flex items-center justify-between rounded-lg px-4 py-3 group relative hover:bg-gray-100"
      >
        <div className="flex items-center overflow-x-hidden">
          <img
            className="h-10 w-10 rounded-full shrink-0"
            src={ProductPlaceholderImg}
            alt=""
          />
          <h4 className="ml-3 font-[Inter] text-sm text-gray-700 font-medium group-hover:text-gray-900 truncate">{therapyProduct?.name}</h4>
        </div>
        <button type="button" className="shrink-0 ml-2">
          <ExternalLinkIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-700" />
        </button>
      </a>
    );
  }

  return (
    <Link
      to={`/products/hcp/${therapyProduct?.product?.id}`}
      className="border flex items-center justify-between rounded-lg px-4 py-3 group relative hover:bg-gray-100"
    >
      <div className="flex items-center overflow-x-hidden">
        {therapyProduct?.product?.main_image_path ? (
          <img
            className="h-10 w-10 rounded-full"
            src={therapyProduct?.product?.main_image_path}
            alt=""
          />
        ) : (
          <img
            className="h-10 w-10 rounded-full"
            src={ProductPlaceholderImg}
            alt=""
          />
        )}
        <h4 className="ml-3 font-[Inter] text-sm text-gray-700 font-medium group-hover:text-gray-900 truncate">{therapyProduct?.product?.name}</h4>
      </div>
    </Link>
  );
}

/* Export
============================================================================= */
export default ProductTrendingListItemProduct;
