export const queryKeys = {

  meetings: (filters) => ['meetings', filters],
  meeting: (meetingId) => [...queryKeys.meetings(), 'meeting', meetingId],

  nextMeeting: ['nextMeeting'],

  meetingTypes: ['meetingTypes'],

  mslMeetingTypes: (mslId) => ['mslMeetingTypes', mslId],
  mslMeetingSlots: (mslId, meetingTypeId, selectedDate) => ['mslMeetingSlots', { mslId, meetingTypeId, selectedDate }],
};
