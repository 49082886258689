import React from 'react';

/* =============================================================================
<EventCardInfoItem />
============================================================================= */
function EventCardInfoItem({ icon, title, description }) {
  return (
    <div className="flex items-center">
      <div className="shrink-0">
        {icon}
      </div>
      <div className="ml-2">
        <h4 className="text-xs font-[Inter] text-gray-400 font-semibold">{title}</h4>
        <p className="text-xs font-[Inter] text-gray-700 font-medium">{description}</p>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default EventCardInfoItem;
