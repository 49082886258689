import React, { useEffect } from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '../api/queries';
import { EmptyState, Spinner } from '../../common';
import ProductActions from '../components/ProductActions';
import ProductHeader from '../components/ProductHeader';
import ProductOverview from '../components/ProductOverview';
import ProductInfo from '../components/ProductInfo';
import { useMarkProductAsViewed } from '../api/mutations';

/* =============================================================================
<ProductScreen />
============================================================================= */
function ProductScreen() {
  const { productId } = useParams();
  const { data: product, isLoading } = useGetProduct(productId);
  const { mutate: markProductAsViewed } = useMarkProductAsViewed();

  useEffect(() => {
    if (productId) {
      markProductAsViewed(productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  if (isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!product && !isLoading) {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="Product not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="The requested product was not found"
        />
      </div>
    );
  }

  return (
    <div className="pb-6">
      <ProductHeader product={product} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-5 mt-5">
        <ProductOverview product={product} />
        <ProductActions product={product} />
        <ProductInfo product={product} />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductScreen;
