import React, { useCallback } from 'react';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { MicrophoneIcon, XIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import Avatar from '../../../../common/Avatar';
import { useSbCalls } from '../../../lib/context';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<DirectCallRemoteUserView />
============================================================================= */
function DirectCallRemoteUserView({
  mediaView, selectedMedia, onCloseMedia, onExpandMedia,
}) {
  const { callId } = useParams();
  const { calls: allCalls } = useSbCalls();
  const call = allCalls.find((_call) => _call?.callId === callId);

  const {
    remoteUser,
    isRemoteVideoEnabled,
    isRemoteAudioEnabled,
    setRemoteMediaView,
  } = call;

  const remoteMediaViewRef = useCallback((node) => {
    setRemoteMediaView(node);
  }, [setRemoteMediaView]);

  return (
    <div
      className={classNames(
        'relative bg-gray-700 h-full flex-1 flex flex-col justify-center overflow-hidden rounded-lg',
        mediaView === 'full' && selectedMedia !== 'remote' ? 'hidden' : 'block',
      )}
    >
      {selectedMedia === 'remote' ? (
        <button
          type="button"
          className="absolute top-0 z-20 right-0 m-2 p-2 rounded-full bg-gray-500 hover:bg-gray-400 focus:ring-2 ring-gray-300"
          onClick={onCloseMedia}
        >
          <XIcon className="w-5 h-5 text-gray-200" />
        </button>
      ) : (
        <button
          type="button"
          className="absolute top-0 z-20 right-0 m-2 p-2 rounded-full bg-gray-500 hover:bg-gray-400 focus:ring-2 ring-gray-300"
          onClick={() => onExpandMedia('remote')}
        >
          <ArrowsExpandIcon className="w-5 h-5 text-gray-200" />
        </button>
      )}
      {isRemoteVideoEnabled ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          ref={remoteMediaViewRef}
          playsInline
          autoPlay
          muted={false}
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
        />
      ) : (
        <div className="flex justify-center">
          <Avatar
            border
            size="extraLarge"
            name={remoteUser?.nickname}
            source={remoteUser?.profileUrl}
          />
        </div>
      )}
      {isRemoteAudioEnabled && !isRemoteVideoEnabled && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
          autoPlay
          playsInline
          ref={remoteMediaViewRef}
        />
      )}
      <div className="flex w-full items-center justify-between absolute left-0 bottom-1 px-6 py-4 text-white">
        <div className="bg-gray-200/20 px-3 py-1 rounded-full">
          <h2 className="text-white text-xs">
            {remoteUser?.nickname}
          </h2>
        </div>
        <div className="relative rounded-full flex justify-center items-center">
          <MicrophoneIcon className="w-5 h-5 text-white" />
          {!isRemoteAudioEnabled && <div className="absolute w-1 h-6 -rotate-45 rounded-full bg-red-500" /> }
        </div>
      </div>
    </div>
  );
}

const propsAreEqual = (prevProps, nextProps) => prevProps.mediaView === nextProps.mediaView
  && prevProps.selectedMedia === nextProps.selectedMedia;

/* Export
============================================================================= */
export default React.memo(DirectCallRemoteUserView, propsAreEqual);
