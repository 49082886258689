import React from 'react';
import ProductInfoItem from './ProductInfoItem';
import { ReactComponent as AdminIcon } from '../../../assets/icons/edit-admin-icon.svg';
import { ReactComponent as ContradictionIcon } from '../../../assets/icons/edit-contradiction-icon.svg';
import { ReactComponent as EfficacyIcon } from '../../../assets/icons/edit-efficacy-icon.svg';
import { ReactComponent as AlternativeIcon } from '../../../assets/icons/edit-alternative-icon.svg';
import { ReactComponent as AdverseIcon } from '../../../assets/icons/edit-adverse-icon.svg';
import { ReactComponent as PrecisionIcon } from '../../../assets/icons/edit-precision-icon.svg';

/* =============================================================================
<ProductInfo />
============================================================================= */
function ProductInfo({ product }) {
  const classAlternativesCount = product?.class_alternatives_count;

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg border sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      <ProductInfoItem
        icon={<AdminIcon className="w-10 h-10 sm:w-12 sm:h-12 shrink-0" />}
        title="Dosing / admin"
        preview={product?.dosing_and_admin_preview}
        description={product?.dosing_and_admin}
        index={0}
      />
      <ProductInfoItem
        icon={<ContradictionIcon className="w-10 h-10 sm:w-12 sm:h-12 shrink-0" />}
        title="Key contraindications"
        preview={product?.key_contraindications_preview}
        description={product?.key_contraindications}
        index={1}
      />
      <ProductInfoItem
        icon={<EfficacyIcon className="w-10 h-10 sm:w-12 sm:h-12 shrink-0" />}
        title="Efficacy & PK"
        preview={product?.efficacy_pk_pd_preview}
        description={product?.efficacy_pk_pd}
        index={2}
      />
      <ProductInfoItem
        index={3}
        icon={<AlternativeIcon className="w-10 h-10 sm:w-12 sm:h-12 shrink-0" />}
        title={(
          <div className="flex flex-row space-x-3 items-center text-left">
            <span>Class alternatives</span>
            {classAlternativesCount !== null && (
              <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 w-fit">
                {classAlternativesCount}
              </span>
            )}
          </div>
        )}
        preview={product?.class_alternatives_preview}
        description={product?.class_alternatives}
      />
      <ProductInfoItem
        icon={<AdverseIcon className="w-10 h-10 sm:w-12 sm:h-12 shrink-0" />}
        title="Key adverse events"
        preview={product?.key_adverse_events_preview}
        description={product?.key_adverse_events}
        index={4}
      />
      <ProductInfoItem
        icon={<PrecisionIcon className="w-10 h-10 sm:w-12 sm:h-12 shrink-0" />}
        title="Precision medicines"
        preview={product?.precision_medicines_preview}
        description={product?.precision_medicines}
        index={5}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default ProductInfo;
