import { useQuery } from '@tanstack/react-query';

import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get HCP user
 */
export const useGetHcpUser = (enabled, successCallback, errorCallback) => {
  const { axiosPrivate } = useAxios();
  return useQuery(queryKeys.hcpUser(), async () => {
    const { data } = await axiosPrivate.get('hcp/account');
    return data.data?.user;
  }, {
    onSuccess: (payload) => {
      if (typeof successCallback === 'function') {
        successCallback(payload);
      }
    },
    onError: (error) => {
      if (typeof errorCallback === 'function') {
        errorCallback(error);
      }
    },
    enabled,
  });
};

/**
 * Get MSL user
 */
export const useGetMslUser = (enabled, successCallback, errorCallback) => {
  const { axiosPrivate } = useAxios();
  return useQuery(queryKeys.mslUser(), async () => {
    const { data } = await axiosPrivate.get('msl/account');
    return data.data?.user;
  }, {
    onSuccess: (payload) => {
      if (typeof successCallback === 'function') {
        successCallback(payload);
      }
    },
    onError: (error) => {
      if (typeof errorCallback === 'function') {
        errorCallback(error);
      }
    },
    enabled,
  });
};

/**
 * Get HCP types
 */
export const useHcpTypes = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(['hcp-types'], async () => {
    const { data } = await axiosPrivate.get('general/hcp-types');
    return data.data?.hcp_types || [];
  });
};

/**
 * Get US states
 */
export const useUsStates = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(['us-states'], async () => {
    const { data } = await axiosPrivate.get('general/us-states');
    return data.data?.us_states || [];
  });
};

/**
 * Get featured timezones
 */
export const useGetFeaturedTimezones = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(['timezone'], async () => {
    const { data } = await axiosPrivate.get('general/timezones/featured');
    return data.data?.featured_timezones || [];
  });
};

/**
 * Get MSL invite details
 */
export const useMslInviteDetails = ({ token }) => {
  const { axiosPrivate } = useAxios();
  return useQuery(['msl-invite-details', token], async () => {
    const { data } = await axiosPrivate.get(`/auth/msl/invite-details?token=${token}`);
    return data.data?.invite;
  });
};

/**
 * Get hcp addresses
 */
export const useGetHcpAddresses = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    ['hcp-addresses'],
    async () => {
      const { data } = await axiosPrivate.get('hcp/account/addresses');
      return data.data?.addresses;
    },
  );
};
