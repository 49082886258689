import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedMeetingId: null,
  showMeetingInfoDrawer: false,
};

export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedMeetingId: (state, action) => {
      state.selectedMeetingId = action.payload;
    },
    setShowMeetingInfoDrawer: (state, action) => {
      state.showMeetingInfoDrawer = action.payload;
    },
    // reset to initial state (such as when user logs out)
    resetState: () => INITIAL_STATE,
  },
});

export default meetingsSlice.reducer;
