import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import SelectDropDown from './SelectDropDown';

function SelectDateDropdown({
  value, onChange, timezone, label, name, ...props
}) {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const _populateDateList = () => {
      const currentDate = moment().tz(timezone);
      const next10Days = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        next10Days.push(moment(currentDate).add(i, 'days'));
      }

      const formattedDates = next10Days.map((day) => {
        let dateString = day.format('dddd, Do MMMM YYYY');
        const today = moment().tz(timezone);
        const tomorrow = moment().tz(timezone).add(1, 'days');
        if (day.isSame(today, 'day')) {
          dateString += ' (Today)';
        } else if (day.isSame(tomorrow, 'day')) {
          dateString += ' (Tomorrow)';
        }

        return {
          key: day.format('YYYY-MM-DD'),
          name: dateString,
        };
      });

      setDates(formattedDates);
    };
    _populateDateList();
  }, [timezone]);

  return (
    <SelectDropDown
      name={name}
      label={label}
      placeholder="Select date..."
      value={value}
      onChange={onChange}
      items={dates}
      {...props}
    />
  );
}

export default SelectDateDropdown;
