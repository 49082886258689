import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClockIcon } from '@heroicons/react/solid';

import { Tabs, ToggleSwitch } from '../../../common';

import { useGetSchedule } from '../../api/queries';
import { useSetActiveSchedule, useSetInActiveSchedule } from '../../api/mutations';

const tabs = [
  { name: 'Weekly hours', key: 'weekly-hours' },
  { name: 'Date overrides', key: 'date-overrides' },
  { name: 'Settings', key: 'settings' },
];

/* =============================================================================
<ScheduleHeader />
============================================================================= */
function ScheduleHeader() {
  const { scheduleId } = useParams();
  const { data: schedule } = useGetSchedule(scheduleId);
  const [active, setActive] = useState(schedule?.is_active);

  const { mutate: setScheduleActive, isSuccess: setActiveSucces } = useSetActiveSchedule();
  const { mutate: setScheduleInActive, isSuccess: setInActiveSuccess } = useSetInActiveSchedule();

  useEffect(() => {
    setActive(schedule?.is_active);
  }, [schedule?.is_active, scheduleId]);

  useEffect(() => {
    if (setActiveSucces) {
      setActive(true);
    }
  }, [setActiveSucces]);

  useEffect(() => {
    if (setInActiveSuccess) {
      setActive(false);
    }
  }, [setInActiveSuccess]);

  const _handleToggleStatus = () => {
    if (active) {
      setScheduleInActive(schedule?.id);
    } else {
      setScheduleActive(schedule?.id);
    }
  };

  return (
    <div className="bg-white border-b">
      <div className="border-b py-7">
        <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto w-full flex flex-col sm:flex-row space-y-5 sm:space-y-0 justify-between sm:items-center">
          <div>
            <h1 className="text-2xl font-bold font-[Inter] text-gray-900">
              {schedule?.name}
            </h1>
            <div className="flex mt-2 space-x-2">
              <ClockIcon className="w-5 h-5 text-gray-300" />
              <p className="font-[Inter] text-sm text-gray-700 font-medium">
                {schedule?.timezone}
              </p>
            </div>
          </div>
          <div className="flex space-x-4 items-center">
            <h3 className="font-[Inter] text-gray-400 text-sm font-semibold">Active</h3>
            <ToggleSwitch enabled={active} onChange={_handleToggleStatus} />
          </div>
        </div>
      </div>
      <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto pt-3 pb-3 md:pt-2 md:pb-0">
        <Tabs
          tabs={tabs}
          link={`/schedules/msl/${schedule?.id}/`}
          contentStyles="border-b-0"
          containerStyles="bg-white flex-1"
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleHeader;
