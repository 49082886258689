import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useAxios } from '../../util/hooks/useAxios';
import { queryKeys } from '../../products/api/query-key-factory';

/**
 * Get Notifications
 */
export const useGetNotifications = (filter, enabled) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    ['notifications', filter],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`shared/account/notifications?filter=${filter}&page=${pageParam}`);
      return data?.data;
    },
    {
      enabled,
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};
