import React from 'react';
import { SuccessModal } from '../../../../common';

function ProductOrderSubmitSuccess({ visible, onBtnClick }) {
  return (
    <SuccessModal
      visible={visible}
      onBtnClick={onBtnClick}
      btnVariant="purple"
      btnTxt="Done"
      title="Thank you for submitting your order"
      description="Your sales representative will be in touch with you."
    />
  );
}

export default ProductOrderSubmitSuccess;
