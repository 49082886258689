import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { StarIcon } from '@heroicons/react/solid';
import { Button } from '../../../../../common';

/* =============================================================================
<MeetingListItemRating />
============================================================================= */
const MeetingListItemRating = ({ meeting, toggleRatingModal }) => (
  <div className="text-gray-500 font-medium font-[Inter] z-50">
    <div className="text-center md:text-left">
      {meeting?.interaction_rating ? (
        <ReactStars
          count={5}
          size={19}
          value={meeting?.interaction_rating?.rating}
          edit={false}
          activeColor="#FBBF24"
          color="#E5E7EB"
        />
      ) : (
        <Button
          customStyleClasses="w-52 z-50"
          title="Rate interaction"
          variant="secondary"
          icon={<StarIcon className="w-5 h-5 text-yellow-400" />}
          onClick={(e) => {
            // Stop the parent button (meeting list item) from opening
            // the meeting/being clicked, as this is within the button
            e.preventDefault();
            e.stopPropagation();
            toggleRatingModal();
          }}
        />
      )}
    </div>
  </div>
);

/* Export
============================================================================= */
export default MeetingListItemRating;
