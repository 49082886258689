import { useState, useEffect } from 'react';
import { useChannel, useEvent } from '@harelpls/use-pusher';

export default function useUserOnlineStatus(
  userId,
  initialUserAvailability,
  useCacheChannel = false,
) {
  const [availability, setAvailability] = useState(null);

  const channelName = useCacheChannel ? `private-cache-users.${userId}.availability` : `private-users.${userId}.availability`;

  const channel = useChannel(userId ? channelName : null);

  useEvent(channel, 'user-availability-status-changed', ({ data }) => {
    setAvailability(data);
  });

  useEffect(() => {
    setAvailability(initialUserAvailability);
  }, [initialUserAvailability]);

  if (!userId) {
    return null;
  }

  return availability;
}
