import React, { useState } from 'react';
import { Carousel, EmptyState, Spinner } from '../../../common';
import LiveUpdatesItem from './LiveUpdatesItem';
import LiveUpdatesHeader from './LiveUpdatesHeader';
import { ReactComponent as EmptyStateIcon } from '../../../assets/icons/updates-empty-state-icon.svg';
import { useGetProductsUpdates } from '../../../products/api/queries';
import ProductLiveUpdates from '../../../products/components/ProductLiveUpdates';

/* =============================================================================
<LiveUpdates />
============================================================================= */
function LiveUpdates() {
  const { data: productsUpdates, isLoading: isProductsUpdatesLoading } = useGetProductsUpdates();
  const isDataPresent = !!(productsUpdates?.products_with_unviewed_live_updates.length || productsUpdates?.products_without_unviewed_live_updates.length);
  const [showStoriesModal, setShowStoriesModal] = useState(false);
  const [productIdToShow, setProductIdToShow] = useState(null);

  const viewProduct = (productId) => {
    setProductIdToShow(productId);
    setShowStoriesModal(true);
  };

  if (!isDataPresent && !isProductsUpdatesLoading) {
    return (
      <div className="">
        <LiveUpdatesHeader />
        <div className="bg-white flex flex-col justify-center items-center pt-6 pb-8 px-8 rounded-lg">
          <EmptyState
            icon={<EmptyStateIcon />}
            title="No live product updates"
          />
        </div>
      </div>
    );
  }

  if (isProductsUpdatesLoading) {
    return (
      <div className="">
        <LiveUpdatesHeader />
        <div className="text-center">
          <Spinner size={25} />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <LiveUpdatesHeader />
      {isDataPresent && (
        <>
          <Carousel>
            {/* Firstly, show products with unseen updates (even if they have seen updates as well) */}
            {productsUpdates?.products_with_unviewed_live_updates?.length > 0
              && productsUpdates?.products_with_unviewed_live_updates?.map((product) => (
                <LiveUpdatesItem
                  key={product.id}
                  product={product}
                  hasUnseenUpdates
                  viewProduct={viewProduct}
                />
              ))}
            {/* Then, show products with viewed updates, if they have no unseen updates (they've seen all updates related to the product) */}
            {productsUpdates?.products_without_unviewed_live_updates?.filter((product) => productsUpdates.unviewed_live_updates_count_by_product_id[product.id] == null).map((product) => (
              <LiveUpdatesItem
                key={product.id}
                product={product}
                hasUnseenUpdates={false}
                viewProduct={viewProduct}
              />
            ))}
          </Carousel>
          <ProductLiveUpdates
            productId={productIdToShow}
            showModal={showStoriesModal}
            setShowModal={setShowStoriesModal}
          />
        </>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default LiveUpdates;
