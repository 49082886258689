import { createSlice } from '@reduxjs/toolkit';
import { localStorageKeys } from '../../config/constants';

const token = localStorage.getItem(localStorageKeys.discreedlyAccessToken);

const INITIAL_STATE = {
  isAuthenticated: !!token,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default authSlice.reducer;
