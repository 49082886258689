/* eslint-disable react/button-has-type */
import React, { useId } from 'react';
import { Tooltip } from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import Spinner from './Spinner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Button({
  type,
  title,
  size,
  icon,
  iconPosition,
  disabled,
  onClick,
  loading,
  variant,
  customStyleClasses,
  tooltipContent,
  tooltipPosition,
}) {
  const id = useId();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const commonStyles = 'flex justify-center hover:opacity-75 items-center font-[Inter] appearance-none focus:outline-none';
  const sizeStyles = `rounded-md px-2 sm:px-3.5 py-2 h-auto sm:h-10 ${
    size === 'block' && 'w-full'
  }`;
  const variantStyles = `${
    variant === 'primary'
      ? 'bg-primary-500 text-white disabled:opacity-75'
      : variant === 'secondary'
        ? 'bg-white border shadow-sm border-gray-300 font-medium text-gray-700 bg-white focus:outline-none disabled:opacity-50 hover:bg-gray-75'
        : variant === 'blue'
          ? 'bg-blue-500 text-white disabled:opacity-75'
          : variant === 'light'
            ? 'bg-indigo-100 text-indigo-500 disabled:opacity-75'
            : variant === 'purple'
              ? 'bg-indigo-600 border border-indigo-600 text-white disabled:opacity-75'
              : variant === 'purpleLight'
                ? 'bg-indigo-100 text-indigo-500 disabled:opacity-75'
                : variant === 'red'
                  ? 'bg-red-600 text-white disabled:opacity-75'
                  : variant === 'transparent'
                    ? 'bg-transparent text-white border border-gray-600 disabled:opacity-75'
                    : variant === 'green'
                      ? 'bg-green-500 text-white border border-green-500 disabled:opacity-75'
                      : variant === 'red-secondary'
                        ? 'bg-white border shadow-sm border-gray-300 font-medium text-red-500 bg-white focus:outline-none disabled:opacity-50 hover:bg-gray-75' : ''
  } text-sm font-medium`;

  // Important: The <span must be there as chrome won't show a tooltip on a disabled element.

  return (
    <>
      <button
        type={type || 'button'}
        disabled={disabled}
        className={classNames(
          variantStyles,
          sizeStyles,
          commonStyles,
          customStyleClasses,
        )}
        onClick={onClick}
        id={id}
        data-tooltip-content={tooltipContent}
        data-tooltip-place={tooltipPosition}
      >
        {!loading ? (
          <>
            {(iconPosition === 'left' && icon) && <span className="mr-2">{icon}</span>}
            <p className="">{title}</p>
            {(iconPosition === 'right' && icon) && <span className="ml-2">{icon}</span>}
          </>
        ) : (
          <div className="px-5 -mt-0.5">
            <Spinner size={15} color={variant === 'secondary' ? '#999' : '#fff'} />
          </div>
        )}
      </button>
      {(tooltipContent && !isMobile) && <Tooltip anchorId={id} />}
    </>
  );
}

Button.defaultProps = {
  variant: 'primary',
  iconPosition: 'left',
  tooltipPosition: 'bottom',
};

export default Button;
