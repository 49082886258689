import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Device } from '@twilio/voice-sdk';
import { useStopwatch } from 'react-timer-hook';
import { CogIcon, MicrophoneIcon } from '@heroicons/react/solid';
import CallActionButton from '../../common/CallActionButton';
import { ReactComponent as EndCallIcon } from '../../assets/icons/edit-phone-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone-icon.svg';
import { ReactComponent as XIcon } from '../../assets/icons/X.svg';
import { useVOIPCallAccessToken } from '../api/mutations';
import { Avatar, AnimatedDotDotDot, Spinner } from '../../common';
import { useGetProduct } from '../../products/api/queries';
import ProductPlaceholderImg from '../../assets/icons/placeholder-product-image-large.png';
import { hcpPaths } from '../../config/paths';
import VOIPCallSettingsModal from '../components/VOIP/VOIPCallSettingsModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const callStates = {
  notStarted: 'notStarted',
  dialing: 'dialing',
  rejected: 'rejected',
  accepted: 'accepted',
  reconnecting: 'reconnecting',
  reconnected: 'reconnected',
  disconnect: 'disconnect',
};

/* =============================================================================
<VOIPCallScreen />
============================================================================= */
function VOIPCallScreen() {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const navigate = useNavigate();
  const { productId } = useParams();

  const { mutate: getVOIPCallAccessToken } = useVOIPCallAccessToken();
  const { data: product, status: productDataStatus } = useGetProduct(productId);
  const [device, setDevice] = useState(null);

  const [showSettings, setShowSettings] = useState(false);
  const [currentTwilioCall, setCurrentTwilioCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);

  const {
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    reset,
  } = useStopwatch({ autoStart: false });

  const [callState, setCallState] = useState(callStates.notStarted);

  useEffect(() => {
    getVOIPCallAccessToken({}, {
      onSuccess: (payload) => {
        const twilioDevice = new Device(payload?.data?.data?.token);
        setDevice(twilioDevice);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device) {
      device.on('error', (error) => {
        // eslint-disable-next-line no-console
        console.error(error); // TODO: handle error, without console.error
      });
    }
  }, [device]);

  const _handleToggleMute = () => {
    if (currentTwilioCall) {
      if (isMuted) {
        currentTwilioCall.mute(false);
        setIsMuted(false);
      } else {
        currentTwilioCall.mute(true);
        setIsMuted(true);
      }
    }
  };

  const _handleGoBack = () => {
    navigate(hcpPaths.product(productId).home);
  };
  const _handleEndCall = () => {
    if (currentTwilioCall) {
      currentTwilioCall.disconnect();
      setCurrentTwilioCall(null);
    }
  };

  const _startCall = async () => {
    if (!device) {
      return;
    }

    if (currentTwilioCall) {
      // eslint-disable-next-line no-alert
      alert('There is an ongoing call already.');
      return;
    }

    device.disconnectAll();

    reset();
    start();

    setCallState(callStates.dialing);

    setIsMuted(false);

    const call = await device.connect({
      params: {
        voip_call_type_key: 'product-hotline',
        product_id: productId,
      },
    });

    setCurrentTwilioCall(call);
  };

  useEffect(() => {
    if (currentTwilioCall) {
      currentTwilioCall.on('accept', () => {
        setCallState(callStates.accepted);
      });

      currentTwilioCall.on('reject', () => {
        setCallState(callStates.rejected);
        setCurrentTwilioCall(null);
      });

      currentTwilioCall.on('reconnecting', () => {
        setCallState(callStates.reconnecting);
      });

      currentTwilioCall.on('reconnected', () => {
        setCallState(callStates.reconnected);
      });

      currentTwilioCall.on('disconnect', () => {
        setCallState(callStates.disconnect);
        setCurrentTwilioCall(null);
      });
    }
  }, [currentTwilioCall]);

  return (
    <>
      <div className="h-full w-full bg-gray-800 flex flex-col space-y-6 sm:space-y-10 p-4 sm:p-12">
        <div className="relative flex-1 rounded-lg flex flex-col justify-center items-center text-center">
          {productDataStatus === 'success' && product ? (
            <>
              <div className={classNames((callState === callStates.accepted || callState === callStates.dialing) ? 'animate-pulse' : null)}>
                <Avatar
                  border
                  size="extraLarge"
                  name={product.name}
                  source={product.main_image_path ? product.main_image_path : ProductPlaceholderImg}
                />
              </div>
              <h2 className="mt-5 mb-2 text-2xl font-semibold text-white text-center">{product.name}</h2>
              {callState === callStates.notStarted && (
                <span className="text-white text-sm">{`Call the ${product.pharma_company.name} product hotline`}</span>
              )}
              {callState === callStates.dialing && (
                <AnimatedDotDotDot customStyles="text-white text-sm" prefixText={`Connecting to ${product.pharma_company.name} product hotline`} />
              )}
              {callState === callStates.accepted && isRunning && (
                <span className="text-white text-sm">{`${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`}</span>
              )}
              {callState === callStates.reconnecting && (
                <AnimatedDotDotDot customStyles="text-white text-sm" prefixText="Reconnecting" />
              )}
              {callState === callStates.reconnected && (
                <span className="text-white text-sm">Reconnected</span>
              )}
              {callState === callStates.rejected && (
                <span className="text-white text-sm">Rejected</span>
              )}
              {callState === callStates.disconnect && (
                <span className="text-white text-sm">Disconnected</span>
              )}
            </>
          ) : (
            <Spinner size={25} color="#ffffff" />
          )}
        </div>
        <div className="flex w-fit space-x-3 bg-gray-700 p-3 rounded-full justify-center items-center m-auto block">
          {(device && isChrome) && (
            <CallActionButton
              icon={<CogIcon className="w-5 h-5 text-black" />}
              onClick={() => setShowSettings(true)}
              tooltipContent="Audio settings"
              tooltipPosition="top"
            />
          )}
          {currentTwilioCall ? (
            <>
              <CallActionButton
                isOff={isMuted}
                icon={<MicrophoneIcon className="w-5 h-5 text-black" />}
                onClick={_handleToggleMute}
                tooltipContent={isMuted ? 'Unmute' : 'Mute'}
                tooltipPosition="top"
              />
              <CallActionButton
                variant="red"
                icon={<EndCallIcon />}
                onClick={_handleEndCall}
              />
            </>
          ) : (
            <>
              <CallActionButton
                variant="white"
                icon={<XIcon />}
                onClick={_handleGoBack}
                tooltipContent="Back to product page"
                tooltipPosition="top"
              />
              <CallActionButton
                disabled={currentTwilioCall || productDataStatus !== 'success' || !device}
                variant="green"
                icon={<PhoneIcon />}
                onClick={_startCall}
                tooltipContent="Call product hotline"
                tooltipPosition="top"
              />
            </>
          )}
        </div>
      </div>
      {isChrome && (
        <VOIPCallSettingsModal
          visible={showSettings && device != null}
          onClose={() => setShowSettings(false)}
          device={device}
        />
      )}
    </>
  );
}

/* Export
============================================================================= */
export default VOIPCallScreen;
