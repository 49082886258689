import { MicrophoneIcon } from '@heroicons/react/solid';
import React from 'react';
import { RoomType as RoomTypes } from 'sendbird-calls';

import { Avatar } from '../../../../common';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<GroupCallMediaContent />
============================================================================= */
function GroupCallMediaContent({ room }) {
  const { participants, localParticipant, roomType } = room;

  if (!room) {
    return null;
  }

  return (
    <div
      className={classNames(
        'flex-1 grid gap-5 grid-cols-12 w-full h-full overflow-hidden',
      )}
    >
      {participants.map((p, index) => (p && (
        <div
          className={classNames(
            'relative h-full bg-gray-700 rounded-lg overflow-hidden w-full h-full',
            participants.length === 1 && 'col-span-12',
            participants.length === 2 && 'col-span-12 sm:col-span-6',
            participants.length === 3 && 'col-span-12 sm:col-span-6',
            participants.length === 3 && index === 2 && 'sm:col-span-3 sm:col-start-4',
            participants.length === 4 && 'col-span-12 sm:col-span-6',
            participants.length === 5 && 'col-span-12 sm:col-span-6',
            participants.length === 5 && index === 4 && 'sm:col-span-3 sm:col-start-4',
            participants.length === 6 && 'col-span-12 sm:col-span-6',
          )}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            autoPlay
            playsInline
            className="w-full h-full"
            muted={p?.participantId === localParticipant?.participantId}
            ref={(el) => {
              if (!el) return;
              p?.setMediaView(el);
            }}
          />
          {(!p?.isVideoEnabled || roomType === RoomTypes.LARGE_ROOM_FOR_AUDIO_ONLY) && (
            <div className="flex justify-center items-center absolute bg-gray-700 left-0 top-0 w-full h-full">
              <div>
                <Avatar
                  source={p?.user.profileUrl}
                  name={p?.user?.nickname}
                  border
                  size={participants?.length <= 4 ? 'extraLarge' : 'extraMedium'}
                />
              </div>
            </div>
          )}
          <div className="flex w-full items-center justify-between absolute left-0 bottom-1 px-6 py-4 text-white">
            <div className="bg-gray-200/20 px-3 py-1 rounded-full">
              <h2 className="text-white text-xs">
                {p?.participantId === localParticipant?.participantId ? 'You' : p?.user?.nickname}
              </h2>
            </div>
            <div className="relative rounded-full flex justify-center items-center">
              <MicrophoneIcon className="w-5 h-5 text-white" />
              {!p?.isAudioEnabled && <div className="absolute w-1 h-6 -rotate-45 rounded-full bg-red-500" /> }
            </div>
          </div>
        </div>
      )))}
    </div>
  );
}

/* Export
============================================================================= */
export default GroupCallMediaContent;
