/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Input({
  label, type, errorText, icon, onIconClick, disabled, ...props
}) {
  return (
    <div>
      {label && (
        <div className="flex justify-between mb-1">
          <label className="block text-sm font-medium font-[Inter] text-gray-700">
            {label}
          </label>
        </div>
      )}
      <div className="relative">
        <input
          type={type || 'text'}
          className={classNames(
            disabled ? 'text-gray-500' : null,
            'appearance-none block w-full px-3 py-2 rounded-md shadow-sm placeholder-gray-400} focus:outline-none sm:text-sm border focus:border-indigo-500 focus:ring-indigo-500 border-gray-300',
          )}
          disabled={disabled}
          autoComplete={type !== 'email' ? 'false' : undefined}
          {...props}
        />
        {icon && (
          <button
            type="button"
            onClick={onIconClick}
            className="absolute right-2 bottom-0 top-0 justify-center align-center px-2 py-1 rounded-sm hover:opacity-80"
            tabIndex="-1"
          >
            {icon}
          </button>
        )}
      </div>
      {!!errorText && (
        <p className="text-sm  font-medium font-[Inter]  py-1 text-red-500">
          !
          {errorText}
        </p>
      )}
    </div>
  );
}

export default Input;
