import React from 'react';
import Stories from 'react-insta-stories';
import { Modal, Spinner } from '../../../../../common';
import { useMarkUpdateAsViewed } from '../../../../api/mutations';
import Header from './Header';

/* =============================================================================
<LiveUpdateModal />
============================================================================= */
function LiveUpdateModal({
  onClose,
  visible,
  stories,
  currentStoryIndex,
}) {
  const windowHeight = window.innerHeight;

  const { mutate: markUpdateAsViewed } = useMarkUpdateAsViewed();
  const handleStoryStart = (index, story) => {
    markUpdateAsViewed({
      updateId: story?.update?.id,
    });
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      customStyles="bg-transparent shadow-none flex justify-center select-none" // select-none is important to prevent selection of anything when holding to pause the story on mobile especially
      showHeader={false}
      size="md"
      closeWhenClickOutside
    >
      {stories && stories?.length > 0 ? (
        <Stories
          height={windowHeight > 850 ? 750 : (windowHeight - 100)}
          width="100%"
          header={Header}
          stories={stories}
          currentIndex={currentStoryIndex}
          keyboardNavigation
          onStoryStart={handleStoryStart} // Do this on story start, and not end, as the onStoryEnd is not triggering when the user skips story manually, it only triggers when the story ends automatically
          onAllStoriesEnd={onClose}
          storyStyles={{
            width: '100%', // This will make videos 100% width on all browsers (without this, Safari will show the video in a small box)
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto',
            // make images not selectable (and can't hold to click save image) as on mobile, holding to pause the story will also select the image
            userSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            pointerEvents: 'none',
            WebkitTouchCallout: 'none',
          }}
        />
      ) : (
        <Spinner size={30} color="white" />
      )}
    </Modal>
  );
}

/* Export
============================================================================= */
export default LiveUpdateModal;
