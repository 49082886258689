import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import HcpLoginScreen from './HcpLoginScreen';
import MslLoginScreen from './MslLoginScreen';
import HcpRegisterScreen from './HcpRegisterScreen';
import PasswordResetScreen from './PasswordResetScreen';
import PasswordResetRequestScreen from './PasswordResetRequestScreen';
import PasswordResetRequestSuccessScreen from './PasswordResetRequestSuccessScreen';
import MslInviteAcceptScreen from './MslInviteAcceptScreen';
import UnauthenticatedRoute from '../../navigation/UnauthenticatedRoute';
import AuthHomeScreen from './AuthHomeScreen';

/* =============================================================================
<AuthScreens />
============================================================================= */
function AuthScreens() {
  return (
    <Routes>
      <Route element={<UnauthenticatedRoute />}>
        <Route path="/" element={<AuthHomeScreen />} />
      </Route>
      <Route path="/auth/*">
        <Route element={<UnauthenticatedRoute />}>
          <Route path="hcp/login" element={<HcpLoginScreen />} />
          <Route path="request-password-reset" element={<PasswordResetRequestScreen />} />
          <Route path="request-password-reset-success/:email" element={<PasswordResetRequestSuccessScreen />} />
          <Route path="reset-password/:token" element={<PasswordResetScreen />} />
          <Route path="hcp/register" element={<HcpRegisterScreen />} />
          <Route path="msl/login" element={<MslLoginScreen />} />
          <Route path="msl/accept-invite/:token" element={<MslInviteAcceptScreen />} exact />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default AuthScreens;
