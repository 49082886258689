import React, { Fragment, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/solid';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import ScheduleDateOverridesHeader from './ScheduleDateOverridesHeader';
import ScheduleDateOverridesItem from './ScheduleDateOverridesItem';
import { Button, EmptyState, Spinner } from '../../../../common';
import { useGetScheduleDateOverrides } from '../../../api/queries';
import DateOverrideEmptyStateImage from '../../../../assets/icons/empty-state/DateOverrideEmptyState.png';
import ScheduleDateOverrideCreateModal from './ScheduleDateOverrideCreateModal';

/* =============================================================================
<ScheduleDateOverrides />
============================================================================= */
function ScheduleDateOverrides() {
  const { scheduleId } = useParams();
  const {
    data,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetScheduleDateOverrides(scheduleId);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const _toggleCreateModal = () => setShowCreateModal((prev) => !prev);

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage,
    onLoadMore: fetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !hasNextPage,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 5px 0px',
  });

  return (
    <div className="flex flex-col bg-white border px-6 sm:px-8 py-6 rounded-lg">
      <ScheduleDateOverridesHeader toggleCreateModal={_toggleCreateModal} />
      {(data && data?.pages[0]?.pagination.total !== 0) && (
        <div className="border border-gray-300 divide-y divide-gray-300 rounded-lg mt-5">
          {data?.pages?.map((page, i) => (
            <Fragment key={page?.pagination?.page}>
              {/* eslint-disable-next-line max-len */}
              {page?.msl_schedule_date_overrides?.length > 0 && page?.msl_schedule_date_overrides?.map((override) => (
                <ScheduleDateOverridesItem
                  key={override?.id}
                  override={override}
                />
              ))}
            </Fragment>
          ))}
          {hasNextPage && (
            <div ref={sentryRef} className="text-center py-4">
              <Spinner />
            </div>
          )}
        </div>
      )}

      {/* loading spinner */}
      {isLoading && (
        <div className="w-full text-center mb-2">
          <Spinner />
        </div>
      )}

      {/* if no data */}
      {(isSuccess && data?.pages[0]?.pagination.total === 0) && (
        <div className="items-center justify-center h-full flex-1 flex pt-7 pb-10">
          <div className="text-center max-w-sm">
            <EmptyState
              title="Date overrides"
              description="Adding a date override is useful for when you have exceptions to your weekly hours."
              icon={<img src={DateOverrideEmptyStateImage} alt="" className="w-72" />}
            />
            <Button
              onClick={_toggleCreateModal}
              customStyleClasses="mt-5 mx-auto"
              variant="purple"
              title="Add date override"
              icon={<PlusIcon className="text-white w-5 h-5" />}
            />
          </div>
        </div>
      )}

      <ScheduleDateOverrideCreateModal visible={showCreateModal} onClose={_toggleCreateModal} />
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleDateOverrides;
