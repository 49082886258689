import { useMutation } from '@tanstack/react-query';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Mark Event As Clicked
 */
export const useMarkEventAsClicked = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/events/${data}/mark-as-clicked`, data));
};
