/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Button, Input, SelectDropDown } from '../../../common';
import ManageProfileUpdateProfileImage from './ManageProfileUpdateProfileImage';
import { getUser } from '../../../auth/redux/selectors';
import { useMslProfileUpdate } from '../../api/mutations';
import { useUsStates } from '../../../auth/api/queries';
import Spinner from '../../../common/Spinner';
import { client as queryClient } from '../../../config/react-query';
import { queryKeys as authQueryKeys } from '../../../auth/api/query-key-factory';
import TextArea from '../../../common/TextArea';

/* =============================================================================
<ManageProfileMslForm />
============================================================================= */
function ManageProfileMslForm() {
  const user = useSelector(getUser);
  const { data: usStates, status: usStatesStatus } = useUsStates();

  const {
    isLoading,
    mutate: updateProfile,
  } = useMslProfileUpdate();

  // IANA timezone names
  const timezones = moment.tz.names().map((timezone) => ({
    key: timezone,
    name: timezone,
  }));

  const initialValues = {
    name: user?.name,
    bio: user?.msl?.bio,
    timezone: user?.timezone,
    us_state_key: user?.msl?.us_state?.key,
  };

  const _handleSubmit = (values) => {
    if (values) {
      updateProfile(values, {
        onSuccess: () => {
          queryClient.invalidateQueries(authQueryKeys.hcpUser());
          queryClient.invalidateQueries(authQueryKeys.mslUser());
        },
      });
    }
  };

  if (usStatesStatus !== 'success') {
    return (
      <Spinner />
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
      {({ values }) => (
        <Form className="xl:mr-96">
          <ManageProfileUpdateProfileImage />
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 my-6">
            <div>
              <Field name="name">
                {({ field }) => (
                  <div className="flex-1">
                    <Input
                      label="Name"
                      {...field}
                    />
                  </div>
                )}
              </Field>
            </div>
            <Field name="us_state_key">
              {({ field }) => (
                <div className="flex-1">
                  <SelectDropDown
                    label="State"
                    placeholder="Select..."
                    items={usStates}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="timezone">
              {({ field }) => (
                <div className="flex-1 sm:col-span-2">
                  <SelectDropDown
                    label="Timezone"
                    placeholder="Select..."
                    items={timezones}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="bio">
              {({ field }) => (
                <div className="col-span-full">
                  <TextArea
                    label="Bio"
                    placeholder="Enter your bio..."
                    customContainerStyles="w-full flex-1"
                    rows={8}
                    {...field}
                  />
                </div>
              )}
            </Field>
          </div>
          <Button
            type="submit"
            title="Save"
            variant="purple"
            loading={isLoading}
            customStyleClasses="w-full sm:w-28 mt-8"
          />
        </Form>
      )}
    </Formik>
  );
}

/* Export
============================================================================= */
export default ManageProfileMslForm;
