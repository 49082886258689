import React from 'react';
import { useParams } from 'react-router-dom';
import { EmojiSadIcon } from '@heroicons/react/outline';

import ProductMslCard from '../ProductMslCard';

import { useSearchAllMslsForProduct } from '../../../api/queries';
import { EmptyState, Spinner } from '../../../../common';

/* =============================================================================
<ProductMslAll />
============================================================================= */
function ProductMslAll() {
  const { productId } = useParams();

  const {
    data: allMslsForProduct,
    isLoading: allMslsLoading,
  } = useSearchAllMslsForProduct(productId);

  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-1 h-full flex flex-col">
      {/* loading */}
      {allMslsLoading && (
        <div className="text-center mt-8">
          <Spinner />
        </div>
      )}

      {(!allMslsLoading && allMslsForProduct?.length !== 0) && (
        <div className="grid grid-cols-1 gap-y-5 gap-x-5 md:grid-cols-2 2xl:grid-cols-3 py-8">
          {allMslsForProduct?.map((msl) => (
            <ProductMslCard
              key={msl?.id}
              msl={msl}
              productId={productId}
            />
          ))}
        </div>
      )}

      {/* empty state */}
      {(!allMslsLoading && !allMslsForProduct?.length > 0) && (
        <div className="items-center justify-center h-full flex-1 flex">
          <div className="text-center">
            <EmptyState
              title="No MSLs found"
              icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
              description="Currently there are no MSLs for this product"
            />
          </div>
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ProductMslAll;
