import React from 'react';

import ChatChannelSingleChat from './ChatChannelSingleChat';
import ChatChannelGroupChat from './ChatChannelGroupChat';

/* =============================================================================
<ChatChannelName />
============================================================================= */
function ChatChannelName({
  channel, forChatHeader, userAvailability,
}) {
  const membersCount = channel?.memberCount;

  if (membersCount > 2) {
    return <ChatChannelGroupChat channel={channel} forChatHeader={forChatHeader} />;
  }

  return (
    <ChatChannelSingleChat
      channel={channel}
      forChatHeader={forChatHeader}
      userAvailability={userAvailability}
    />
  );
}

/* Export
============================================================================= */
export default ChatChannelName;
