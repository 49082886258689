/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useId } from 'react';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function TextArea({
  label, value, onChange, icon, disabled, customStyles, customContainerStyles, rows, ...props
}) {
  const idOne = useId();

  return (
    <div className={customContainerStyles}>
      {label && (
        <label htmlFor={idOne} className="block text-sm font-medium font-[Inter] text-gray-700 mb-2">
          {label}
        </label>
      )}

      <div className="">
        <textarea
          rows={rows}
          name={idOne}
          id={idOne}
          className={classNames(
            customStyles,
            'block w-full rounded-md shadow-sm border border focus:border-indigo-500 focus:ring-indigo-500 border-gray-300 sm:text-sm',
          )}
          disabled={disabled}
          onChange={onChange}
          {...props}
        >
          {value}
        </textarea>
      </div>
    </div>
  );
}

TextArea.defaultProps = {
  rows: 4,
};

TextArea.propTypes = {
  rows: PropTypes.number,
};

export default TextArea;
