import React, { useState } from 'react';
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid';

/* =============================================================================
<ScheduleNotActiveInfoBox />
============================================================================= */
function ScheduleNotActiveInfoBox() {
  return (
    <div className="p-4 rounded-lg bg-yellow-50 justify-between">
      <div className="flex items-center space-x-3">
        <ExclamationCircleIcon className="w-5 h-5 text-yellow-400" />
        <h2 className="text-sm text-yellow-800 font-[Inter] font-medium">Please set an active schedule</h2>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleNotActiveInfoBox;
