import React from 'react';
import { WithSeeMore } from 'react-insta-stories';
import LiveUpdateModalItem from './LiveUpdatesModal/LiveUpdateModalItem';

/* =============================================================================
<Content />
============================================================================= */
const Content = ({
  isPaused, action, story, onClose,
}) => (
  <WithSeeMore story={story} action={action}>
    <LiveUpdateModalItem
      story={story}
      onClose={onClose}
      isPaused={isPaused}
    />
  </WithSeeMore>
);

/* Export
============================================================================= */
export default Content;
