import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Channel from '@sendbird/uikit-react/Channel';
import { ChatIcon, EmojiSadIcon } from '@heroicons/react/outline';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import ChatChannelHeader from './ChatChannelHeader';
import { updateSelectedSendBirdChannelUrl } from '../../redux/actions';
import { EmptyState, Spinner } from '../../../common';

/* =============================================================================
<ChatChannel />
============================================================================= */
function ChatChannel() {
  const dispatch = useDispatch();
  const { channelUrl } = useParams();

  useEffect(() => {
    dispatch(updateSelectedSendBirdChannelUrl(channelUrl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelUrl]);

  // When component is unmounted, update redux state to null
  useEffect(() => () => {
    dispatch(updateSelectedSendBirdChannelUrl(null));
  });

  // Show empty state when channelUrl is not provided
  if (!channelUrl) {
    return null;
  }

  return (
    <div className="flex-1 w-full flex flex-col">
      <Channel
        channelUrl={channelUrl}
        renderChannelHeader={() => <Header />}
        isReactionEnabled={false}
        disableUserProfile // disables popup when clicking on user profile image
        renderPlaceholderLoader={() => (
          <div className="text-center mt-6">
            <Spinner />
          </div>
        )}
        renderPlaceholderInvalid={() => (
          <div className="text-center h-full justify-center flex py-10">
            <EmptyState
              title="Chat not found"
              icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
              description="We couldn't find the chat you're looking for."
            />
          </div>
        )}
        renderPlaceholderEmpty={() => (
          <div className="text-center h-full justify-center flex py-10">
            <EmptyState
              title="Start a conversation"
              icon={<ChatIcon className="w-9 h-9  text-gray-400" />}
              description="Send the first message"
            />
          </div>
        )}
      />
    </div>
  );
}

const Header = () => {
  const { currentGroupChannel } = useChannelContext();

  return <ChatChannelHeader channel={currentGroupChannel} />;
};

/* Export
============================================================================= */
export default ChatChannel;
