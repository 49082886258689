import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Link,
  Input,
  Checkbox,
  Toast,
  SelectDropDown,
} from '../../common';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open-icon.svg';
import { ReactComponent as EyeCloseIcon } from '../../assets/icons/eye-close-icon.svg';
import { setIsAuthenticated } from '../redux/actions';
import { useHcpRegister } from '../api/mutations';
import { useHcpTypes, useUsStates } from '../api/queries';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<HcpRegisterScreen />
============================================================================= */
function HcpRegisterScreen() {
  const dispatch = useDispatch();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);
  const [policyAccept, setPolicyAccept] = useState(false);
  const {
    isLoading: registerLoading,
    mutate: register,
  } = useHcpRegister();
  const { data: hcpTypes } = useHcpTypes();
  const { data: usStates } = useUsStates();

  const _togglePassword = () => setPasswordToggle((prev) => !prev);

  const _toggleConfirmPassword = () => setConfirmPasswordToggle((prev) => !prev);

  const _handleSubmit = (values) => {
    if (policyAccept) {
      register(values, {
        onSuccess: (payload) => {
          localStorage.setItem(localStorageKeys.discreedlyAccessToken, payload?.data.data?.access_token);
          localStorage.setItem(localStorageKeys.discreedlyUserType, 'hcp');
          dispatch(setIsAuthenticated(true));
        },
      });
    } else {
      toast.custom((toastProps) => (
        <Toast
          status="warn"
          title="Please accept our terms and privacy policy to continue"
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-extrabold font-[Inter] text-gray-900">
          Register your HCP account
        </h2>
        <p className="mt-2 mb-4 text-sm text-gray-600">
          Or
          {' '}
          <Link to="/auth/hcp/login" label="sign in to your account" />
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={_handleSubmit}
      >
        {({ values }) => (
          <Form className="space-y-6">
            <Field name="name">
              {({ field }) => (
                <Input
                  label="Name"
                  {...field}
                />
              )}
            </Field>
            <Field name="email">
              {({ field }) => (
                <Input
                  label="Email address"
                  {...field}
                />
              )}
            </Field>
            <Field name="license_number">
              {({ field }) => (
                <Input
                  label="NPI license number"
                  {...field}
                />
              )}
            </Field>
            <Field name="hcp_type_key">
              {({ field }) => (
                <div className="flex-1">
                  <SelectDropDown
                    label="Healthcare provider type"
                    placeholder="Select type..."
                    items={hcpTypes}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="us_state_key">
              {({ field }) => (
                <div className="flex-1">
                  <SelectDropDown
                    label="State"
                    placeholder="Select state..."
                    items={usStates}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="password">
              {({ field }) => (
                <Input
                  label="Password"
                  type={!passwordToggle ? 'password' : 'text'}
                  icon={passwordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                  onIconClick={_togglePassword}
                  {...field}
                />
              )}
            </Field>
            <Field name="password_confirmation">
              {({ field }) => (
                <Input
                  label="Confirm Password"
                  type={!confirmPasswordToggle ? 'password' : 'text'}
                  icon={confirmPasswordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                  onIconClick={_toggleConfirmPassword}
                  {...field}
                />
              )}
            </Field>
            <Checkbox
              checked={policyAccept}
              label={(
                <p className="text-left">
                  I accept the
                  {' '}
                  <a
                    href="https://www.discreedly.com"
                    target="blank"
                    className="text-primary-700 hover:text-red-400
                  focus:outline-none focus:ring focus:ring-red-400 hover:underline"
                  >
                    terms
                  </a>
                  {' '}
                  and
                  {' '}
                  <a
                    href="https://www.discreedly.com"
                    target="blank"
                    className="text-primary-500 hover:text-red-400
                  focus:outline-none focus:ring focus:ring-red-400 hover:underline"
                  >
                    privacy policy
                  </a>
                </p>
              )}
              onChange={(e) => setPolicyAccept(e.target.checked)}
              name="accept-terms-and-privacy-policy"
            />
            <Button type="submit" loading={registerLoading} title="Sign up" size="block" />
          </Form>
        )}
      </Formik>
    </div>
  );
}

const initialValues = {
  name: '',
  email: '',
  password: '',
  us_state_key: '',
  license_number: '',
  password_confirmation: '',
  hcp_type_key: '',
};

/* Export
============================================================================= */
export default HcpRegisterScreen;
