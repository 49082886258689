import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ClockIcon } from '@heroicons/react/solid';

import { ToggleSwitch } from '../../../common';
import { useSetActiveSchedule, useSetInActiveSchedule } from '../../api/mutations';
import { mslPaths } from '../../../config/paths';

/* =============================================================================
<ScheduleItem />
============================================================================= */
function ScheduleItem({ schedule }) {
  const [active, setActive] = useState(schedule?.is_active);
  const { mutate: setScheduleActive } = useSetActiveSchedule();
  const { mutate: setScheduleInActive } = useSetInActiveSchedule();

  useEffect(() => {
    setActive(schedule?.is_active);
  }, [schedule?.is_active]);

  const _handleToggleSchedule = (value) => {
    if (value) {
      setScheduleActive(schedule?.id, {
        onSuccess: () => {
          setActive(true);
        },
      });
    } else {
      setScheduleInActive(schedule?.id, {
        onSuccess: () => {
          setActive(false);
        },
      });
    }
  };

  return (
    <Link to={mslPaths.schedule(schedule?.id).home} className="flex flex-col justify-between bg-white border px-6 py-6 rounded-lg hover:shadow-sm transition-all ease-in-out space-y-6 group">
      <div className="flex items-center space-x-2">
        <h3 className="font-[Inter] text-gray-400 text-sm font-semibold">Active</h3>
        <ToggleSwitch enabled={active} onChange={_handleToggleSchedule} />
      </div>
      <div className="text-left">
        <h3 className="text-gray-700 text-xl font-semibold group-hover:text-gray-900 transition-all ease-in-out">{schedule?.name}</h3>
        <div className="flex items-center space-x-2 mt-3">
          <ClockIcon className="w-5 h-5 text-gray-300" />
          <div>
            <h3 className="font-[Inter] text-gray-400 text-xs font-semibold">Timezone</h3>
            <p className="font-[Inter] text-gray-700 text-xs font-medium">{schedule?.timezone}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

/* Export
============================================================================= */
export default ScheduleItem;
