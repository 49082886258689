import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HcpDashboardScreen from './HcpDashboardScreen';
import MslDashboardScreen from './MslDashboardScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';
import { Notfound } from '../../common';

/* =============================================================================
<DashboardScreens />
============================================================================= */
function DashboardScreens() {
  return (
    <Routes>
      <Route path="dashboard">
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="hcp" element={<HcpDashboardScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute allowHcp={false} allowMsl />}>
          <Route path="msl" element={<MslDashboardScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default DashboardScreens;
