import React from 'react';

import { useParams } from 'react-router-dom';
import ManageProfileHeader from '../components/ManageProfileHeader';
import ManageProfileDetails from '../components/ManageProfileDetails';
import ManageProfileProductKnowledge from '../components/ManageProfileProductKnowledge';
import ManageProfileChangePassword from '../components/ManageProfileChangePassword';
import ManageProfileChangeEmail from '../components/ManageProfileChangeEmail';

const tabs = [
  { name: 'Profile', key: 'profile' },
  { name: 'Product knowledge', key: 'product-knowledge' },
  { name: 'Change password', key: 'change-password' },
  { name: 'Change email', key: 'change-email' },
];

/* =============================================================================
<MslManageProfileScreen />
============================================================================= */
function MslManageProfileScreen() {
  const { tab } = useParams();

  return (
    <div className="py-7 bg-white w-full min-h-full">
      <ManageProfileHeader tabs={tabs} />
      {tab === 'profile' && (
        <ManageProfileDetails
          heading="Update profile"
          description="Update your account photo and details here"
        />
      )}
      {tab === 'product-knowledge' && <ManageProfileProductKnowledge />}
      {tab === 'change-password' && <ManageProfileChangePassword />}
      {tab === 'change-email' && <ManageProfileChangeEmail />}
    </div>
  );
}

/* Export
============================================================================= */
export default MslManageProfileScreen;
