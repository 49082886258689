import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';

import { ReactComponent as ChevronDown } from '../assets/icons/edit-chevron-down-icon.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DropDownForm({
  label, errorTxt, items, name, value, saveKey,
}) {
  const { setFieldValue } = useFormikContext();
  const [query, setQuery] = useState('');

  const filteredItems = query === ''
    ? items
    : items?.filter((item) => item?.name.toLowerCase().includes(query.toLowerCase()));

  const _handleChange = (selectedItem) => {
    if (saveKey) {
      setFieldValue(name, selectedItem.key);
    } else {
      setFieldValue(name, selectedItem?.name);
    }
  };

  return (
    <Combobox
      as="div"
      id={name}
      name={name}
      value={value}
      onChange={_handleChange}
    >
      <Combobox.Label className="block text-sm font-medium font-[Inter] text-gray-700">
        {label}
      </Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Button className="w-full">
          <Combobox.Input
            className={`w-full rounded-md border ${
              errorTxt ? 'border-red-500' : 'border-gray-300'
            } bg-white py-2 pl-3
           pr-10 shadow-sm focus:border-${
             errorTxt ? 'red' : 'primary'
           }-200 focus:outline-none focus:ring-1 focus:ring-${
             errorTxt ? 'red' : 'primary'
           }-200 sm:text-sm`}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item) => item?.charAt(0).toUpperCase() + item?.slice(1).replace('-', ' ')}
          />
          {!!errorTxt && (
          <p className="text-sm font-medium text-red-500">
            !
            {errorTxt}
          </p>
          )}
        </Combobox.Button>
        <Combobox.Button className="absolute flex right-0 top-1 bottom-0  flex items-center mb-1 rounded-r-md px-4 focus:outline-none">
          <ChevronDown aria-hidden="true" />
        </Combobox.Button>

        {filteredItems?.length > 0 && (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md
          bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredItems?.map((item) => (
              <Combobox.Option
                key={item.key || item?.id}
                value={item}
                className={({ active }) => classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? ' bg-primary-500 text-white' : 'text-gray-900',
                )}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {item.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

DropDownForm.defaultProps = {
  saveKey: true,
};

export default DropDownForm;
