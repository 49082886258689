/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { EmojiSadIcon } from '@heroicons/react/outline';

import { ReactComponent as ChevronDown } from '../assets/icons/edit-chevron-down-icon.svg';
import EmptyState from './EmptyState';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DropDown({
  label, items, name, emptyIcon, emptyTxt, value, onChange, customContainerStyles,
}) {
  const [query, setQuery] = useState('');

  const filteredItems = query === ''
    ? items
    : items?.filter((item) => item?.name.toLowerCase().includes(query.toLowerCase()));

  const _handleChange = (selectedItem) => {
    onChange(selectedItem);
    if (query == null) {
      setQuery(selectedItem?.name);
    }
  };

  const _renderDisplayName = (item) => {
    if (typeof item === 'string') {
      return item;
    }
    return item?.name;
  };

  return (
    <Combobox
      as="div"
      id={name}
      name={name}
      value={value && filteredItems ? filteredItems.filter((individualItem) => individualItem.key === value)[0]?.name : query}
      onChange={_handleChange}
      className={customContainerStyles}
    >
      <Combobox.Label className="block text-sm font-medium font-[Inter] text-gray-700 mb-1">
        {label}
      </Combobox.Label>
      <div className="relative">
        <Combobox.Button className="w-full">
          <Combobox.Input
            className={`w-full rounded-md border border-gray-300
            bg-white py-2 pl-3
           pr-10 shadow-sm focus:border-indigo-600 focus:outline-none focus:ring-1 sm:text-sm`}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={query}
            autoComplete="off"
          />
        </Combobox.Button>
        <Combobox.Button className="absolute flex right-0 top-1 bottom-0  flex items-center mb-1 rounded-r-md px-4 focus:outline-none">
          <ChevronDown aria-hidden="true" />
        </Combobox.Button>

        {filteredItems?.length > 0 ? (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md
          bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredItems?.map((item) => (
              <Combobox.Option
                key={item.key || item?.id}
                value={item}
                className={({ active }) => classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? ' bg-indigo-500 text-white' : 'text-gray-900',
                )}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block',
                        selected && 'font-semibold',
                      )}
                    >
                      {item.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        ) : (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md
        bg-white py-1 text-base shadow-lg ring-1  ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <div className="p-5">

              <EmptyState
                icon={emptyIcon || <EmojiSadIcon className="w-5 h-5 text-gray-500" />}
                title={emptyTxt || 'No results'}
              />
            </div>
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default DropDown;
