import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CogIcon,
  MicrophoneIcon,
  UsersIcon,
  VideoCameraIcon,
  ShareIcon,
} from '@heroicons/react/solid';
import { toast } from 'react-hot-toast';
import useClipboard from 'react-use-clipboard';
import { RoomType } from 'sendbird-calls';
import { CallActionButton, PromptModal, Toast } from '../../../common';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/edit-phone-icon.svg';
import { FRONTEND_BASE_URL } from '../../../config/secrets';
import { generalPaths } from '../../../config/paths';
import { localStorageKeys } from '../../../config/constants';

/* =============================================================================
<GroupCallActions />
============================================================================= */
function GroupCallActions({
  toggleSettingsModal,
  toggleParticipantsDrawer,
  room,
}) {
  const navigate = useNavigate();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const [showLeaveCallPrompt, setShowLeaveCallPrompt] = useState(false);

  const discreedlyGroupCallId = room?.group_call_discreedly_id;

  const [isLinkCopied, setCopyLink] = useClipboard(`${FRONTEND_BASE_URL}${generalPaths.joinGroupCall(discreedlyGroupCallId)}`, {
    successDuration: 1000,
  });

  useEffect(() => {
    if (isLinkCopied) {
      toast.custom((toastProps) => (
        <Toast
          status="success"
          title="Call join link copied to clipboard."
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    }
  }, [isLinkCopied]);

  const localParticipant = room?.localParticipant;
  const isLocalAudioEnabled = localParticipant?.isAudioEnabled;
  const isLocalVideoEnabled = localParticipant?.isVideoEnabled;

  const _toggleLeaveCallPrompt = () => setShowLeaveCallPrompt((prev) => !prev);

  const _handleToggleVideo = () => {
    if (localParticipant?.isVideoEnabled) {
      localParticipant?.stopVideo();
    } else {
      localParticipant?.startVideo();
    }
  };

  const _handleToggleAudio = () => {
    if (localParticipant?.isAudioEnabled) {
      localParticipant?.muteMicrophone();
    } else {
      localParticipant?.unmuteMicrophone();
    }
  };

  const _handleEndCall = () => {
    room?.exit();
    setShowLeaveCallPrompt(false);
    navigate(`/meetings/list/${userType}/upcoming`);
  };

  return (
    <>
      <div className="flex w-fit space-x-3 bg-gray-700 p-3 rounded-full justify-center items-center m-auto block z-10">
        <CallActionButton
          isOff={!isLocalAudioEnabled}
          icon={<MicrophoneIcon className="w-5 h-5 text-black" />}
          onClick={_handleToggleAudio}
          tooltipContent={isLocalAudioEnabled ? 'Mute audio' : 'Unmute audio'}
        />
        {room.roomType === RoomType.SMALL_ROOM_FOR_VIDEO && (
          <CallActionButton
            isOff={!isLocalVideoEnabled}
            icon={<VideoCameraIcon className="w-5 h-5 text-black" />}
            onClick={_handleToggleVideo}
            tooltipContent={isLocalVideoEnabled ? 'Stop video' : 'Start video'}
          />
        )}
        <CallActionButton
          icon={<UsersIcon className="w-5 h-5 text-black" />}
          onClick={toggleParticipantsDrawer}
          tooltipContent="Show participants"
        />
        <CallActionButton
          icon={<CogIcon className="w-5 h-5 text-black" />}
          onClick={toggleSettingsModal}
          tooltipContent="Call settings"
        />
        <CallActionButton
          icon={<ShareIcon className="w-5 h-5 text-black" />}
          onClick={setCopyLink}
          tooltipContent="Share join link"
        />
        <CallActionButton
          variant="red"
          icon={<PhoneIcon />}
          onClick={_toggleLeaveCallPrompt}
        />
      </div>
      <PromptModal
        open={showLeaveCallPrompt}
        btnTxt="Yes, leave"
        title="Leave the call"
        onClose={_toggleLeaveCallPrompt}
        onBtnPress={_handleEndCall}
        description="Are you sure you would like to leave the call?"
      />
    </>
  );
}

/* Export
============================================================================= */
export default GroupCallActions;
