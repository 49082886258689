import React, { useState } from 'react';
import ProductHeaderOptionsMenu from './ProductHeaderOptionsMenu';
import ProductMediaModal from '../ProductModals/ProductMediaModal';
import ProductInfoModal from '../ProductModals/ProductInfoModal';
import ProductSubscriptionButton from '../ProductSubscriptionButton';
import ProductSpeakToMedicalExpertButton from '../ProductSpeakToMedicalExpertButton';
import ProductOrderSampleModal from '../ProductModals/ProductOrderSampleModal';
import ProductOrderSubmitSuccess from '../ProductModals/ProductOrderSampleModal/ProductOrderSubmitSuccessModal';
import CompanyNameLinkWithHoverPreview from '../../../common/CompanyNameLinkWithHoverPreview';
import AvatarWithLiveUpdates from './AvatarWithLiveUpdates';

/* =============================================================================
<ProductHeader />
============================================================================= */
function ProductHeader({ product }) {
  const treatment_guidelines = product?.treatment_guidelines;
  const formulary = product?.formulary;
  const open_trials = product?.open_trials;

  const [mediaModal, setMediaModal] = useState(false);
  const [orderSampleModal, setOrderSampleModal] = useState(false);
  const [guidelineModal, setGuidelineModal] = useState(false);
  const [formularyModal, setFormularyModal] = useState(false);
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const [orderSubmitSuccessModal, setOrderSubmitSuccessModal] = useState(false);

  const _toggleMediaModal = () => setMediaModal((prev) => !prev);
  const _toggleOrderSampleModal = () => setOrderSampleModal((prev) => !prev);
  const _toggleGuidelineModal = () => setGuidelineModal((prev) => !prev);
  const _toggleFormularyModal = () => setFormularyModal((prev) => !prev);
  const _toggleOpenTrialModal = () => setOpenTrialModal((prev) => !prev);
  const _toggleOrderSubmitSuccessModal = () => setOrderSubmitSuccessModal((prev) => !prev);

  return (
    <div className="bg-white pb-5 border-b">
      <div className="-z-10">
        {product?.banner_image_path ? (
          <img className="w-full h-32 object-cover lg:h-48" src={product?.banner_image_path} alt="" />
        ) : (
          <div className="w-full h-32 object-cover bg-sky-500" />
        )}
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-8 md:-mt-12 lg:flex lg:items-end lg:space-x-5">
          <AvatarWithLiveUpdates product={product} />
          <div className="mt-6 lg:flex lg:min-w-0 lg:flex-1 lg:items-center lg:justify-end lg:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 block md:block overflow-hidden">
              <h1 className="truncate text-2xl font-bold text-gray-700">{product.name}</h1>
              <div className="overflow-hidden">
                <CompanyNameLinkWithHoverPreview company={product?.pharma_company} />
              </div>
            </div>
            <div className="justify-stretch mt-6 flex flex-col space-y-3 lg:flex-row lg:space-y-0 lg:space-x-3">
              <ProductSpeakToMedicalExpertButton
                productId={product?.id}
              />
              <div className="w-full lg:w-40">
                <ProductSubscriptionButton
                  productId={product?.id}
                />
              </div>
              <ProductHeaderOptionsMenu
                productId={product?.id}
                onMediaClick={_toggleMediaModal}
                onTreatmentGuidelineClick={_toggleGuidelineModal}
                onFormularyClick={_toggleFormularyModal}
                onOpenTrailClick={_toggleOpenTrialModal}
                onOrderSampleClick={_toggleOrderSampleModal}
              />
            </div>
          </div>
        </div>
      </div>
      <ProductMediaModal
        visible={mediaModal}
        onClose={_toggleMediaModal}
      />
      <ProductOrderSampleModal
        visible={orderSampleModal}
        onClose={_toggleOrderSampleModal}
        onSuccess={_toggleOrderSubmitSuccessModal}
      />

      {/* Success Modals */}
      <ProductOrderSubmitSuccess
        visible={orderSubmitSuccessModal}
        onBtnClick={_toggleOrderSubmitSuccessModal}
      />

      <ProductInfoModal
        visible={guidelineModal}
        title="Treatment Guidelines"
        onClose={_toggleGuidelineModal}
        description={treatment_guidelines}
      />
      <ProductInfoModal
        title="Formulary"
        visible={formularyModal}
        description={formulary}
        onClose={_toggleFormularyModal}
      />
      <ProductInfoModal
        title="Open Trials"
        visible={openTrialModal}
        description={open_trials}
        onClose={_toggleOpenTrialModal}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default ProductHeader;
