import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import CompanyScreen from './CompanyScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<CompanyScreens />
============================================================================= */
function CompanyScreens() {
  return (
    <Routes>
      <Route path="companies">
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="hcp/:companyId/:tab" element={<CompanyScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default CompanyScreens;
