import React from 'react';

import TextArea from '../TextArea';
import RadioGroup from '../RadioGroup';

/* =============================================================================
<MslPrescreenerQuestion />
============================================================================= */
function MslPrescreenerQuestion({ question, onChange, values }) {
  const _handleTextChange = (e) => {
    onChange((prev) => ({
      ...prev,
      [question?.id]: e.target.value,
    }));
  };

  const _handleChoiceChange = (option) => {
    onChange((prev) => ({
      ...prev,
      [question?.id]: option.target.id,
    }));
  };

  return (
    <div className="space-y-2.5">
      <h2 className="text-gray-700 font-[Inter] font-medium">
        {question?.title}
        {question.is_required === true && <span className="text-red-500 ml-1">*</span>}
      </h2>
      {question?.prescreener_question_type_key === 'free-text' && (
        <TextArea
          value={values[question?.id]}
          onChange={_handleTextChange}
        />
      )}
      {question?.prescreener_question_type_key === 'multiple-choice' && (
        <RadioGroup
          value={values[question?.id]}
          options={question?.options?.map((option) => ({ id: option.id, title: option.title }))}
          onChange={_handleChoiceChange}
        />
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default MslPrescreenerQuestion;
