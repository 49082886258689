import React from 'react';
import {
  TagIcon,
  PencilIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

/* =============================================================================
<ProductOverview />
============================================================================= */
function ProductOverview({ product }) {
  const indication = product?.indication;
  const classes = product?.classes;
  const warning = product?.black_box_warning;
  const codes = product?.codes || [];

  return (
    <div className="border rounded-lg bg-white">
      <h1 className="px-6 py-4 text-lg font-[Inter] font-medium border-b">Overview</h1>
      <div className="space-y-6 p-6">
        {indication && (
        <div className="flex space-x-2">
          <PencilIcon className="w-4 h-4 text-gray-300" />
          <div className="space-y-1 flex-1">
            <h3 className="font-[Inter text-xs font-semibold text-gray-400 tracking-wider">
              INDICATION
            </h3>
            <p className="font-[Inter] text-sm font-medium leading-5 text-gray-700">{indication}</p>
          </div>
        </div>
        )}
        {classes?.length > 0 && (
          <div className="flex space-x-2">
            <TagIcon className="w-4 h-4 text-gray-300" />
            <div className="space-y-1 flex-1">
              <h3 className="font-[Inter text-xs font-semibold text-gray-400 tracking-wider">DRUG CLASSES</h3>
              <div className="space-y-1.5">
                {classes?.map((drugClass) => (
                  <span className="text-xs text-gray-600 bg-gray-200 mr-1.5 px-2.5 py-0.5 font-medium rounded-full text-center inline-block" key={drugClass?.id}>
                    {drugClass?.class_option?.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
        {codes?.length > 0 && (
          <div className="flex space-x-2">
            <InformationCircleIcon className="w-4 h-4 text-gray-300" />
            <div className="space-y-1">
              <h3 className="font-[Inter text-xs font-semibold text-gray-400 tracking-wider">
                NDC CODES
              </h3>
              <p className="font-[Inter] text-sm font-medium text-gray-700 flex-1">
                {' '}
                {codes.join(', ')}
              </p>
            </div>
          </div>
        )}
        {warning && (
          <div className="bg-red-50 p-4 rounded-md">
            <div className="flex items-center">
              <ExclamationIcon className="w-5 h-5 text-red-500" />
              <h3 className="ml-2 font-[Inter] text-xs text-red-700 font-semibold tracking-wider">BOXED WARNINGS</h3>
            </div>
            <p className="ml-7 mt-1 font-[Inter] text-sm text-red-700 flex-1">{warning}</p>
          </div>
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductOverview;
