import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/outline';
import { useUpdateAvailabilityStatus } from '../../../../../auth/api/mutations';
import Spinner from '../../../../Spinner';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const availabilityStatuses = [
  {
    title: 'Online',
    color: 'bg-green-500',
    key: 'online',
  },
  {
    title: 'Away',
    color: 'bg-yellow-500',
    key: 'away',
  },
  {
    title: 'Busy',
    color: 'bg-red-500',
    key: 'busy',
  },
];

function DashboardSideNavAvailabilityStatus({ user }) {
  const [currentStatus, setCurrentStatus] = React.useState(user?.user_set_availability_status?.key);

  const {
    mutate: updateStatus, isLoading,
  } = useUpdateAvailabilityStatus();

  const handleStatusUpdate = (statusKey) => {
    updateStatus(statusKey, {
      onSuccess: () => {
        setCurrentStatus(statusKey);
      },
    });
  };

  return (
    <div className="relative flex flex-col">
      <Menu as="div" className="flex m-4 outline-none">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-56 -top-32 z-10 mt-2 rounded-md bg-white py-1 border border-2 border-gray-200 focus:outline-none">
            {availabilityStatuses
              .map((status, i) => (
                <Menu.Item key={i}>
                  <button
                    type="button"
                    className="px-5 py-2.5 flex items-center w-full justify-between text-gray-700 font-normal text-sm hover:opacity-75"
                    onClick={() => handleStatusUpdate(status.key)}
                  >
                    <div className="flex items-center">
                      <div className={`w-2.5 h-2.5 ${status.color} rounded-full mr-3`} />
                      <p>{status.title}</p>
                    </div>
                    {status.key === currentStatus && (
                      <CheckIcon className="w-5 h-5 text-gray-400" />
                    )}
                  </button>
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
        <Menu.Button className="h-15 hover:opacity-75 bg-white border border-2 border-gray-200 flex-1 flex text-gray-400 text-sm px-6 rounded-md justify-between py-4 items-center text-sm font-[Inter] ">
          {!isLoading ? (
            <>
              <div className="flex items-center">
                <div
                  className={classNames(
                    'w-2.5 h-2.5 rounded-full',
                    currentStatus === 'online' ? 'bg-green-500' : '',
                    currentStatus === 'away' ? 'bg-yellow-500' : '',
                    currentStatus === 'busy' ? 'bg-red-500' : '',
                  )}
                />
                <div className="ml-4 font-medium">
                  Status:
                  {' '}
                  <span className="font-[Inter] text-sm text-gray-700 font-medium">{availabilityStatuses.find((status) => status.key === currentStatus)?.title}</span>
                </div>
              </div>
              <SelectorIcon className="w-5 h-5 text-gray-400" />
            </>
          ) : (
            <div className="flex items-center justify-center mx-auto">
              <Spinner size={20} color="#FC6A6B" />
            </div>
          )}
        </Menu.Button>
      </Menu>
    </div>
  );
}

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
  && prevProps.user?.user_set_availability_status?.key === nextProps.user?.user_set_availability_status?.key
);

/* Export
============================================================================= */
export default React.memo(DashboardSideNavAvailabilityStatus, propsAreEqual);
