import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelHandler, GroupChannelModule } from '@sendbird/chat/groupChannel';

import { SENDBIRD_APP_ID } from '../../config/secrets';

import { updateUnreadMessagesCount } from '../redux/actions';
import { getUser } from '../../auth/redux/selectors';

/* =============================================================================
<ChatMessagesListener />
============================================================================= */
function ChatMessagesListener({ user }) {
  const dispatch = useDispatch();

  const [isConnected, setIsConnected] = useState(false);

  const sb = SendbirdChat.init({
    appId: SENDBIRD_APP_ID,
    modules: [
      new GroupChannelModule(),
    ],
  });

  useEffect(() => {
    async function connectToSendBirdChat() {
      const userSendBirdId = user?.send_bird_user?.id;
      const userSendBirdToken = user?.send_bird_user?.access_token;

      try {
        await sb.connect(userSendBirdId, userSendBirdToken);
        setIsConnected(true);
        // The user is connected to Sendbird server.
      } catch (err) {
        // Handle error.
      }
    }
    if (sb && user && !isConnected) {
      connectToSendBirdChat().then(() => {
        updateMessages();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sb, user, isConnected]);

  const updateMessages = useCallback(async () => {
    dispatch(updateUnreadMessagesCount(await sb.groupChannel.getTotalUnreadMessageCount()));
  }, [dispatch, sb.groupChannel]);

  useEffect(() => {
    const groupChannelHandler = new GroupChannelHandler({
      onMessageReceived: async () => {
        updateMessages();
      },
      onChannelChanged: async () => {
        updateMessages();
      },
    });

    sb.groupChannel.addGroupChannelHandler('unique-handler-123', groupChannelHandler);

    return () => {
      sb.groupChannel.removeGroupChannelHandler('unique-handler-123');
    };
  }, [sb, updateMessages]);

  return null;
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(ChatMessagesListener, propsAreEqual));
