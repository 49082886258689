import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';

import { Button, Input } from '../../common';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open-icon.svg';
import { ReactComponent as EyeCloseIcon } from '../../assets/icons/eye-close-icon.svg';

import { useUpdateAccountPassword } from '../../auth/api/mutations';

/* =============================================================================
<ManageProfileChangePassword />
============================================================================= */
function ManageProfileChangePassword() {
  const { mutateAsync: updatePassword, isLoading, isSuccess } = useUpdateAccountPassword();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  const _togglePassword = () => setPasswordToggle((prev) => !prev);

  const _toggleConfirmPassword = () => setConfirmPasswordToggle((prev) => !prev);

  const _handleSubmit = async (values, { resetForm }) => {
    if (values) {
      await updatePassword(values);
    }
    resetForm();
  };

  return (
    <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h2 className="font-[Inter] text-lg font-semibold text-gray-700">Change password</h2>
      <p className="font-[Inter] text-sm text-gray-500">Change your password connected to this account</p>
      <div className="w-full h-1 border-b my-5" />
      <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
        {() => (
          <Form className="w-full lg:w-1/2">
            <div className="space-y-6 mb-6">
              <Field name="password">
                {({ field }) => (
                  <Input
                    label="New password"
                    type={!passwordToggle ? 'password' : 'text'}
                    icon={passwordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                    onIconClick={_togglePassword}
                    {...field}
                  />
                )}
              </Field>
              <Field name="password_confirmation">
                {({ field }) => (
                  <Input
                    label="Confirm new password"
                    type={!confirmPasswordToggle ? 'password' : 'text'}
                    icon={confirmPasswordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                    onIconClick={_toggleConfirmPassword}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <Button
              type="submit"
              title="Save"
              variant="purple"
              loading={isLoading}
              customStyleClasses="w-full sm:w-28"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

const initialValues = {
  password: '',
  password_confirmation: '',
};

/* Export
============================================================================= */
export default ManageProfileChangePassword;
