import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PrescribeIcon } from '../../../assets/icons/edit-prescribe-icon.svg';
import { ReactComponent as SampleIcon } from '../../../assets/icons/edit-sample-icon.svg';
import { ReactComponent as HelpIcon } from '../../../assets/icons/edit-help-icon.svg';
import ProductActionCard from './ProductActionCard';
import ProductSampleUseModal from '../ProductModals/ProductSampleUseModal';
import ProductPrescriptionModal from '../ProductModals/ProductPrescriptionModal';
import ProductSampleUseSuccessModal from '../ProductModals/ProductSampleUseModal/ProductSampleUseSuccessModal';
import ProductPrescriptionSuccessModal from '../ProductModals/ProductPrescriptionModal/ProductPrescriptionSuccessModal';
import { Toast } from '../../../common';
import { hcpPaths } from '../../../config/paths';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ProductActions />
============================================================================= */
function ProductActions({ product }) {
  const productId = product?.id;
  const navigate = useNavigate();
  const canOrderSamples = product?.can_order_samples;

  const [recordSampleModal, setRecordSampleModal] = useState(false);
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [recordUseSuccessModal, setRecordUseSuccessModal] = useState(false);
  const [prescriptionSuccessModal, setPrescriptionSuccessModal] = useState(false);

  const _toggleRecordSampleModal = () => setRecordSampleModal((prev) => !prev);
  const _togglePrescriptionModal = () => setPrescriptionModal((prev) => !prev);
  const _toggleRecordUseSuccessModal = () => setRecordUseSuccessModal((prev) => !prev);
  const _togglePrescriptionSuccessModal = () => setPrescriptionSuccessModal((prev) => !prev);

  const _callHotline = () => {
    navigate(hcpPaths.product(productId).hotline);
  };

  const _showToast = () => {
    toast.custom((toastProps) => (
      <Toast
        status="warn"
        title="This company does not yet support this feature."
        onClose={() => toast.remove(toastProps.id)}
      />
    ));
  };

  return (
    <div className={classNames('grid gap-5 grid-cols-1', product.hotline_phone_number != null ? 'xl:grid-cols-3' : 'lg:grid-cols-2')}>
      <ProductActionCard
        icon={<PrescribeIcon />}
        title="PRESCRIBE"
        btnTxt="Prescribe now"
        onClick={_togglePrescriptionModal}
        description="Ready to prescribe this product?"
      />
      <ProductActionCard
        icon={<SampleIcon />}
        title="SAMPLE"
        btnTxt="Record use of samples"
        onClick={canOrderSamples ? _toggleRecordSampleModal : _showToast}
        description="Using a sample of this product?"
      />
      {product.hotline_phone_number != null && (
        <ProductActionCard
          icon={<HelpIcon />}
          title="NEED HELP?"
          btnColor="red"
          btnTxt="Call hotline now"
          onClick={_callHotline}
          description="Call the product hotline for support"
        />
      )}

      {/* main modals */}
      <ProductSampleUseModal
        visible={recordSampleModal}
        onSuccess={_toggleRecordUseSuccessModal}
        onClose={_toggleRecordSampleModal}
      />
      <ProductPrescriptionModal
        visible={prescriptionModal}
        onSuccess={_togglePrescriptionSuccessModal}
        onClose={_togglePrescriptionModal}
      />

      {/* success modals */}
      <ProductSampleUseSuccessModal
        visible={recordUseSuccessModal}
        onBtnClick={_toggleRecordUseSuccessModal}
      />
      <ProductPrescriptionSuccessModal
        visible={prescriptionSuccessModal}
        onBtnClick={_togglePrescriptionSuccessModal}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default ProductActions;
