/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import Toast from '../../common/Toast';
import { client as queryClient } from '../../config/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError, showToastSuccessMessageFromAxiosResponse } from '../../util/functions';
import { localStorageKeys } from '../../config/constants';

/**
 * Create meeting
 */
export const useCreateMeeting = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => {
    const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
    return axiosPrivate.post(`${userType}/meetings`, data);
  }, {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Cancel Meeting
 */
export const useCancelMeeting = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((meetingId) => axiosPrivate.post(`shared/meetings/${meetingId}/cancel`), {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(queryKeys.meetings('upcoming'));
      queryClient.invalidateQueries(queryKeys.meetings('past'));
      queryClient.invalidateQueries(queryKeys.meeting(variables));

      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Rate Meeting
 */
export const useRateMeeting = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/meetings/${data?.meetingId}/rate`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.meetings('upcoming'));
      queryClient.invalidateQueries(queryKeys.meetings('past'));
      toast.custom((toastProps) => (
        <Toast
          status="success"
          title="Thank you for your feedback."
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Create Meeting type
 */
export const useCreateMeetingType = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('msl/meeting-types', data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['meetingTypes']);
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Meeting type
 */
export const useUpdateMeetingType = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`msl/meeting-types/${data?.meetingTypeId}`, data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['meetingTypes']);
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Delete Meeting type
 */
export const useDeleteMeetingType = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((meetingTypeId) => axiosPrivate.delete(`msl/meeting-types/${meetingTypeId}`), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['meetingTypes']);
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};
