import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Modal,
  Button,
  Input, Toast,
} from '../../../common';

import { useInviteUser } from '../../api/mutations';
import { queryKeys as chatQueryKeys } from '../../api/query-key-factory';
import { client as queryClient } from '../../../config/react-query';

/* =============================================================================
<ChatInviteModal />
============================================================================= */
function ChatInviteModal({ visible, onClose }) {
  const emailInputRef = useRef(null);
  const { channelUrl } = useParams();
  const [email, setEmail] = useState('');

  const { mutate: inviteUsers, isLoading } = useInviteUser();

  const _handleSendInvite = () => {
    inviteUsers({
      email,
      send_bird_channel_url: channelUrl,
    }, {
      onSuccess: async () => {
        toast.custom((toastProps) => (
          <Toast
            status="success"
            title="User added to chat successfully."
            onClose={() => toast.remove(toastProps.id)}
          />
        ));
        setEmail('');
        onClose();
        await queryClient.invalidateQueries(chatQueryKeys.channelDetailsAndMembers(channelUrl));
      },
    });
  };

  return (
    <Modal
      visible={visible}
      heading="Add user to chat"
      onClose={onClose}
      size="sm"
      initialFocus={emailInputRef}
    >
      <div className="p-5 space-y-3">
        {/* search input */}
        <div className="rounded-md">
          <Input
            value={email}
            placeholder="Enter the user's email..."
            onChange={(e) => setEmail(e.target?.value)}
            emailInputRef={emailInputRef}
          />
        </div>
        <div className="">
          <Button
            type="submit"
            title="Add user to chat"
            variant="purple"
            loading={isLoading}
            customStyleClasses="w-full h-10"
            onClick={_handleSendInvite}
          />
        </div>
      </div>
    </Modal>
  );
}

/* Export
============================================================================= */
export default ChatInviteModal;
