import React from 'react';
import { useSelector } from 'react-redux';
import MeetingCallTypeSelect from '../MeetingCallTypeSelect';
import {
  Button, Input, SelectDateDropdown, SelectTimeDropdown,
} from '../../../common';
import { getUser } from '../../../auth/redux/selectors';

/* =============================================================================
<ScheduleMeetingWithHcpForm />
============================================================================= */
function ScheduleMeetingWithHcpForm({
  title,
  onTitleChange,
  description,
  onDescriptionChange,
  date,
  onDateChange,
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
  callType,
  onCallTypeChange,
  onSubmit,
  submitStatus,
}) {
  const user = useSelector(getUser);

  return (
    <div className="p-6 bg-white border shadow-sm rounded-lg space-y-8">
      <div className="space-y-4">
        <h2 className="text-gray-700 font-[Inter] font-medium">
          Meeting details
        </h2>
        <div className="space-y-4">
          <Input
            label="Title"
            value={title}
            onChange={(e) => onTitleChange(e.target.value)}
          />
          <Input
            label="Description (optional)"
            value={description}
            onChange={(e) => onDescriptionChange(e.target.value)}
          />
        </div>
      </div>
      <div className="space-y-4">
        <h2 className="text-gray-700 font-[Inter] font-medium">
          Select a date and time
        </h2>
        <div className="space-y-4">
          <SelectDateDropdown
            value={date}
            onChange={(e) => onDateChange(e.target.value)}
            timezone={user?.timezone}
            label="Date"
            name="date"
          />
          <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4 w-full">
            <SelectTimeDropdown
              value={startTime}
              onChange={(e) => onStartTimeChange(e.target.value)}
              label="Start time"
              name="start_time"
              placeholder="Select start time..."
            />
            <SelectTimeDropdown
              value={endTime}
              onChange={(e) => onEndTimeChange(e.target.value)}
              label="End time"
              name="end_time"
              placeholder="Select end time..."
            />
          </div>
        </div>
      </div>
      {/*
      <MeetingCallTypeSelect
        onCallTypeChange={onCallTypeChange}
        value={callType}
      />
      */}
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleMeetingWithHcpForm;
