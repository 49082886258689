import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import { useMarkNotification, useUnMarkNotification } from '../../../../notification/api/mutations';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DashboardNotificationItemMenu({ id, isSeen }) {
  const { mutate: markNotification } = useMarkNotification();
  const { mutate: unMarkNotification } = useUnMarkNotification();

  const _handleMarkNotification = () => {
    if (!isSeen) {
      markNotification(id);
    } else {
      unMarkNotification(id);
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex max-w-sm items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 group">
          <DotsVerticalIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={_handleMarkNotification}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm w-full text-left',
                )}
              >
                {isSeen ? 'Mark as unread' : 'Mark as read'}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default DashboardNotificationItemMenu;
