import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Button, Input, SelectDropDown } from '../../../common';
import ManageProfileUpdateProfileImage from './ManageProfileUpdateProfileImage';
import { getUser } from '../../../auth/redux/selectors';
import { useHcpProfileUpdate } from '../../api/mutations';
import { useHcpTypes, useUsStates } from '../../../auth/api/queries';
import { client as queryClient } from '../../../config/react-query';
import { queryKeys as authQueryKeys } from '../../../auth/api/query-key-factory';
import Spinner from '../../../common/Spinner';

/* =============================================================================
<ManageProfileHcpForm />
============================================================================= */
function ManageProfileHcpForm() {
  const user = useSelector(getUser);
  const { data: hcpTypes, status: hcpTypesStatus } = useHcpTypes();
  const { data: usStates, status: usStatesStatus } = useUsStates();

  const {
    isLoading,
    mutate: updateProfile,
  } = useHcpProfileUpdate();

  const initialValues = {
    phone: user?.phone,
    name: user?.name,
    us_state_key: user?.hcp?.us_state?.key,
    hcp_type_key: user?.hcp?.hcp_type?.key,
    license_number: user?.hcp?.license_number,
    timezone: user?.timezone,
  };

  // IANA timezone names
  const timezones = moment.tz.names().map((timezone) => ({
    key: timezone,
    name: timezone,
  }));

  const _handleSubmit = (values) => {
    const {
      phone,
      name,
      hcp_type_key,
      us_state_key,
      license_number,
      timezone,
    } = values;

    if (values) {
      updateProfile({
        phone,
        us_state_key,
        hcp_type_key,
        license_number,
        name,
        timezone,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(authQueryKeys.hcpUser());
          queryClient.invalidateQueries(authQueryKeys.mslUser());
        },
      });
    }
  };

  if (hcpTypesStatus !== 'success' || usStatesStatus !== 'success') {
    return (
      <Spinner />
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
      {({ values }) => (
        <Form className="xl:mr-96">
          <ManageProfileUpdateProfileImage />
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 my-6">
            <Field name="name">
              {({ field }) => (
                <div className="flex-1">
                  <Input
                    label="Name"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="license_number">
              {({ field }) => (
                <div className="flex-1">
                  <Input
                    label="NPI license number"
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="hcp_type_key">
              {({ field }) => (
                <div className="flex-1">
                  <SelectDropDown
                    label="Healthcare provider type"
                    placeholder="Select..."
                    items={hcpTypes}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="us_state_key">
              {({ field }) => (
                <div className="flex-1">
                  <SelectDropDown
                    label="State"
                    placeholder="Select..."
                    items={usStates}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="timezone">
              {({ field }) => (
                <div className="flex-1 sm:col-span-2">
                  <SelectDropDown
                    label="Timezone"
                    placeholder="Select..."
                    items={timezones}
                    {...field}
                  />
                </div>
              )}
            </Field>
            <Field name="phone" className="">
              {({ field }) => (
                <div className="flex-1 sm:col-span-2">
                  <Input
                    label="Phone number"
                    {...field}
                  />
                </div>
              )}
            </Field>
          </div>
          <Button
            type="submit"
            title="Save"
            variant="purple"
            loading={isLoading}
            customStyleClasses="w-full sm:w-28"
          />
        </Form>
      )}
    </Formik>
  );
}

/* Export
============================================================================= */
export default ManageProfileHcpForm;
