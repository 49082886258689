import React from 'react';
import moment from 'moment';
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import DashboardNotificationItemMenu from './DashboardNotificationItemMenu';

function DashboardNotificationItem({ notification }) {
  const title = notification?.data?.notification_meta?.title;
  const body = notification?.data?.notification_meta?.body;
  const createdAt = notification?.created_at;
  const style = notification?.data?.notification_meta?.style;
  const isSeen = !!notification?.read_at;

  return (
    <div className="flex justify-between px-5 py-6 bg-white border-b space-x-4">
      {style === 'success' && <CheckCircleIcon className="w-6 h-6 text-green-500" />}
      {style === 'danger' && <XCircleIcon className="w-6 h-6 text-red-400" />}
      {style === 'warning' && <ExclamationIcon className="w-6 h-6 text-yellow-400" />}
      {(style === 'info' || style === 'primary' || style == null) && <InformationCircleIcon className="w-6 h-6 text-blue-400" />}
      <div className="flex-1 space-y-2">
        <p className="text-sm font-semibold font-[Inter] text-gray-900">{title}</p>
        {body && <p className="text-sm font-[Inter] text-gray-900">{body}</p>}
        <p className="text-xs font-[Inter] text-gray-400">{moment(createdAt).fromNow()}</p>
      </div>
      <DashboardNotificationItemMenu id={notification?.id} isSeen={isSeen} />
    </div>
  );
}

export default DashboardNotificationItem;
