export const userTypes = {
  hcp: {
    key: 'hcp',
    name: 'HCP',
  },
  msl: {
    key: 'msl',
    name: 'MSL',
  },
};

export const userAvailabilityStatuses = {
  online: {
    key: 'online',
    name: 'Online',
    tailwindBgColor: 'bg-green-500',
  },
  away: {
    key: 'away',
    name: 'Away',
    tailwindBgColor: 'bg-orange-400',
  },
  busy: {
    key: 'busy',
    name: 'Busy',
    tailwindBgColor: 'bg-red-500',
  },
  offline: {
    key: 'offline',
    name: 'Offline',
    tailwindBgColor: 'bg-gray-400',
  },
  'in-a-meeting': {
    key: 'in-a-meeting',
    name: 'In a meeting',
    tailwindBgColor: 'bg-red-500',
  },
  'on-a-call': {
    key: 'on-a-call',
    name: 'On a call',
    tailwindBgColor: 'bg-red-500',
  },
};

export const localStorageKeys = {
  discreedlyAccessToken: '@discreedly/accessToken',
  discreedlyUserType: '@discreedly/userType',
};
