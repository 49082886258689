import React from 'react';

import { Button } from '../../../common';

/* =============================================================================
<ProductActionCard />
============================================================================= */
function ProductActionCard({
  icon,
  title,
  btnTxt,
  onClick,
  btnColor,
  description,
}) {
  return (
    <div className="border flex-1 bg-white rounded-lg xl:mt-0 p-6">
      <div className="flex flex-row mb-6 space-x-5">
        <div className="shrink-0">{icon}</div>
        <div className="flex-1">
          <h2 className="font-[Inter] text-xs font-semibold text-gray-400 tracking-wider break-all">{title}</h2>
          <p className="font-[Inter] font-medium mt-1.5 text-gray-700 pr-10">{description}</p>
        </div>
      </div>
      <Button
        title={btnTxt}
        size="block"
        variant={btnColor}
        onClick={onClick}
      />
    </div>
  );
}

ProductActionCard.defaultProps = {
  btnColor: 'purple',
};

/* Export
============================================================================= */
export default ProductActionCard;
