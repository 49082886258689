import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Link } from '../../common';
import { hcpPaths, mslPaths } from '../../config/paths';

/* =============================================================================
<AuthHomeScreen />
============================================================================= */
function AuthHomeScreen() {
  const navigate = useNavigate();

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-extrabold font-[Inter] text-gray-900">
          Welcome to Discreedly
        </h2>
      </div>
      <div className="space-y-3">
        <Button
          title="Continue as an HCP"
          className="w-full"
          size="block"
          variant="green"
          onClick={() => navigate(hcpPaths.signIn)}
        />
        <Button
          title="Continue as an MSL"
          className="w-full"
          size="block"
          variant="secondary"
          onClick={() => navigate(mslPaths.signIn)}
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default AuthHomeScreen;
