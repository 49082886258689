import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';

import { Button } from '../../../common';

/* =============================================================================
<MeetingTypesHeader />
============================================================================= */
function MeetingTypesHeader({ toggleCreateModal }) {
  return (
    <div className="flex justify-between md:items-center flex-col md:flex-row space-y-4 md:space-y-0">
      <div>
        <h1 className="text-2xl font-bold font-[Inter] text-gray-900">
          Meeting types
        </h1>
        <p className="font-[Inter] text-sm text-gray-500 font-medium">
          Meeting types describe the purpose and duration of a meeting
        </p>
      </div>
      <Button
        variant="purple"
        title="Meeting type"
        icon={<PlusIcon className="w-5 h-5 text-white" />}
        onClick={toggleCreateModal}
        customStyleClasses="w-full md:w-40"
      />
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingTypesHeader;
