/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector } from 'react-redux';
import { useRemoveProfileImage, useUpdateProfileImage } from '../../api/mutations';
import { getUser } from '../../../auth/redux/selectors';
import { Avatar } from '../../../common';
import { client as queryClient } from '../../../config/react-query';
import { queryKeys as authQueryKeys } from '../../../auth/api/query-key-factory';

/* =============================================================================
<ManageProfileUpdateProfileImage />
============================================================================= */
function ManageProfileUpdateProfileImage() {
  const user = useSelector(getUser);
  const color = user?.color;
  const name = user?.name;

  const {
    mutateAsync: updateProfileImg,
    isLoading: isUpdatingProfileImage,
  } = useUpdateProfileImage();
  const {
    mutate: removeProfileImg,
    isLoading: isRemovingProfileImage,
  } = useRemoveProfileImage();

  const uploadProfileImage = async (file) => {
    if (typeof file === 'object') {
      const imageForm = new FormData();

      imageForm.append('profile_image', file);

      updateProfileImg(imageForm, {
        onSuccess: () => {
          queryClient.invalidateQueries(authQueryKeys.hcpUser());
          queryClient.invalidateQueries(authQueryKeys.mslUser());
        },
      });
    } else if (file === undefined) {
      removeProfileImg();
    }
  };

  const _handleImageSelect = async (e) => {
    const selectedProfileImg = e.target.files[0];
    if (selectedProfileImg) {
      await uploadProfileImage(selectedProfileImg);
    }
  };

  const _handleRemove = () => {
    removeProfileImg(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries(authQueryKeys.hcpUser());
        queryClient.invalidateQueries(authQueryKeys.mslUser());
      },
    });
  };

  return (
    <div className="mb-5">
      <p className="font-[Inter] font-medium mb-3 text-sm text-gray-700">Profile photo</p>
      <div className="flex items-center">
        <Avatar
          color={color}
          name={name}
          size="medium"
          source={user?.profile_image_path}
        />
        <input
          type="file"
          name="file-input"
          id="file-input"
          className="w-0 h-0 opacity-0  overflow-hidden absolute z-0"
          accept="image/png, image/jpeg"
          onChange={_handleImageSelect}
          disabled={isUpdatingProfileImage || isRemovingProfileImage}
        />
        <label
          className="cursor-pointer ml-6 inline-flex font-[Inter] font-medium items-center shadow-sm border shadow-sm border-gray-300 hover:bg-gray-50 rounded-md text-sm border text-indigo-600 px-4 py-2"
          htmlFor="file-input"
        >
          <span>{isUpdatingProfileImage ? 'Uploading...' : 'Change'}</span>
        </label>

        {user?.profile_image_path && (
          <button
            type="button"
            className="font-[Inter] text-sm text-gray-500 hover:text-gray-400 font-medium ml-2 px-4 py-2"
            onClick={_handleRemove}
            disabled={isUpdatingProfileImage || isRemovingProfileImage}
          >
            {isRemovingProfileImage ? 'Removing...' : 'Remove'}
          </button>
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ManageProfileUpdateProfileImage;
