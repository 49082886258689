import React from 'react';
import { CalendarIcon, ChatIcon, PhoneIcon } from '@heroicons/react/solid';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '../../../common';
import { hcpPaths } from '../../../config/paths';
import { useCreateChannel } from '../../../chat/api/mutations';

/* =============================================================================
<MslProfileHeaderActions />
============================================================================= */
function MslProfileHeaderActions({
  mslId, mslUserId, userAvailability,
}) {
  const navigate = useNavigate();

  const { mutate: createChannel, status: createChannelStatus } = useCreateChannel();

  const _handleChatClick = () => {
    createChannel({
      name: '',
      user_ids: [mslUserId],
    }, {
      onSuccess: (response) => {
        navigate(hcpPaths.chat(response?.data?.data?.send_bird_channel_url));
      },
    });
  };

  const _handleCallMslClick = () => {
    navigate(hcpPaths.mslProfile(mslId).initiateCall);
  };

  return (
    <>
      <Button
        title="Chat"
        variant="secondary"
        customStyleClasses="w-full"
        icon={(
          <ChatIcon className="w-5 h-5 text-gray-400" />
        )}
        loading={createChannelStatus === 'loading'}
        onClick={_handleChatClick}
      />
      <Button
        title="Call"
        variant="secondary"
        customStyleClasses="w-full"
        disabled={userAvailability?.dynamic_status?.key !== 'online'}
        onClick={_handleCallMslClick}
        icon={(
          <div className="relative">
            <PhoneIcon className="w-5 h-5 text-gray-400" />

            {userAvailability?.dynamic_status?.key === 'online' && (
              <span className="absolute top-0 left-2 flex h-1.5 w-1.5">
                <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-red-400 opacity-75" />
                <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-500" />
              </span>
            )}
          </div>
        )}
      />
      <NavLink to={hcpPaths.mslProfile(mslId).scheduleMeeting}>
        <Button
          title="Schedule"
          variant="secondary"
          customStyleClasses="w-full"
          icon={(
            <CalendarIcon className="w-5 h-5 text-gray-400" />
          )}
        />
      </NavLink>
    </>
  );
}

/* Export
============================================================================= */
export default MslProfileHeaderActions;
