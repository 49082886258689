import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Channel Details and Members
 */
export const useGetChannelDetailsAndMembers = (channelUrl) => {
  const { axiosPrivate } = useAxios();
  return useQuery(queryKeys.channelDetailsAndMembers(channelUrl), async () => {
    const { data } = await axiosPrivate.get(`shared/send-bird/get-group-channel?send_bird_channel_url=${channelUrl}`);
    return data?.data;
  }, {
    enabled: channelUrl != null,
  });
};
