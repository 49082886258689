/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError } from '../../util/functions';

/**
 * Invite user
 */
export const useInviteUser = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('/shared/send-bird/invite-user-to-group-channel', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Create channel
 */
export const useCreateChannel = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('/shared/send-bird/create-group-channel', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Leave channel
 */
export const useLeaveChannel = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('/shared/send-bird/leave-group-channel', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};
