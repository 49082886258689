import React, { useId } from 'react';
import { Tooltip } from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<CallActionButton />
============================================================================= */
function CallActionButton({
  icon, isOff, disabled, variant, onClick, tooltipContent, tooltipPosition, customStyles, ...props
}) {
  const id = useId();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <button
      type="button"
      className={classNames(
        'relative w-10 h-10 rounded-full hover:opacity-75 disabled:opacity-75 flex justify-center items-center bg-white',
        variant === 'green' && 'bg-green-500',
        variant === 'red' && 'bg-red-500',
        customStyles,
      )}
      onClick={onClick}
      disabled={disabled}
      data-tooltip-content={tooltipContent}
      data-tooltip-place={tooltipPosition}
      id={id}
      tabIndex={-1}
      {...props}
    >
      {(tooltipContent && !isMobile) && <Tooltip anchorId={id} content={tooltipContent} />}
      {icon}
      {isOff && <div className="absolute w-0.5 h-6 -rotate-45 rounded-full bg-red-500" />}
    </button>
  );
}

CallActionButton.defaultProps = {
  variant: 'white',
  tooltipPosition: 'top',
};

/* Export
============================================================================= */
export default CallActionButton;
