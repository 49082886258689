import { EmojiSadIcon, SearchIcon } from '@heroicons/react/outline';
import React, { useState, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EventCard, Spinner, EmptyState, Button,
} from '../../common';
import EventsHeader from '../components/EventsHeader';
import useIntersectionObserver from '../../util/hooks/useIntersectionObserver';
import { useGetEvents } from '../api/queries';

/* =============================================================================
<EventsScreen />
============================================================================= */
function EventsScreen() {
  const navigate = useNavigate();
  const [onlyLiveEvents, setOnlyLiveEvents] = useState(true);
  const {
    data,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetEvents(onlyLiveEvents ? 'live' : 'upcoming');

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-7 min-h-full flex flex-col space-y-6">
      <EventsHeader enabled={onlyLiveEvents} onChange={setOnlyLiveEvents} />
      {isSuccess && data?.pages[0]?.pagination?.total !== 0 && (
        <div className="h-full flex flex-wrap justify-between grid grid-cols-1 gap-3 xl:gap-5 xs:grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
          {isSuccess && data?.pages?.map((page) => (
            <Fragment key={page?.pagination?.page}>
              {page?.events?.map((event) => (
                <EventCard key={event.id} event={event} />
              ))}
            </Fragment>
          ))}
        </div>
      )}

      {/* fetch data on scroll end */}
      <div ref={loadMoreRef} className={`${!hasNextPage ? 'hidden' : ''} text-2xl flex justify-center mb-8`}>
        {isFetchingNextPage && <Spinner size={20} />}
      </div>

      {/* loading spinner */}
      {isLoading && (
        <div className="text-center mb-8">
          <Spinner />
        </div>
      )}

      {/* if no data */}
      {data?.pages[0]?.events.length === 0 && (
        <div className="items-center justify-center h-full flex-1 flex">
          <div className="text-center">
            <EmptyState
              title="No results"
              icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
              description={onlyLiveEvents ? 'No live events' : 'No upcoming events'}
            />
            <Button
              onClick={() => navigate(onlyLiveEvents ? '/events/hcp' : '/events/hcp/live')}
              customStyleClasses="w-48 mt-5"
              variant="purple"
              title={onlyLiveEvents ? 'Upcoming events' : 'Live events'}
              icon={<SearchIcon className="text-white w-5 h-5" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default EventsScreen;
