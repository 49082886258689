import React from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Input } from '../../common';
import { getUser } from '../../auth/redux/selectors';
import { useUpdateAccountEmail } from '../../auth/api/mutations';

/* =============================================================================
<ManageProfileChangeEmail />
============================================================================= */
function ManageProfileChangeEmail() {
  const user = useSelector(getUser);
  const {
    isLoading,
    mutateAsync: updateEmail,
  } = useUpdateAccountEmail();

  const initialValues = {
    current_email: user?.email,
    email: '',
  };

  const _handleSubmit = async (values, { setFieldValue }) => {
    if (values) {
      await updateEmail(values, {
        onSuccess: () => {
          setFieldValue('current_email', values?.email);
          setFieldValue('email', '');
        },
      });
    }
  };

  return (
    <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h2 className="font-[Inter] text-lg font-semibold text-gray-700">Change email address</h2>
      <p className="font-[Inter] text-sm text-gray-500">Change the email connected to this account</p>
      <div className="w-full h-1 border-b my-5" />
      <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
        {() => (
          <Form className="w-full lg:w-1/2">
            <div className="space-y-6 mb-6">
              <Field name="current_email">
                {({ field }) => (
                  <Input
                    label="Current email address"
                    disabled
                    {...field}
                  />
                )}
              </Field>
              <Field name="email">
                {({ field }) => (
                  <Input
                    label="Enter new email address"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <Button
              type="submit"
              title="Save"
              variant="purple"
              loading={isLoading}
              customStyleClasses="w-full sm:w-28"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

/* Export
============================================================================= */
export default ManageProfileChangeEmail;
