import React from 'react';

/* =============================================================================
<ProductTrendingListItemHeader />
============================================================================= */
function ProductTrendingListItemHeader({ categoryName }) {
  return (
    <div className="border-b mb-5 -mx-5 px-5 pb-5 flex justify-between items-center">
      <h3 className="font-[Inter] text-lg font-semibold text-gray-700">{categoryName}</h3>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductTrendingListItemHeader;
