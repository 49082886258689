import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MeetingsList from '../components/MyMeetings/MeetingsList';
import MyMeetingsHeader from '../components/MyMeetings/MyMeetingsHeader';
import { setSelectedMeetingId, setShowMeetingInfoDrawer } from '../redux/actions';

/* =============================================================================
<MyMeetingsScreen />
============================================================================= */
function MyMeetingsScreen() {
  const dispatch = useDispatch();
  const { tab, meetingId } = useParams();

  useEffect(() => {
    if (meetingId != null && meetingId !== '.') {
      dispatch(setShowMeetingInfoDrawer(true));
      dispatch(setSelectedMeetingId(meetingId));
    }
  }, [meetingId, dispatch]);

  return (
    <div className="bg-white min-h-full">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-7 h-full">
        <MyMeetingsHeader />
        <div className="w-full">
          <MeetingsList filter={tab === 'upcoming' ? 'upcoming' : 'past'} />
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MyMeetingsScreen;
