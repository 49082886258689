import React, { useState } from 'react';
import moment from 'moment';
import { UserAddIcon } from '@heroicons/react/solid';
import {
  Button, Drawer, Spinner, TabsSwitch,
} from '../../../../common';
import ChatInfoParticipants from './ChatInfoParticipants';
import { useGetChannelDetailsAndMembers } from '../../../api/queries';

const tabs = [
  { name: 'Participants' },
  { name: 'Details' },
];

/* =============================================================================
<ChatInfoDrawer />
============================================================================= */
function ChatInfoDrawer({
  open, onClose, toggleInviteModal, onChannelLeave, channelUrl,
}) {
  /* only pass through channelUrl when open, so on the chat page, it doesn't load these details when the info drawer is closed, to reduce API requests */
  const { data, status } = useGetChannelDetailsAndMembers(open ? channelUrl : null);
  const [selectedTab, setSelectedTab] = useState('Participants');

  return (
    <Drawer
      heading="Chat details"
      open={open}
      onClose={onClose}
      contentContainerStyles=""
      footer={(channelUrl && status === 'success') && (
        <div className="flex flex-col-reverse sm:flex-row p-6 space-y-3 sm:space-y-0 sm:space-x-4 space-y-reverse border-t">
          <Button
            size="block"
            variant="secondary"
            title="Leave"
            onClick={onChannelLeave}
          />
          <Button
            size="block"
            variant="purple"
            title="Invite"
            iconPosition="right"
            icon={<UserAddIcon className="w-4 h-4 text-white" />}
            onClick={toggleInviteModal}
          />
        </div>
      )}
    >
      {(channelUrl && status === 'success') ? (
        <div className="flex flex-col flex-1 overflow-y-scroll">
          <TabsSwitch
            tabs={tabs}
            isActive={selectedTab}
            onChange={setSelectedTab}
            contentStyles="px-4 sm:px-6"
            switchToDropdownOnSmallScreens={false}
          />
          <div className="flex-1">
            <div className="h-full flex flex-col">
              {selectedTab === 'Participants' && (
                <ChatInfoParticipants
                  channelUrl={channelUrl}
                />
              )}
              {selectedTab === 'Details' && (
                <div className="px-6 my-5 space-y-3 flex flex-col">
                  <div className="flex max-w-full justify-between">
                    <h3 className="w-24 text-sm text-gray-400 font-medium font-[Inter]">Created</h3>
                    <div className="flex-1">
                      <p className="text-sm text-gray-700 font-medium font-[Inter]">{data?.group_channel_details?.created_at ? moment.unix(data?.group_channel_details?.created_at).fromNow() : null}</p>
                    </div>
                  </div>
                  <div className="flex max-w--full justify-between">
                    <h3 className="w-24 text-sm text-gray-400 font-medium font-[Inter]">Members</h3>
                    <div className="flex-1">
                      <p className="text-sm text-gray-700 font-medium font-[Inter]">{`${data?.members?.length} members`}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="justify-center items-center w-full text-center">
          <Spinner />
        </div>
      )}
    </Drawer>
  );
}

/* Export
============================================================================= */
export default ChatInfoDrawer;
