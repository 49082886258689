import React, { useRef, Fragment } from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { useDebounce } from 'use-debounce';
import useIntersectionObserver from '../../util/hooks/useIntersectionObserver';
import { useGetCompanyProducts } from '../api/queries';
import { EmptyState, ProductCard, Spinner } from '../../common';

/* =============================================================================
<CompanyProducts />
============================================================================= */
function CompanyProducts({ companyId, query, onSearch }) {
  const [value] = useDebounce(query, 500);

  const {
    data,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetCompanyProducts(companyId, value);

  const isSearchLoading = isLoading || value !== query; // Meaning debounce is still running

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <div>
      <ul className="max-w-7xl mx-auto divide-y divide-gray-200 px-4 sm:px-6 lg:px-8">
        {isSuccess && !isSearchLoading && data?.pages?.map((loopPage) => (
          <Fragment key={loopPage?.pagination?.page}>
            {loopPage?.products?.map((product) => (
              <li className="py-5 sm:py-6" key={product?.id}>
                <ProductCard
                  product={product}
                  showCompany={false}
                />
              </li>
            ))}
          </Fragment>
        ))}

        {/* fetch data on scroll end */}
        <div
          ref={loadMoreRef}
          className={`${!hasNextPage ? 'hidden' : ''}`}
        />
      </ul>

      {/* loading spinner */}
      {(isSearchLoading || (hasNextPage && isFetchingNextPage)) && (
        <div className="text-center mt-8">
          <Spinner />
        </div>
      )}

      {/* Empty State */}
      {(isSuccess && !isSearchLoading && data?.pages[0]?.pagination?.total === 0) && (
        <div className="text-center mt-8">
          <EmptyState
            title="No results"
            icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
            description="No products were found"
          />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default CompanyProducts;
