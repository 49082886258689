import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Company
 */
export const useGetCompany = (companyId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.company(companyId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/pharma-companies/${companyId}`);
      return data?.data?.pharma_company;
    },
  );
};

/**
 * Get Company Products
 */
export const useGetCompanyProducts = (companyId, searchQuery) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.companyProducts(companyId, searchQuery),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`hcp/pharma-companies/${companyId}/products/search?query=${searchQuery}&page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};

/**
 * Get Company Pipeline Products
 */
export const useGetCompanyPipelineProducts = (companyId) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.companyPipelineProducts(companyId),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`hcp/pharma-companies/${companyId}/pipeline-products?page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};

/**
 * Get Company Prescreener Questions
 */
export const useGetCompanyPrescreenerQuestions = (companyId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.companyPrescreenerQuestions(companyId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/pharma-companies/${companyId}/prescreener`);
      return data?.data?.questions;
    },
  );
};
