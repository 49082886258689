import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import {
  Button, Link, Input,
} from '../../common';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open-icon.svg';
import { ReactComponent as EyeCloseIcon } from '../../assets/icons/eye-close-icon.svg';
import { setIsAuthenticated } from '../redux/actions';
import { useMslLogin } from '../api/mutations';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<MslLoginScreen />
============================================================================= */
function MslLoginScreen() {
  const dispatch = useDispatch();

  const {
    mutate: mslLogin,
    isLoading: mslLoginLoading,
  } = useMslLogin();
  const [passwordToggle, setPasswordToggle] = useState(false);

  const _togglePassword = () => setPasswordToggle((prev) => !prev);

  const _handleSubmit = (values) => {
    if (values) {
      mslLogin(values, {
        onSuccess: async (payload) => {
          const accessToken = payload?.data.data?.access_token;
          localStorage.setItem(localStorageKeys.discreedlyAccessToken, accessToken);
          localStorage.setItem(localStorageKeys.discreedlyUserType, 'msl');
          dispatch(setIsAuthenticated(true));
        },
      });
    }
  };

  return (
    <div className="space-y-8">
      <h2 className="text-3xl font-extrabold font-[Inter] text-gray-900">
        Sign in to your MSL account
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={_handleSubmit}
      >
        {() => (
          <Form>
            <div className="space-y-6">
              <Field name="email">
                {({ field }) => (
                  <Input
                    label="Email address"
                    type="email"
                    {...field}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <Input
                    label="Password"
                    type={!passwordToggle ? 'password' : 'text'}
                    icon={passwordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                    onIconClick={_togglePassword}
                    {...field}
                  />
                )}
              </Field>
              <Button type="submit" loading={mslLoginLoading} title="Sign in" size="block" />
            </div>
            <div className="mt-4">
              <Link label="Forgot your password?" to="/auth/request-password-reset" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const initialValues = {
  email: '',
  password: '',
};

/* Export
============================================================================= */
export default MslLoginScreen;
