import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../auth/redux/selectors';

/* =============================================================================
<MeetingInfoOverview />
============================================================================= */
function MeetingInfoOverview({ meeting }) {
  const user = useSelector(getUser);

  const description = meeting?.description;

  const startAt = moment(meeting?.utc_start_at).tz(user?.timezone);
  const endAt = moment(meeting?.utc_end_at).tz(user?.timezone);
  const isUpcoming = moment(meeting?.utc_end_at).isAfter(moment());
  const isOngoing = moment().isBetween(startAt, endAt);
  const isPast = moment(meeting?.utc_end_at).isBefore(moment());
  const isCancelled = meeting?.cancelled_at !== null;

  const startTimeFormatted = moment(meeting?.utc_start_at).tz(user?.timezone).format('h:mma');
  const endTimeFormatted = moment(meeting?.utc_end_at).tz(user?.timezone).format('h:mma');
  const dateFormatted = moment(meeting?.utc_start_at).tz(user?.timezone).format('ddd, MMM Do YYYY');

  return (
    <div className="mb-3 px-4 sm:px-6">
      <div className="max-w-xs">
        <div className="flex max-w-full justify-between">
          <h3 className="w-24 text-sm text-gray-400 font-medium font-[Inter]">Date</h3>
          <div className="flex-1">
            <p className="text-sm text-gray-700 font-medium font-[Inter]">{dateFormatted}</p>
          </div>
        </div>
        <div className="flex max-w-full mt-3 justify-between">
          <h3 className="w-24 text-sm text-gray-400 font-medium font-[Inter]">Time</h3>
          <div className="flex-1">
            <p className="text-sm text-gray-700 font-medium font-[Inter]">{`${startTimeFormatted} - ${endTimeFormatted}`}</p>
          </div>
        </div>
        <div className="flex max-w-full mt-3 justify-between">
          <h3 className="w-24 text-sm text-gray-400 font-medium font-[Inter]">Status</h3>
          <div className="flex-1 flex items-center">
            <div className="text-left text-sm text-gray-500 font-medium font-[Inter] w-full shrink-0 w-fit max-w-full">
              {isCancelled ? (
                <div className="bg-red-100 text-xs flex justify-center rounded-full text-red-600 py-1.5 sm:py-1 px-5">
                  Canceled
                </div>
              ) : isOngoing ? (
                <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-5">
                  Ongoing
                </div>
              ) : isUpcoming ? (
                <div className="bg-green-100 text-xs flex justify-center rounded-full text-green-600 py-1.5 sm:py-1 px-5">
                  Upcoming
                </div>
              ) : isPast ? (
                <div className="bg-gray-100 text-xs flex justify-center rounded-full text-gray-600 py-1.5 sm:py-1 px-5">
                  Ended
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {description && (
        <div className="mt-3">
          <h3 className="flex-1 text-sm text-gray-400 font-medium font-[Inter]">
            Description
          </h3>
          <p className="text-sm mt-1 text-gray-700 font-medium font-[Inter]">
            {description}
          </p>
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingInfoOverview;
