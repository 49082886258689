import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/outline';
import { connect } from 'react-redux';
import { EmptyState, Spinner } from '../../common';
import { ReactComponent as CustomCalendarIcon } from '../../assets/icons/edit-calender-bg-icon.svg';
import { useGetNextMeeting } from '../../meeting/api/queries';
import { getUser } from '../../auth/redux/selectors';
import { mslPaths } from '../../config/paths';

/* =============================================================================
<MslDashboardOverview />
============================================================================= */
function MslDashboardOverview({ user }) {
  const { data: meeting, isLoading } = useGetNextMeeting();

  const date = moment(meeting?.utc_start_at).tz(user?.timezone).format('dddd, Do MMM');
  const startTime = moment(meeting?.utc_start_at).tz(user?.timezone).format('h:mma');
  const endTime = moment(meeting?.utc_end_at).tz(user?.timezone).format('h:mma');

  return (
    <div className="">
      <h2 className="font-[Inter] text-lg font-semibold text-gray-600 mb-3">Overview</h2>
      <div className="grid grid-cols-1 gap-y-5 gap-x-5">
        <div className="group bg-white px-6 rounded-lg border">
          {(meeting && !isLoading) && (
            <div className="pt-6">
              <div className="flex items-center">
                <CustomCalendarIcon />
                <div className="ml-5">
                  <h1 className="font-[Inter] tracking-wider text-xs text-gray-400 font-semibold">NEXT MEETING</h1>
                  <p className="font-[Inter] text-xl sm:text-xl font-semibold text-gray-700">
                    {`${date}, ${startTime} - ${endTime}`}
                  </p>
                </div>
              </div>
              <NavLink to={mslPaths.meetings('upcoming')}>
                <div className="border-t mt-6 -mx-6 p-4 font-[Inter] text-sm text-indigo-600 hover:text-indigo-500 font-medium">
                  View upcoming meetings
                </div>
              </NavLink>
            </div>
          )}
          {(!meeting && !isLoading) && (
            <div className="flex flex-col justify-center items-center py-6 px-8 rounded-lg my-6">
              <EmptyState icon={<CalendarIcon className="w-5 h-5 text-gray-400 text-center" />} title="Upcoming meetings" description="You have no upcoming meetings" />
            </div>
          )}

          {/* loading spinner */}
          {isLoading && (
            <div className="text-center my-8">
              <Spinner size={25} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(MslDashboardOverview);
