/* eslint-disable react/no-unstable-nested-components,jsx-a11y/heading-has-content */
import React from 'react';
import remarkGfm from 'remark-gfm';
import rehypeEmbed from '@hongvanpc10/rehype-embed';
import ReactMarkdown from 'react-markdown';

function Markdown({ content }) {
  return (
    <ReactMarkdown
      children={content}
      remarkPlugins={[remarkGfm, rehypeEmbed]}
      linkTarget="_blank"
      className="space-y-4 markdown-content"
      components={{
        h1: ({ node, ...props }) => (<h1 className="font-semibold text-2xl" {...props} />),
        h2: ({ node, ...props }) => (<h2 className="font-semibold text-xl" {...props} />),
        h3: ({ node, ...props }) => (<h3 className="font-semibold text-md" {...props} />),
        h4: ({ node, ...props }) => (<h4 className="font-semibold text-sm" {...props} />),
        a: ({ node, ...props }) => {
          const { href } = props;
          if (href.startsWith('https://www.youtube.com/watch?v=')) {
            const videoId = href.split('https://www.youtube.com/watch?v=')[1];
            return (
              <div className="w-full h-full">
                <iframe
                  width="448"
                  height="252"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            );
          }
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          return <a {...props} />;
        },
      }}
    />
  );
}

export default Markdown;
