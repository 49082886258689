import React from 'react';
import { connect } from 'react-redux';

import { usePusher } from '@harelpls/use-pusher';
import { ReactComponent as HandIcon } from '../../assets/icons/edit-hand-icon.svg';
import UpcomingMeeting from '../components/UpcomingMeeting';
import LiveUpdates from '../components/LiveUpdates';
import Events from '../components/Events';

import { getUser } from '../../auth/redux/selectors';

/* =============================================================================
<HcpDashboardScreen />
============================================================================= */
function HcpDashboardScreen({ user }) {
  const userName = user?.name.split(' ')[0];

  return (
    <div className="max-w-3xl mx-auto my-7 space-y-6 px-4 sm:px-6 xl:px-0">
      <div className="flex mb-5">
        <HandIcon />
        <h2 className="font-[Inter] ml-4 text-2xl font-extrabold text-gray-900">
          {`Hello, ${userName}!`}
        </h2>
      </div>
      <UpcomingMeeting user={user} />
      <LiveUpdates />
      <Events />
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(HcpDashboardScreen);
