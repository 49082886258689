import React from 'react';
import LiveUpdateModalItemHeader from './LiveUpdateModalItem/LiveUpdateModalItemHeader';

/* =============================================================================
<Header />
============================================================================= */
function Header({ companyName, companyLogo, publishedAt }) {
  return (
    <div className="px-2 py-1">
      <LiveUpdateModalItemHeader
        companyName={companyName}
        companyLogo={companyLogo}
        publishedAt={publishedAt}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default Header;
