import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

import { Button, Input, SuccessModal } from '../../common';
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open-icon.svg';
import { ReactComponent as EyeCloseIcon } from '../../assets/icons/eye-close-icon.svg';

import { useResetPassword } from '../api/mutations';

/* =============================================================================
<PasswordResetScreen />
============================================================================= */
function PasswordResetScreen() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  const { token } = useParams();
  const {
    mutate, isLoading, data: userData,
  } = useResetPassword();

  const _togglePassword = () => setPasswordToggle((prev) => !prev);

  const _toggleConfirmPassword = () => setConfirmPasswordToggle((prev) => !prev);

  const _handleSuccessModalBtnClick = () => {
    const userType = userData?.data?.data?.user_type?.key;

    setModal((prev) => !prev);
    navigate(`/auth/${userType}/login`);
  };

  const _handleSubmit = (values) => {
    if (values) {
      mutate({
        ...values,
        reset_password_token: token,
      }, {
        onSuccess: () => {
          setModal(true);
        },
      });
    }
  };

  return (
    <div>
      <h2 className="text-3xl mb-5 font-extrabold font-[Inter] text-gray-900">
        Reset password
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={_handleSubmit}
      >
        {() => (
          <Form className="space-y-6">
            <Field name="password">
              {({ field }) => (
                <Input
                  label="Password"
                  type={!passwordToggle ? 'password' : 'text'}
                  icon={passwordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                  onIconClick={_togglePassword}
                  {...field}
                />
              )}
            </Field>
            <Field name="password_confirmation">
              {({ field }) => (
                <Input
                  label="Confirm Password"
                  type={!confirmPasswordToggle ? 'password' : 'text'}
                  icon={confirmPasswordToggle ? <EyeCloseIcon /> : <EyeOpenIcon />}
                  onIconClick={_toggleConfirmPassword}
                  {...field}
                />
              )}
            </Field>
            <Button type="submit" loading={isLoading} title="Reset password" size="block" />
          </Form>
        )}
      </Formik>
      <SuccessModal
        visible={modal}
        grayBtn
        btnTxt="Sign in"
        title="Password changed"
        onBtnClick={_handleSuccessModalBtnClick}
        description="Your password has been reset successfully."
      />
    </div>
  );
}

const initialValues = {
  password: '',
  password_confirmation: '',
};

/* Export
============================================================================= */
export default PasswordResetScreen;
