import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<UserAvailabilityIndicator />
============================================================================= */
function UserAvailabilityIndicator({
  variant,
  containerStyles,
  availability,
  animateOnlineStatus,
}) {
  if (!availability) {
    return null;
  }

  const dynamicStatus = availability?.dynamic_status;

  if (variant === 'text') {
    return (
      <span
        className={classNames(
          'pb-0.5 px-3 rounded-full',
          dynamicStatus?.key === 'online'
            ? 'bg-green-100' : dynamicStatus?.key === 'busy'
              ? 'bg-red-100' : dynamicStatus?.key === 'away'
                ? 'bg-orange-100' : dynamicStatus?.key === 'in-a-meeting'
                  ? 'bg-red-100' : dynamicStatus?.key === 'on-a-call'
                    ? 'bg-red-100' : dynamicStatus?.key === 'offline'
                      ? 'bg-gray-100' : null,
        )}
      >
        <span
          className={classNames(
            'font-[Inter] text-xs font-medium',
            dynamicStatus?.key === 'online'
              ? 'text-green-600' : dynamicStatus?.key === 'busy'
                ? 'text-red-600' : dynamicStatus?.key === 'away'
                  ? 'text-orange-600' : dynamicStatus?.key === 'in-a-meeting'
                    ? 'text-red-600' : dynamicStatus?.key === 'on-a-call'
                      ? 'text-red-600' : dynamicStatus?.key === 'offline'
                        ? 'text-gray-400' : null,
          )}
        >
          {dynamicStatus?.name}
        </span>
      </span>
    );
  }

  if (variant === 'pulseWithText') {
    return (
      <div className="relative flex items-center space-x-1 transition-all ease-in-out">
        <span className="flex h-3.5 w-3.5">
          {dynamicStatus?.key === 'online' && <span className={classNames('absolute inline-flex h-3.5 w-3.5 rounded-full bg-green-400 opacity-75', animateOnlineStatus ? 'animate-ping' : '')} />}
          <div
            className={classNames(
              'relative inline-flex rounded-full border-2 border-white h-3.5 w-3.5',
              dynamicStatus?.key === 'online'
                ? 'bg-green-400' : dynamicStatus?.key === 'busy'
                  ? 'bg-red-500' : dynamicStatus?.key === 'away'
                    ? 'bg-orange-400' : dynamicStatus?.key === 'in-a-meeting'
                      ? 'bg-red-500' : dynamicStatus?.key === 'on-a-call'
                        ? 'bg-red-500' : dynamicStatus?.key === 'offline'
                          ? 'bg-gray-400' : null,
            )}
          />
        </span>
        <span className="font-medium leading-5 text-sm text-gray-700">
          {dynamicStatus?.name}
        </span>
      </div>
    );
  }

  return (
    <span className={`absolute ${containerStyles} flex h-4 w-4`}>
      {dynamicStatus?.key === 'online' && <span className={classNames('absolute inline-flex h-4 w-4 rounded-full bg-green-400 opacity-75', animateOnlineStatus ? 'animate-ping' : '')} />}
      <div
        className={classNames(
          'relative inline-flex w-4 h-4 rounded-full border-2 border-white',
          dynamicStatus?.key === 'online'
            ? 'bg-green-400' : dynamicStatus?.key === 'busy'
              ? 'bg-red-500' : dynamicStatus?.key === 'away'
                ? 'bg-orange-400' : dynamicStatus?.key === 'in-a-meeting'
                  ? 'bg-red-500' : dynamicStatus?.key === 'on-a-call'
                    ? 'bg-red-500' : dynamicStatus?.key === 'offline'
                      ? 'bg-gray-400' : null,
        )}
      />
    </span>
  );
}

// By default, prop animateOnlineStatus is set to true
UserAvailabilityIndicator.defaultProps = {
  animateOnlineStatus: true,
};

/* Export
============================================================================= */
export default UserAvailabilityIndicator;
