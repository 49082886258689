/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { client as queryClient } from '../../config/react-query';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError } from '../../util/functions';

/**
 * Mark notification as read
 */
export const useMarkNotification = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((notificationId) => axiosPrivate.post(`/shared/account/notifications/${notificationId}/mark-as-read`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications', 'read']);
      queryClient.invalidateQueries(['notifications', 'unread']);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
   * Mark notification as Unread
  */
export const useUnMarkNotification = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((notificationId) => axiosPrivate.post(`/shared/account/notifications/${notificationId}/mark-as-unread`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications', 'unread']);
      queryClient.invalidateQueries(['notifications', 'read']);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};
