import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';

/* =============================================================================
<ScheduleDateOverridesHeader />
============================================================================= */
function ScheduleDateOverridesHeader({ toggleCreateModal }) {
  return (
    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 justify-between sm:items-center">
      <h1 className="text-gray-700 font-medium font-[Inter]">Manage date overrides</h1>
      <button type="button" className="flex items-center space-x-2 hover:opacity-75" onClick={toggleCreateModal}>
        <PlusIcon className="w-4 h-4 text-indigo-600" />
        <p className="text-sm text-indigo-600 font-medium font-[Inter]">Add date override</p>
      </button>
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleDateOverridesHeader;
