import { createContext } from 'react';

import { RoomType } from 'sendbird-calls';
import { initialState } from './state';

const stub = () => {
  throw new Error('You forgot to wrap your component in <AuthContext>.');
};

export const initialContext = {
  ...initialState,
  isAuthenticated: false,
  initAndAuth: stub,
  deauth: stub,

  // Media Device Control
  updateMediaDevices: stub,
  selectAudioInputDevice: stub,
  selectAudioOutputDevice: stub,
  selectVideoInputDevice: stub,

  // Direct Calls
  isBusy: false,
  currentCall: undefined,
  dial: stub,
  addDirectCallSound: stub,
  removeDirectCallSound: stub,
  clearCalls: stub,

  // Rooms
  createRoom: stub,
  getCachedRoomById: stub,
  fetchRoomById: stub,
  RoomType,
};

const CallContext = createContext(initialContext);

export default CallContext;
