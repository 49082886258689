import React from 'react';
import { useParams } from 'react-router-dom';
import { Drawer, Spinner } from '../../../../common';
import { useGetDirectCall } from '../../../api/queries';
import DirectCallParticipant from './DirectCallParticipant';
import { useSbCalls } from '../../../lib/context';

/* =============================================================================
<DirectCallParticipantsDrawer />
============================================================================= */
function DirectCallParticipantsDrawer({ open, onClose }) {
  const { callId } = useParams();
  const { calls: allCalls } = useSbCalls();
  const call = allCalls.find((_call) => _call?.callId === callId);

  const { customItems } = call;

  const discreedlyDirectCallId = customItems?.discreedly_direct_call_id;

  const {
    data: directCall,
    status: getDirectCallStatus,
  } = useGetDirectCall(discreedlyDirectCallId);
  const callee = directCall?.callee_user;
  const caller = directCall?.caller_user;

  return (
    <Drawer
      heading="Participants"
      open={open}
      onClose={onClose}
      contentContainerStyles=""
      footer={null}
    >
      {(directCall && getDirectCallStatus === 'success') ? (
        <div className="flex flex-col flex-1 overflow-y-scroll px-4 sm:px-6 divide-y">
          <DirectCallParticipant participant={caller} />
          <DirectCallParticipant participant={callee} />
        </div>
      ) : (
        <div className="justify-center items-center w-full text-center">
          <Spinner />
        </div>
      )}
    </Drawer>
  );
}

/* Export
============================================================================= */
export default DirectCallParticipantsDrawer;
