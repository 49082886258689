import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Avatar } from '../../../common';
import { getUser } from '../../../auth/redux/selectors';
import { localStorageKeys } from '../../../config/constants';

/* =============================================================================
<ChatChannelAvatarTwoMembers />
============================================================================= */
function ChatChannelAvatarTwoMembers({ channel, forChatHeader, user }) {
  const currentUserSendbirdId = user?.send_bird_user?.id;
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  // eslint-disable-next-line max-len
  const currentSendBirdUser = channel?.members?.find((member) => member?.userId === currentUserSendbirdId);
  // eslint-disable-next-line max-len
  const otherSendBirdUser = channel?.members?.find((member) => member?.userId !== currentUserSendbirdId);

  const sendbirdUserToShow = otherSendBirdUser || currentSendBirdUser;

  // eslint-disable-next-line max-len
  const profileLink = (sendbirdUserToShow?.metaData?.discreedly_user_type_key === 'hcp' && userType === 'msl') ? `/profile/hcps/${sendbirdUserToShow?.metaData.discreedly_user_type_specific_id}` : (sendbirdUserToShow?.metaData?.discreedly_user_type_key === 'msl' && userType === 'hcp') ? `/profile/msls/${sendbirdUserToShow?.metaData.discreedly_user_type_specific_id}` : null;

  const avatarComponent = (
    <Avatar
      size="medium"
      color={sendbirdUserToShow?.metaData?.discreedly_user_profile_color}
      name={sendbirdUserToShow?.nickname}
      source={sendbirdUserToShow?.profileUrl}
    />
  );

  return (
    <div className="mt-1">
      {(profileLink && sendbirdUserToShow.user_id !== currentUserSendbirdId) ? (
        <div className="w-12 md:w-14 relative">
          <Link to={profileLink} className="hover:opacity-75">
            {avatarComponent}
          </Link>
        </div>
      ) : (
        <div className="w-12 md:w-13 relative">
          {avatarComponent}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(ChatChannelAvatarTwoMembers);
