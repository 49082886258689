import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Avatar } from '../../../../common';
import { getUser } from '../../../../auth/redux/selectors';
import { localStorageKeys, userTypes } from '../../../../config/constants';
import { hcpPaths, mslPaths } from '../../../../config/paths';

/* =============================================================================
<ChatInfoParticipant />
============================================================================= */
function ChatInfoParticipant({ participant }) {
  const user = useSelector(getUser);
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  const name = participant?.name;
  const color = participant?.color;
  const profileImage = participant?.profile_image_path;

  const otherUser = participant;
  // eslint-disable-next-line max-len
  const profileLink = (otherUser?.user_type?.key === userTypes.hcp.key && userType === userTypes.msl.key) ? mslPaths.hcpProfile(otherUser?.user_type_specific_data.hcp_id).home : (otherUser?.user_type?.key === userTypes.msl.key && userType === userTypes.hcp.key) ? hcpPaths.mslProfile(otherUser?.user_type_specific_data.msl_id).home : null;

  const avatarComponent = (
    <Avatar
      size="medium"
      name={name}
      color={color}
      source={profileImage}
    />
  );

  const nameComponent = (<h2 className="text-sm font-[Inter] font-medium text-gray-700">{name}</h2>);

  return (
    <div className="flex items-center justify-between py-4" key={participant?.id}>
      <div className="flex space-x-3 items-center">
        {(participant?.id !== user?.id && profileLink) ? (
          <Link to={profileLink} target="_blank" className="hover:opacity-75">
            {avatarComponent}
          </Link>
        ) : avatarComponent}
        <div>
          {(participant?.id !== user?.id && profileLink) ? (
            <Link to={profileLink} target="_blank" className="hover:opacity-75">
              {nameComponent}
            </Link>
          ) : nameComponent}
          {participant?.user_type?.key === userTypes.hcp.key && (
            <p className="font-[Inter] font-normal text-sm text-gray-500">{`${participant?.user_type?.name} • ${participant?.user_type_specific_data?.us_state?.name} • ${participant?.user_type_specific_data?.hcp_type?.name}`}</p>
          )}
          {participant?.user_type?.key === userTypes.msl.key && (
            <p className="font-[Inter] font-normal text-sm text-gray-500">{`${participant?.user_type?.name} • ${participant?.user_type_specific_data?.us_state?.name}`}</p>
          )}
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatInfoParticipant;
