import React from 'react';
import moment from 'moment';
import { Avatar } from '../../../../../../common';

/* =============================================================================
<LiveUpdateModalItemHeader />
============================================================================= */
function LiveUpdateModalItemHeader({
  companyName, companyLogo, publishedAt,
}) {
  return (
    <div className="flex justify-between relative pt-3">
      <div className="flex items-center space-x-2.5">
        <div className="border-2 border border-white rounded-full">
          <Avatar
            source={companyLogo || null}
            name={companyName}
            color="#EAB308"
            size="medium"
          />
        </div>
        <div className="text-white">
          <h1 className="text-sm font-[Inter] font-semibold [text-shadow:0_4px_8px_rgba(0,0,0,0.12)]">{companyName}</h1>
          <h2 className="text-xs font-[Inter] [text-shadow:0_4px_8px_rgba(0,0,0,0.12)]">
            {moment(publishedAt).fromNow()}
          </h2>
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default LiveUpdateModalItemHeader;
