import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import EventsScreen from './EventsScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<EventsScreens />
============================================================================= */
function EventsScreens() {
  return (
    <Routes>
      <Route path="events">
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="hcp/*" element={<EventsScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default EventsScreens;
