import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  unreadMessagesCount: 0,
  selectedSendBirdChannelUrl: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: INITIAL_STATE,
  reducers: {
    updateUnreadMessagesCount: (state, action) => {
      state.unreadMessagesCount = action.payload;
    },
    updateSelectedSendBirdChannelUrl: (state, action) => {
      state.selectedSendBirdChannelUrl = action.payload;
    },
    // reset to initial state (such as when user logs out)
    resetState: () => INITIAL_STATE,
  },
});

export default chatSlice.reducer;
