import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';
import { localStorageKeys } from '../../config/constants';

/**
 * Get Meetings
 */
export const useGetMeetings = (status) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.meetings(status),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`shared/meetings?filter[status]=${status}&page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};

/**
 * Get Meeting
 */
export const useGetMeeting = (meetingId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.meeting(meetingId),
    async () => {
      const userType = localStorage.getItem(localStorageKeys.discreedlyUserType);
      const { data } = await axiosPrivate.get(`${userType}/meetings/${meetingId}`);
      return data?.data;
    },

    {
      enabled: meetingId != null,
    },
  );
};

/**
 * Get next meeting
 */
export const useGetNextMeeting = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.nextMeeting,
    async () => {
      const { data } = await axiosPrivate.get('shared/meetings/next-meeting');
      return data?.data?.next_meeting;
    },
  );
};

/**
 * Get meeting types
 */
export const useGetMeetingTypes = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.meetingTypes,
    async () => {
      const { data } = await axiosPrivate.get('msl/meeting-types');
      return data?.data?.msl_meeting_types;
    },
  );
};

/**
 * Get msl meeting types
 */
export const useMslMeetingTypes = (mslId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.mslMeetingTypes(mslId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/msls/${mslId}/meeting-types`);
      return data?.data?.msl_meeting_types;
    },
  );
};

/**
 * Get msl meeting slots
 */
export const useMslMeetingSlots = ({
  mslId, meetingTypeId, date, timezone,
}) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.mslMeetingSlots(mslId, meetingTypeId, date),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/msls/${mslId}/slots?requested_timezone=${timezone}&date=${date}&meeting_type_id=${meetingTypeId}`);
      return data?.data;
    },
    {
      enabled: date != null && meetingTypeId != null && mslId != null && timezone != null,
    },
  );
};
