import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../auth/redux/selectors';
import { useGetChannelDetailsAndMembers } from '../../../api/queries';
import ChatInfoParticipant from './ChatInfoParticipant';

/* =============================================================================
<ChatInfoParticipants />
============================================================================= */
function ChatInfoParticipants({ channelUrl }) {
  const { data, status } = useGetChannelDetailsAndMembers(channelUrl);
  const participants = data?.members;

  if (status !== 'success') {
    return null;
  }

  return (
    <div className="my-2 px-6 divide-y">
      {participants?.map((participant) => (
        <ChatInfoParticipant
          participant={participant?.discreedly_data}
        />
      ))}
    </div>
  );
}

/* Export
============================================================================= */
export default ChatInfoParticipants;
