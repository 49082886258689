import React from 'react';
import {
  Route,
  Routes,
  BrowserRouter, Navigate,
} from 'react-router-dom';

import { connect } from 'react-redux';

import AuthScreens from '../auth/screens/AuthScreens';
import ProfileScreens from '../profile/screens/ProfileScreens';
import DashboardScreens from '../dashboard/screens/DashboardScreens';
import EventsScreens from '../events/screens/EventsScreens';
import CompanyScreens from '../company/screens/CompanyScreens';
import ProductScreens from '../products/screens/ProductScreens';
import ChatScreens from '../chat/screens/ChatScreens';
import CallScreens from '../calls/screens/CallsScreens';
import CallsListener from '../calls/components/CallsListener';
import ChatMessagesListener from '../chat/components/ChatMessagesListener';
import MeetingScreens from '../meeting/screens/MeetingScreens';
import SchedulesScreens from '../schedules/screens/SchedulesScreens';
import { getIsAuthenticated, getUser } from '../auth/redux/selectors';
import AuthHomeScreen from '../auth/screens/AuthHomeScreen';

/* =============================================================================
<AppNavigation />
============================================================================= */
function AppNavigation({ isAuthenticated }) {
  return (
    <BrowserRouter>
      <Routes>
        {/* Redirect to login page, where if logged in, will automatically navigate to dashboard page */}

      </Routes>
      <AuthScreens />
      <DashboardScreens />
      <ProductScreens />
      <EventsScreens />
      <CompanyScreens />
      <ProfileScreens />
      <ChatScreens />
      <CallScreens />
      <MeetingScreens />
      <SchedulesScreens />
      {isAuthenticated && (
        <>
          <CallsListener />
          <ChatMessagesListener />
        </>
      )}
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
  && prevProps.user?.unseen_missed_direct_calls_count // Needed so the sidebar counter is updated instantly (without page refresh)
  === nextProps.user?.unseen_missed_direct_calls_count // Needed so the sidebar counter is updated instantly (without page refresh)
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(AppNavigation, propsAreEqual));
