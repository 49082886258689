import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import MyMeetingsScreen from './MyMeetingsScreen';
import ScheduleMeetingWithMslScreen from './ScheduleMeetingWithMslScreen';
import ScheduleMeetingWithHcpScreen from './ScheduleMeetingWithHcpScreen';
import MeetingTypesScreen from './MeetingTypesScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<MeetingScreens />
============================================================================= */
function MeetingScreens() {
  return (
    <Routes>
      <Route path="meetings">
        <Route element={<AuthenticatedRoute allowHcp={false} allowMsl />}>
          <Route path="list/msl" render={() => <Navigate to="list/msl/upcoming" />} />
          <Route path="list/msl/:tab/:meetingId?" element={<MyMeetingsScreen />} />
          <Route path="msl/schedule-meeting-with-hcp/:hcpId" element={<ScheduleMeetingWithHcpScreen />} />
          <Route path="types/msl" element={<MeetingTypesScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="list/hcp" render={() => <Navigate to="list/hcp/upcoming" />} />
          <Route path="list/hcp/:tab/:meetingId?" element={<MyMeetingsScreen />} />
          <Route path="hcp/schedule-meeting-with-msl/:mslId" element={<ScheduleMeetingWithMslScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default MeetingScreens;
