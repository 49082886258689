import React from 'react';
import { connect } from 'react-redux';

import { Avatar } from '../../../common';
import { getUser } from '../../../auth/redux/selectors';

/* =============================================================================
<ChatChannelAvatarThreeMembers />
============================================================================= */
function ChatChannelAvatarThreeMembers({ channel, user }) {
  const currentUserSendbirdId = user?.send_bird_user?.id;
  const filteredMembers = channel?.members?.filter(
    (_member) => _member?.userId !== currentUserSendbirdId,
  );
  return (
    <div className="flex">
      <div className="">
        <Avatar
          border
          size="small"
          source={filteredMembers[0]?.profileUrl}
          name={filteredMembers[0]?.nickname}
          color={filteredMembers[0]?.metaData?.discreedly_user_profile_color}
        />
      </div>
      <div className="mt-4 -ml-3">
        <Avatar
          border
          size="small"
          source={filteredMembers[1]?.profileUrl}
          name={filteredMembers[1]?.nickname}
          color={filteredMembers[1]?.metaData?.discreedly_user_profile_color}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.channel?.url === nextProps.channel?.url
  && prevProps.channel?.membersCount === nextProps.channel?.membersCount
  && prevProps.channel?.members?.length === nextProps.channel?.members?.length;

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(ChatChannelAvatarThreeMembers, propsAreEqual));
