import React, { useState, useEffect } from 'react';
import {
  Link, useNavigate, useLocation, useParams,
} from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { Avatar, ToggleSwitch } from '../../../common';
import { useGetProduct } from '../../api/queries';
import ProductPlaceholderImg from '../../../assets/icons/placeholder-product-image-large.png';
import { hcpPaths } from '../../../config/paths';

/* =============================================================================
<ProductMslHeader />
============================================================================= */
function ProductMslHeader() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { data: product } = useGetProduct(productId);
  const { pathname } = useLocation();

  const [onlineMslToggle, setOnlineMslToggle] = useState(false);

  const productName = product?.name;
  const productLogo = product?.main_image_path;

  // SET SWITCH TO ENABLED IF USER NAVIGATE DIRECTLY TO ONLINE
  useEffect(() => {
    if (pathname?.includes('online')) {
      setOnlineMslToggle(true);
    } else {
      setOnlineMslToggle(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const _handleGoBack = () => {
    navigate(-1);
  };

  const _handleToggleSwitch = (value) => {
    setOnlineMslToggle(value);
    if (value === true) {
      navigate(hcpPaths.product(productId).onlineMsls);
    } else {
      navigate(hcpPaths.product(productId).allMsls);
    }
  };

  return (
    <div className="bg-white border-b">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <button
          type="button"
          onClick={_handleGoBack}
          className="flex pb-3 -ml-1.5 items-center text-sm font-[Inter] text-gray-500 hover:text-gray-400 font-medium"
        >
          <ChevronLeftIcon className="w-5 h-5 text-gray-400 mr-1" />
          Back
        </button>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between sm:space-x-10 mt-1 sm:mt-0">
          <div className="flex items-center overflow-hidden">
            <Link to={hcpPaths.product(productId).home} className="hover:opacity-75">
              <Avatar
                source={productLogo || ProductPlaceholderImg}
                size="medium"
              />
            </Link>
            <Link to={hcpPaths.product(productId).home}>
              <h1 className="font-[Inter] text-lg font-semibold text-gray-700 ml-4 truncate hover:opacity-75">{productName}</h1>
            </Link>
          </div>
          <div className="flex items-center mt-5 sm:mt-0 shrink-0">
            <h2 className="font-[Inter] text-sm text-gray-700 font-semibold ml-3 sm:ml-0 sm:mr-3 order-last sm:order-first">Online MSLs only</h2>
            <ToggleSwitch
              enabled={onlineMslToggle}
              onChange={_handleToggleSwitch}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductMslHeader;
