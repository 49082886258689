import React from 'react';
import moment from 'moment';
import { ReactComponent as NotificationActive } from '../../../assets/icons/edit-notification-unmarked-icon.svg';
import ProductPlaceholderImg from '../../../assets/icons/placeholder-product-image-large.png';
import Avatar from '../../../common/Avatar';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<LiveUpdatesItem />
============================================================================= */
function LiveUpdatesItem({ product, hasUnseenUpdates, viewProduct }) {
  const publishedAt = product?.last_pharma_company_story_published_at;

  return (
    <div className="h-full px-6 py-5 bg-white hover:border hover:border-gray-200 border border-white rounded-lg">
      <button type="button" className="w-24 flex flex-col items-center" onClick={() => viewProduct(product?.id)}>
        <div
          className={classNames(
            'border-4 p-1 flex justify-center items-center relative rounded-full',
            hasUnseenUpdates ? 'border-yellow-400' : 'border-gray-200',
          )}
        >
          <div className={classNames('w-full h-full max-w-xs rounded-full overflow-hidden')}>
            <Avatar
              source={product?.main_image_path || ProductPlaceholderImg}
              name={product?.name}
              size="large"
            />
          </div>
          {hasUnseenUpdates && (
            <span className="absolute top-0 right-0">
              <span className="flex h-6 w-6">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75" />
                <NotificationActive className="h-6 w-6" />
              </span>
            </span>
          )}
        </div>
        <h3
          className={classNames(
            hasUnseenUpdates ? 'text-gray-400' : 'text-gray-700',
            'text-center font-[Inter] text-sm text-gray-400 mt-3 font-semibold w-full mx-auto truncate',
          )}
        >
          {product?.name}
        </h3>
        <p className="text-center font-[Inter] text-xs text-gray-400 font-semibold mt-0.5">
          {publishedAt ? moment(publishedAt).fromNow() : '-'}
        </p>
      </button>
    </div>
  );
}

/* Export
============================================================================= */

const propsAreEqual = (prevProps, nextProps) => prevProps.product === nextProps.product && prevProps.hasUnseenUpdates === nextProps.hasUnseenUpdates;
export default React.memo(LiveUpdatesItem, propsAreEqual);
