import React, { useCallback } from 'react';
import {
  MicrophoneIcon,
  VideoCameraIcon,
  CogIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import { DesktopComputerIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import CallActionButton from '../../../common/CallActionButton';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/edit-phone-icon.svg';
import { useSbCalls } from '../../lib/context';

/* =============================================================================
<DirectCallsActions />
============================================================================= */
function DirectCallsActions({
  toggleSettingsModal,
  toggleParticipantsDrawer,
}) {
  const { callId } = useParams();
  const { calls: allCalls } = useSbCalls();
  const call = allCalls.find((_call) => _call?.callId === callId);

  const {
    callState,
    isVideoCall,
    isLocalVideoEnabled,
    isLocalAudioEnabled,
    isLocalScreenShareEnabled,
    startVideo,
    stopVideo,
    startScreenShare,
    stopScreenShare,
    muteMicrophone,
    unmuteMicrophone,
    end,
  } = call;

  const _handleToggleVideo = useCallback(() => {
    if (isLocalVideoEnabled) {
      stopVideo();
      if (isLocalScreenShareEnabled) {
        stopScreenShare(); // Must stop screen share if user video is stopped (and screen share is enabled)
      }
    } else {
      startVideo();
    }
  }, [isLocalScreenShareEnabled, isLocalVideoEnabled, startVideo, stopScreenShare, stopVideo]);

  const _handleToggleAudio = useCallback(() => {
    if (isLocalAudioEnabled) {
      muteMicrophone();
    } else {
      unmuteMicrophone();
    }
  }, [isLocalAudioEnabled, muteMicrophone, unmuteMicrophone]);

  // Toggle screen share
  const toggleScreenShare = useCallback(async () => {
    if (isLocalScreenShareEnabled) {
      stopScreenShare();
    } else {
      try {
        await startScreenShare();
      } catch (e) {
        stopScreenShare();
        // eslint-disable-next-line no-alert
        alert('There was an error starting screen share.');
      }
    }
  }, [isLocalScreenShareEnabled, startScreenShare, stopScreenShare]);

  const handleEndCall = useCallback(() => {
    end();
  }, [end]);

  return (
    <div className="flex w-fit space-x-3 bg-gray-700 p-3 rounded-full justify-center items-center m-auto block z-10">
      <CallActionButton
        isOff={!isLocalAudioEnabled}
        icon={<MicrophoneIcon className="w-5 h-5 text-black" />}
        onClick={_handleToggleAudio}
        tooltipContent={isLocalAudioEnabled ? 'Mute audio' : 'Unmute audio'}
      />
      {isVideoCall && (
        <CallActionButton
          isOff={!isLocalVideoEnabled}
          icon={<VideoCameraIcon className="w-5 h-5 text-black" />}
          onClick={_handleToggleVideo}
          tooltipContent={isLocalVideoEnabled ? 'Stop video' : 'Start video'}
        />
      )}
      {(isVideoCall && callState !== 'dialing' && isLocalVideoEnabled) && (
        <CallActionButton
          isOff={false}
          disabled={!isLocalVideoEnabled}
          icon={(<DesktopComputerIcon className="w-5 h-5 text-black" />)}
          onClick={toggleScreenShare}
          tooltipContent={isLocalScreenShareEnabled ? 'Stop screen share' : 'Share screen'}
          customStyles="hidden sm:flex"
        />
      )}
      <CallActionButton
        icon={<UsersIcon className="w-5 h-5 text-black" />}
        onClick={toggleParticipantsDrawer}
        tooltipContent="Show participants"
      />
      <CallActionButton
        icon={<CogIcon className="w-5 h-5 text-black" />}
        onClick={toggleSettingsModal}
        tooltipContent="Call settings"
      />
      <CallActionButton
        variant="red"
        icon={<PhoneIcon />}
        onClick={handleEndCall}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default DirectCallsActions;
