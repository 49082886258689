import React, {
  useState, Fragment, useContext, useEffect,
} from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDebounce } from 'use-debounce';
import {
  Button, EmptyState, Spinner, ProductCard,
} from '../../common';
import ProductBarCodeScanModal from '../components/ProductModals/ProductBarCodeScanModal';
import { ReactComponent as ScanIcon } from '../../assets/icons/edit-scan-icon.svg';
import { useGetProducts } from '../api/queries';
import { GlobalSearchContext } from '../../providers/GlobalSearchProvider';

/* =============================================================================
<ProductSearchScreen />
============================================================================= */
function ProductSearchScreen() {
  const { searchQuery, searchInputRef } = useContext(GlobalSearchContext);
  const [value] = useDebounce(searchQuery, 500);
  const {
    data,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetProducts({ query: value });

  const isSearchLoading = isLoading || value !== searchQuery; // Meaning debounce is still running

  const [scannerModal, setScannerModal] = useState(false);
  const _toggleScannerModal = () => setScannerModal((prevState) => !prevState);

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage,
    onLoadMore: fetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !hasNextPage,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 50px 0px',
  });

  useEffect(() => {
    searchInputRef.current.focus(); // When come onto search screen, focus on search input, so user can start typing right away without clicking on the search input
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-8 bg-white flex flex-col min-h-full">
      <div className="flex border-b bg-gray-50 mb-2">
        <div className="flex flex-col sm:flex-row justify-between w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex-1">
            <h1 className="text-2xl font-bold font-[Inter] text-gray-900 truncate">
              {searchQuery !== '' ? `Search for "${searchQuery}"` : 'All products'}
            </h1>
            {isSuccess && !isSearchLoading ? (
              <h3 className="text-sm font-medium font-[Inter] text-gray-700">
                {data?.pages[0].pagination.total}
                {' '}
                <span className="text-gray-400">results</span>
              </h3>
            ) : (
              <h3 className="text-sm font-medium font-[Inter] text-gray-400">
                Searching...
              </h3>
            )}
          </div>
          <Button
            title="Scan barcode"
            variant="secondary"
            icon={<ScanIcon className="" />}
            customStyleClasses="mt-5 sm:ml-5 sm:mt-1.5"
            onClick={_toggleScannerModal}
          />
          <ProductBarCodeScanModal visible={scannerModal} onClose={_toggleScannerModal} />
        </div>
      </div>

      <ul className="divide-y divide-gray-200 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {isSuccess && !isSearchLoading && data?.pages?.map((page) => (
          <Fragment key={page?.pagination?.page}>
            {page?.products?.map((product) => (
              <li className="py-5 sm:py-6" key={product?.id}>
                <ProductCard product={product} />
              </li>
            ))}
          </Fragment>
        ))}
      </ul>

      {hasNextPage && (
        <div ref={sentryRef} className="text-center mt-8 mb-4">
          <Spinner />
        </div>
      )}

      {/* loading spinner */}
      {isSearchLoading && (
        <div className="text-center mt-8">
          <Spinner />
        </div>
      )}

      {/* if no data */}
      {isSuccess && !isSearchLoading && data?.pages[0].pagination.total === 0 && (
        <div className="items-center justify-center h-full flex-1 flex">
          <div className="text-center py-8">
            <EmptyState
              title="No products found"
              icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
              description="Try searching another phrase"
            />
          </div>
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ProductSearchScreen;
