import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChatChannels from './ChatChannels';
import ChatChannel from './ChatChannel';

/* =============================================================================
<ChatDesktopLayout />
============================================================================= */
function ChatDesktopLayout() {
  return (
    <div className="flex h-full">
      <ChatChannels />
      <Routes>
        <Route path=":channelUrl" element={<ChatChannel />} />
      </Routes>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatDesktopLayout;
