import React from 'react';
import Avatar from '../../../../../common/Avatar';

/* =============================================================================
<MeetingInfoPrescreenerResponse />
============================================================================= */
function MeetingInfoPrescreenerResponse({ response }) {
  const submittedBy = response?.submitted_by_user;
  const answers = response?.answers;
  return (
    <div className="pb-3">
      <h3 className="flex-1 text-sm text-gray-400 font-medium font-[Inter] mt-3">Answers by</h3>
      <div className="flex mt-2 mb-4 items-center">
        <Avatar
          border
          size="small"
          name={submittedBy?.name}
          source={submittedBy?.profile_image_path}
          color={submittedBy?.color}
        />
        <p className="text-sm text-gray-500 font-[Inter] font-medium ml-2">
          {submittedBy?.name}
        </p>
      </div>

      <div className="border divide-y rounded-md">
        {answers?.map((answer) => (
          <div className="px-4 py-4">
            <h1 className="font-[Inter] text-gray-700 text-sm font-medium">{answer?.question_title}</h1>
            <p className="font-[Inter] text-gray-500 mt-2 text-sm font-medium">
              {answer?.answer_title || '-'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingInfoPrescreenerResponse;
