/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from 'react';
import { CheckIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Stepper = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepsRef = useRef();

  const updateStep = (stepNumber, tempSteps) => {
    const newSteps = [...tempSteps];
    let count = 0;
    while (count < newSteps.length) {
      if (count === stepNumber) { // current step
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      } else if (count < stepNumber) { // step completed
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else { // step pending
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    stepsRef.current = steps.map((step, index) => ({
      description: step,
      completed: false,
      highlighted: index === 0,
      selected: index === 0,
    }));

    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const stepsDisplay = newStep.map((step, index) => (
    <div
      key={index}
      className={classNames(index !== newStep.length - 1 ? 'w-full flex items-center' : 'flex items-center')}
    >
      <div className="relative flex flex-col items-center text-teal-600">
        <div
          className={`rounded-full transition duration-500 ease-in-out border-2
           border-gray-300 w-8 h-8 md:h-10 md:w-10 flex items-center justify-center py-3 
           ${step.selected && 'border-indigo-600'} ${!step.highlighted && step.completed ? 'bg-indigo-600' : null}`}
        >
          {step.highlighted && (
            <div className="w-2.5 h-2.5 rounded-full bg-indigo-600" />
          )}
          {!step.highlighted && step.completed && <CheckIcon className="w-5 h-5 text-white" />}
        </div>
      </div>
      <div className={`flex-auto border-t-2 transition duration-500 ease-in-out ${step.completed ? 'border-indigo-600' : 'border-gray-300'}`} />
    </div>
  ));

  return (
    <div className="w-full flex items-center m-auto block self-center justify-center">
      {stepsDisplay}
    </div>
  );
};
export default Stepper;
