import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Msl Dashboard Data
 */
export const useMslCallDashboardData = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.mslCallDashboard(),
    async () => {
      const { data } = await axiosPrivate.get('msl/dashboard/calls?start_date=1900-01-01&end_date=3000-01-01');
      return data?.data;
    },
  );
};
