import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { EmojiSadIcon } from '@heroicons/react/outline';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { EmptyState, Spinner } from '../../common';
import ProductsTrendingHeader from '../components/ProductsTrendingHeader';
import ProductTrendingListItem from '../components/ProductTrendingListItem';
import { useGetTrendingLists } from '../api/queries';
import { getUser } from '../../auth/redux/selectors';

/* =============================================================================
<ProductsTrendingScreen />
============================================================================= */
function ProductsTrendingScreen() {
  const user = useSelector(getUser);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedUsState, setSelectedUsState] = useState(user?.hcp?.us_state?.key);
  const {
    data,
    status,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetTrendingLists(selectedCategory, selectedUsState);

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage,
    onLoadMore: fetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !hasNextPage,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 5px 0px',
  });

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col h-full py-7 space-y-6">
      <ProductsTrendingHeader
        selectedCategory={selectedCategory}
        onCategorySelect={setSelectedCategory}
        selectedUsState={selectedUsState}
        onUsStateSelect={setSelectedUsState}
      />

      <div className="flex-1 h-full">
        {(status === 'success' && data?.pages[0]?.pagination.total !== 0) && data?.pages?.map((page) => (
          <>
            <Fragment key={page?.pagination?.page}>
              {page?.trending_product_lists?.map((list) => (
                <ProductTrendingListItem
                  key={list?.id}
                  list={list}
                />
              ))}
            </Fragment>
            {hasNextPage && (
              <div ref={sentryRef} className="text-center py-4">
                <Spinner />
              </div>
            )}
          </>
        ))}

        {/* loading spinner */}
        {status === 'loading' && (
          <div className="text-center mt-4">
            <Spinner />
          </div>
        )}

        {/* if no data */}
        {(status === 'success' && data?.pages[0]?.pagination.total === 0) && (
          <div className="items-center justify-center h-full flex-1 flex">
            <div className="text-center">
              <EmptyState
                title="No lists found"
                icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
                description="Please search something else"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductsTrendingScreen;
