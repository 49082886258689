import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ScheduleHeader from '../components/Schedule/ScheduleHeader';
import ScheduleWeeklyHours from '../components/Schedule/ScheduleWeeklyHours';
import ScheduleDateOverrides from '../components/Schedule/ScheduleDateOverrides';
import ScheduleSettings from '../components/Schedule/ScheduleSettings';
import { useGetSchedule } from '../api/queries';
import { Spinner } from '../../common';

/* =============================================================================
<ScheduleScreen />
============================================================================= */
function ScheduleScreen() {
  const { tab, scheduleId } = useParams();
  const { status: getScheduleStatus } = useGetSchedule(scheduleId);
  const [status, setStatus] = useState(false);

  return (
    <div className="h-full flex flex-col">
      {getScheduleStatus === 'success' && (
        <>
          <ScheduleHeader status={status} onStatusChange={setStatus} />
          <div className="max-w-7xl px-4 sm:px-6 lg:px-8 w-full mx-auto my-4 sm:my-6 pb-6 flex-col flex">
            {tab === 'weekly-hours' && <ScheduleWeeklyHours />}
            {tab === 'date-overrides' && <ScheduleDateOverrides />}
            {tab === 'settings' && <ScheduleSettings />}
          </div>
        </>
      )}
      {getScheduleStatus === 'loading' && (
        <div className="mt-8 items-center w-full text-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleScreen;
