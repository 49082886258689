import React from 'react';
import moment from 'moment';
import SelectDropDown from './SelectDropDown';

const timeIncrements = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 24; i++) {
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < 12; j++) {
    const hours = i.toString().padStart(2, '0');
    const minutes = (j * 5).toString().padStart(2, '0');
    const key = `${hours}:${minutes}`;
    const label = moment(`${hours}:${minutes}`, 'HH:mm').format('h:mma');
    timeIncrements.push({ key, label });
  }
}

function SelectTimeDropdown({
  value, onChange, label, name, ...props
}) {
  return (
    <SelectDropDown
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      items={timeIncrements}
      {...props}
    />
  );
}

export default SelectTimeDropdown;
