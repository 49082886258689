import React from 'react';
import { useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Avatar, Tabs } from '../../common';
import { useGetCompany } from '../api/queries';

/* =============================================================================
<CompanyHeader />
============================================================================= */
function CompanyHeader({ companyId, onSearch, searchQuery }) {
  const { tab } = useParams();

  const { data: company } = useGetCompany(companyId);

  const companyName = company?.name;
  const companyBanner = company?.banner_image_path;
  const companyLogo = company?.logo_path;

  const tabs = [
    { name: 'Products', key: 'products' },
    { name: 'Pipeline products', key: 'pipeline-products' },
    { name: 'About', key: 'about' },
  ];

  const _handleQuerySearchSubmit = (e) => {
    e.preventDefault();
    onSearch(e.target.value);
  };

  return (
    <div className="bg-white border-b">
      <div>
        {companyBanner ? (
          <img className="h-32 w-full object-cover lg:h-48" src={companyBanner} alt="" />
        ) : (
          <div className="w-full h-32 object-cover bg-sky-500" />
        )}
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <Avatar
            border
            color={company?.color}
            source={companyLogo}
            name={companyName}
            size="extraLarge"
          />
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
              <h1 className="truncate text-2xl font-bold text-gray-700">{companyName}</h1>
            </div>
            <div className="justify-stretch mt-3 sm:mt-6 flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-3">
              {/* Actions go here */}
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden mb-3">
          <h1 className="truncate text-2xl font-bold text-gray-700">{companyName}</h1>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row mt-0 md:mt-6 mb-5 md:mb-0">
        <Tabs
          tabs={tabs}
          link={`/companies/hcp/${companyId}/`}
          contentStyles="border-b-0"
          containerStyles="bg-white flex-1 mb-1 md:mb-0"
        />

        {tab === 'products' && (
          <div className="relative rounded-md shadow-sm mt-1">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 top-1 md:-top-1.5">
              <SearchOutlined className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="search"
              name="search"
              id="search-field"
              autoComplete="off"
              className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Search products..."
              onChange={_handleQuerySearchSubmit}
              value={searchQuery}
            />
          </div>
        )}
      </div>

    </div>
  );
}

/* Export
============================================================================= */
export default CompanyHeader;
