import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmojiSadIcon } from '@heroicons/react/outline';

import { EmptyState, Spinner } from '../../common';

import { useGetCompany } from '../api/queries';
import CompanyHeader from '../components/CompanyHeader';
import CompanyProducts from '../components/CompanyProducts';
import CompanyPipelineProducts from '../components/CompanyPipelineProducts';
import CompanyAbout from '../components/CompanyAbout';

/* =============================================================================
<CompanyScreen />
============================================================================= */
function CompanyScreen() {
  const { companyId, tab } = useParams();
  const [searchQuery, setSearchQuery] = useState('');

  const { data: company, isLoading } = useGetCompany(companyId);

  if (!company && isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!company && !isLoading) {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="Company not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="Company not found"
        />
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full pb-8">
      <CompanyHeader
        companyId={companyId}
        onSearch={setSearchQuery}
        searchQuery={searchQuery}
      />
      {tab === 'products' && (
        <CompanyProducts
          companyId={companyId}
          query={searchQuery}
          onSearch={setSearchQuery}
        />
      )}
      {tab === 'pipeline-products' && (
        <CompanyPipelineProducts companyId={companyId} />
      )}
      {tab === 'about' && (
        <CompanyAbout companyName={company?.name} bio={company?.bio} />
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default CompanyScreen;
