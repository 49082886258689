import React from 'react';
import LiveUpdateModalItemHeader from './LiveUpdateModalItemHeader';
import MultiLineParagraph from '../../../../../../common/MultiLineParagraph';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<LiveUpdateModalItem />
============================================================================= */
function LiveUpdateModalItem({
  onClose, isPaused, story,
}) {
  const update = story?.update;
  const storyFormat = update?.story_format_key;
  const companyId = update?.pharma_company?.id;
  const companyLogo = update?.pharma_company?.logo_path;
  const companyName = update?.pharma_company?.name;
  const publishedAt = update?.published_at;
  const text = update?.text;
  const textLength = text?.length;

  return (
    <div className="bg-gray-500 h-full w-full relative flex flex-col justify-center">
      {storyFormat === 'text' ? (
        <div className="bg-indigo-700 flex items-center justify-center px-6 sm:px-14 h-full w-full absolute">
          <p className={classNames(
            'font-[Inter] text-center font-semibold text-white',
            textLength > 600 && 'text-xs',
            textLength > 4.7 * 150 && 'text-sm',
            textLength > 4.7 * 100 && 'text-md',
            textLength > 4.7 * 50 && 'text-lg leading-10',
            textLength <= 4.7 * 50 && 'text-2xl leading-10',
          )}>
            <MultiLineParagraph content={text || '-'} linked={false} containerStyles="space-y-4" textStyles={textLength <= 4.7 * 50 ? 'text-2xl leading-8' : 'leading-7'} />
          </p>
        </div>
      ) : null}
      <div className="bg-white flex flex-col justify-between mb-12 text-white px-5 py-6 h-full bg-transparent">
        <LiveUpdateModalItemHeader
          companyId={companyId}
          companyName={companyName}
          companyLogo={companyLogo}
          publishedAt={publishedAt}
          onClose={onClose}
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
const propsAreEqual = (prevProps, nextProps) => prevProps.story === nextProps.story && prevProps.isPaused === nextProps.isPaused && prevProps.onClose === nextProps.onClose;
export default React.memo(LiveUpdateModalItem, propsAreEqual);
