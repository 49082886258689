import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DirectCallMediaView from '../components/DirectCall/DirectCallMediaView';
import DirectCallActions from '../components/DirectCall/DirectCallActions';
import { useAttachPrescreenerToDirectCall } from '../api/mutations';
import { hcpPaths, mslPaths } from '../../config/paths';
import { useSbCalls } from '../lib/context';
import DirectCallParticipantsDrawer from '../components/DirectCall/DirectCallParticipantsDrawer';
import CallSettingsModal from '../components/CallModals/CallSettingsModal';
import OutgoingCallSound from '../../assets/sounds/outgoing-call.mp3';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<DirectCallsScreen />
============================================================================= */
function DirectCallsScreen() {
  const navigate = useNavigate();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  const { callId, prescreenerResponseId } = useParams();

  const { calls: allCalls } = useSbCalls();
  const call = allCalls?.find((_call) => _call?.callId === callId);
  const {
    customItems,
    end,
    callState,
    isOngoing,
    endResult,
  } = call || {};

  const { mutate: attachPrescreenerToDirectCall } = useAttachPrescreenerToDirectCall();

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [participantsDrawerOpen, setParticipantsDrawerOpen] = useState(false);
  const [hasPrescreenerResponseBeenAttachedToCall, setHasPrescreenerResponseBeenAttachedToCall] = useState(false);

  const toggleSettingsModal = () => setSettingsModalOpen(!settingsModalOpen);
  const toggleParticipantsDrawer = () => setParticipantsDrawerOpen(!participantsDrawerOpen);

  const discreedlyDirectCallId = customItems?.discreedly_direct_call_id;

  const audioElement = useMemo(() => new Audio(), []);
  const [isPlayingDialingSound, setIsPlayingDialingSound] = useState(false);

  useEffect(() => {
    if (callState === 'dialing') {
      if (!isPlayingDialingSound) {
        setIsPlayingDialingSound(true);

        // If audio element src is empty
        audioElement.src = OutgoingCallSound;
        // Start playing the sound, if it's not already playing
        audioElement.play();
        audioElement.loop = true;
      }
    } else {
      setIsPlayingDialingSound(false);

      // Must destroy the audio element if not ringing, otherwise it may keep playing in the background, or when you click the play button on your computer, it will start playing!
      audioElement.pause();
      audioElement.src = '';
      audioElement.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState]);

  useEffect(() => () => {
    // Exit call on unmount (clean up)
    if (end) {
      end();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (call && discreedlyDirectCallId && prescreenerResponseId && !hasPrescreenerResponseBeenAttachedToCall) {
      attachPrescreenerToDirectCall({
        prescreenerResponseId,
        directCallId: discreedlyDirectCallId,
      }, {
        onSuccess: () => {
          setHasPrescreenerResponseBeenAttachedToCall(true);
        },
      });
    }
  }, [call, discreedlyDirectCallId, prescreenerResponseId, attachPrescreenerToDirectCall, hasPrescreenerResponseBeenAttachedToCall]);

  useEffect(() => {
    if (!isOngoing) {
      if (endResult === 'completed') {
        // Call ended successfully
        navigate(userType === 'hcp' ? hcpPaths.dashboard : mslPaths.dashboard);
      } else {
        navigate(`/calls/${callId}/ended`);
      }
    }
  }, [isOngoing, callState, endResult, userType, navigate, callId]);

  if (!call) {
    return null;
  }

  return (
    <div className="h-full w-full bg-gray-800 flex flex-col space-y-6 sm:space-y-10 p-4 sm:p-12">
      <DirectCallMediaView />
      <DirectCallActions
        toggleSettingsModal={toggleSettingsModal}
        toggleParticipantsDrawer={toggleParticipantsDrawer}
      />
      <CallSettingsModal
        visible={settingsModalOpen}
        onClose={toggleSettingsModal}
      />
      <DirectCallParticipantsDrawer
        open={participantsDrawerOpen}
        onClose={toggleParticipantsDrawer}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default DirectCallsScreen;
