import React, { Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CalendarIcon } from '@heroicons/react/outline';
import { EmptyState, Spinner } from '../../../../common';
import { useGetMeetings } from '../../../api/queries';
import useIntersectionObserver from '../../../../util/hooks/useIntersectionObserver';
import { getUser } from '../../../../auth/redux/selectors';
import MeetingsListItem from './MeetingsListItem';

/* =============================================================================
<MeetingsList />
============================================================================= */
function MeetingsList({ filter }) {
  const user = useSelector(getUser);
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMeetings(filter);

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  const renderItem = (item) => (
    <MeetingsListItem
      filter={filter}
      key={item?.id}
      meeting={item}
      userTimezone={user?.timezone}
    />
  );

  return (
    <div className="w-full relative h-full">
      <ul className="divide-y divide-gray-200 border-gray-200">
        {data?.pages?.map((page) => (
          <Fragment key={page?.pagination?.page}>
            {page?.meetings?.map(renderItem)}
          </Fragment>
        ))}
      </ul>

      {/* fetch data on scroll end */}
      <div ref={loadMoreRef} className={`${!hasNextPage ? 'hidden' : ''} text-2xl flex w-full justify-center mb-3`}>
        {isFetchingNextPage && <Spinner />}
      </div>

      {isLoading && (
        <div className="w-full">
          <div className="text-center mt-8">
            <Spinner />
          </div>
        </div>
      )}
      {/* if no data */}
      {data?.pages[0]?.pagination?.total === 0 && (
        <div className="text-center mt-8">
          <EmptyState
            title={filter === 'past' ? 'No past meetings' : 'No upcoming meetings'}
            icon={<CalendarIcon className="w-9 h-9  text-gray-400" />}
            description={filter === 'past' ? 'You have no past meetings' : 'You have no upcoming meetings'}
          />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingsList;
