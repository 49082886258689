import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { hcpPaths } from '../../../../config/paths';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<SeeMoreCollapsed />
============================================================================= */
const SeeMoreCollapsed = ({ update, action }) => (
  <>
    {update?.caption && (
      <div className="z-40 px-5">
        <h1 className="font-[Inter] text-white font-medium pb-2 [text-shadow:0_4px_8px_rgba(0,0,0,0.12)]">{update?.caption}</h1>
      </div>
    )}
    <div className="px-5">
      <button
        type="button"
        onClick={() => {
          action('pause');
          if (update?.see_more_url) {
            window.open(update?.see_more_url, '_blank', 'noopener,noreferrer');
          } else {
            window.open(hcpPaths.product(update?.product?.id).home, '_blank');
          }
        }}
        className={classNames(
          'flex mb-5 hover:opacity-75 transition-all ease-in-out justify-between items-center rounded-lg px-6 py-5 w-full mx-auto text-white font-[Inter] text-sm font-medium drop-shadow-sm hover:drop-shadow-md',
          update?.story_format_key === 'text' ? 'bg-indigo-600' : 'bg-gray-600 bg-opacity-75',
        )}
      >
        See more
        <ChevronRightIcon className="w-5" />
      </button>
    </div>
  </>
);

/* Export
============================================================================= */
export default SeeMoreCollapsed;
