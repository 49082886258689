import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Button, Input, Modal, TextArea,
} from '../../../common';

import { useUpdateMeetingType, useDeleteMeetingType } from '../../api/mutations';
import PromptModal from '../../../common/PromptModal';

function MeetingTypeUpdateModal({ visible, meetingType, onClose }) {
  const { mutate: deleteMeetingType, isLoading: deleteMeetingTypeLoading } = useDeleteMeetingType();
  const { mutateAsync: updateMeetingType, isLoading } = useUpdateMeetingType();

  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);

  const name = meetingType?.name;

  const initialValues = {
    name: meetingType?.name,
    duration_in_minutes: meetingType?.duration_in_minutes,
    description: meetingType?.description,
  };

  const _handleUpdateMeetingType = async (values) => {
    await updateMeetingType({
      name: values?.name,
      meetingTypeId: meetingType?.id,
      duration_in_minutes: values?.duration_in_minutes,
      description: values?.description,
    });
    onClose();
  };

  const _handleDeleteMeetingType = () => {
    deleteMeetingType(meetingType?.id);
  };

  return (
    <>
      <Modal
        size="sm"
        visible={visible}
        onClose={onClose}
        heading={name}
      >
        <div className="px-6 py-6">
          <Formik initialValues={initialValues} onSubmit={_handleUpdateMeetingType}>
            {({ values }) => (
              <Form className="space-y-4">
                <Field name="name">
                  {({ field }) => (
                    <Input
                      label="Name"
                      {...field}
                    />
                  )}
                </Field>
                <Field name="duration_in_minutes">
                  {({ field }) => (
                    <Input
                      label="Duration (in minutes)"
                      {...field}
                    />
                  )}
                </Field>
                <Field name="description">
                  {({ field }) => (
                    <TextArea
                      label="Description (optional)"
                      {...field}
                    />
                  )}
                </Field>
                <div className="flex flex-col space-y-3 pt-3">
                  <Button
                    type="submit"
                    title="Save changes"
                    variant="purple"
                    customStyleClasses="w-full"
                    loading={isLoading}
                  />
                  <Button
                    variant="secondary"
                    title="Delete"
                    onClick={() => setShowDeletePromptModal(true)}
                    customStyleClasses="w-full"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <PromptModal
        title="Confirm delete"
        btnTxt="Delete"
        description="Are you sure you would like to delete this meeting type?"
        open={showDeletePromptModal}
        onClose={() => setShowDeletePromptModal(false)}
        onBtnPress={_handleDeleteMeetingType}
        btnLoading={deleteMeetingTypeLoading}
      />
    </>
  );
}

export default MeetingTypeUpdateModal;
