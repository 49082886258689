import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import DirectCallDialingView from './DirectCallDialingView';
import DirectCallRemoteUserView from './DirectCallRemoteUserView';
import DirectCallLocalUserView from './DirectCallLocalUserView';
import { useSbCalls } from '../../../lib/context';
import { Alert } from '../../../../common';
import AnimatedDotDotDot from '../../../../common/AnimatedDotDotDot';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<DirectCallMediaView />
============================================================================= */
function DirectCallMediaView() {
  const [mediaView, setMediaView] = useState('split');
  const [selectedMedia, setSelectedMedia] = useState(null);

  const { callId } = useParams();
  const { calls: allCalls } = useSbCalls();
  const call = allCalls.find((_call) => _call?.callId === callId);

  const {
    callState,
    isLocalVideoEnabled,
    setLocalMediaView,
  } = call;

  const _handleSetSelectedMedia = (value) => {
    setMediaView('full');
    setSelectedMedia(value);
  };

  const _handleCloseSelectedMedia = () => {
    setMediaView('split');
    setSelectedMedia(null);
  };

  const localMediaViewRef = useCallback((node) => {
    setLocalMediaView(node);
  }, [setLocalMediaView]);

  const is = useCallback((...states) => states.some((state) => state === callState), [callState]);
  const isNot = useCallback((...states) => !states.some((state) => state === callState), [callState]);
  const connectedStates = ['established', 'connected', 'reconnecting', 'reconnected'];

  return (
    <div className="flex flex-col justify-center flex-1">
      {callState === 'dialing' && (
        <div className="flex-1 space-y-8 md:space-y-0 justify-center items-center flex flex-col">
          <DirectCallDialingView />
          {isLocalVideoEnabled && (
            <video
              ref={localMediaViewRef}
              playsInline
              autoPlay
              muted
              className="max-h-32 sm:max-h-52 bg-black rounded-md border-2 border-white m-auto md:absolute md:top-6 md:right-6 md:w-72 md:max-h-auto"
            />
          )}
        </div>
      )}
      {is(...connectedStates) && (
        <>
          {is('reconnecting') && (
            <div className="mb-5 transition-all ease-in-out">
              <Alert
                title={(
                  <AnimatedDotDotDot
                    prefixText="Connection lost, trying to reconnect"
                  />
                )}
                status="warning"
                description={null}
              />
            </div>
          )}
          <div className={classNames(
            mediaView === 'split' && 'flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-5 justify-between',
            'w-full h-full',
          )}
          >
            <DirectCallLocalUserView
              mediaView={mediaView}
              selectedMedia={selectedMedia}
              onExpandMedia={_handleSetSelectedMedia}
              onCloseMedia={_handleCloseSelectedMedia}
            />
            <DirectCallRemoteUserView
              mediaView={mediaView}
              selectedMedia={selectedMedia}
              onExpandMedia={_handleSetSelectedMedia}
              onCloseMedia={_handleCloseSelectedMedia}
            />
          </div>
        </>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default DirectCallMediaView;
