import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';

import { NavLink } from 'react-router-dom';
import {
  Button, EmptyState, EventCard, Spinner, Carousel,
} from '../../common';
import { ReactComponent as EmptyStateIcon } from '../../assets/icons/edit-empty-state-icon.svg';
import { ReactComponent as BeakerIcon } from '../../assets/icons/edit-beaker-icon.svg';
import { useGetEvents } from '../../events/api/queries';
import { hcpPaths } from '../../config/paths';

/* =============================================================================
<Events />
============================================================================= */
function Events() {
  const { data, isLoading } = useGetEvents('live');

  return (
    <div>
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-lg font-semibold font-[Inter] text-gray-900">Happening now</h3>
        <NavLink to={hcpPaths.events.live} className="flex items-center text-xs text-gray-500 font-[Inter] font-semibold hover:text-gray-600">
          All events
          <span>
            <ChevronRightIcon className="w-3 h-4 ml-1" />
          </span>
        </NavLink>
      </div>

      {data?.pages[0].events?.length > 0 && (
        <div className="">
          <Carousel>
            {data?.pages[0].events?.slice(0, 10).map((event) => (
              <EventCard
                key={event.id}
                event={event}
                customContainerStyles="w-80"
                truncateDescription
              />
            ))}
          </Carousel>
        </div>
      )}

      {data?.pages[0]?.events.length === 0 && (
      <div>
        <div className="bg-white flex flex-col justify-center items-center pt-6 pb-12 px-8 rounded-lg">
          <EmptyState icon={<EmptyStateIcon />} title="No live events" description="Subscribe to more products to see more events" />
          <NavLink className="mt-5" to={hcpPaths.globalSearch.home}>
            <Button
              customStyleClasses="w-48"
              variant="purple"
              title="Search products"
              icon={<BeakerIcon />}
            />
          </NavLink>
        </div>
      </div>
      )}

      {isLoading && (
        <div className="text-center">
          <Spinner size={25} />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default Events;
