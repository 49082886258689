import React from 'react';
import { connect, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { UserAvailabilityIndicator } from '../../../common';

import { getUser } from '../../../auth/redux/selectors';
import { localStorageKeys, userTypes } from '../../../config/constants';
import { hcpPaths, mslPaths } from '../../../config/paths';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ChatChannelSingleChat />
============================================================================= */
function ChatChannelSingleChat({
  channel, forChatHeader, userAvailability,
}) {
  const user = useSelector(getUser);
  const currentUserSendbirdId = user?.send_bird_user?.id;

  // eslint-disable-next-line max-len
  const currentSendBirdUser = channel?.members?.find((member) => member?.userId === currentUserSendbirdId);
  // eslint-disable-next-line max-len
  const otherSendBirdUser = channel?.members?.find((member) => member?.userId !== currentUserSendbirdId);

  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  // If there is only one user in the channel, show that user's info
  const sendBirdUserToShow = otherSendBirdUser || currentSendBirdUser;

  // eslint-disable-next-line max-len
  const profileLink = (sendBirdUserToShow?.metaData?.discreedly_user_type_key === userTypes.hcp.key && userType === userTypes.msl.key) ? mslPaths.hcpProfile(sendBirdUserToShow?.metaData.discreedly_user_type_specific_id).home : (sendBirdUserToShow?.metaData?.discreedly_user_type_key === userTypes.msl.key && userType === userTypes.hcp.key) ? hcpPaths.mslProfile(sendBirdUserToShow?.metaData.discreedly_user_type_specific_id).home : null;

  const nameComponent = (
    <span className="truncate w-full text-sm leading-5 font-semibold font-[Inter] text-gray-700">
      {sendBirdUserToShow?.nickname}
    </span>
  );

  return (
    <div className={classNames('flex flex-col text-left items-start w-full overflow-x-hidden overflow-hidden')}>
      {(sendBirdUserToShow.user_id !== currentUserSendbirdId && profileLink != null) ? (
        <Link to={profileLink} className="hover:opacity-75">
          {nameComponent}
        </Link>
      ) : nameComponent}

      {/* eslint-disable-next-line max-len */}
      {(forChatHeader && userAvailability && sendBirdUserToShow.user_id !== currentUserSendbirdId) && (
        <UserAvailabilityIndicator
          variant="pulseWithText"
          availability={userAvailability}
          animateOnlineStatus={false}
        />
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ChatChannelSingleChat;
