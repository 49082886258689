import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Alert, SelectDateDropdown, SelectDropDown, Spinner,
} from '../../../../common';
import { useMslMeetingSlots } from '../../../api/queries';
import { getUser } from '../../../../auth/redux/selectors';

/* =============================================================================
<MslMeetingTimeSelect />
============================================================================= */
function MslMeetingTimeSelect({
  meetingTypeId, onSlotSelect, slot, date, onDateSelect,
}) {
  const user = useSelector(getUser);
  const { mslId } = useParams();

  const {
    data: mslMeetingSlots,
    status: mslMeetingSlotsStatus,
    isLoading: mslMeetingSlotsIsLoading,
    isFetching: mslMeetingSlotsIsFetching,
  } = useMslMeetingSlots({
    mslId,
    meetingTypeId,
    date,
    timezone: user?.timezone,
  });

  const userTimezone = user?.timezone;

  useEffect(() => {
    onSlotSelect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mslId, meetingTypeId, date]);

  const timeSlots = mslMeetingSlots?.formatted_slots_in_utc?.map((mslMeetingSlot) => ({
    name: `${moment.utc(mslMeetingSlot[0])
      .tz(userTimezone)
      .format('h:mma')} - ${moment.utc(mslMeetingSlot[1])
      .tz(userTimezone)
      .format('h:mma')}`,
    key: `${moment.utc(mslMeetingSlot[0])
      .format('YYYY-MM-DD HH:mm:ss')}|${moment.utc(mslMeetingSlot[1])
      .format('YYYY-MM-DD HH:mm:ss')}`,
  }));

  return (
    <div className="space-y-4">
      <h2 className="text-gray-700 font-[Inter] font-medium">
        Select a time slot
      </h2>
      <div className="flex flex-col">
        <SelectDateDropdown
          value={date}
          onChange={(e) => onDateSelect(e.target.value)}
          timezone={user?.timezone}
          label="Date"
          name="date"
        />
        {mslMeetingSlotsIsLoading && mslMeetingSlotsIsFetching && (
          <div className="mt-3">
            <Spinner size={20} />
          </div>
        )}
        {mslMeetingSlotsStatus === 'success' && timeSlots.length === 0 && (
          <div className="mt-3">
            <Alert
              title="There are no availabilities for this date"
              status="info"
              description="Please select a different date"
            />
          </div>
        )}
        {mslMeetingSlotsStatus === 'success' && timeSlots.length !== 0 && (
          <div className="mt-4">
            <SelectDropDown
              label="Time"
              placeholder="Select time slot..."
              items={timeSlots}
              value={slot}
              onChange={(e) => onSlotSelect(e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslMeetingTimeSelect;
