import React from 'react';

import ChatChannelAvatarTwoMembers from './ChatChannelAvatarTwoMembers';
import ChatChannelAvatarThreeMembers from './ChatChannelAvatarThreeMembers';
import ChatChannelAvatarFourMembers from './ChatChannelAvatarFourMembers';

/* =============================================================================
<ChatChannelAvatar />
============================================================================= */
function ChatChannelAvatar({ channel, forChatHeader }) {
  const membersCount = channel?.memberCount;

  if (membersCount === 3) {
    return <ChatChannelAvatarThreeMembers channel={channel} />;
  }

  if (membersCount > 3) {
    return <ChatChannelAvatarFourMembers channel={channel} />;
  }

  return <ChatChannelAvatarTwoMembers channel={channel} forChatHeader={forChatHeader} />;
}

/* Export
============================================================================= */
export default ChatChannelAvatar;
