import React, { useState, useEffect } from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import {
  Button, EmptyState, Modal, ProductCard, Spinner,
} from '../../../common';
import { ReactComponent as ScanIcon } from '../../../assets/icons/edit-scan-icon.svg';
import { ReactComponent as ArrowPathIcon } from '../../../assets/icons/edit-arrow-path.svg';

import { useGetProductFromBarcode } from '../../api/mutations';

function ProductBarCodeScanModal({ visible, onClose }) {
  const [cameraFlip, setCameraFlip] = useState('environment');
  const [scannerResult, setScannerResult] = useState();
  const [product, setProduct] = useState();
  const {
    isLoading: scannerLoading,
    mutate: getProductFromBarcode,
  } = useGetProductFromBarcode();

  const _toggleCameraFlip = () => setCameraFlip((prev) => (prev === 'environment' ? 'user' : 'environment'));

  // clearing state on unmount
  useEffect(() => {
    _clearState();
    return () => _clearState();
  }, []);

  const _handleScan = (err, result) => {
    if (result) {
      if (result?.format === 11) { // QR
        setScannerResult(result);
        getProductFromBarcode({
          barcode_type: 'qr',
          barcode_value: result?.text,
        }, {
          onSuccess: (response) => {
            setProduct(response?.data?.data?.product);
          },
        });
      }
      if (result?.format === 7) { // UPC A
        setScannerResult(result);
        getProductFromBarcode({
          barcode_type: 'upc-a',
          barcode_value: result?.text,
        }, {
          onSuccess: (response) => {
            setProduct(response?.data?.data?.product);
          },
        });
      }
    }
  };

  const _clearState = () => {
    setProduct(null);
    setScannerResult(null);
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      heading="Scan a product barcode"
      description="Find out more about the product and speak to an expert."
    >
      {/* scanner component */}
      {!scannerResult ? (
        <div className="p-5 rounded-lg overflow-hidden ">
          <BarcodeScannerComponent
            width="100%"
            height={500}
            facingMode={cameraFlip}
            onUpdate={_handleScan}
          />
          <div className="mt-4 flex justify-center">
            <Button
              icon={<ArrowPathIcon className="w-5 h-5 text-indigo-500" />}
              variant="secondary"
              title="Flip the camera"
              onClick={_toggleCameraFlip}
            />
          </div>
        </div>
      ) : null}

      {/* loading of product fetch */}
      {scannerLoading && (
        <div className="w-full text-center my-10">
          <Spinner size={25} />
        </div>
      )}

      {/* product card */}
      {product && (
        <div className="flex flex-col items-center justify-start space-y-5 p-5">
          <div className="p-5 flex w-full items-center border rounded-lg bg-white">
            <ProductCard
              product={product}
              alwaysShowActionsVertically
              alwaysShowActionsAsFullWidth
            />
          </div>
          <div className="w-full">
            <Button
              title="Scan a different product"
              variant="secondary"
              icon={<ScanIcon />}
              customStyleClasses="w-full"
              onClick={_clearState}
            />
          </div>
        </div>
      )}

      {/* product not found */}
      {!product && scannerResult && !scannerLoading ? (
        <div className="my-10 flex flex-col items-center">
          <EmptyState
            title="We couldn't find the product"
            icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
          />
          <Button
            title="Try again"
            variant="secondary"
            icon={<ScanIcon />}
            customStyleClasses="w-40 mt-5"
            onClick={_clearState}
          />
        </div>
      ) : null}
    </Modal>
  );
}

export default ProductBarCodeScanModal;
