import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsAuthenticated, getUser } from '../auth/redux/selectors';
import { hcpPaths, mslPaths } from '../config/paths';
import AuthLayout from '../common/Layout/AuthLayout';

function UnauthenticatedRoute({ showMainLayout }) {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);

  const authenticatedCheck = user;

  const successComponent = showMainLayout ? <AuthLayout /> : <Outlet />;
  const redirectPath = user ? (user?.user_type?.key === 'hcp' ? hcpPaths.dashboard : mslPaths.dashboard) : null;

  return (!authenticatedCheck ? successComponent : <Navigate to={redirectPath} />);
}

UnauthenticatedRoute.defaultProps = {
  showMainLayout: true,
};

export default UnauthenticatedRoute;
