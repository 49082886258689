import React from 'react';
import {
  ChatIcon,
  CalendarIcon,
  PhoneIcon,
  UsersIcon,
} from '@heroicons/react/outline';

import { ReactComponent as DashboardIconActive } from '../assets/icons/nav-dashboard-icon-active.svg';
import { ReactComponent as DashboardIconInActive } from '../assets/icons/nav-dashboard-icon-Inactive.svg';
import { ReactComponent as ScheduleIconActive } from '../assets/icons/edit-edit-active-icon.svg';
import { ReactComponent as ScheduleIconInActive } from '../assets/icons/edit-edit-inActive-icon.svg';
import { generalPaths, mslPaths } from '../config/paths';

export default [
  {
    title: 'DASHBOARD',
    items: [
      {
        to: mslPaths.dashboard,
        title: 'Overview',
        iconActive: <DashboardIconActive width={22} />,
        iconInActive: <DashboardIconInActive width={22} />,
      },

    ],
  },
  {
    title: 'CONNECT',
    items: [
      {
        to: mslPaths.chats(),
        title: 'Messaging',
        iconActive: <ChatIcon width={24} color="#374151" />,
        iconInActive: <ChatIcon width={24} color="#9CA3AF" />,
      },
      {
        to: mslPaths.meetings('upcoming'),
        title: 'Meetings',
        iconActive: <CalendarIcon width={24} color="#374151" />,
        iconInActive: <CalendarIcon width={24} color="#9CA3AF" />,
      },
      {
        to: generalPaths.callHistory,
        title: 'Call history',
        iconActive: <PhoneIcon width={24} color="#374151" />,
        iconInActive: <PhoneIcon width={24} color="#9CA3AF" />,
      },
    ],
  },
  {
    title: 'SETTINGS',
    items: [
      {
        to: mslPaths.schedules,
        title: 'Schedule',
        iconActive: <ScheduleIconActive className="ml-0.5" />,
        iconInActive: <ScheduleIconInActive className="ml-0.5" />,
      },
      {
        to: mslPaths.meetingTypes,
        title: 'Meeting types',
        iconActive: <UsersIcon width={22} color="#374151" />,
        iconInActive: <UsersIcon width={22} color="#9CA3AF" />,
      },
    ],
  },
];
