/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import Toast from '../../common/Toast';
import { client as queryClient } from '../../config/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError, showToastSuccessMessageFromAxiosResponse } from '../../util/functions';

/**
 * Register HCP
 */
export const useHcpRegister = () => {
  const { axiosPublic } = useAxios();
  return useMutation((data) => axiosPublic.post('auth/hcp/register', {
    ...data,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
   * Login HCP
   */
export const useHcpLogin = () => {
  const { axiosPublic } = useAxios();
  return useMutation((data) => axiosPublic.post('auth/hcp/login', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
   * Login MSL
   */
export const useMslLogin = () => {
  const { axiosPublic } = useAxios();
  return useMutation((data) => axiosPublic.post('auth/msl/login', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
   * Accept MSL invite
   */
export const useAcceptMslInvite = () => {
  const { axiosPublic } = useAxios();
  return useMutation((data) => axiosPublic.post('auth/msl/accept-invite', {
    ...data,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Request password reset
 */
export const useRequestPasswordReset = () => {
  const { axiosPublic } = useAxios();
  return useMutation((data) => axiosPublic.post('auth/request-reset-password', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Reset password
 */
export const useResetPassword = () => {
  const { axiosPublic } = useAxios();
  return useMutation((data) => axiosPublic.post('auth/reset-password', data), {
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Account Password
 */
export const useUpdateAccountPassword = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('shared/account/update-password', data), {
    onSuccess: (response) => {
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Account email
 */
export const useUpdateAccountEmail = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('shared/account/update-email', data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(queryKeys.hcpUser());
      queryClient.invalidateQueries(queryKeys.mslUser());
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Availability Status
 */
export const useUpdateAvailabilityStatus = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((status) => axiosPrivate.post(`shared/account/availability/show-as-${status}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.hcpUser());
      queryClient.invalidateQueries(queryKeys.mslUser());
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Create Hcp Address
 */
export const useCreateHcpAddress = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('hcp/account/addresses', data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['hcp-addresses']);
      toast.custom((toastProps) => (
        <Toast
          status="success"
          title="Successfully added your address."
          description={null}
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
   * Delete Hcp Address
   */
export const useDeleteHcpAddress = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((addressId) => axiosPrivate.delete(`hcp/account/addresses/${addressId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['hcp-addresses']);
      toast.custom((toastProps) => (
        <Toast
          status="success"
          title="Successfully deleted."
          description={null}
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Logout user
 */
export const useLogoutUser = () => {
  const { axiosPrivate } = useAxios();
  return useMutation(() => axiosPrivate.get('/auth/logout'), {
    onSuccess: () => {
      toast.custom((toastProps) => (
        <Toast
          status="success"
          title="Successfully signed out."
          description={null}
          onClose={() => toast.remove(toastProps.id)}
        />
      ));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Broadcast Authentication
 */
export const useBroadcastAuthentication = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('auth/broadcasting', data), {
    retry: 2, // This will retry for any error (401, 403, etc..., when don't have permission, 403 is returned) -- important
  });
};
