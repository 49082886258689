import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  BeakerIcon,
  ThumbUpIcon,
  DuplicateIcon,
  PhotographIcon,
  DocumentTextIcon,
  DotsVerticalIcon,
  PresentationChartLineIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';

import { useGetProduct } from '../../api/queries';
import { useProductSamplesLogInterest } from '../../api/mutations';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ProductHeaderOptionsMenu />
============================================================================= */
function ProductHeaderOptionsMenu({
  productId,
  onMediaClick,
  onOrderSampleClick,
  onTreatmentGuidelineClick,
  onFormularyClick,
  onOpenTrailClick,
}) {
  const { data: product } = useGetProduct(productId);
  const { mutate: logInterestInProduct } = useProductSamplesLogInterest();

  const media = product?.media;
  const canOrderSamples = product?.can_order_samples;
  const miLettersPath = product?.mi_letters_file_path;
  const digitalPiPath = product?.digital_pi_file_path;
  const reportUrl = product?.pharma_company?.report_adverse_event_website_url;
  const treatment_guidelines = product?.treatment_guidelines;
  const formulary = product?.formulary;
  const open_trials = product?.open_trials;

  const _handleOrderSampleClick = () => {
    if (!canOrderSamples) {
      logInterestInProduct(productId);
    } else {
      onOrderSampleClick();
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="space-x-2 sm:space-x-0 h-10 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 sm:px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <DotsVerticalIcon className="mt-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items static className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {miLettersPath && (
          <Menu.Item>
            {({ active }) => (
              <a
                href={miLettersPath}
                target="_blank"
                rel="noreferrer"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm',
                )}
              >
                <DocumentTextIcon
                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                MI letters
              </a>
            )}
          </Menu.Item>
          )}
          {digitalPiPath && (
            <Menu.Item>
              {({ active }) => (
                <a
                  href={digitalPiPath}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                >
                  <DocumentTextIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Digital PI
                </a>
              )}
            </Menu.Item>
          )}
          {media && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                  onClick={onMediaClick}
                >
                  <PhotographIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Media
                </button>
              )}
            </Menu.Item>
          )}
          {reportUrl && (
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`${reportUrl}`}
                  target="blank"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm',
                  )}
                >
                  <ExclamationCircleIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Report adverse event
                </a>
              )}
            </Menu.Item>
          )}
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                onClick={_handleOrderSampleClick}
              >
                <BeakerIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                Order samples
              </button>
            )}
          </Menu.Item>
          {treatment_guidelines && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                  onClick={onTreatmentGuidelineClick}
                >
                  <PresentationChartLineIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Treatment guidelines
                </button>
              )}
            </Menu.Item>
          )}
          {formulary && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                  onClick={onFormularyClick}
                >
                  <DuplicateIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Formulary
                </button>
              )}
            </Menu.Item>
          )}
          {open_trials && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex w-full items-center px-4 py-2 text-sm',
                  )}
                  onClick={onOpenTrailClick}
                >
                  <ThumbUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Open trials
                </button>
              )}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

/* Export
============================================================================= */
export default ProductHeaderOptionsMenu;
