import React from 'react';

/* =============================================================================
<LiveUpdatesHeader />
============================================================================= */
function LiveUpdatesHeader() {
  return (
    <div className="flex justify-between items-center mb-3">
      <h3 className="text-lg font-semibold leading-6 text-gray-900 font-[Inter]">Live updates</h3>
    </div>
  );
}

/* Export
============================================================================= */
export default LiveUpdatesHeader;
