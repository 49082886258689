import React from 'react';
import MeetingInfoParticipant from './MeetingInfoParticipant';

/* =============================================================================
<MeetingInfoParticipants />
============================================================================= */
function MeetingInfoParticipants({ meeting }) {
  const participants = meeting?.participants;

  return (
    <div className="my-2 px-6 divide-y">
      {participants?.map((participant) => (
        <MeetingInfoParticipant
          key={participants?.id}
          participant={participant}
        />
      ))}
    </div>
  );
}

/* Export
============================================================================= */
export default MeetingInfoParticipants;
