import React, { createContext, useMemo } from 'react';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axios from 'axios';
import { API_URL } from '../config/secrets';
import useLogout from '../util/hooks/useLogout';
import { localStorageKeys } from '../config/constants';

export const AxiosContext = createContext(undefined);

// Get access token form local storage (function)
const getAccessToken = () => localStorage.getItem(localStorageKeys.discreedlyAccessToken);

/* =============================================================================
<AxiosProvider />
============================================================================= */
function AxiosProvider({ children }) {
  const { logout } = useLogout();

  const axiosPrivate = axios.create({
    baseURL: API_URL,
  });

  const axiosPublic = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Required
  });

  axiosPrivate.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );

  const refreshAuthLogic = (failedRequest) => axios.create({
    withCredentials: true,
  }).post(`${API_URL}auth/refresh-access-token`)
    .then(async (tokenRefreshResponse) => {
      const newAccessToken = tokenRefreshResponse.data.data.access_token;
      failedRequest.response.config.headers.Authorization = `Bearer ${newAccessToken}`;
      localStorage.setItem(localStorageKeys.discreedlyAccessToken, newAccessToken);
      return Promise.resolve();
    })
    .catch((e) => {
      // If 498 (refresh token expired)
      if (e.response.status === 498) {
        logout();
      }
    });

  createAuthRefreshInterceptor(axiosPrivate, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true,
  });

  const onError = (error) => Promise.reject(error);

  // Add error handler to both axios instances
  axiosPrivate.interceptors.response.use((response) => response, onError);
  axiosPublic.interceptors.response.use((response) => response, onError);

  const value = useMemo(() => ({
    axiosPrivate,
    axiosPublic,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>;
}

/* Export
============================================================================= */
export default AxiosProvider;
