import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { DirectCallEndResult } from 'sendbird-calls';
import CallRingingModal from './CallRingingModal';
import { useSbCalls } from '../../lib/context';
import CallRateModal from './CallRateModal';
import { getUser } from '../../../auth/redux/selectors';
import IncomingCallSound from '../../../assets/sounds/incoming-call.mp3';

/* =============================================================================
<CallsListener />
============================================================================= */
function CallsListener({ user }) {
  const sbCalls = useSbCalls();
  const { calls, isAuthenticated } = sbCalls;

  const ringingCalls = calls ? calls.filter((callItem) => callItem.myRole === 'dc_callee' && callItem.callState === 'ringing' && callItem.isOngoing === true) : null;
  const ringingCall = ringingCalls ? ringingCalls[ringingCalls.length - 1] : null;

  const endedCalls = calls ? calls.filter((callItem) => callItem.callState === 'ended') : null;
  const endedCall = endedCalls ? endedCalls[endedCalls.length - 1] : null;

  const [showCallRatingModal, setShowCallRatingModal] = useState(false);

  const { endResult: endedCallEndResult, customItems: endedCallCustomItems } = endedCall || {};

  const audioElement = useMemo(() => new Audio(), []);
  const [isPlayingRingingSound, setIsPlayingRingingSound] = useState(false);

  useEffect(() => {
    if (ringingCall) {
      if (!isPlayingRingingSound) {
        setIsPlayingRingingSound(true);

        // If audio element src is empty
        audioElement.src = IncomingCallSound;
        // Start playing the sound, if it's not already playing
        audioElement.play();
        audioElement.loop = true;
      }
    } else {
      setIsPlayingRingingSound(false);

      // Must destroy the audio element if not ringing, otherwise it may keep playing in the background, or when you click the play button on your computer, it will start playing!
      audioElement.pause();
      audioElement.src = '';
      audioElement.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ringingCall]);

  useEffect(() => {
    if (endedCall) {
      if (endedCallEndResult === DirectCallEndResult.COMPLETED) {
        setShowCallRatingModal(true);
      }
    }
  }, [endedCall, endedCallEndResult]);

  return (
    <>
      <CallRingingModal
        visible={ringingCall != null}
        onClose={() => null}
      />
      {user?.user_type?.key === 'hcp' && (
        <CallRateModal
          directCallId={endedCallCustomItems?.discreedly_direct_call_id}
          visible={showCallRatingModal}
          onClose={() => setShowCallRatingModal(false)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
);

/* Export
============================================================================= */
export default connect(mapStateToProps)(React.memo(CallsListener, propsAreEqual));
