import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';

/* =============================================================================
<CounterInput />
============================================================================= */
function CounterInput({
  value, onChange, minValue, maxValue,
}) {
  const _handleMinusClick = () => {
    const newValue = value - 1;
    if (newValue >= minValue) {
      onChange(newValue);
    }
  };

  const _handlePlusClick = () => {
    const newValue = value + 1;
    if (newValue <= maxValue) {
      onChange(newValue);
    }
  };

  return (
    <div className="space-x-2 flex">
      <button type="button" onClick={_handleMinusClick} className="w-10 h-10 border border-gray-300 shadow-sm hover:bg-gray-50 flex justify-center items-center rounded-lg group">
        <MinusIcon className="w-3 h-3 text-gray-500 group-hover:text-gray-900" />
      </button>
      <div className="w-14 h-10 border border-gray-300 shadow-sm font-[Inter] text-sm flex justify-center items-center rounded-lg select-none">{value}</div>
      <button type="button" onClick={_handlePlusClick} className="w-10 h-10 border border-gray-300 shadow-sm hover:bg-gray-50 flex justify-center items-center rounded-lg group">
        <PlusIcon className="w-3 h-3 text-gray-500 group-hover:text-gray-900" />
      </button>
    </div>
  );
}

CounterInput.defaultProps = {
  minValue: 0,
  maxValue: 1000,
};

/* Export
============================================================================= */
export default CounterInput;
