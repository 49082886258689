/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError, showToastSuccessMessageFromAxiosResponse } from '../../util/functions';

/**
 *  HCP Profile Update
 */
export const useHcpProfileUpdate = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('/hcp/account', data), {
    onSuccess: (response) => {
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * MSL Profile Update
 */
export const useMslProfileUpdate = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('/msl/account', data), {
    onSuccess: (response) => {
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Profile Image
 */
export const useUpdateProfileImage = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((profile_image) => axiosPrivate.post('/shared/account/update-profile-image', profile_image), {
    onSuccess: (response) => {
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Remove Profile Image
 */
export const useRemoveProfileImage = () => {
  const { axiosPrivate } = useAxios();
  return useMutation(() => axiosPrivate.post('/shared/account/remove-profile-image'), {
    onSuccess: (response) => {
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};
