import SendBirdCall, { RoomType, SoundType } from 'sendbird-calls';
import { client as queryClient } from '../../../config/react-query';
import { queryKeys as callQueryKeys } from '../../api/query-key-factory';
import { queryKeys as authQueryKeys } from '../../../auth/api/query-key-factory';
import { queryKeys as dashboardQueryKeys } from '../../../dashboard/api/query-key-factory';

const registerDirectCallListeners = (
  call,
  dispatchUpdate,
) => {
  call.onEstablished = (thisCall) => {
    dispatchUpdate({ callState: 'established' });
  };
  call.onConnected = (thisCall) => {
    dispatchUpdate({ callState: 'connected' });
  };
  call.onReconnected = (thisCall) => {
    dispatchUpdate({ callState: 'reconnected' });
  };
  call.onReconnecting = (thisCall) => {
    dispatchUpdate({ callState: 'reconnecting' });
  };
  call.onEnded = (thisCall) => {
    dispatchUpdate({
      callState: 'ended',
      isEnded: true,
      isOngoing: false,
      endResult: thisCall?.endResult,
    });

    // Custom rules
    queryClient.invalidateQueries(callQueryKeys.callHistory());

    // Where the missed calls count is stored
    queryClient.invalidateQueries(authQueryKeys.hcpUser());
    queryClient.invalidateQueries(authQueryKeys.mslUser());

    // So on the dashboard, the data is updated
    queryClient.invalidateQueries(dashboardQueryKeys.mslCallDashboard());
  };
  call.onRemoteAudioSettingsChanged = (thisCall) => {
    dispatchUpdate({ isRemoteAudioEnabled: call.isRemoteAudioEnabled });
  };
  call.onRemoteVideoSettingsChanged = (thisCall) => {
    dispatchUpdate({ isRemoteVideoEnabled: thisCall.isRemoteVideoEnabled });
  };
  call.onCustomItemsUpdated = (thisCall) => {
    dispatchUpdate({ customItems: thisCall._customItems || thisCall?.customItems });
  };
  call.onScreenShareStopped = (thisCall) => {
    dispatchUpdate({
      isLocalScreenShareEnabled: false,
    });
  };
  // onCustomItemsDeleted() {
  //
  // },
};

export const statefyRoom = (
  room,
  dispatch,
) => {
  const dispatchUpdate = (part) => {
    const payload = {
      roomId: room.roomId,
      ...part,
    };
    dispatch({ type: 'UPDATE_ROOM', payload });
  };

  const updateRoom = () => {
    dispatchUpdate(statefyRoom(room, dispatch));
  };

  const updateLocalParticipant = (participant) => {
    dispatch({
      type: 'UPDATE_ROOM_LOCAL_PARTICIPANT',
      payload: {
        roomId: room.roomId,
        participant,
      },
    });
  };
  const upsertRemoteParticipant = (participant) => {
    const discreedlyGroupCallId = room?.customItems?.discreedly_group_call_id;
    queryClient.invalidateQueries(callQueryKeys.groupCallParticipants(discreedlyGroupCallId));

    dispatch({
      type: 'UPSERT_ROOM_REMOTE_PARTICIPANT',
      payload: {
        roomId: room.roomId,
        participant: statefyRemoteParticipant(participant),
      },
    });
  };

  const deleteRemoteParticipant = (participant) => {
    const discreedlyGroupCallId = room?.customItems?.discreedly_group_call_id;
    queryClient.invalidateQueries(callQueryKeys.groupCallParticipants(discreedlyGroupCallId));

    dispatch({
      type: 'DELETE_ROOM_REMOTE_PARTICIPANT',
      payload: {
        roomId: room.roomId,
        participantId: participant.participantId,
      },
    });
  };

  const statefulLocalParticipants = room.localParticipant
    ? [statefyLocalParticipant(room.localParticipant, updateLocalParticipant)] : [];
  const statefulRemoteParticipants = room.remoteParticipants.map(statefyRemoteParticipant);
  const statefulParticipants = [...statefulLocalParticipants, ...statefulRemoteParticipants];

  room.on('remoteParticipantEntered', upsertRemoteParticipant);
  room.on('remoteParticipantStreamStarted', upsertRemoteParticipant);
  room.on('remoteParticipantExited', deleteRemoteParticipant);
  room.on('remoteAudioSettingsChanged', upsertRemoteParticipant);
  room.on('remoteVideoSettingsChanged', upsertRemoteParticipant);
  room.on('error', (error) => {
    // eslint-disable-next-line no-console
    console.error(error); // TODO: Handle error
  });

  return {
    roomId: room.roomId,
    roomType: room.roomType,
    createdAt: room.createdAt,
    group_call_discreedly_id: room.customItems?.discreedly_group_call_id,
    createdBy: room.createdBy,
    setAudioForLargeRoom: room.setAudioForLargeRoom,
    participants: statefulParticipants,
    localParticipant: statefulLocalParticipants[0],
    remoteParticipants: statefulRemoteParticipants,
    enter(params) {
      return room.enter(params).then(() => {
        updateRoom();
      }).then(() => {
        if (room.roomType === RoomType.LARGE_ROOM_FOR_AUDIO_ONLY) {
          const audioView = document.createElement('audio');
          audioView.autoplay = true;
          room.setAudioForLargeRoom(audioView);
        }
      });
    },
    exit() {
      room.exit();
      updateRoom();
    },
  };
};

export const statefyLocalParticipant = (
  participant,
  update,
) => ({
  participantId: participant.participantId,
  enteredAt: participant.enteredAt,
  updatedAt: participant.updatedAt,
  exitedAt: participant.exitedAt,
  duration: participant.duration,
  isLocalParticipant: participant.isLocalParticipant,
  state: participant.state,
  user: participant.user,
  isAudioEnabled: participant.isAudioEnabled,
  isVideoEnabled: participant.isVideoEnabled,
  setMediaView(mediaView) {
    return participant.setMediaView(mediaView);
  },
  setLocalMediaView(mediaView) {
    return participant.setLocalMediaView(mediaView);
  },
  muteMicrophone() {
    participant.muteMicrophone();
    update({ isAudioEnabled: false });
  },
  unmuteMicrophone() {
    participant.unmuteMicrophone();
    update({ isAudioEnabled: true });
  },
  startVideo() {
    participant.startVideo();
    update({ isVideoEnabled: true });
  },
  stopVideo() {
    participant.stopVideo();
    update({ isVideoEnabled: false });
  },
});

export const statefyRemoteParticipant = (
  participant,
) => ({
  participantId: participant.participantId,
  enteredAt: participant.enteredAt,
  updatedAt: participant.updatedAt,
  exitedAt: participant.exitedAt,
  duration: participant.duration,
  isLocalParticipant: participant.isLocalParticipant,
  state: participant.state,
  user: participant.user,
  isAudioEnabled: participant.isAudioEnabled,
  isVideoEnabled: participant.isVideoEnabled,
  setMediaView(mediaView) {
    return participant.setMediaView(mediaView);
  },
});

export const statefyDirectCall = (
  call,
  dispatch,
  registerListener,
) => {
  const dispatchUpdate = (part) => {
    const payload = {
      callId: call.callId,
      ...part,
    };
    dispatch({ type: 'UPDATE_CALL', payload });
  };

  if (registerListener) {
    registerDirectCallListeners(call, dispatchUpdate);
  }

  return {
    callState: (call.localUser.userId === call.caller.userId) ? 'dialing' : 'ringing',
    callId: call.callId,
    caller: call.caller, // This should not mutate
    callee: call.callee, // This should not mutate
    isVideoCall: call.isVideoCall,
    localUser: call.localUser, // This should not mutate
    remoteUser: call.remoteUser, // This should not mutate
    isLocalAudioEnabled: call.isLocalAudioEnabled,
    isLocalScreenShareEnabled: call.isLocalScreenShareEnabled,
    isRemoteAudioEnabled: call.isRemoteAudioEnabled,
    isLocalVideoEnabled: call.isLocalVideoEnabled,
    isRemoteVideoEnabled: call.isRemoteVideoEnabled,
    myRole: call.myRole,
    isOngoing: call.isOngoing,
    endedBy: call.endedBy, // This should not mutate
    isEnded: call.isEnded,
    endResult: call.endResult,
    // callLog: call.callLog, // This should not mutate
    customItems: call._customItems || call?.customItems, // This should not mutate
    localMediaView: call.localMediaView,

    startScreenShare() {
      dispatchUpdate({ isLocalScreenShareEnabled: true });
      return call.startScreenShare({
        video: {
          enabled: false,
        },
      });
    },

    stopScreenShare() {
      dispatchUpdate({ isLocalScreenShareEnabled: false });
      return call.stopScreenShare();
    },

    setLocalMediaView(mediaView) {
      dispatchUpdate({ localMediaView: mediaView });
      return call.setLocalMediaView(mediaView);
    },
    setRemoteMediaView(mediaView) {
      dispatchUpdate({ remoteMediaView: mediaView });
      return call.setRemoteMediaView(mediaView);
    },

    stopVideo() {
      dispatchUpdate({ isLocalVideoEnabled: false });
      return call.stopVideo();
    },
    startVideo() {
      dispatchUpdate({ isLocalVideoEnabled: true });
      return call.startVideo();
    },
    getDuration() {
      return call.getDuration();
    },
    accept(params) {
      return call.accept(params);
    },
    end() {
      return call.end();
    },

    muteMicrophone() {
      dispatchUpdate({ isLocalAudioEnabled: false });
      return call.muteMicrophone();
    },
    unmuteMicrophone() {
      dispatchUpdate({ isLocalAudioEnabled: true });
      return call.unmuteMicrophone();
    },

    // captureLocalVideoView(callback?) {
    //   return call.captureLocalVideoView(callback);
    // },
    // captureRemoteVideoView(callback?) {
    //   return call.captureRemoteVideoView(callback);
    // },

    // updateCustomItems(customItems, callback?): Promise<> {
    // },
    // deleteCustomItems(customItemsKeys: string[], callback?): Promise<> {
    // },
    // deleteAllCustomItems(callback?): Promise<> {
    // },

    // startRecording(options): string {
    // },
    // stopRecording(recordingId: string): boolean {
    // },
  };
};
