import React from 'react';
import { CalendarIcon, ClockIcon, PhoneIcon } from '@heroicons/react/solid';

import { EmptyState, Spinner } from '../../common';
import { useMslCallDashboardData } from '../api/queries';

/* =============================================================================
<MslDashboardPerformance />
============================================================================= */
function MslDashboardPerformance() {
  const { data, isLoading } = useMslCallDashboardData();
  const directCalls = data?.direct_calls;
  const callsCount = directCalls?.total_count;
  const averageDurationOfCallInMinutes = directCalls?.average_duration_in_minutes;
  const meetingsCount = data?.meetings?.total_count;

  return (
    <div className="">
      <h2 className="font-[Inter] text-lg font-semibold text-gray-600 mb-3">Performance</h2>
      <div className="grid grid-cols-1 gap-y-5 lg:grid-cols-3">
        <div className="bg-white p-6 rounded-lg lg:rounded-none lg:rounded-l-lg border">
          {(callsCount > 0 && !isLoading) && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <PhoneIcon className="w-6 h-6 text-indigo-600" />
              </div>
              <h1 className="font-[Inter] text-xl text-gray-700 font-semibold">{`${callsCount} calls`}</h1>
              <h2 className="font-[Inter] text-gray-600 font-medium mt-1.5">Number of on-demand calls</h2>
            </div>
          )}
          {!callsCount && !isLoading && (
            <div className="flex flex-col justify-center items-center text-center py-6 px-8 rounded-lg my-6">
              <EmptyState
                title="Number of on-demand calls"
                description="You haven't had any calls yet"
                icon={<PhoneIcon className="w-5 h-5 mb-1 text-gray-400 text-center" />}
              />
            </div>
          )}
          {/* loading spinner */}
          {isLoading && (
            <div className="text-center my-8">
              <Spinner size={25} />
            </div>
          )}
        </div>
        <div className="bg-white p-6 rounded-lg lg:rounded-none border">
          {(callsCount !== 0 && !isLoading) && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <ClockIcon className="w-6 h-6 text-indigo-600" />
              </div>
              <h1 className="font-[Inter] text-xl text-gray-700 font-semibold">{averageDurationOfCallInMinutes ? `${parseFloat(averageDurationOfCallInMinutes).toFixed(2)} mins` : null}</h1>
              <h2 className="font-[Inter] text-gray-600 font-medium mt-1.5">Avg. duration of on-demand calls</h2>
            </div>
          )}
          {callsCount === 0 && !isLoading && (
            <div className="flex flex-col justify-center items-center text-center py-6 px-8 rounded-lg my-6">
              <EmptyState
                title="Avg. duration of on-demand calls"
                description="You haven't had any calls yet"
                icon={<ClockIcon className="w-5 h-5 mb-1 text-gray-400 text-center" />}
              />
            </div>
          )}
          {/* loading spinner */}
          {isLoading && (
            <div className="text-center my-8">
              <Spinner size={25} />
            </div>
          )}
        </div>
        <div className="bg-white p-6 rounded-lg lg:rounded-none lg:rounded-r-lg border">
          {(meetingsCount > 0 && !isLoading) && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <CalendarIcon className="w-6 h-6 text-indigo-600" />
              </div>
              <h1 className="font-[Inter] text-xl text-gray-700 font-semibold">{`${meetingsCount} meetings`}</h1>
              <h2 className="font-[Inter] text-gray-600 font-medium mt-1.5">Number of meetings</h2>
            </div>
          )}
          {!meetingsCount && !isLoading && (
            <div className="flex flex-col justify-center items-center text-center py-6 px-8 rounded-lg my-6">
              <EmptyState
                title="Number of meetings"
                description="You haven't had any meetings yet"
                icon={<CalendarIcon className="w-5 h-5 mb-1 text-gray-400 text-center" />}
              />
            </div>
          )}
          {/* loading spinner */}
          {isLoading && (
            <div className="text-center my-8">
              <Spinner size={25} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslDashboardPerformance;
