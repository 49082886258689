import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ChatDesktopLayout from '../components/ChatDesktopLayout';
import ChatMobileLayout from '../components/ChatMobileLayout';

/* =============================================================================
<ChatScreen />
============================================================================= */
function ChatScreen() {
  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });

  return isBigScreen ? <ChatDesktopLayout /> : <ChatMobileLayout />;
}

/* Export
============================================================================= */
export default ChatScreen;
