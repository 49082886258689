import React from 'react';
import { SelectDropDown } from '../../../common';
import { useUsStates } from '../../../auth/api/queries';
import { useGetTrendingListsCategories } from '../../api/queries';

const getCategories = (categories, parentLabel, newArray = []) => {
  categories.forEach((category) => {
    const label = parentLabel ? `${parentLabel} > ${category.name}` : category.name;
    newArray.push({ label, key: category.id });
    if (category.sub_categories.length > 0) {
      getCategories(category.sub_categories, label, newArray);
    }
  });
  return newArray;
};

/* =============================================================================
<ProductsTrendingHeader />
============================================================================= */
function ProductsTrendingHeader({
  selectedCategory, onCategorySelect, selectedUsState, onUsStateSelect,
}) {
  const { data: trendingCategories } = useGetTrendingListsCategories();
  const { data: usStates } = useUsStates();

  return (
    <div className="space-y-5">
      <h1 className="text-2xl font-bold font-[Inter] text-gray-900">
        Therapy area products
      </h1>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 max-w-lg max-w-full">
        <SelectDropDown
          label="Category"
          placeholder="All"
          items={trendingCategories ? getCategories(trendingCategories) : []}
          name="trending_product_list_category_id"
          value={selectedCategory}
          onChange={(e) => onCategorySelect(e.target.value)}
        />
        <SelectDropDown
          label="State"
          placeholder="Select..."
          items={usStates}
          name="us_state_key"
          value={selectedUsState}
          onChange={(e) => onUsStateSelect(e.target.value)}
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductsTrendingHeader;
