import React from 'react';

/* =============================================================================
<SeeMore />
============================================================================= */
const SeeMore = () => (<div />);

/* Export
============================================================================= */
export default SeeMore;
