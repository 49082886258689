import { useQuery } from '@tanstack/react-query';

import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Other User Availability
 */
export const useGetOtherUserAvailability = (userId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.otherUserAvailability(userId),
    async () => {
      const { data } = await axiosPrivate.get(`shared/other-user-availability/${userId}`);
      return data?.data?.availability;
    },
    {
      enabled: userId != null,
      staleTime: 0, // Very important
    },
  );
};

/**
 * Get Msl Profile
 */
export const useGetMslProfile = (mslId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.msl(mslId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/msls/${mslId}`);
      return data?.data?.msl;
    },
    {
      enabled: mslId != null,
      staleTime: 0, // Very important as otherwise they will get stuck on whatever online status they were on
    },
  );
};

/**
 * Get Hcp Profile
 */
export const useGetHcpProfile = (hcpId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.hcp(hcpId),
    async () => {
      const { data } = await axiosPrivate.get(`msl/hcps/${hcpId}`);
      return data?.data?.hcp;
    },
    {
      enabled: hcpId != null,
      staleTime: 0, // Very important as otherwise they will get stuck on whatever online status they were on
    },
  );
};
