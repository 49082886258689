import React, { useState } from 'react';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';
import ChatChannelsList from './ChatChannelsList';
import ChatChannelsHeader from './ChatChannelsHeader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ChatChannels />
============================================================================= */
function ChatChannels() {
  const [query, setQuery] = useState('');

  return (
    <div className={classNames('bg-white h-full flex flex-col w-full lg:w-96 shrink-0 border-r')}>
      <ChatChannelsHeader query={query} onQueryChange={setQuery} />
      <ChannelListProvider
        className={classNames('lg:px-2 flex-1 w-full overflow-y-scroll')}
        allowProfileEdit={false}
        disableAutoSelect
        queries={{
          channelListQuery: {
            order: 'latest_last_message',
            memberStateFilter: 'joined',
            includeEmpty: true,
            nicknameContainsFilter: query,
            limit: 15,
          },
        }}
      >
        <ChatChannelsList query={query} onQueryChange={setQuery} />
      </ChannelListProvider>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatChannels;
