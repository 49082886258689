import React from 'react';
import { useParams } from 'react-router-dom';

import ManageProfileHeader from '../components/ManageProfileHeader';
import ManageProfileDetails from '../components/ManageProfileDetails';
import ManageProfileSubscriptions from '../components/ManageProfileSubscriptions';
import ManageProfileChangePassword from '../components/ManageProfileChangePassword';
import ManageProfileChangeEmail from '../components/ManageProfileChangeEmail';

const tabs = [
  { name: 'Account', key: 'account' },
  { name: 'Subscriptions', key: 'subscriptions' },
  { name: 'Change password', key: 'change-password' },
  { name: 'Change email', key: 'change-email' },
];

/* =============================================================================
<HcpManageProfileScreen />
============================================================================= */
function HcpManageProfileScreen() {
  const { tab } = useParams();

  return (
    <div className="py-7 bg-white w-full min-h-full">
      <ManageProfileHeader tabs={tabs} />
      {tab === 'account' && (
        <ManageProfileDetails
          heading="Account details"
          description="Update your account photo and details here"
        />
      )}
      {tab === 'subscriptions' && <ManageProfileSubscriptions />}
      {tab === 'change-password' && <ManageProfileChangePassword />}
      {tab === 'change-email' && <ManageProfileChangeEmail />}
    </div>
  );
}

/* Export
============================================================================= */
export default HcpManageProfileScreen;
