import toast from 'react-hot-toast';
import Toast from '../common/Toast';

export const isBrowser = typeof window !== 'undefined';

function getInitials(name) {
  if (typeof name !== 'string' || !name.trim()) {
    return '';
  }

  const words = name.split(' ');
  let initials = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (!word) {
      // eslint-disable-next-line no-continue
      continue;
    }

    initials += word[0].toUpperCase();

    if (initials.length >= 2) {
      break;
    }
  }

  return initials;
}

function showToastSuccessMessageFromAxiosResponse(response) {
  const message = response?.data?.message?.title;
  const description = response?.data?.message?.description;

  if (message) {
    toast.custom((toastProps) => (
      // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
      <Toast
        status="success"
        title={message}
        description={description}
        onClose={() => toast.remove(toastProps.id)}
      />
    ));
  }
}

function showToastErrorFromAxiosError(error) {
  const errorMessage = error?.response?.data?.message?.title;
  if (errorMessage) {
    toast.custom((toastProps) => (
      // eslint-disable-next-line react/jsx-filename-extension,react/react-in-jsx-scope
      <Toast
        status="error"
        title={errorMessage}
        onClose={() => toast.remove(toastProps.id)}
      />
    ));
  }
}

export {
  getInitials,
  showToastErrorFromAxiosError,
  showToastSuccessMessageFromAxiosResponse,
};
