import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ChatChannel from './ChatChannel';
import ChatChannels from './ChatChannels';

/* =============================================================================
<ChatMobileLayout />
============================================================================= */
function ChatMobileLayout() {
  return (
    <div className="flex h-full w-full">
      <Routes>
        <Route path="/" element={<ChatChannels />} />
        <Route path="/:channelUrl" element={<ChatChannel />} />
      </Routes>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatMobileLayout;
