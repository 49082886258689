import React from 'react';
import { Drawer, Spinner } from '../../../../common';
import { useGetGroupCallParticipants } from '../../../api/queries';
import GroupCallParticipant from './GroupCallParticipant';

/* =============================================================================
<GroupCallParticipantsDrawer />
============================================================================= */
function GroupCallParticipantsDrawer({ discreedlyGroupCallId, open, onClose }) {
  const {
    data: participants,
    status: getParticipantsStatus,
  } = useGetGroupCallParticipants(discreedlyGroupCallId);

  return (
    <Drawer
      heading="Participants"
      open={open}
      onClose={onClose}
      contentContainerStyles=""
      footer={null}
    >
      {(participants && getParticipantsStatus === 'success') ? (
        <div className="flex flex-col flex-1 overflow-y-scroll px-4 sm:px-6 divide-y">
          {participants?.map((participant) => (
            <GroupCallParticipant participant={participant?.user} />
          ))}
        </div>
      ) : (
        <div className="justify-center items-center w-full text-center">
          <Spinner />
        </div>
      )}
    </Drawer>
  );
}

/* Export
============================================================================= */
export default GroupCallParticipantsDrawer;
