import React, { useState } from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeftIcon, PhoneIcon } from '@heroicons/react/solid';
import {
  MslInformation,
  MslPrescreener,
  EmptyState,
  Spinner,
} from '../../common';
import Button from '../../common/Button';
import { useGetMslProfile } from '../../profile/api/queries';
import { useSubmitPrescreenerAnswers } from '../../company/api/mutations';
import useUserOnlineStatus from '../../util/hooks/useUserOnlineStatus';
import { useSbCalls } from '../lib/context';

/* =============================================================================
<HcpInitiateCallWithMslScreen />
============================================================================= */
function HcpInitiateCallWithMslScreen() {
  const navigate = useNavigate();
  const { mslId } = useParams();
  const { data: msl, isLoading } = useGetMslProfile(mslId);
  const companyId = msl?.pharma_company?.id;
  const [answers, setAnswers] = useState({});
  const [startingCall, setStartingCall] = useState(false);
  const sbCalls = useSbCalls();

  const userAvailability = useUserOnlineStatus(msl?.user?.id, msl?.availability, false);
  const dynamicAvailabilityStatusKey = userAvailability?.dynamic_status?.key;

  const {
    isLoading: submitPrescreenerAnswersLoading,
    mutate: submitPrescreenerAnswers,
  } = useSubmitPrescreenerAnswers();

  const _handleStartCall = async () => {
    await submitPrescreenerAnswers({ companyId, answers }, {
      onSuccess: async (response) => {
        const prescreenerResponseId = response?.data?.data?.prescreener_response?.id;
        // TODO: need the await? (it doesn't have it in the sample app)
        setStartingCall(true);
        const call = await sbCalls.dial({
          userId: msl?.user?.send_bird_user?.id,
          isVideoCall: true, // If we don't make it true, it won't allow screen share.
          callOption: {
            audioEnabled: true,
            videoEnabled: false, // Default, user can change it at any time.
          },
        });
        if (call) {
          const callUrl = prescreenerResponseId ? `/calls/hcp/direct/${call?.callId}/${prescreenerResponseId}` : `/calls/hcp/direct/${call?.callId}`;
          navigate(callUrl);
        }

        setStartingCall(false);
      },
    });
  };

  if (!msl && isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner size={20} />
      </div>
    );
  }

  if (!msl && !isLoading) {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="MSL not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="MSL not found"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col items-center p-6">
          <h2 className="text-xs text-gray-400 uppercase font-semibold font-[Inter] tracking-wide mt-2">SPEAK WITH A MEDICAL EXPERT</h2>
          <h1 className="text-2xl text-gray-700 font-bold font-[Inter] mt-1 mb-8 text-center">What is your goal for this call?</h1>
          <div className="w-full max-w-3xl space-y-4">
            <MslInformation mslId={mslId} userAvailability={userAvailability} />
            <MslPrescreener
              companyId={companyId}
              answers={answers}
              setAnswers={setAnswers}
            />
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-6 lg:px-8 py-6 bg-white justify-between sm:items-center border-t flex w-full sm:space-x-10">
        <div className="flex-1 flex flex-col items-start hidden sm:flex">
          <Button
            title="Back"
            variant="secondary"
            icon={<ChevronLeftIcon className="w-4 h-4 text-black" />}
            onClick={() => navigate(-1)}
            customStyleClasses="w-full sm:w-24"
          />
        </div>
        <div className="flex-1 flex flex-col sm:items-end">
          <Button
            variant="green"
            iconPosition="left"
            icon={<PhoneIcon className="w-4 h-4 text-white" />}
            title="Start call"
            customStyleClasses="w-full sm:w-48"
            onClick={_handleStartCall}
            loading={submitPrescreenerAnswersLoading || startingCall}
            disabled={submitPrescreenerAnswersLoading || startingCall || dynamicAvailabilityStatusKey !== 'online'}
            tooltipContent={dynamicAvailabilityStatusKey !== 'online' ? 'This expert is currently unavailable.' : null}
            tooltipPosition="top"
          />
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default HcpInitiateCallWithMslScreen;
