import {
  Field, Form, Formik,
} from 'formik';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Button, Input, Alert,
} from '../../common';
import { useRequestPasswordReset } from '../api/mutations';

/* =============================================================================
<PasswordResetRequestScreen />
============================================================================= */
function PasswordResetRequestScreen() {
  const navigate = useNavigate();
  const {
    mutate: requestPasswordReset, isLoading,
  } = useRequestPasswordReset();

  const _handleSubmit = (values) => {
    requestPasswordReset(values, {
      onSuccess: () => {
        navigate(`/auth/request-password-reset-success/${values.email}`);
      },
    });
  };

  return (
    <div>
      <h2 className="text-3xl mb-3 font-extrabold font-[Inter] text-gray-900">
        Reset password
      </h2>
      <Alert status="info" title="Enter your email address to get started with your password reset." />
      <Formik initialValues={{ email: '' }} onSubmit={_handleSubmit}>
        {() => (
          <Form className="space-y-6 mt-7" action="#" method="POST">
            <Field name="email">
              {({ field }) => (
                <Input
                  label="Email address"
                  {...field}
                />
              )}
            </Field>
            <Button
              size="block"
              type="submit"
              loading={isLoading}
              title="Request password reset"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

/* Export
============================================================================= */
export default PasswordResetRequestScreen;
