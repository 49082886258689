import React from 'react';
import { FieldArray, Field } from 'formik';

import { PlusIcon, TrashIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { Checkbox, SelectDropDown, SelectTimeDropdown } from '../../../../common';

/* =============================================================================
<ScheduleWeeklyHoursDayItem />
============================================================================= */
function ScheduleWeeklyHoursDayItem({ day, dayIndex }) {
  const _handleRemove = (arrayHelper, i) => {
    arrayHelper.remove(i);
  };

  return (
    <div className="flex flex-col md:flex-row justify-between items-start px-6 py-3">
      <div style={{ flex: 0.5 }} className="flex items-center py-3 space-x-3">
        <Checkbox
          color="indigo"
          checked={day?.hours?.length > 0}
          disabled
        />
        <h1 className="text-sm text-gray-700 font-medium font-[Inter]">{day?.name}</h1>
      </div>
      <div className="flex-1">
        <FieldArray name={`days.${dayIndex}.hours`}>
          {(arrayHelper) => (
            <div className="flex justify-between flex-col md:flex-row">
              <div className="flex flex-col space-y-3 py-3 md:items-center">
                {day?.hours?.length > 0 ? day.hours?.map((currentLoopDay, i) => (
                  <div key={i} className="flex w-full items-center space-x-5 font-[Inter] text-sm">
                    <div className="flex items-center space-x-2">
                      <Field name={`days.${dayIndex}.hours.${i}.start_time`}>
                        {({ field }) => (
                          <SelectTimeDropdown
                            label={null}
                            placeholder="From"
                            customStyles="flex-1"
                            {...field}
                          />
                        )}
                      </Field>
                      <p className="text-gray-500 w-5 text-center">to</p>
                      <Field name={`days.${dayIndex}.hours.${i}.end_time`}>
                        {({ field }) => (
                          <SelectTimeDropdown
                            label={null}
                            name={`days.${dayIndex}.hours.${i}.end_time`}
                            placeholder="Until"
                            customStyles="flex-1"
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                    <button type="button" onClick={() => _handleRemove(arrayHelper, i)} className="group shrink-0">
                      <TrashIcon className="w-5 h-5 text-gray-400 group-hover:text-red-500 transition-all ease-in-out" />
                    </button>
                  </div>
                )) : (
                  <div className="">
                    <p className="text-sm text-gray-700 font-[Inter]">Unavailable</p>
                  </div>
                )}
              </div>
              <button
                type="button"
                onClick={() => arrayHelper.push({
                  start_time: '',
                  end_time: '',
                })}
                className="border flex justify-center items-center md:self-center rounded-lg w-9 h-9 my-2 md:my-0 hover:shadow-md transition-all ease-in-out"
              >
                <PlusIcon className="w-4 h-4 text-indigo-600" />
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleWeeklyHoursDayItem;
