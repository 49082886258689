import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EmojiSadIcon, SearchIcon } from '@heroicons/react/outline';
import ProductMslCard from '../ProductMslCard';

import { useSearchAvailableMslsForProduct } from '../../../api/queries';
import { EmptyState, Spinner, Button } from '../../../../common';

/* =============================================================================
<ProductMslAvailable />
============================================================================= */
function ProductMslAvailable() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const {
    data: availableMslsForProduct,
    isLoading: availableMslsLoading,
  } = useSearchAvailableMslsForProduct(productId);

  return (
    <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-1 h-full flex flex-col">
      {/* loading */}
      {availableMslsLoading && (
        <div className="text-center mt-8">
          <Spinner />
        </div>
      )}

      {(!availableMslsLoading && availableMslsForProduct?.length !== 0) && (
        <div className="grid grid-cols-1 gap-y-5 gap-x-5 md:grid-cols-2 2xl:grid-cols-3 py-8">
          {availableMslsForProduct?.map((msl) => (
            <ProductMslCard
              key={msl?.id}
              msl={msl}
              productId={productId}
            />
          ))}
        </div>
      )}

      {/* empty state */}
      {(!availableMslsForProduct?.length && !availableMslsLoading > 0) && (
        <div className="items-center justify-center h-full flex-1 flex">
          <div className="text-center py-10">
            <EmptyState
              title="No online MSLs"
              icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
              description="Currently there are no online MSLs"
            />
            <Button
              onClick={() => navigate(-1)}
              customStyleClasses="mt-5 w-52 mx-auto"
              variant="purple"
              title="Search all MSLs"
              icon={<SearchIcon className="text-white w-5 h-5" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ProductMslAvailable;
