import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AppLogo from '../../../../assets/logos/MainLogo.png';
import DashboardSideNav from './DashboardSideNav';

function DashboardSideBar({ user, onChange, open }) {
  return (
    <div>
      {/* mobile sideNav */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={() => onChange(false)}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => onChange(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <CloseOutlined className="text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 mx-10 items-center px-4">
                  <img
                    className="h-12 w-auto m-auto"
                    src={AppLogo}
                    alt=""
                  />
                </div>
                <DashboardSideNav onClose={() => onChange(false)} user={user} />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed border-r lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-white-700 pt-5">
          <div className="flex flex-shrink-0 mx-10 items-center px-4">
            <Link to="/">
              <img
                className="h-12 w-auto m-auto"
                src={AppLogo}
                alt=""
              />
            </Link>
          </div>
          <DashboardSideNav user={user} />
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line max-len
const propsAreEqual = (prevProps, nextProps) => prevProps.user?.id === nextProps.user?.id
  && prevProps.user?.unseen_missed_direct_calls_count === nextProps.user?.unseen_missed_direct_calls_count
  && prevProps.user?.unread_notifications_count === nextProps.user?.unread_notifications_count
  && prevProps.unreadMessagesCount === nextProps.unreadMessagesCount
  && prevProps.open === nextProps.open;

/* Export
============================================================================= */
export default React.memo(DashboardSideBar, propsAreEqual);
