/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PhoneIcon, CalendarIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from 'react-tooltip';
import { getUser } from '../../../../auth/redux/selectors';
import { localStorageKeys, userTypes } from '../../../../config/constants';
import { hcpPaths, mslPaths } from '../../../../config/paths';

/* =============================================================================
<ChatChannelHeaderButtons />
============================================================================= */
function ChatChannelHeaderButtons({
  channel, userAvailability, toggleChannelInfoDrawer,
}) {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  const currentUserSendbirdId = user?.send_bird_user?.id;

  const readyToCall = userAvailability?.dynamic_status?.key === 'online';

  const membersCount = channel?.memberCount;
  const otherUser = channel?.members?.find(
    (m) => m?.userId !== currentUserSendbirdId,
  );

  const _handleCallClick = () => {
    if (userType === userTypes.hcp.key && otherUser?.metaData?.discreedly_user_type_key === userTypes.msl.key) {
      navigate(hcpPaths.mslProfile(otherUser?.metaData?.discreedly_user_type_specific_id).initiateCall);
    } else if (userType === userTypes.msl.key && otherUser?.metaData?.discreedly_user_type_key === userTypes.hcp.key) {
      navigate(mslPaths.hcpProfile(otherUser?.metaData?.discreedly_user_type_specific_id).initiateCall);
    }
  };

  const _handleScheduleClick = () => {
    if (userType === userTypes.hcp.key && otherUser?.metaData?.discreedly_user_type_key === userTypes.msl.key) {
      navigate(hcpPaths.mslProfile(otherUser?.metaData?.discreedly_user_type_specific_id).scheduleMeeting);
    } else if (userType === userTypes.msl.key && otherUser?.metaData?.discreedly_user_type_key === userTypes.hcp.key) {
      navigate(mslPaths.hcpProfile(otherUser?.metaData?.discreedly_user_type_specific_id).scheduleMeeting);
    }
  };

  return (
    <div className="space-x-1.5 shrink-0">
      {membersCount === 2 && (
        <>
          <button
            id="start-a-call"
            type="button"
            className="p-2 hover:opacity-75 disabled:opacity-75 hidden md:inline"
            onClick={_handleCallClick}
            disabled={!readyToCall}
            data-tooltip-content={readyToCall ? 'Start a call' : 'User is not available'}
            data-tooltip-place="bottom"
          >
            <PhoneIcon className="w-6 h-6 text-gray-400" />
            <Tooltip anchorId="start-a-call" content={readyToCall ? 'Start a call' : 'User is not available'} />
          </button>
          <button
            id="schedule-meeting"
            type="button"
            className="p-2 hover:opacity-75 disabled:opacity-75 hidden md:inline"
            onClick={_handleScheduleClick}
            data-tooltip-content="Schedule a meeting"
            data-tooltip-place="bottom"
          >
            <CalendarIcon className="w-6 h-6 text-gray-400" />
            <Tooltip anchorId="schedule-meeting" content="Schedule a meeting" />
          </button>
        </>
      )}
      <button type="button" className="p-2 hover:opacity-75" onClick={toggleChannelInfoDrawer}>
        <InformationCircleIcon className="w-6 h-6 text-gray-400" />
      </button>
    </div>
  );
}

/* Export
============================================================================= */
export default ChatChannelHeaderButtons;
