import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { XIcon } from '@heroicons/react/solid';
import { DirectCallEndResult } from 'sendbird-calls';
import { useSbCalls } from '../lib/context';
import { Avatar, CallActionButton } from '../../common';
import { hcpPaths, mslPaths } from '../../config/paths';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<CallEndedScreen />
============================================================================= */
function CallEndedScreen() {
  const navigate = useNavigate();
  const { callId } = useParams();
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  const { calls: allCalls } = useSbCalls();
  const call = allCalls?.find((_call) => _call?.callId === callId);
  const { remoteUser, endResult } = call || {};

  useEffect(() => {
    if (!call) {
      navigate(userType === 'hcp' ? hcpPaths.dashboard : mslPaths.dashboard);
    }
  }, [call, navigate, userType]);

  const _handleClose = () => {
    navigate(userType === 'hcp' ? hcpPaths.dashboard : mslPaths.dashboard);
  };

  return (
    <div className="h-full w-full bg-gray-800 flex flex-col space-y-6 sm:space-y-10 p-4 sm:p-12">
      <div className="relative flex-1 rounded-lg justify-center items-center flex">
        <div className="justify-center items-center text-center flex flex-col">
          <Avatar
            border
            size="extraLarge"
            name={remoteUser?.nickname}
            source={remoteUser?.profileUrl}
          />
          <h2 className="mt-5 mb-2 text-2xl font-semibold text-white">{remoteUser?.nickname}</h2>
          <p className="text-white text-sm">
            {((endResult === DirectCallEndResult.NO_ANSWER || endResult === DirectCallEndResult.DECLINED) && 'No answer')
              || (endResult === DirectCallEndResult.CONNECTION_LOST && 'Connection lost')
              || (endResult === DirectCallEndResult.COMPLETED && 'Call completed')
              || (endResult === DirectCallEndResult.CANCELED && 'Call canceled')
              || 'Call ended'}
          </p>
        </div>
      </div>
      <div className="flex w-fit space-x-3 bg-gray-700 p-3 rounded-full justify-center items-center m-auto block">
        <CallActionButton
          icon={<XIcon className="w-5 h-5 text-gray-600" />}
          onClick={_handleClose}
          tooltipContent="Go to dashboard"
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default CallEndedScreen;
