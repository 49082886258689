import React, { useState } from 'react';
import { EmojiSadIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeftIcon, PhoneIcon } from '@heroicons/react/solid';
import { EmptyState, Spinner } from '../../common';
import Button from '../../common/Button';
import { useGetHcpProfile } from '../../profile/api/queries';
import useUserOnlineStatus from '../../util/hooks/useUserOnlineStatus';
import HcpInformation from '../../common/HcpInformation';
import { useSbCalls } from '../lib/context';

/* =============================================================================
<MslInitiateCallWithHcpScreen />
============================================================================= */
function MslInitiateCallWithHcpScreen() {
  const navigate = useNavigate();
  const { hcpId } = useParams();
  const { data: hcp, isLoading } = useGetHcpProfile(hcpId);

  const [startingCall, setStartingCall] = useState(false);
  const sbCalls = useSbCalls();

  const userAvailability = useUserOnlineStatus(hcp?.user?.id, hcp?.availability, false);
  const dynamicAvailabilityStatusKey = userAvailability?.dynamic_status?.key;

  const _handleStartCall = async () => {
    setStartingCall(true);
    // TODO: need the await? (it doesn't have it in the sample app)
    const call = await sbCalls.dial({
      userId: hcp?.user?.send_bird_user?.id,
      isVideoCall: true, // If we don't make it true, it won't allow screen share.
      callOption: {
        audioEnabled: true,
        videoEnabled: false, // Default, user can change it at any time.
      },
    });
    if (call) {
      navigate(`/calls/msl/direct/${call?.callId}`);
    }

    setStartingCall(false);
  };

  if (!hcp && isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner size={20} />
      </div>
    );
  }

  if (!hcp && !isLoading) {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="HCP not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="HCP not found"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col items-center p-6">
          <h2 className="text-xs text-gray-400 uppercase font-semibold font-[Inter] tracking-wide mt-2">SPEAK WITH AN HCP</h2>
          <h1 className="text-2xl text-gray-700 font-bold font-[Inter] mt-1 mb-8 text-center">{`Speak with ${hcp?.user?.name}`}</h1>
          <div className="w-full max-w-3xl space-y-4">
            <HcpInformation hcpId={hcpId} userAvailability={userAvailability} />
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-6 lg:px-8 py-6 bg-white justify-between sm:items-center border-t flex w-full sm:space-x-10">
        <div className="flex-1 flex flex-col items-start hidden sm:flex">
          <Button
            title="Back"
            variant="secondary"
            icon={<ChevronLeftIcon className="w-4 h-4 text-black" />}
            onClick={() => navigate(-1)}
            customStyleClasses="w-full sm:w-24"
          />
        </div>
        <div className="flex-1 flex flex-col sm:items-end">
          <Button
            variant="green"
            iconPosition="left"
            icon={<PhoneIcon className="w-4 h-4 text-white" />}
            title="Start call"
            customStyleClasses="w-full sm:w-48"
            onClick={_handleStartCall}
            loading={startingCall}
            disabled={startingCall || dynamicAvailabilityStatusKey !== 'online'}
            tooltipContent={dynamicAvailabilityStatusKey !== 'online' ? 'This HCP is currently unavailable.' : null}
            tooltipPosition="top"
          />
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslInitiateCallWithHcpScreen;
