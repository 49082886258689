import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { ClockIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import ProductPlaceholderImg from '../../assets/icons/placeholder-product-image-large.png';
import Button from '../Button';
import EventCardInfoItem from './EventCardInfoItem';
import { useMarkEventAsClicked } from '../../events/api/mutations';
import { getUser } from '../../auth/redux/selectors';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<EventCard />
============================================================================= */
function EventCard({
  event, showLarge, customContainerStyles, truncateDescription,
}) {
  const user = useSelector(getUser);
  const { mutate: markEventAsClicked } = useMarkEventAsClicked();
  const name = event?.name;
  const hosts = event?.hosts;
  const description = event?.description;
  const startAt = moment(event?.utc_start_at).tz(user?.timezone);
  const endAt = moment(event?.utc_end_at).tz(user?.timezone);
  const isLive = moment().isBetween(startAt, endAt);
  const location = event?.location;
  const products = event?.associated_products;
  const bannerImage = event.image_paths[0] || null;
  const url = event?.hosted_at_url;

  const _handleJoinClick = () => {
    markEventAsClicked(event?.id);
  };

  return (
    <div
      className={classNames(
        'col-span-1',
        showLarge ? 'sm:col-span-2' : '',
        customContainerStyles,
      )}
    >
      <div className="border p-4 h-full bg-white rounded-lg flex flex-col justify-between">
        <div className="space-y-4 mb-6">
          <div className="relative">
            <div className="group w-full h-52 block overflow-hidden rounded-lg bg-gray-100">
              {bannerImage && <img alt="" className="w-full h-full object-cover object-center" src={bannerImage} />}
              {isLive && (
                <div
                  className="flex justify-center items-center
                    bg-white rounded-full px-2.5 py-1 absolute right-2 bottom-2"
                >
                  <span className="h-1.5 w-1.5 relative flex">
                    <span className="absolute animate-ping inline-flex h-1.5 w-1.5 rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-500" />
                  </span>
                  <p className="font-[Inter] ml-1 text-sm font-semibold text-gray-700">LIVE</p>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-1">
            <h3 className="text-base font-[Inter] font-semibold text-gray-700 truncate">{name}</h3>
            <p
              className={
                classNames(
                  'text-xs font-[Inter] font-medium text-gray-600',
                  truncateDescription ? 'truncate' : '',
                )
              }
            >
              {description || '-'}
            </p>
          </div>

          <div className="space-y-2">
            <EventCardInfoItem
              title="Time"
              description={`${startAt.format('dddd, DD MMM')} at ${startAt.format('h:mma')} • ${endAt.diff(startAt, 'minutes', true).toFixed(0)} mins`}
              icon={<ClockIcon className="w-5 h-5 text-indigo-500" />}
            />
            <EventCardInfoItem
              title="Location"
              description={location}
              icon={<LocationMarkerIcon className="w-5 h-5 text-indigo-500" />}
            />
            <EventCardInfoItem
              title="Hosts"
              description={hosts || '-'}
              icon={<UsersIcon className="w-5 h-5 text-indigo-500" />}
            />
          </div>
        </div>

        <div>
          {products?.length > 0 && (
            <>
              <div className="flex justify-between">
                <h5 className="text-xs font-[Inter] text-gray-400 font-semibold mb-2">Related product</h5>
                <p className="text-xs font-[Inter] text-gray-500 font-semibold">
                  {products?.length > 1 && `+${products?.length - 1} ${products?.length === 2 ? 'product' : 'products'}`}
                </p>
              </div>
              <NavLink to={`/products/hcp/${products[0]?.id}`} className="flex items-center bg-white border rounded-lg p-2.5 hover:bg-gray-50 ">
                <img
                  className="w-11 h-11 rounded-md"
                  src={products[0]?.main_image_path ? products[0]?.main_image_path : ProductPlaceholderImg}
                  alt=""
                />
                <div className="ml-3 overflow-hidden">
                  <p className="text-xs font-medium text-indigo-600 truncate
                      focus:outline-none"
                  >
                    {products[0]?.name}
                  </p>
                  <p className="text-xs font-[Inter] text-gray-500 font-medium truncate">{event?.pharma_company?.name}</p>
                </div>
              </NavLink>
            </>
          )}

          <a href={url} target="blank" onClick={_handleJoinClick}>
            <Button title="Join" variant="light" size="block" customStyleClasses="mt-4" />
          </a>
        </div>
      </div>
    </div>
  );
}

EventCard.defaultProps = {
  truncateDescription: false,
};

/* Export
============================================================================= */
export default EventCard;
