import React, { useState } from 'react';
import ShowMoreText from 'react-show-more-text';

import ProductInfoModal from '../ProductModals/ProductInfoModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<ProductInfoItem />
============================================================================= */
function ProductInfoItem({
  icon,
  title,
  preview,
  description,
  index,
}) {
  const [modal, setModal] = useState(false);

  const _toggleModal = () => setModal((prev) => !prev);

  return (
    <div
      key={index}
      className={classNames(
        index === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
        index === 1 ? 'sm:rounded-tr-lg' : '',
        index === 4 ? 'sm:rounded-bl-lg' : '',
        index === 5 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
      )}
    >
      <div className="shrink-0">
        {icon}
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium">
          <button type="button" onClick={_toggleModal} className="focus:outline-none">
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            {title}
          </button>
        </h3>
        <p className="mt-2 text-sm text-gray-500">{preview}</p>
      </div>
      <span
        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
        aria-hidden="true"
      >
        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
      <ProductInfoModal
        icon={icon}
        title={title}
        description={description}
        visible={modal}
        onClose={_toggleModal}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default ProductInfoItem;
