import React from 'react';

import Spinner from '../Spinner';
import MslPrescreenerQuestion from './MslPrescreenerQuestion';
import { useGetCompanyPrescreenerQuestions } from '../../company/api/queries';

/* =============================================================================
<MslPrescreener />
============================================================================= */
function MslPrescreener({ companyId, answers, setAnswers }) {
  const {
    data: questions,
    isLoading: questionLoading,
    status,
  } = useGetCompanyPrescreenerQuestions(companyId);

  return (
    <div className="w-full space-y-6 p-6 bg-white border shadow-sm rounded-lg">
      {!questionLoading && questions?.map((question) => (
        <MslPrescreenerQuestion
          key={question?.id}
          question={question}
          values={answers}
          onChange={setAnswers}
        />
      ))}
      {questionLoading && (
        <div className="flex justify-center p-5">
          <Spinner />
        </div>
      )}
      {(status === 'success' && questions.length === 0) && (
        <p className="text-center">No pre-screener questions</p>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default MslPrescreener;
