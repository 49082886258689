import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Notfound } from '../../common';
import ProductScreen from './ProductScreen';
import ProductSearchScreen from './ProductSearchScreen';
import ProductsTrendingScreen from './ProductsTrendingScreen';
import ProductMslsScreen from './ProductMslsScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<ProductScreens />
============================================================================= */
function ProductScreens() {
  return (
    <Routes>
      <Route path="products">
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="search/hcp" element={<ProductSearchScreen />} />
          <Route path="hcp/:productId" element={<ProductScreen />} />
          <Route path="trending/hcp" element={<ProductsTrendingScreen />} />
          <Route path="hcp/:productId/msls/*" element={<ProductMslsScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default ProductScreens;
