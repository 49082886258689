import React from 'react';
import { PhoneIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Button from '../../common/Button';
import { hcpPaths } from '../../config/paths';

/* =============================================================================
<ProductSpeakToMedicalExpertButton />
============================================================================= */
function ProductSpeakToMedicalExpertButton({ productId }) {
  return (
    <NavLink to={hcpPaths.product(productId).allMsls}>
      <Button
        customStyleClasses="w-full"
        title="Speak to a medical expert"
        variant="secondary"
        icon={(
          <div className="relative">
            <PhoneIcon className="w-5 h-5 text-gray-400" />
            <span className="absolute top-0 left-2 flex h-1.5 w-1.5">
              <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-red-400 opacity-75" />
              <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-500" />
            </span>
          </div>
        )}
      />
    </NavLink>
  );
}

/* Export
============================================================================= */
export default ProductSpeakToMedicalExpertButton;
