import React from 'react';
import { SendBirdProvider as OfficialSendBirdProvider } from '@sendbird/uikit-react';
import { connect } from 'react-redux';
import { SENDBIRD_APP_ID } from '../config/secrets';
import { getIsAuthenticated, getUser } from '../auth/redux/selectors';

function SendBirdChatProvider({ user, isAuthenticated, children }) {
  const userSendBirdId = user?.send_bird_user?.id;
  const userSendBirdToken = user?.send_bird_user?.access_token;

  if (!user || !isAuthenticated) {
    return children;
  }

  return (
    <OfficialSendBirdProvider
      appId={SENDBIRD_APP_ID}
      accessToken={userSendBirdToken}
      userId={userSendBirdId}
      colorSet={myColorSet}
      // profileUrl={userProfileImg} // Don't include the user's profile image or the user's nickname, as it will attempt to update the user, when we don't want that, and the frontend doesn't have permission, so outputs a 400 console error.
    >
      {children}
    </OfficialSendBirdProvider>
  );
}

const myColorSet = {
  '--sendbird-light-primary-500': '#374151',
  '--sendbird-light-primary-400': '#6B7280',
  '--sendbird-light-primary-300': '#374151',
  '--sendbird-light-primary-200': '#E5E7EB',
  '--sendbird-light-primary-100': '#F3F4F6',
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(SendBirdChatProvider);
