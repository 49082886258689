export const hcpPaths = {
  signIn: '/auth/hcp/login',
  dashboard: '/dashboard/hcp',
  meeting: (meetingId, status = 'upcoming') => `/meetings/list/hcp/${status}/${meetingId}`,
  meetings: (status = 'upcoming') => `/meetings/list/hcp/${status}`,
  product: (productId) => ({
    home: `/products/hcp/${productId}`,
    allMsls: `/products/hcp/${productId}/msls`,
    onlineMsls: `/products/hcp/${productId}/msls/online`,
    hotline: `/calls/products/${productId}/hotline`,
  }),
  globalSearch: ({
    home: '/products/search/hcp',
  }),
  events: ({
    home: '/events/hcp/live',
    upcoming: '/events/hcp',
    live: '/events/hcp/live',
  }),
  trendingProducts: ({
    home: '/products/trending/hcp',
  }),
  mslProfile: (mslId) => ({
    home: `/profile/msls/${mslId}`,
    scheduleMeeting: `/meetings/hcp/schedule-meeting-with-msl/${mslId}`,
    initiateCall: `/calls/hcp/initiate/${mslId}`,
  }),
  companyProfile: (companyId) => ({
    home: `/companies/hcp/${companyId}/products`,
    products: `/companies/hcp/${companyId}/products`,
    pipelineProducts: `/companies/hcp/${companyId}/pipeline-products`,
    about: `/companies/hcp/${companyId}/about`,
  }),
  chats: () => '/messaging',
  chat: (channelUrl) => `/messaging/${channelUrl}`,
  myAccount: ({
    home: '/profile/hcp/manage-profile/account',
    subscriptions: '/profile/hcp/manage-profile/subscriptions',
    changePassword: '/profile/hcp/manage-profile/change-password',
    changeEmail: '/profile/hcp/manage-profile/change-email',
  }),
};

export const mslPaths = {
  signIn: '/auth/msl/login',
  dashboard: '/dashboard/msl',
  meeting: (meetingId, status = 'upcoming') => `/meetings/list/msl/${status}/${meetingId}`,
  meetings: (status = 'upcoming') => `/meetings/list/msl/${status}`,
  hcpProfile: (hcpId) => ({
    home: `/profile/hcps/${hcpId}`,
    scheduleMeeting: `/meetings/msl/schedule-meeting-with-hcp/${hcpId}`,
    initiateCall: `/calls/msl/initiate/${hcpId}`,
  }),
  chats: () => '/messaging',
  chat: (channelUrl) => `/messaging/${channelUrl}`,
  schedules: '/schedules/msl',
  schedule: (scheduleId) => ({
    home: `/schedules/msl/${scheduleId}/weekly-hours`,
    weeklyHours: `/schedules/msl/${scheduleId}/weekly-hours`,
    dateOverrides: `/schedules/msl/${scheduleId}/date-overrides`,
    settings: `/schedules/msl/${scheduleId}/settings`,
  }),
  meetingTypes: '/meetings/types/msl',
  myAccount: ({
    home: '/profile/msl/manage-profile/profile',
    productKnowledge: '/profile/msl/manage-profile/product-knowledge',
    changePassword: '/profile/msl/manage-profile/change-password',
    changeEmail: '/profile/msl/manage-profile/change-email',
  }),
};

export const generalPaths = {
  joinGroupCall: (groupCallId) => `/calls/group/${groupCallId}/join`,
  chats: () => '/messaging',
  chat: (channelUrl) => `/messaging/${channelUrl}`,
  callHistory: '/calls/history',
};
