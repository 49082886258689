import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { StarIcon } from '@heroicons/react/solid';
import CallRateModal from '../../CallsListener/CallRateModal';
import { Button } from '../../../../common';

/* =============================================================================
<CallHistoryListItemRating />
============================================================================= */
const CallHistoryListItemRating = ({ call }) => {
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState(null);

  return (
    <div className="text-gray-500 font-medium font-[Inter]">
      <div className="text-center md:text-left">
        {call?.interaction_rating ? (
          <ReactStars
            count={5}
            size={19}
            value={call?.interaction_rating?.rating}
            edit={false}
            activeColor="#FBBF24"
            color="#E5E7EB"
          />
        ) : (
          <Button
            customStyleClasses="w-full sm:w-52"
            title="Rate interaction"
            variant="secondary"
            icon={<StarIcon className="w-5 h-5 text-yellow-400" />}
            onClick={(e) => {
              setShowRatingModal(true);
            }}
          />
        )}
      </div>
      <CallRateModal
        directCallId={call?.id}
        visible={showRatingModal}
        onClose={() => {
          setRating(null);
          setShowRatingModal(false);
        }}
      />
    </div>
  );
};

/* Export
============================================================================= */
export default CallHistoryListItemRating;
