import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

function Spinner({ size, color }) {
  return (
    <LoadingOutlined
      style={{
        fontSize: size,
        color,
      }}
      spin
    />
  );
}

Spinner.defaultProps = {
  size: 20,
  color: '#4F46E5',
};

export default Spinner;
