import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ArrowRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Modal } from '../../../common';
import { hcpPaths } from '../../../config/paths';
import Markdown from '../../../common/Markdown';

/* =============================================================================
<ProductInfoModal />
============================================================================= */
function ProductInfoModal({
  icon,
  title,
  description,
  visible,
  onClose,
}) {
  const { productId } = useParams();

  return (
    <Modal
      visible={visible}
      icon={icon}
      heading={title}
      onClose={onClose}
      closeWhenClickOutside
    >
      <div className="p-5 max-h-96 overflow-y-scroll">
        <p className="text-sm font-normal break-all text-gray-700">
          <Markdown content={description} />
        </p>
      </div>
      <div className="border-b w-full" />
      <div className="flex items-center font-medium p-5 flex space-x-1">
        <QuestionMarkCircleIcon className="w-5 h-5 text-gray-300" />
        <div>
          <p className="text-xs ml-1 text-gray-800">Have a question?</p>
          <NavLink to={hcpPaths.product(productId).allMsls} className="flex items-center hover:opacity-75">
            <p className="text-xs ml-1 text-indigo-600">Speak to a medical expert</p>
            <ArrowRightIcon className="w-3 h-5 ml-1 text-indigo-600" />
          </NavLink>
        </div>
      </div>
    </Modal>
  );
}

/* Export
============================================================================= */
export default ProductInfoModal;
