import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { PromptModal, Toast } from '../../../../common';
import ChatInfoDrawer from '../ChatInfoDrawer';
import ChatInviteModal from '../../ChatModals/ChatInviteModal';
import ChatChannelHeaderMembersInfo from './ChatChannelHeaderMembersInfo';
import ChatChannelHeaderButtons from './ChatChannelHeaderButtons';
import useUserOnlineStatus from '../../../../util/hooks/useUserOnlineStatus';
import { getUser } from '../../../../auth/redux/selectors';
import { useGetOtherUserAvailability } from '../../../../profile/api/queries';
import { useLeaveChannel } from '../../../api/mutations';
import { hcpPaths, mslPaths } from '../../../../config/paths';
import { localStorageKeys, userTypes } from '../../../../config/constants';

/* =============================================================================
<ChatChannelHeader />
============================================================================= */
function ChatChannelHeader({ channel }) {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  const [showChannelInfoDrawer, setShowChannelInfoDrawer] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);

  const { mutate: leaveChannel, isLoading: leaveChannelLoading } = useLeaveChannel();

  const _toggleChannelInfoDrawer = () => setShowChannelInfoDrawer((prev) => !prev);
  const _toggleLeaveModal = () => setLeaveModal((prev) => !prev);

  const _toggleInviteModal = () => {
    if (showInviteModal) {
      setShowInviteModal(false);
    } else {
      setShowChannelInfoDrawer(false); // Important: as if the drawer is open and the modal is open, in Chrome, there's an issue where the email input isn't focusable
      setShowInviteModal(true);
    }
  };

  const _leaveChannel = () => {
    leaveChannel({
      send_bird_channel_url: channel?.url,
    }, {
      onSuccess: async () => {
        toast.custom((toastProps) => (
          <Toast
            status="success"
            title="Successfully left the chat."
            onClose={() => toast.remove(toastProps.id)}
          />
        ));
        _toggleLeaveModal();

        // If user is HCP, go to HCP chat page, if MSL, go to MSL chat page
        if (userType === userTypes.hcp.key) {
          navigate(hcpPaths.chats());
        } else {
          navigate(mslPaths.chats());
        }
      },
    });
  };

  const currentUserSendbirdId = user?.send_bird_user?.id;
  // eslint-disable-next-line max-len
  const otherSendBirdUser = channel?.members?.find((member) => member?.userId !== currentUserSendbirdId);

  // eslint-disable-next-line max-len
  const { data: userAvailabilityData } = useGetOtherUserAvailability(otherSendBirdUser?.metaData?.discreedly_user_id);
  // eslint-disable-next-line max-len
  const userAvailability = useUserOnlineStatus(otherSendBirdUser?.metaData?.discreedly_user_id, userAvailabilityData, false);

  return (
    <div className="bg-white border-b py-2 pr-6 h-16 flex justify-between items-center w-full z-10">
      {channel?.url && (
        <>
          <div className="overflow-x-hidden">
            <ChatChannelHeaderMembersInfo
              channel={channel}
              userAvailability={userAvailability}
            />
          </div>
          <p>{userAvailability?.userId}</p>
          <ChatChannelHeaderButtons
            channel={channel}
            toggleChannelInfoDrawer={_toggleChannelInfoDrawer}
            userAvailability={userAvailability}
          />
        </>
      )}
      <ChatInfoDrawer
        open={showChannelInfoDrawer}
        onClose={_toggleChannelInfoDrawer}
        toggleInviteModal={_toggleInviteModal}
        onChannelLeave={_toggleLeaveModal}
        channelUrl={channel?.url}
      />
      {showInviteModal && (
        <ChatInviteModal visible onClose={_toggleInviteModal} />
      )}
      <PromptModal
        btnTxt="Leave chat"
        open={leaveModal}
        title="Leave chat"
        onClose={_toggleLeaveModal}
        onBtnPress={_leaveChannel}
        btnLoading={leaveChannelLoading}
        description="Are you sure you would like to leave this chat?"
      />
    </div>
  );
}

/* Export
============================================================================= */
// eslint-disable-next-line max-len
export default ChatChannelHeader; // Don't memoize this component, as otherwise, when the members list change (on invite/leave channel), the component won't re-render
