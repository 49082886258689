import React, { useEffect } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function SelectDropDown({
  label, items, name, value, onChange, customStyles, placeholder, noDefaultOption, ...props
}) {
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      )}
      <select
        id={name}
        name={name}
        className={classNames('block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base border shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm', customStyles)}
        value={value}
        onChange={onChange}
        {...props}
      >
        {!noDefaultOption && (
          <option
            name={null}
            value=""
          >
            {placeholder || 'Select...'}
          </option>
        )}
        {items?.map((item) => (
          <option
            name={item?.label}
            value={item?.key}
            key={item?.key}
          >
            {item?.label || item?.name}
          </option>
        ))}
      </select>
    </div>
  );
}

SelectDropDown.defaultProps = {
  noDefaultOption: false,
};

export default SelectDropDown;
