import React, { useEffect } from 'react';
import SendBirdCall, { useMedia } from 'sendbird-calls';
import { SelectDropDown, Modal } from '../../../common';
import { useSbCalls } from '../../lib/context';

/* =============================================================================
<CallSettingsModal />
============================================================================= */
function CallSettingsModal({ visible, onClose, showVideoSettings }) {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const {
    availableVideoInputDevices,
    availableAudioInputDevices,
    availableAudioOutputDevices,
    selectedVideoInputDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectVideoInputDevice,
    selectAudioInputDevice,
    selectAudioOutputDevice,
  } = useSbCalls();

  const _handleAudioInputDeviceChange = (e) => {
    if (e.target.value != null) {
      selectAudioInputDevice(e.target.value);
    }
  };

  const _handleAudioOutputDeviceChange = (e) => {
    if (e.target.value != null) {
      selectAudioOutputDevice(e.target.value);
    }
  };

  const _handleVideoInputDeviceChange = (e) => {
    if (e.target.value != null) {
      selectVideoInputDevice(e.target.value);
    }
  };

  useEffect(() => {
    // Use this instead of the `useMedia` hook to avoid the issue where even after call ends, the video stream is still active (green light on mac camera)
    // This (updateMediaDevices) is needed to give permission to the latest input/output devices
    if (visible) {
      SendBirdCall.updateMediaDevices({ audio: true, video: showVideoSettings });
    }
  }, [visible, showVideoSettings]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      heading="Settings"
      onClose={onClose}
      closeWhenClickOutside
    >
      <div className="p-6 space-y-6 pb-8">
        {showVideoSettings && (
          <SelectDropDown
            name="camera"
            label="Camera"
            placeholder="Select camera..."
            value={selectedVideoInputDeviceId}
            onChange={_handleVideoInputDeviceChange}
            items={availableVideoInputDevices ? availableVideoInputDevices?.map((device) => ({ key: device?.deviceId, label: device?.label })) : []}
          />
        )}
        <SelectDropDown
          name="microphone"
          label="Microphone"
          placeholder="Select microphone..."
          value={selectedAudioInputDeviceId}
          onChange={_handleAudioInputDeviceChange}
          items={availableAudioInputDevices ? availableAudioInputDevices?.map((device) => ({ key: device?.deviceId, label: device?.label })) : []}
        />
        {isChrome && (
          <SelectDropDown
            name="speaker"
            label="Speaker"
            placeholder="Select speaker..."
            value={selectedAudioOutputDeviceId}
            onChange={_handleAudioOutputDeviceChange}
            items={availableAudioOutputDevices ? availableAudioOutputDevices?.map((device) => ({ key: device?.deviceId, label: device?.label })) : []}
          />
        )}
      </div>
    </Modal>
  );
}

CallSettingsModal.defaultProps = {
  showVideoSettings: true,
};

/* Export
============================================================================= */
export default CallSettingsModal;
