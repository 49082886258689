import React from 'react';
import moment from 'moment';
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { Avatar, EmptyState, Spinner } from '../../common';
import { useGetNextMeeting } from '../../meeting/api/queries';
import { hcpPaths } from '../../config/paths';

/* =============================================================================
<UpcomingMeeting />
============================================================================= */
function UpcomingMeeting({ user }) {
  const { data: meeting, isLoading } = useGetNextMeeting();

  const id = meeting?.id;
  const title = meeting?.title;
  const participants = meeting?.participants;
  const startTime = moment(meeting?.utc_start_at).tz(user?.timezone).format('h:mma');
  const endTime = moment(meeting?.utc_end_at).tz(user?.timezone).format('h:mma');
  const date = moment(meeting?.utc_start_at).tz(user?.timezone).format('ddd, MMM Do YYYY');

  if (!meeting && !isLoading) {
    return (
      <div className="bg-white h-48 flex flex-col justify-center items-center py-2 px-8 rounded-lg">
        <EmptyState icon={<CalendarIcon className="w-6 h-6 text-gray-400 text-center" />} title="Upcoming meetings" description="You have no upcoming meetings" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white h-44 flex flex-col justify-center items-center px-8 rounded-lg">
        <div className="text-center bg-white">
          <Spinner size={25} />
        </div>
      </div>
    );
  }

  return (
    <Link to={hcpPaths.meeting(id)}>
      <div className="bg-indigo-700 py-7 px-8 rounded-lg hover:bg-indigo-800 transition-all group">
        <div className="flex justify-between">
          <div className="overflow-hidden">
            <h5 className="text-sm text-primary-500 font-semibold font-[Inter]">Upcoming meeting</h5>
            <h4 className="text-2xl font-semibold text-white truncate">{title}</h4>
          </div>
          <div className="hidden sm:flex">
            {participants?.slice(0, 2).map((participant) => (
              <div className="-ml-2" key={participant?.id}>
                <div className="border border-2 border-white rounded-full">
                  <Avatar
                    size="small"
                    source={participant?.user?.profile_image_path}
                    name={participant?.user?.name}
                    color={participant?.user?.color}
                    tooltipContent={`${participant?.user?.name} (${participant?.user?.user_type?.name})`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4 sm:mt-7 flex flex-col space-x-0 space-y-3 sm:flex-row sm:space-x-6 sm:space-y-0">
          <div className="flex">
            <div className="bg-indigo-600 p-2.5 flex items-center rounded-md">
              <CalendarIcon className="w-5 h-5 text-white" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-[Inter] font-semibold text-gray-300">Date</p>
              <p className="text-sm font-[Inter] font-semibold text-white">{date}</p>
            </div>
          </div>
          <div className="flex">
            <div className="bg-indigo-600 p-2.5 flex items-center rounded-md">
              <ClockIcon className="w-5 h-5 text-white" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-[Inter] font-semibold text-gray-300">Time</p>
              <p className="text-sm font-[Inter] font-semibold text-white">{`${startTime} - ${endTime}`}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
);

/* Export
============================================================================= */
export default React.memo(UpcomingMeeting, propsAreEqual);
