import React from 'react';
import { Avatar, UserAvailabilityIndicator } from '../../../common';
import HcpProfileHeaderActions from './HcpProfileHeaderActions';
import { useGetHcpProfile } from '../../api/queries';
import useUserOnlineStatus from '../../../util/hooks/useUserOnlineStatus';

/* =============================================================================
<HcpProfileHeader />
============================================================================= */
function HcpProfileHeader({ hcpId }) {
  const { data: hcp } = useGetHcpProfile(hcpId);
  const name = hcp?.user?.name;
  const userId = hcp?.user?.id;
  const color = hcp?.user?.color;
  const avatar = hcp?.user?.profile_image_path;
  const initialAvailability = hcp?.availability;

  const userAvailability = useUserOnlineStatus(userId, initialAvailability, false);

  return (
    <div className="bg-white pb-5 border-b">
      <div>
        <div className="w-full h-32 object-cover bg-sky-500" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <Avatar
              border
              source={avatar}
              name={name}
              color={color}
              size="extraLarge"
            />
          </div>
          <div className="mt-6 sm:mt-12 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 sm:mt-10 min-w-0 flex-1 sm:block">
              <div>
                <h1 className="truncate text-2xl font-bold text-gray-700">{name}</h1>
                <UserAvailabilityIndicator variant="pulseWithText" availability={userAvailability} />
              </div>
            </div>
            <div className="justify-stretch mt-6 sm:mt-10 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3">
              <HcpProfileHeaderActions
                hcpId={hcpId}
                hcpUserId={userId}
                userAvailability={userAvailability}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default HcpProfileHeader;
