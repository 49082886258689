import React, { useCallback, useEffect, useState } from 'react';
import { SelectDropDown, Modal } from '../../../common';

/* =============================================================================
<VOIPCallSettingsModal />
============================================================================= */
function VOIPCallSettingsModal({ device, visible, onClose }) {
  const [stream, setStream] = useState(null);

  const [dynamicCurrentAudioInputDevice, setDynamicCurrentAudioInputDevice] = useState('default');
  const [dynamicCurrentAudioOutputDevice, setDynamicCurrentAudioOutputDevice] = useState('default');

  const [availableInputDevices, setAvailableInputDevices] = useState([]);
  const [availableOutputDevices, setAvailableOutputDevices] = useState([]);

  const updateInputDevice = async (deviceId) => {
    device.audio.setInputDevice(deviceId).then(() => {
      setDynamicCurrentAudioInputDevice(deviceId);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
  };

  const updateOutputDevice = (deviceId) => {
    device.audio.speakerDevices.set(deviceId).then(() => {
      setDynamicCurrentAudioOutputDevice(deviceId);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
  };

  const getAvailableInputDevices = useCallback(async () => {
    const tempDevices = [];
    device.audio.availableInputDevices.forEach((loopDevice, id) => {
      tempDevices.push({
        key: id,
        label: loopDevice.label,
      });
    });
    setAvailableInputDevices(tempDevices);
  }, [device]);

  const getAvailableOutputDevices = useCallback(async () => {
    const tempDevices = [];
    device.audio.speakerDevices._availableDevices.forEach((loopDevice, id) => {
      tempDevices.push({
        key: id,
        label: loopDevice.label,
      });
    });
    setAvailableOutputDevices(tempDevices);
  }, [device]);

  useEffect(() => {
    async function getUserMedia() {
      const tempStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(tempStream);
    }

    if (stream == null) {
      getUserMedia();
    }

    if (device) {
      device.audio.on('deviceChange', async () => {
        await getAvailableInputDevices();
        await getAvailableOutputDevices();
      });
    }
  }, [device, getAvailableInputDevices, getAvailableOutputDevices, stream]);

  if (!device) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      heading="Settings"
      onClose={onClose}
      closeWhenClickOutside
    >
      <div className="p-6 space-y-6 pb-8">
        <SelectDropDown
          name="microphone"
          label="Microphone"
          placeholder="Select microphone..."
          noDefaultOption
          value={dynamicCurrentAudioInputDevice}
          onChange={(e) => updateInputDevice(e.target.value)}
          items={availableInputDevices}
        />
        {device.audio.isOutputSelectionSupported && (
          <SelectDropDown
            name="speaker"
            label="Speaker"
            placeholder="Select speaker..."
            noDefaultOption
            value={dynamicCurrentAudioOutputDevice}
            onChange={(e) => updateOutputDevice(e.target.value)}
            items={availableOutputDevices}
          />
        )}
      </div>
    </Modal>
  );
}

/* Export
============================================================================= */
export default VOIPCallSettingsModal;
