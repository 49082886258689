import React from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import { useParams } from 'react-router-dom';
import { PlusIcon, TrashIcon } from '@heroicons/react/solid';
import {
  Field, FieldArray, Form, Formik,
} from 'formik';
import {
  Button, Modal, SelectDropDown,
} from '../../../../common';
import '../../../../util/calenderCustom.css';

import { useCreateDateOverride } from '../../../api/mutations';

const timeIncrements = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 24; i++) {
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < 12; j++) {
    const hours = i.toString().padStart(2, '0');
    const minutes = (j * 5).toString().padStart(2, '0');
    const key = `${hours}:${minutes}`;
    const label = moment(`${hours}:${minutes}`, 'HH:mm').format('h:mma');
    timeIncrements.push({ key, label });
  }
}

function ScheduleDateOverrideCreateModal({ visible, onClose }) {
  const { scheduleId } = useParams();
  const {
    mutateAsync: createDateOverride, isLoading,
  } = useCreateDateOverride();

  const initialValues = {
    date: new Date(),
    hours: [
      {
        start_time: '',
        end_time: '',
      },
    ],
  };

  const _handleCreateSchedule = async (values) => {
    const payload = {
      scheduleId,
      date: moment(values?.date).format('YYYY-MM-DD'),
      start_time: [],
      end_time: [],
    };

    values?.hours?.forEach((hour, i) => {
      if (hour?.start_time) {
        payload.start_time.push(hour?.start_time);
      }
      if (hour?.end_time) {
        payload.end_time.push(hour?.end_time);
      }
    });

    await createDateOverride(payload, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Modal
      size="sm"
      visible={visible}
      onClose={onClose}
      heading="Create date override"
    >
      <div className="px-6 pb-6">
        <Formik initialValues={initialValues} onSubmit={_handleCreateSchedule}>
          {({ values, setFieldValue }) => (
            <Form className="space-y-6 mt-6">
              <Calendar
                value={values?.date}
                onChange={(_value) => setFieldValue('date', _value)}
                minDate={new Date()}
              />
              <FieldArray name="hours">
                {(arrayHelper) => (
                  <div className="space-y-3">
                    <h2 className="text-gray-700 font-medium font-[Inter] mb-4">What hours are your available?</h2>
                    {values.hours?.map((_, i) => (
                      <div key={i} className="flex w-full mb-2 items-center space-x-5 font-[Inter] text-sm justify-between">
                        <div className="flex items-center space-x-2 flex-1">
                          <Field name={`hours.${i}.start_time`}>
                            {({ field }) => (
                              <SelectDropDown
                                label={null}
                                items={timeIncrements}
                                name={`hours.${i}.start_time`}
                                placeholder="From"
                                customStyles="flex-1"
                                {...field}
                             />
                            )}
                          </Field>
                          <p className="text-gray-500 w-5 text-center">to</p>
                          <Field name={`hours.${i}.end_time`}>
                            {({ field }) => (
                              <SelectDropDown
                                label={null}
                                items={timeIncrements}
                                name={`hours.${i}.end_time`}
                                placeholder="Until"
                                customStyles="flex-1"
                                {...field}
                             />
                            )}
                          </Field>
                        </div>
                        <button type="button" onClick={() => values.hours?.length > 1 && arrayHelper.remove(i)} className="group shrink-0">
                          <TrashIcon className="w-5 h-5 text-gray-400 group-hover:text-red-500 transition-all ease-in-out" />
                        </button>
                      </div>
                    ))}

                    <button
                      type="button"
                      onClick={() => arrayHelper.push({
                        start_time: '',
                        end_time: '',
                      })}
                      className="border flex justify-center items-center md:self-center rounded-lg w-9 h-9 my-2 md:my-0 hover:shadow-md transition-all ease-in-out"
                    >
                      <PlusIcon className="w-4 h-4 text-indigo-600" />
                    </button>
                  </div>
                )}
              </FieldArray>

              <div className="flex justify-end border-t space-x-3 pt-4 mt-8">
                <Button
                  variant="secondary"
                  title="Cancel"
                  onClick={onClose}
                  customStyleClasses="w-24"
                />
                <Button
                  type="submit"
                  variant="purple"
                  loading={isLoading}
                  title="Apply"
                  customStyleClasses="w-24"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default ScheduleDateOverrideCreateModal;
