import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Notfound } from '../../common';
import MslProfileScreen from './MslProfileScreen';
import HcpManageProfileScreen from './HcpManageProfileScreen';
import MslManageProfileScreen from './MslManageProfileScreen';
import HcpProfileScreen from './HcpProfileScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<ProfileScreens />
============================================================================= */
function ProfileScreens() {
  return (
    <Routes>
      <Route path="profile">
        <Route element={<AuthenticatedRoute allowHcp allowMsl={false} />}>
          <Route path="msls/:mslId" element={<MslProfileScreen />} />
          <Route path="hcp/manage-profile/:tab" element={<HcpManageProfileScreen />} />
        </Route>
        <Route element={<AuthenticatedRoute allowHcp={false} allowMsl />}>
          <Route path="hcps/:hcpId" element={<HcpProfileScreen />} />
          <Route path="msl/manage-profile/:tab" element={<MslManageProfileScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default ProfileScreens;
