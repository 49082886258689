import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CalendarIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import { Spinner } from '../../common';
import MeetingScheduledModal from '../components/MeetingScheduledModal';
import HcpInformation from '../../common/HcpInformation';
import { useCreateMeeting } from '../api/mutations';
import useUserOnlineStatus from '../../util/hooks/useUserOnlineStatus';
import ScheduleMeetingWithHcpForm
  from '../components/ScheduleMeetingWithHcp/ScheduleMeetingWithHcpForm';
import { useGetHcpProfile } from '../../profile/api/queries';
import { client as queryClient } from '../../config/react-query';
import { queryKeys } from '../api/query-key-factory';
import Button from '../../common/Button';

/* =============================================================================
<ScheduleMeetingWithHcpScreen />
============================================================================= */
function ScheduleMeetingWithHcpScreen() {
  const navigate = useNavigate();
  const { hcpId } = useParams();
  const { data: hcp, status: hcpStatus } = useGetHcpProfile(hcpId);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [callType, setCallType] = useState('video-call');
  const [showMeetingConfirmationModal, setShowMeetingConfirmationModal] = useState(false);

  const userAvailability = useUserOnlineStatus(hcp?.user?.id, hcp?.availability, false);

  const {
    mutateAsync: scheduleMeeting,
    status: scheduleMeetingStatus,
    data: scheduleMeetingData,
  } = useCreateMeeting();

  const onCallTypeChange = (e) => {
    setCallType(e?.target?.id);
  };

  const _handleSubmit = async () => {
    await scheduleMeeting({
      hcp_id: hcpId,
      title,
      date,
      description,
      start_time: startTime,
      end_time: endTime,
      group_call_type_key: callType,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.nextMeeting);
        queryClient.invalidateQueries(queryKeys.meetings('upcoming'));
        queryClient.invalidateQueries(queryKeys.meetings('past'));
        setShowMeetingConfirmationModal(true);
      },
    });
  };

  if (hcpStatus !== 'success') {
    return (
      <div className="mt-6 text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="flex flex-col items-center p-6">
          <h2 className="text-xs text-gray-400 uppercase font-semibold font-[Inter] tracking-wide mt-2">SCHEDULE A MEETING</h2>
          <h1 className="text-2xl text-gray-700 font-bold font-[Inter] mt-1 mb-8 text-center">{`Meet with ${hcp?.user?.name}`}</h1>
          <div className="w-full max-w-3xl space-y-4">
            <HcpInformation hcpId={hcpId} userAvailability={userAvailability} />
            <ScheduleMeetingWithHcpForm
              title={title}
              onTitleChange={setTitle}
              description={description}
              onDescriptionChange={setDescription}
              date={date}
              onDateChange={setDate}
              startTime={startTime}
              onStartTimeChange={setStartTime}
              endTime={endTime}
              onEndTimeChange={setEndTime}
              onCallTypeChange={onCallTypeChange}
              callType={callType}
            />
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-6 lg:px-8 py-6 bg-white justify-between sm:items-center border-t flex w-full sm:space-x-10">
        <div className="flex-1 flex flex-col items-start hidden sm:flex">
          <Button
            title="Back"
            variant="secondary"
            icon={<ChevronLeftIcon className="w-4 h-4 text-black" />}
            onClick={() => navigate(-1)}
            customStyleClasses="w-full sm:w-24"
          />
        </div>
        <div className="flex-1 flex flex-col sm:items-end">
          <Button
            variant="green"
            iconPosition="left"
            icon={<CalendarIcon className="w-4 h-4 text-white" />}
            title="Schedule meeting"
            customStyleClasses="w-full sm:w-48"
            onClick={_handleSubmit}
            loading={scheduleMeetingStatus === 'loading'}
            disabled={scheduleMeetingStatus === 'loading'}
          />
        </div>
      </div>
      {(showMeetingConfirmationModal && scheduleMeetingStatus === 'success' && scheduleMeetingData) && (
        <MeetingScheduledModal
          meeting={scheduleMeetingData?.data?.data?.meeting}
          visible
          onClose={() => setShowMeetingConfirmationModal(false)}
        />
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleMeetingWithHcpScreen;
