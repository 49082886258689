import React from 'react';
import MultiLineParagraph from '../../common/MultiLineParagraph';

/* =============================================================================
<CompanyAbout />
============================================================================= */
function CompanyAbout({ companyName, bio }) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 md:py-8">
      <div className="bg-white overflow-hidden rounded-lg border">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            About
            {' '}
            {companyName}
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <dt className="text-xs font-semibold tracking-wider leading-4 text-gray-400">BIO</dt>
              <dd className="mt-1 text-sm font-medium text-gray-700 leading-5">
                <MultiLineParagraph content={bio || '-'} />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default CompanyAbout;
