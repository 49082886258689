import React from 'react';
import { useParams } from 'react-router-dom';
import { EmojiSadIcon } from '@heroicons/react/outline';
import MslProfileHeader from '../components/MslProfileHeader';
import { CompanyName, EmptyState, Spinner } from '../../common';
import { useGetMslProfile } from '../api/queries';
import MultiLineParagraph from '../../common/MultiLineParagraph';
import CompanyNameLinkWithHoverPreview from '../../common/CompanyNameLinkWithHoverPreview';

/* =============================================================================
<MslProfileScreen />
============================================================================= */
function MslProfileScreen() {
  const { mslId } = useParams();
  const { data: msl, isLoading } = useGetMslProfile(mslId);

  if (!msl && isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!msl && !isLoading) {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="MSL not found"
          icon={<EmojiSadIcon className="w-9 h-9 text-gray-400" />}
          description="MSL not found"
        />
      </div>
    );
  }

  return (
    <div>
      <MslProfileHeader mslId={mslId} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 md:py-8">
        <div className="bg-white overflow-hidden rounded-lg border">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">iMSL information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Overview of experience</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">FULL NAME</h1>
                <dd className="mt-1 text-sm font-medium text-gray-700 leading-5">{msl?.user?.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">EMAIL ADDRESS</h1>
                <dd className="mt-1 text-sm font-medium text-gray-700 leading-5">{msl?.email}</dd>
              </div>
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">COMPANY</h1>
                <CompanyNameLinkWithHoverPreview company={msl?.pharma_company} />
              </div>
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">STATE</h1>
                <dd className="mt-1 text-sm font-medium text-gray-700 leading-5">{msl?.us_state?.name}</dd>
              </div>
              <div className="sm:col-span-2">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">BIO</h1>
                <dd className="mt-1 text-sm font-medium text-gray-700 leading-5">
                  <MultiLineParagraph content={msl?.bio ? msl?.bio : '-'} />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslProfileScreen;
