import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import ChatScreen from './ChatScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<ChatScreens />
============================================================================= */
function ChatScreens() {
  return (
    <Routes>
      <Route path="messaging/*">
        <Route element={<AuthenticatedRoute />}>
          <Route path="*" element={<ChatScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default ChatScreens;
