import React from 'react';
import { SuccessModal } from '../../../../common';

function ProductPrescriptionSuccessModal({ visible, onBtnClick }) {
  return (
    <SuccessModal
      visible={visible}
      onBtnClick={onBtnClick}
      btnVariant="purple"
      btnTxt="Done"
      title="Successfully recorded your prescription."
    />
  );
}

export default ProductPrescriptionSuccessModal;
