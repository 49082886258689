/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { client as queryClient } from '../../config/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError, showToastSuccessMessageFromAxiosResponse } from '../../util/functions';

/**
 * Create schedule
 */
export const useCreateSchedule = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post('msl/schedules', data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(queryKeys.schedules);
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Schedule
 */
export const useUpdateSchedule = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`msl/schedules/${data?.scheduleId}`, data), {
    onSuccess: (payload, variables) => {
      queryClient.invalidateQueries(queryKeys.schedules);
      queryClient.invalidateQueries(queryKeys.schedule(variables.scheduleId));

      showToastSuccessMessageFromAxiosResponse(payload);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Delete Schedule
 */
export const useDeleteSchedule = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((scheduleId) => axiosPrivate.delete(`msl/schedules/${scheduleId}`), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(queryKeys.schedules);
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Update Schedule Day Rules
 */
export const useUpdateScheduleDayRules = () => {
  const { axiosPrivate } = useAxios();
  return useMutation(({ scheduleId, payload }) => axiosPrivate.put(`msl/schedules/${scheduleId}/day-rules${payload?.length > 0 ? `?${payload?.join('&')}` : ''}`), {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(queryKeys.dayRules(variables.scheduleId));
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Create Date Override
 */
export const useCreateDateOverride = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`msl/schedules/${data?.scheduleId}/date-overrides`, data), {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(queryKeys.dateOverrides(variables.scheduleId));

      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Delete Date Override
 */
export const useDeleteDateOverride = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`msl/schedules/${data?.scheduleId}/date-overrides/delete`, data), {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(queryKeys.dateOverrides(variables.scheduleId));
      showToastSuccessMessageFromAxiosResponse(response);
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Set Active Schedule
 */
export const useSetActiveSchedule = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((scheduleId) => axiosPrivate.post(`msl/schedules/${scheduleId}/set-active`), {
    onSuccess: (payload, variables) => {
      queryClient.invalidateQueries(queryKeys.schedules);
      queryClient.invalidateQueries(queryKeys.schedule(variables));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
   * Set inActive Schedule
   */
export const useSetInActiveSchedule = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((scheduleId) => axiosPrivate.post(`msl/schedules/${scheduleId}/set-inactive`), {
    onSuccess: (payload, variables) => {
      queryClient.invalidateQueries(queryKeys.schedules);
      queryClient.invalidateQueries(queryKeys.schedule(variables));
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};
