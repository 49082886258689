export const queryKeys = {

  products: (query) => ['products', query],
  product: (productId) => [...queryKeys.products(), 'product', productId],
  productDiagnosis: (productId) => [...queryKeys.product(), 'product-diagnosis', productId],

  searchAllMslsForProduct: (productId) => ['search-all-msls-for-product', productId],
  searchAvailableMslsForProduct: (productId) => ['search-available-msls-for-product', productId],

  productSubscriptions: ['product-subscriptions'],
  productSubscriptionsProductIds: ['product-subscriptions-product-ids'],
  productPrescriptions: ['product-prescriptions'],

  trendingProductsLists: (filters) => ['trending-products-lists', filters],
  trendingProductsListsCategories: () => [...queryKeys.trendingProductsLists(), 'trending-products-list-categories'],

  productsUpdates: ['productUpdates'],
  productUpdates: (productId) => ['productUpdates', productId],

  productKnowledge: ['productKnowledge'],
};
