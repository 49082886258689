import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import Avatar from '../../Avatar';
import PromptModal from '../../PromptModal';
import { useLogoutUser } from '../../../auth/api/mutations';
import { userTypes } from '../../../config/constants';
import { hcpPaths, mslPaths } from '../../../config/paths';
import useLogout from '../../../util/hooks/useLogout';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DashboardProfileDropDown({ user }) {
  const { logout } = useLogout();
  const { mutate: logoutUser, isLoading } = useLogoutUser();
  const [logoutModal, setLogoutModal] = useState(false);

  const color = user?.color;
  const email = user?.email;
  const username = user?.name;
  const profileImg = user?.profile_image_path;
  const userType = user?.msl ? 'msl' : user?.hcp ? 'hcp' : null;

  const _toggleLogoutModal = () => setLogoutModal((prev) => !prev);

  const _handleLogoutClick = async () => {
    await logoutUser(null, {
      onSuccess: () => {
        logout();
      },
      onError: () => {
        // Logout even if there is an error (clears tokens etc...) - this is important
        logout();
      },
    });
  };

  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button className="flex max-w-sm items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
          <Avatar
            size="small"
            color={color}
            name={username}
            source={profileImg}
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-30 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3">
            <p className="text-sm">{username}</p>
            <p className="truncate text-sm font-medium text-gray-900">{email}</p>
          </div>
          <div className="py-1">
            {userType === userTypes.hcp.key && (
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to={hcpPaths.myAccount.subscriptions}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                  >
                    Subscriptions
                  </NavLink>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to={userType === userTypes.hcp.key ? hcpPaths.myAccount.home : mslPaths.myAccount.home}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm',
                  )}
                >
                  Manage account
                </NavLink>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item onClick={_toggleLogoutModal}>
              {({ active }) => (
                <button
                  type="submit"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm',
                  )}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
      <PromptModal
        title="Logout"
        btnTxt="Logout"
        description="Are you sure you would like to logout of your account?"
        open={logoutModal}
        onClose={_toggleLogoutModal}
        onBtnPress={_handleLogoutClick}
        btnLoading={isLoading}
      />
    </Menu>
  );
}

const propsAreEqual = (prevProps, nextProps) => (
  prevProps.user?.id === nextProps.user?.id
);

/* Export
============================================================================= */
export default React.memo(DashboardProfileDropDown, propsAreEqual);
