import React from 'react';
import { useParams } from 'react-router-dom';

import { Tabs } from '../../common';
import { localStorageKeys } from '../../config/constants';

/* =============================================================================
<ManageProfileHeader />
============================================================================= */
function ManageProfileHeader({ tabs, selectedTab }) {
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  return (
    <div className="border-b">
      <div className="max-w-6xl mx-auto px-4 md:px-6 lg:px-8 pb-5 md:pb-0">
        <h1 className="font-[Inter] mb-2 text-2xl font-bold text-grey-900">Manage account</h1>
        <Tabs
          tabs={tabs}
          link={`/profile/${userType}/manage-profile/`}
          selectedTab={selectedTab}
          contentStyles="border-none"
        />
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ManageProfileHeader;
