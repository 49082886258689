import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProductMslHeader from '../components/ProductMsl/ProductMslHeader';
import ProductMslAll from '../components/ProductMsl/ProductMslAll';
import ProductMslAvailable from '../components/ProductMsl/ProductMslAvailable';

/* =============================================================================
<ProductMslsScreen />
============================================================================= */
function ProductMslsScreen() {
  return (
    <div className="flex flex-col min-h-full">
      <ProductMslHeader />
      <Routes>
        <Route path="/" element={<ProductMslAll />} />
        <Route path="/online" element={<ProductMslAvailable />} />
      </Routes>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductMslsScreen;
