import React, { useState } from 'react';
import { BellIcon, MenuAlt1Icon } from '@heroicons/react/outline';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import DashboardSideBar from './DashboardSideBar';
import DashboardSearch from './DashboardSearch';
import DashboardProfileDropDown from './DashboardProfileDropDown';
import DashboardNotificationSlider from './DashboardNotificationSlider';
import MeetingInfoDrawer from '../../../meeting/components/MyMeetings/MeetingInfoDrawer';
import { getSelectedMeetingId, getShowMeetingInfoDrawer } from '../../../meeting/redux/selectors';
import { setShowMeetingInfoDrawer, setSelectedMeetingId } from '../../../meeting/redux/actions';
import { getUser } from '../../../auth/redux/selectors';

function DashboardLayout({ user }) {
  const dispatch = useDispatch();

  // Get subscribed products now, so it shows as 'subscribed' immediately when the user sees a product
  // Removed for now to reduce API calls
  // const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);
  // const { data: subscribedProductIds } = useGetSubscriptionsProductIds(userType === 'hcp');

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationSliderOpen, setNotificationSliderOpen] = useState(false);

  const selectedMeetingId = useSelector(getSelectedMeetingId);
  const showMeetingInfoDrawer = useSelector(getShowMeetingInfoDrawer);

  const _toggleNotificationSliderOpen = () => setNotificationSliderOpen((prevState) => !prevState);

  return (
    <div className="h-full">
      <DashboardSideBar open={sidebarOpen} onChange={setSidebarOpen} user={user} />

      <div className="h-full flex flex-1 flex-col lg:pl-64">
        <div className="sticky top-0 flex h-16 flex-shrink-0 bg-white border-b z-20">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-between px-4">
            <div className="flex flex-1">
              {user?.user_type?.key === 'hcp' && <DashboardSearch />}
            </div>
            <div className="ml-4 flex items-center lg:ml-6 space-x-3">

              <button
                onClick={() => setNotificationSliderOpen(true)}
                type="button"
                className="relative rounded-full bg-white m-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
                {user?.unread_notifications_count > 0 && (
                  <div className="w-3 h-3 border-2 rounded-full bg-red-500 absolute top-0 right-0" />
                )}
              </button>

              <DashboardNotificationSlider
                open={notificationSliderOpen}
                onClose={_toggleNotificationSliderOpen}
              />

              {/* Profile dropdown */}
              <DashboardProfileDropDown user={user} />
            </div>
          </div>
        </div>

        {/* Main content */}
        <main className="bg-gray-100 h-full overflow-y-auto w-full">
          <Outlet />
        </main>

        <MeetingInfoDrawer
          open={showMeetingInfoDrawer}
          onClose={() => {
            dispatch(setShowMeetingInfoDrawer(false));
            dispatch(setSelectedMeetingId(null));
          }}
          meetingId={selectedMeetingId}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(DashboardLayout);
