import React from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';

import { Button, Spinner } from '../../../../common';
import ScheduleWeeklyHoursDayItem from './ScheduleWeeklyHoursDayItem';

import { useGetScheduleDayRules } from '../../../api/queries';
import { useUpdateScheduleDayRules } from '../../../api/mutations';

/* =============================================================================
<ScheduleWeeklyHours />
============================================================================= */
function ScheduleWeeklyHours() {
  const { scheduleId } = useParams();
  const { data, status: getScheduleDayRulesStatus } = useGetScheduleDayRules(scheduleId);
  const { mutateAsync: updateScheduleRules, isLoading } = useUpdateScheduleDayRules();

  const initialValues = {
    days: [
      {
        name: 'Monday',
        key: 'monday',
        // eslint-disable-next-line max-len
        hours: data?.monday_rules?.length > 0 ? data.monday_rules.map((rule) => ({ start_time: rule.start_time ? rule.start_time.slice(0, -3) : null, end_time: rule.end_time ? rule.end_time.slice(0, -3) : null })) : [],
      },
      {
        name: 'Tuesday',
        key: 'tuesday',
        // eslint-disable-next-line max-len
        hours: data?.tuesday_rules?.length > 0 ? data.tuesday_rules.map((rule) => ({ start_time: rule.start_time ? rule.start_time.slice(0, -3) : null, end_time: rule.end_time ? rule.end_time.slice(0, -3) : null })) : [],
      },
      {
        name: 'Wednesday',
        key: 'wednesday',
        // eslint-disable-next-line max-len
        hours: data?.wednesday_rules?.length > 0 ? data.wednesday_rules.map((rule) => ({ start_time: rule.start_time ? rule.start_time.slice(0, -3) : null, end_time: rule.end_time ? rule.end_time.slice(0, -3) : null })) : [],
      },
      {
        name: 'Thursday',
        key: 'thursday',
        // eslint-disable-next-line max-len
        hours: data?.thursday_rules?.length > 0 ? data.thursday_rules.map((rule) => ({ start_time: rule.start_time ? rule.start_time.slice(0, -3) : null, end_time: rule.end_time ? rule.end_time.slice(0, -3) : null })) : [],
      },
      {
        name: 'Friday',
        key: 'friday',
        // eslint-disable-next-line max-len
        hours: data?.friday_rules?.length > 0 ? data.friday_rules.map((rule) => ({ start_time: rule.start_time ? rule.start_time.slice(0, -3) : null, end_time: rule.end_time ? rule.end_time.slice(0, -3) : null })) : [],
      },
      {
        name: 'Saturday',
        key: 'saturday',
        // eslint-disable-next-line max-len
        hours: data?.saturday_rules?.length > 0 ? data.saturday_rules.map((rule) => ({ start_time: rule.start_time ? rule.start_time.slice(0, -3) : null, end_time: rule.end_time ? rule.end_time.slice(0, -3) : null })) : [],
      },
      {
        name: 'Sunday',
        key: 'sunday',

        // eslint-disable-next-line max-len
        hours: data?.sunday_rules?.length > 0 ? data.sunday_rules.map((rule) => ({ start_time: rule.start_time, end_time: rule.end_time })) : [],
      },
    ],
  };

  const _handleSubmit = async (values) => {
    const payload = [];

    values?.days?.forEach((_day) => _day?.hours.forEach((hour, hourIndex) => {
      if (hour?.start_time) {
        payload.push(`${_day?.key}_start_time[${hourIndex}]=${hour?.start_time.slice(0, 5)}`);
      }
      if (hour?.end_time) {
        payload.push(`${_day?.key}_end_time[${hourIndex}]=${hour?.end_time?.slice(0, 5)}`);
      }
    }));

    await updateScheduleRules({ scheduleId, payload });
  };

  return (
    <div className="bg-white border px-6 sm:px-8 py-6 rounded-lg">
      {getScheduleDayRulesStatus === 'success' ? (
        <>
          <h1 className="text-gray-700 font-medium font-[Inter]">Manage your weekly hours</h1>
          <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
            {({ values }) => (
              <Form className="mt-5">
                <div className="border mb-6 rounded-md divide-y">
                  {values?.days?.map((day, dayIndex) => (
                    <ScheduleWeeklyHoursDayItem
                      key={dayIndex}
                      day={day}
                      dayIndex={dayIndex}
                      values={day?.hours}
                    />
                  ))}
                </div>
                <Button
                  type="submit"
                  variant="purple"
                  loading={isLoading}
                  title="Save changes"
                  customStyleClasses="w-36"
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <div className="w-full text-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleWeeklyHours;
