import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Schedules
 */
export const useGetSchedules = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.schedules,
    async () => {
      const { data } = await axiosPrivate.get('/msl/schedules');
      return data?.data?.msl_schedules;
    },
  );
};

/**
 * Get Schedule
 */
export const useGetSchedule = (scheduleId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.schedule(scheduleId),
    async () => {
      const { data } = await axiosPrivate.get(`msl/schedules/${scheduleId}`);
      return data?.data?.msl_schedule;
    },
    {
      enabled: scheduleId != null,
    },
  );
};

/**
 * Get Schedule Day Rules
 */
export const useGetScheduleDayRules = (scheduleId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.dayRules(scheduleId),
    async () => {
      const { data } = await axiosPrivate.get(`msl/schedules/${scheduleId}/day-rules`);
      return data?.data;
    },
    {
      enabled: scheduleId != null,
    },
  );
};

/**
 * Get Schedule Date Overrides
 */
export const useGetScheduleDateOverrides = (scheduleId) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.dateOverrides(scheduleId),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`msl/schedules/${scheduleId}/date-overrides/upcoming?page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
      enabled: scheduleId != null,
    },
  );
};
