/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { client as queryClient } from '../../config/react-query';
import { queryKeys } from './query-key-factory';
import { queryKeys as authQueryKeys } from '../../auth/api/query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';
import { showToastErrorFromAxiosError } from '../../util/functions';

/**
 * Rate Direct Call
 */
export const useRateDirectCall = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/direct-calls/${data.callId}/rate`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.callHistory());
    },
    onError: (error) => {
      showToastErrorFromAxiosError(error);
    },
  });
};

/**
 * Attach Prescreener To Direct Call
 */
export const useAttachPrescreenerToDirectCall = () => {
  const { axiosPrivate } = useAxios();
  return useMutation((data) => axiosPrivate.post(`hcp/direct-calls/${data?.directCallId}/attach-prescreener-response`, {
    prescreener_response_id: data?.prescreenerResponseId,
  }));
};

/**
 * Mark missed calls as seen
*/
export const useMarkMissedCallsSeen = () => {
  const { axiosPrivate } = useAxios();
  return useMutation(() => axiosPrivate.post('/shared/direct-calls/mark-missed-calls-as-seen'), {
    onSuccess: () => {
      queryClient.invalidateQueries(authQueryKeys.mslUser());
      queryClient.invalidateQueries(authQueryKeys.hcpUser());
    },
  });
};

/**
 * Get VOIP call access token
 */
export const useVOIPCallAccessToken = () => {
  const { axiosPrivate } = useAxios();
  return useMutation(() => axiosPrivate.post('/shared/voip/token'));
};
