import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from '@tanstack/react-query';
import '@sendbird/uikit-react/dist/index.css';
import UserProvider from './providers/UserProvider';
import AppNavigation from './navigation/AppNavigation';
import { client as queryClient } from './config/react-query';
import PusherProvider from './providers/PusherProvider';
import { SbCallsProvider } from './calls/lib/context';
import SendBirdChatProvider from './providers/SendBirdChatProvider';
import AxiosProvider from './providers/AxiosProvider';
import SendBirdCallsAuthenticator from './calls/components/SendBirdCallsAuthenticator';
import OnlineStatusListener from './profile/components/OnlineStatusListener';
import GlobalSearchProvider from './providers/GlobalSearchProvider';

/* =============================================================================
<App />
============================================================================= */
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SbCallsProvider>
        {/* The AxiosProvider must be inside SbCallsProvider, so when AxiosProvider calls useLogout (when refresh token expires), it de-authenticates from SendBird server - otherwise it can't access the context. */}
        <AxiosProvider>
          <UserProvider>
            <SendBirdCallsAuthenticator />
            <PusherProvider>
              <OnlineStatusListener />
              <SendBirdChatProvider>
                <GlobalSearchProvider>
                  <AppNavigation />
                </GlobalSearchProvider>
              </SendBirdChatProvider>
            </PusherProvider>
          </UserProvider>
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 3000,
            }}
          />
        </AxiosProvider>
      </SbCallsProvider>
    </QueryClientProvider>
  );
}

/* Export
============================================================================= */
export default App;
