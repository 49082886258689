import React from 'react';

import ManageProfileHcpForm from './ManageProfileHcpForm';
import ManageProfileMslForm from './ManageProfileMslForm';
import { localStorageKeys } from '../../../config/constants';

/* =============================================================================
<ManageProfileDetails />
============================================================================= */
function ManageProfileDetails({ heading, description }) {
  const userType = window.localStorage.getItem(localStorageKeys.discreedlyUserType);

  return (
    <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h2 className="font-[Inter] text-lg font-semibold text-gray-700">{heading}</h2>
      <p className="font-[Inter] text-sm text-gray-500">{description}</p>
      <div className="w-full h-1 border-b my-5" />
      {userType === 'hcp'
        ? <ManageProfileHcpForm />
        : userType === 'msl'
          ? <ManageProfileMslForm /> : null}
    </div>
  );
}

/* Export
============================================================================= */
export default ManageProfileDetails;
