import React, { useContext } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { GlobalSearchContext } from '../../../providers/GlobalSearchProvider';

function DashboardSearch() {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, searchInputRef } = useContext(GlobalSearchContext);

  // check if the page is 768px or less using useMediaQuery
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const _handleSearchBarClick = () => {
    if (!location.pathname.includes('/products/search/hcp')) {
      navigate('/products/search/hcp');
    }//
  };

  return (
    <div className="flex w-full md:ml-0">
      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="search-field"
          className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder={isMobile ? 'Search drugs...' : 'Search drugs by name, indication, company, code or class...'}
          type="text"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onClick={_handleSearchBarClick}
          autoComplete="off"
          ref={searchInputRef}
        />
      </div>
    </div>
  );
}

export default DashboardSearch;
