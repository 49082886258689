import React from 'react';
import { useParams } from 'react-router-dom';
import { ClockIcon } from '@heroicons/react/solid';
import { useMslMeetingTypes } from '../../../api/queries';
import Spinner from '../../../../common/Spinner';
import { Alert } from '../../../../common';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/* =============================================================================
<MslMeetingTypeSelect />
============================================================================= */
function MslMeetingTypeSelect({ meetingTypeId, onMeetingTypeIdSelect }) {
  const { mslId } = useParams();
  const { data: mslMeetingTypes, status: mslMeetingTypesStatus } = useMslMeetingTypes(mslId);

  return (
    <div className="space-y-4">
      <h2 className="text-gray-700 font-[Inter] font-medium">
        Select meeting type
      </h2>
      <div className="space-y-4">
        {mslMeetingTypesStatus === 'success' && mslMeetingTypes?.map((type) => (
          <button
            key={type?.id}
            type="button"
            onClick={() => onMeetingTypeIdSelect(type?.id)}
            className={classNames(
              meetingTypeId === type?.id ? 'border-2 border-indigo-500 border shadow-sm' : 'border-2 border border-gray-200',
              'w-full flex flex-row rounded-md px-6 py-4 space-x-3 items-center justify-between hover:shadow-sm hover:opacity-80 transition-all',
            )}
          >
            <div className="flex items-center space-x-4">
              <div style={{ backgroundColor: type?.color }} className="w-3 h-3 rounded-full" />
              <div className="text-left flex-1">
                <h3 className="text-sm text-gray-700 font-medium">{type?.name}</h3>
                {type?.description && <p className="text-sm text-gray-500">{type?.description}</p>}
              </div>
            </div>
            <div className="flex items-center justify-end space-x-1 w-24">
              <ClockIcon className="w4 h-4 text-gray-300 mt-0.5" />
              <p className="text-sm font-normal font-[Inter] text-gray-500">{`${type?.duration_in_minutes} mins`}</p>
            </div>
          </button>
        ))}
        {(mslMeetingTypesStatus === 'success' && mslMeetingTypes?.length === 0) && (
          <Alert
            description="Meetings with this MSL cannot be booked currently"
            title="No meeting types"
            status="error"
          />
        )}
        {mslMeetingTypesStatus === 'loading' && (
          <div className="flex justify-center p-5">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslMeetingTypeSelect;
