import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactHover, { Trigger, Hover } from 'react-hover';
import { useMediaQuery } from 'react-responsive';
import { usePopper } from 'react-popper';
import Button from './Button';
import { hcpPaths } from '../config/paths';
import Avatar from './Avatar';

/* =============================================================================
<CompanyNameLinkWithHoverPreview />
============================================================================= */
function CompanyNameLinkWithHoverPreview({ company }) {
  const homeLink = hcpPaths.companyProfile(company?.id).home;
  const productsLink = hcpPaths.companyProfile(company?.id).products;
  const pipelineProductsLink = hcpPaths.companyProfile(company?.id).pipelineProducts;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const [showHoverPreview, setShowHoverPreview] = useState(false);

  const [hoveringOverLink, setHoveringOverLink] = useState(false);
  const [hoveringOverPreview, setHoveringOverPreview] = useState(false);

  const showPreview = () => {
    setShowHoverPreview(true);
  };

  const hidePreview = () => {
    setShowHoverPreview(false);
  };

  const hoverComponent = (
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      onMouseEnter={() => {
        setHoveringOverPreview(true);
      }}
      onMouseLeave={() => {
        setHoveringOverPreview(false);
        if (!hoveringOverLink) {
          hidePreview();
        }
      }}
      className="w-80 z-50 bg-white border shadow-sm pb-5 overflow-hidden rounded-lg"
    >
      <NavLink to={homeLink}>
        <div className="h-24 overflow-hidden">
          {company?.banner_image_path ? (
            <img
              src={company?.banner_image_path}
              className="w-full h-full object-cover "
              alt=""
            />
          ) : (
            <div className="w-full h-full object-cover bg-sky-500" />
          )}
        </div>
        <div className="flex flex-col items-center px-4 -mt-12">
          <Avatar
            border
            size="large"
            name={company?.name}
            source={company?.logo_path}
            color={company?.color}
          />
          <div className="space-y-1 w-full text-center w-full mb-4 mt-4">
            <h2 className="font-[Inter] text-lg text-gray-700 font-semibold truncate px-5">{company?.name}</h2>
            {company?.bio && (<p className="font-[Inter] text-sm font-normal text-gray-600 text-center truncate px-5 mb-3">{company?.bio}</p>)}
          </div>
          <div className="space-y-3 w-full">
            <NavLink to={productsLink}>
              <Button
                size="block"
                variant="secondary"
                title="View products"
                customStyleClasses="text-indigo-600 mb-2"
              />
            </NavLink>
            <NavLink to={pipelineProductsLink}>
              <Button
                size="block"
                variant="secondary"
                title="View pipeline products"
                customStyleClasses="text-indigo-600"
              />
            </NavLink>
          </div>
        </div>
      </NavLink>
    </div>
  );

  const linkComponent = (
    <span className="mt-1 text-sm font-medium flex-shrink-0 text-indigo-600 hover:text-indigo-400 leading-5 truncate">
      {company?.name}
    </span>
  );

  if (isMobile) {
    return (
      <NavLink to={homeLink}>
        {linkComponent}
      </NavLink>
    );
  }

  return (
    <>
      <NavLink
        to={homeLink}
        ref={setReferenceElement}
        onMouseEnter={() => {
          setHoveringOverLink(true);
          showPreview();
        }}
        onMouseLeave={() => {
          setHoveringOverLink(false);
          if (!hoveringOverPreview) {
            hidePreview();
          }
        }}
      >
        {linkComponent}
      </NavLink>
      {(showHoverPreview || hoveringOverPreview) && hoverComponent}
    </>
  );
}

/* Export
============================================================================= */
export default CompanyNameLinkWithHoverPreview;
