import React, { useEffect } from 'react';

import CallHistoryList from '../components/CallHistoryList';
import { useMarkMissedCallsSeen } from '../api/mutations';

/* =============================================================================
<CallHistoryScreen />
============================================================================= */
function CallHistoryScreen() {
  const { mutate: markMissedCallsAsSeen } = useMarkMissedCallsSeen();

  useEffect(() => {
    markMissedCallsAsSeen();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white min-h-full">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-7 h-full">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Call history
        </h1>
        <div className="w-full">
          <CallHistoryList />
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default CallHistoryScreen;
