import React from 'react';
import {
  LibraryIcon,
  RefreshIcon,
  StarIcon,
  CheckCircleIcon,
} from '@heroicons/react/solid';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import ProductPlaceholderImg from '../assets/icons/placeholder-product-image-large.png';
import Avatar from './Avatar';

/* =============================================================================
<PipelineProductCard />
============================================================================= */
function PipelineProductCard({ pipelineProduct, showCompany }) {
  const name = pipelineProduct?.name;
  const image = pipelineProduct?.main_image_path;
  const company = pipelineProduct?.pharma_company;

  return (
    <li key={pipelineProduct.id} className="w-full">
      <a href={pipelineProduct.product_url} target="_blank" rel="noreferrer" className="group block">
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex-shrink-0 self-start group-hover:opacity-75 mb-3 sm:mb-0">
            <Avatar source={image || ProductPlaceholderImg} name={name} size="large" />
          </div>
          <div className="flex flex-col w-full sm:ml-5 sm:mr-10">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="min-w-0 flex-1 grid grid-cols-1 gap-5">
                <div>
                  <p className="text-sm font-medium text-indigo-600 truncate hover:text-indigo-400
                  focus:outline-none focus:ring focus:ring-indigo-400"
                  >
                    {name}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <StarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="truncate">
                      Disease treated:
                      {' '}
                      {pipelineProduct.disease_treated}
                    </span>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <RefreshIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="truncate">
                      Clinical trial phase:
                      {' '}
                      {pipelineProduct.clinical_trial_phase}
                    </span>
                  </p>
                  {showCompany && (
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <LibraryIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="truncate">{company.name}</span>
                    </p>
                  )}
                  {pipelineProduct.is_currently_enrolling_patients && (
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="truncate">
                        Currently enrolling patients
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ExternalLinkIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-700 hidden sm:flex"
            aria-hidden="true"
          />
        </div>
      </a>
    </li>
  );
}

PipelineProductCard.defaultProps = {
  showCompany: true,
};

/* Export
============================================================================= */
export default PipelineProductCard;
