import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

function Link({
  label, to, color, styles,
}) {
  return (
    <RouterLink
      to={to}
      className={`text-sm font-medium text-${color}-600 hover:opacity-75 font-[Inter] hover:text-${color}-700 ${styles}`}
    >
      {label}
    </RouterLink>
  );
}

Link.defaultProps = {
  color: 'indigo',
};

Link.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default Link;
