import React from 'react';

/* =============================================================================
<EmptyState />
============================================================================= */
function EmptyState({ title, icon, description }) {
  return (
    <div className="flex flex-col text-center items-center justify-center space-y-1">
      <div>{icon}</div>
      <p className="font-[Inter] text-sm font-medium text-gray-600">
        {title}
      </p>
      <p className="font-[Inter] text-sm font-normal text-gray-400 max-w-48">
        {description}
      </p>
    </div>
  );
}

/* Export
============================================================================= */
export default EmptyState;
