import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { queryKeys } from './query-key-factory';
import { useAxios } from '../../util/hooks/useAxios';

/**
 * Get Products
 */
export const useGetProducts = ({ query }) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.products(query),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`hcp/products/search?query=${query}&page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
    },
  );
};

/**
 * Get Product
 */
export const useGetProduct = (productId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.product(productId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/products/${productId}`);
      return data?.data?.product;
    },
    {
      enabled: productId != null,
    },
  );
};

/**
 * Get Product Diagnosis
 */
export const useGetProductDiagnosis = (productId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.productDiagnosis(productId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/products/${productId}/preset-diagnoses`);
      return data?.data?.preset_diagnoses;
    },
    {
      enabled: productId != null,
    },
  );
};

/**
 * Search All Msls for Product
 */
export const useSearchAllMslsForProduct = (productId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.searchAllMslsForProduct(productId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/products/${productId}/msls`);
      return data?.data?.msls;
    },
    {
      staleTime: 0, // Very important so the list updates as soon as MSL list changes (MSL goes online/offline etc...)
    },
  );
};

/**
 * Search Available Msls for Product
 */
export const useSearchAvailableMslsForProduct = (productId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.searchAvailableMslsForProduct(productId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/products/${productId}/msls/available`);
      return data?.data?.msls;
    },
    {
      staleTime: 0, // Very important so the list updates as soon as MSL list changes (MSL goes online/offline etc...)
    },
  );
};

/**
 * Get all subscriptions
 */
export const useGetSubscriptions = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(queryKeys.productSubscriptions, async () => {
    const { data } = await axiosPrivate.get('hcp/products/subscriptions');
    return data?.data?.hcp_product_subscriptions;
  });
};

/**
 * Get subscriptions product IDs
 */
export const useGetSubscriptionsProductIds = (enabled = true) => {
  const { axiosPrivate } = useAxios();
  return useQuery(queryKeys.productSubscriptionsProductIds, async () => {
    const { data } = await axiosPrivate.get('hcp/products/subscriptions/product-ids');
    return data?.data?.subscribed_to_product_ids;
  }, {
    enabled,
  });
};

/**
 * Get Trending Lists
 */
export const useGetTrendingLists = (categoryId, usState) => {
  const { axiosPrivate } = useAxios();
  return useInfiniteQuery(
    queryKeys.trendingProductsLists({ categoryId, usState }),
    async ({ pageParam = 1 }) => {
      const { data } = await axiosPrivate.get(`hcp/trending-product-lists?us_state_key=${usState}&trending_product_list_category_id=${categoryId === 'All' ? null : categoryId}&page=${pageParam}`);
      return data?.data;
    },
    {
      getNextPageParam: ({ pagination }) => (pagination?.has_more_pages
        ? pagination.page + 1 : undefined),
      enabled: usState != null && usState !== 'Select...',
    },
  );
};

/**
 * Get Trending Lists Categories
 */
export const useGetTrendingListsCategories = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.trendingProductsListsCategories(),
    async () => {
      const { data } = await axiosPrivate.get('hcp/trending-product-lists/categories');
      return data?.data?.trending_product_list_categories;
    },
  );
};

/**
 * Get Products Updates
 */
export const useGetProductsUpdates = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.productsUpdates,
    async () => {
      const { data } = await axiosPrivate.get('hcp/products/updates');
      return data?.data;
    },
    {
      staleTime: 0, // Allow it to update frequently
    },
  );
};

/**
 * Get Product Updates
 */
export const useGetProductUpdates = (productId) => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.productUpdates(productId),
    async () => {
      const { data } = await axiosPrivate.get(`hcp/products/${productId}/active-updates`);
      return data?.data?.stories;
    },
    {
      enabled: productId != null,
    },
  );
};

/**
 * Get Product Knowledge
 */
export const useGetProductKnowledge = () => {
  const { axiosPrivate } = useAxios();
  return useQuery(
    queryKeys.productKnowledge,
    async () => {
      const { data } = await axiosPrivate.get('msl/account/product-knowledge');
      return data?.data?.product_knowledge;
    },
  );
};
