import React from 'react';
import { setIsAuthenticated, setUser } from '../../auth/redux/actions';
import { localStorageKeys } from '../../config/constants';
import { client as queryClient } from '../../config/react-query';
import store from '../../redux/store';
import { useSbCalls } from '../../calls/lib/context';
import { resetState as resetChatState } from '../../chat/redux/actions';
import { resetState as resetMeetingsState } from '../../meeting/redux/actions';

export default function useLogout() {
  const { clearCalls, deauth } = useSbCalls();

  // Create logout function and return it
  const logout = () => {
    store.dispatch(setIsAuthenticated(false));
    store.dispatch(setUser(null));
    localStorage.removeItem(localStorageKeys.discreedlyAccessToken);
    localStorage.removeItem(localStorageKeys.discreedlyUserType);
    queryClient.clear();

    // Reset and de-authenticate from Sendbird Calls (very important!)
    clearCalls();
    deauth();

    // Reset redux state
    store.dispatch(resetChatState());
    store.dispatch(resetMeetingsState());
  };

  return { logout };
}
