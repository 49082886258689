import React from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarIcon, ChatIcon, PhoneIcon } from '@heroicons/react/solid';
import {
  Avatar, Button, UserAvailabilityIndicator,
} from '../../../common';
import useUserOnlineStatus from '../../../util/hooks/useUserOnlineStatus';
import { useCreateChannel } from '../../../chat/api/mutations';
import { hcpPaths } from '../../../config/paths';

/* =============================================================================
<ProductMslsCard />
============================================================================= */
function ProductMslsCard({ msl }) {
  const navigate = useNavigate();
  const name = msl?.user?.name;
  const color = msl?.user?.color;
  const location = msl?.us_state?.name;
  const avatar = msl?.user?.profile_image_path;
  const initialAvailability = msl?.availability;

  const { mutate: createChannel, status: createChannelStatus } = useCreateChannel();

  const userAvailability = useUserOnlineStatus(msl?.user?.id, initialAvailability, false);

  const _handleChatClick = () => {
    createChannel({
      name: '',
      user_ids: [msl?.user?.id],
    }, {
      onSuccess: (response) => {
        navigate(hcpPaths.chat(response?.data?.data?.send_bird_channel_url));
      },
    });
  };

  const profileLink = hcpPaths.mslProfile(msl?.id).home;
  const callLink = hcpPaths.mslProfile(msl?.id).initiateCall;
  const scheduleLink = hcpPaths.mslProfile(msl?.id).scheduleMeeting;

  const _handleCallMslClick = () => {
    navigate(callLink);
  };

  const _scheduleAMeeting = () => {
    navigate(scheduleLink);
  };

  return (
    <div className="bg-white shadow flex flex-col justify-between pt-6 px-6 rounded-xl overflow-hidden">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between mb-1">
          <UserAvailabilityIndicator
            availability={userAvailability}
            variant="text"
          />
          <span className="text-xs text-gray-400 font-medium leading-4">
            {userAvailability?.dynamic_status.key === 'away'
              ? moment(userAvailability?.away_since).fromNow() : userAvailability?.dynamic_status.key === 'busy' ? moment(userAvailability.busy_since).fromNow() : null}
          </span>
        </div>
        <div className="flex flex-row justify-between mt-3.5">
          <div className="overflow-x-hidden mr-4">
            <Link to={profileLink}>
              <h1 className="font-[Inter] text-gray-700 hover:text-gray-500 font-semibold leading-6 truncate">{name}</h1>
            </Link>
            <h2 className="font-[Inter] text-gray-500 font-medium text-sm leading-6">{location}</h2>
          </div>
          <div className="relative">
            <Link to={profileLink} className="hover:opacity-75">
              <Avatar
                size="medium"
                name={name}
                source={avatar}
                color={color}
              />
            </Link>
            <UserAvailabilityIndicator
              availability={userAvailability}
              containerStyles="bottom-1 right-0 sm:bottom-0 sm:right-0"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center border-t -mx-6 mt-6 divide-y sm:divide-y-0 sm:divide-x divide-gray-200">
        <div className="flex-1">
          <Button
            title="Chat"
            loading={createChannelStatus === 'loading'}
            variant="secondary"
            customStyleClasses="border-none py-4 sm:py-6 shadow-none rounded-none w-full"
            icon={(
              <ChatIcon className="w-5 h-5 text-gray-400" />
          )}
            onClick={_handleChatClick}
          />
        </div>
        <div className="flex-1">
          <Button
            title="Call"
            variant="secondary"
            customStyleClasses="border-none py-4 sm:py-6 flex-1 shadow-none rounded-none w-full"
            disabled={userAvailability?.dynamic_status.key !== 'online'}
            onClick={_handleCallMslClick}
            icon={(
              <div className="relative">
                <PhoneIcon className="w-5 h-5 text-gray-400" />

                {userAvailability?.dynamic_status.key === 'online' ? (
                  <span className="absolute top-0 left-2 flex h-1.5 w-1.5">
                    <span className="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-red-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-500" />
                  </span>
                ) : null}
              </div>
          )}
          />
        </div>
        <div className="flex-1">
          <Button
            title="Schedule"
            variant="secondary"
            customStyleClasses="border-none py-4 sm:py-6 flex-1 shadow-none w-full"
            onClick={_scheduleAMeeting}
            icon={(
              <CalendarIcon className="w-5 h-5 text-gray-400" />
          )}
          />
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default ProductMslsCard;
