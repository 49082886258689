import React, { useEffect, useState } from 'react';

import PeriodicJob from '../util/periodicJob';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AnimatedDotDotDot = ({ prefixText, customStyles }) => {
  const dots = ['.', '..', '...'];
  const [frame, setFrame] = useState(0);
  useEffect(() => {
    const job = new PeriodicJob((x, count) => {
      setFrame(count);
    });
    job.start();
    return () => { job.stop(); };
  }, []);
  return (
    <span className={classNames(customStyles)}>
      {`${prefixText}${dots[frame % 3]}`}
    </span>
  );
};

export default AnimatedDotDotDot;
