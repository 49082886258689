import React from 'react';

import { EmojiSadIcon } from '@heroicons/react/outline';
import ProductTrendingListItemHeader from './ProductTrendingListItemHeader';
import ProductTrendingListItemProduct from './ProductTrendingListItemProduct';

import { useUsStates } from '../../../auth/api/queries';
import { EmptyState } from '../../../common';

/* =============================================================================
<ProductTrendingListItem />
============================================================================= */
function ProductTrendingListItem({ list }) {
  const { data: usStates } = useUsStates();

  const categoryName = list?.name;
  const products = list?.products;

  return (
    <div className="border bg-white p-5 rounded-lg my-4">
      <ProductTrendingListItemHeader
        usStates={usStates}
        categoryName={categoryName}
      />
      <div className="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-2 2xl:grid-cols-4">
        {products?.length > 0 && products?.map((therapyProduct) => (
          <ProductTrendingListItemProduct
            key={therapyProduct?.id}
            therapyProduct={therapyProduct}
          />
        ))}
      </div>

      {products?.length === 0 && (
        <div className="text-center py-6">
          <EmptyState
            title="No results"
            icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
            description="No products found"
          />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default ProductTrendingListItem;
