import React from 'react';
import { useParams } from 'react-router-dom';

import { Modal } from '../../../common';
import { useGetProduct } from '../../api/queries';
import Markdown from '../../../common/Markdown';

function ProductMediaModal({ visible, onClose }) {
  const { productId } = useParams();
  const { data: product } = useGetProduct(productId);

  const markdown = product?.media;

  return (
    <Modal
      size="xl"
      visible={visible}
      onClose={onClose}
      heading="Media"
      closeWhenClickOutside
    >
      <div className="w-full p-5 h-full overflow-y-scroll">
        <Markdown content={markdown} />
      </div>
    </Modal>
  );
}

export default ProductMediaModal;
