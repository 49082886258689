import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetFeaturedTimezones } from '../../../auth/api/queries';
import { Button, Input, Spinner } from '../../../common';
import { useGetSchedule } from '../../api/queries';
import { useUpdateSchedule, useDeleteSchedule } from '../../api/mutations';
import { mslPaths } from '../../../config/paths';
import PromptModal from '../../../common/PromptModal';

/* =============================================================================
<ScheduleSettings />
============================================================================= */
function ScheduleSettings() {
  const navigate = useNavigate();
  const { scheduleId } = useParams();
  const { data: schedule, isLoading: scheduleLoading } = useGetSchedule(scheduleId);
  const { mutateAsync: updateSchedule, isLoading: updateLoading } = useUpdateSchedule(scheduleId);
  const {
    mutateAsync: deleteSchedule,
    isLoading: deleteLoading,
  } = useDeleteSchedule(scheduleId);
  const { data: timezones, isLoading: timezonesLoading } = useGetFeaturedTimezones();

  const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);

  const initialValues = {
    name: schedule?.name,
    timezone: timezones?.find((timezone) => schedule?.timezone === timezone?.key)?.key || '',
    buffer_before_meeting_in_minutes: schedule?.buffer_before_meeting_in_minutes,
    buffer_after_meeting_in_minutes: schedule?.buffer_after_meeting_in_minutes,
    minimum_time_to_book_ahead_in_minutes: schedule?.minimum_time_to_book_ahead_in_minutes,
    maximum_time_to_book_ahead_in_minutes: schedule?.maximum_time_to_book_ahead_in_minutes,
  };

  const _handleSubmit = async (values) => {
    const payload = {
      scheduleId,
      name: values?.name,
      buffer_before_meeting_in_minutes: values?.buffer_before_meeting_in_minutes,
      buffer_after_meeting_in_minutes: values?.buffer_after_meeting_in_minutes,
      minimum_time_to_book_ahead_in_minutes: values?.minimum_time_to_book_ahead_in_minutes,
      maximum_time_to_book_ahead_in_minutes: values?.maximum_time_to_book_ahead_in_minutes,
    };

    await updateSchedule(payload);
  };

  const _handleDelete = async () => {
    await deleteSchedule(scheduleId, {
      onSuccess: () => {
        navigate(mslPaths.schedules);
      },
    });
  };

  return (
    <div className="bg-white border px-8 py-6 rounded-lg">
      <h1 className="text-gray-700 font-medium font-[Inter]">Settings</h1>
      {schedule && timezones && (
        <Formik initialValues={initialValues} onSubmit={_handleSubmit}>
          {({ values }) => (
            <Form className="mr-0 max-w-3xl flex flex-wrap justify-between mt-4 grid grid-cols-1 gap-5 xl:gap-5 xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
              <Field name="name">
                {({ field }) => (
                  <Input label="Name" {...field} />
                )}
              </Field>
              <Field name="timezone">
                {({ field }) => (
                  <Input
                    label="Timezone"
                    {...field}
                    disabled
                  />
                )}
              </Field>
              <Field name="buffer_before_meeting_in_minutes">
                {({ field }) => (
                  <Input label="Buffer before each meeting (in minutes)" {...field} />
                )}
              </Field>
              <Field name="buffer_after_meeting_in_minutes">
                {({ field }) => (
                  <Input label="Buffer after each meeting (in minutes)" {...field} />
                )}
              </Field>
              <Field name="minimum_time_to_book_ahead_in_minutes">
                {({ field }) => (
                  <Input label="Minimum time to book ahead (in minutes)" {...field} />
                )}
              </Field>
              <Field name="maximum_time_to_book_ahead_in_minutes">
                {({ field }) => (
                  <Input label="Maximum time to book ahead (in minutes)" {...field} />
                )}
              </Field>
              <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-y-0 sm:space-x-4 mt-2">
                <Button
                  type="submit"
                  variant="purple"
                  title="Save changes"
                  customStyleClasses="w-full sm:w-40 shrink-0"
                  loading={updateLoading}
                />
                <Button
                  variant="secondary"
                  title="Delete schedule"
                  customStyleClasses="w-full sm:w-40 shrink-0"
                  loading={deleteLoading}
                  onClick={() => setShowDeletePromptModal(true)}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
      {(timezonesLoading || scheduleLoading) && (
        <div className="w-full text-center mb-2">
          <Spinner />
        </div>
      )}
      <PromptModal
        title="Confirm delete"
        btnTxt="Delete"
        description="Are you sure you would like to delete this schedule?"
        open={showDeletePromptModal}
        onClose={() => setShowDeletePromptModal(false)}
        onBtnPress={_handleDelete}
        btnLoading={deleteLoading}
      />
    </div>
  );
}

/* Export
============================================================================= */
export default ScheduleSettings;
