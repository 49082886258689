import React from 'react';
import {
  InformationCircleIcon,
  LibraryIcon,
  StarIcon,
} from '@heroicons/react/solid';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';
import ProductSubscriptionButton from '../products/components/ProductSubscriptionButton';
import ProductPlaceholderImg from '../assets/icons/placeholder-product-image-large.png';
import ProductSpeakToMedicalExpertButton from '../products/components/ProductSpeakToMedicalExpertButton';
import { hcpPaths } from '../config/paths';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ConditionalLink = ({ children, to, condition }) => ((!!condition && to) ? <Link to={to} className="group block">{children}</Link>
  : (
    <div className="block">
      {children}
    </div>
  ));

/* =============================================================================
<ProductCard />
============================================================================= */
function ProductCard({
  product,
  showCompany,
  showActions,
  alwaysShowActionsVertically,
  alwaysShowActionsAsFullWidth,
}) {
  const name = product?.name;
  const productId = product?.id;
  const image = product?.main_image_path;
  const indication = product?.indication;
  const codes = product?.codes || [];

  return (
    <div key={product.id} className="w-full">
      <ConditionalLink to={hcpPaths.product(productId).home} condition={showActions}>
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <div className="flex-shrink-0 self-start group-hover:opacity-75 mb-3 sm:mb-0">
            <Avatar source={image || ProductPlaceholderImg} name={name} size="large" />
          </div>
          <div className="flex flex-col w-full sm:ml-5 sm:mr-10">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="min-w-0 flex-1 grid grid-cols-1 gap-5">
                <div>
                  <p
                    className={classNames(
                      showActions ? 'text-indigo-600' : 'text-gray-800',
                      'text-sm font-medium truncate focus:outline-none focus:ring focus:ring-indigo-300',
                    )}
                  >
                    {product.name}
                  </p>

                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <StarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="truncate">{indication}</span>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="truncate">
                      NDC codes:
                      {' '}
                      {codes.join(', ')}
                    </span>
                  </p>
                  {showCompany && (
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <LibraryIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span className="flex-shrink-0 leading-5">
                      {product?.pharma_company?.name}
                    </span>
                  </p>
                  )}
                </div>
              </div>
            </div>

            {showActions && (
              <div className={classNames(
                alwaysShowActionsVertically ? 'flex-col space-y-3' : 'flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-3',
                alwaysShowActionsAsFullWidth ? 'w-full' : '',
                'justify-stretch flex z-100 mt-5',
              )}
              >
                <ProductSpeakToMedicalExpertButton productId={productId} />
                <div className={classNames(alwaysShowActionsAsFullWidth ? 'w-full' : 'w-full sm:w-40', '')}>
                  <ProductSubscriptionButton productId={productId} />
                </div>
              </div>
            )}
          </div>

          {showActions && (
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400 group-hover:text-gray-700 hidden sm:flex"
              aria-hidden="true"
            />
          )}
        </div>
      </ConditionalLink>
    </div>
  );
}

ProductCard.defaultProps = {
  showCompany: true,
  showActions: true,
  alwaysShowActionsVertically: false,
  alwaysShowActionsAsFullWidth: false,
};

/* Export
============================================================================= */
export default ProductCard;
