import React, { Fragment, useRef } from 'react';
import { PhoneIcon } from '@heroicons/react/outline';
import { EmptyState, Spinner } from '../../../common';
import { useGetCallHistory } from '../../api/queries';
import useIntersectionObserver from '../../../util/hooks/useIntersectionObserver';
import CallHistoryListItem from './CallHistoryListItem';

/* =============================================================================
<CallHistoryList />
============================================================================= */
function CallHistoryList() {
  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetCallHistory();

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    enabled: hasNextPage,
    onIntersect: fetchNextPage,
  });

  return (
    <div className="w-full relative h-full">
      <ol className="divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
        {data?.pages?.map((page) => (
          <Fragment key={page?.pagination?.page}>
            {page?.direct_calls?.map((call) => (
              <CallHistoryListItem call={call} />
            ))}
          </Fragment>
        ))}
      </ol>

      {/* fetch data on scroll end */}
      <div ref={loadMoreRef} className={`${!hasNextPage ? 'hidden' : ''} text-2xl flex w-full justify-center mb-3`}>
        {isFetchingNextPage && <Spinner />}
      </div>

      {/* loading spinner */}
      {isLoading && (
        <div className="w-full">
          <div className="text-center">
            <Spinner />
          </div>
        </div>
      )}
      {/* if no data */}
      {data?.pages[0]?.direct_calls.length === 0 && (
        <div className="text-center h-full mt-8">
          <EmptyState
            title="No call history"
            icon={<PhoneIcon className="w-8 h-8  text-gray-400" />}
            description="You haven't had any calls yet"
          />
        </div>
      )}
    </div>
  );
}

/* Export
============================================================================= */
export default CallHistoryList;
