import React from 'react';
import { Avatar } from '../../common';

/* =============================================================================
<MslDashboardHeader />
============================================================================= */
function MslDashboardHeader({
  profileImage,
  name,
  color,
}) {
  return (
    <div className="flex items-center">
      <Avatar
        size="extraMedium"
        name={name}
        source={profileImage}
        color={color}
      />
      <div className="ml-5">
        <h1 className="font-[Inter] text-sm font-medium text-gray-600 leading-5">Welcome back</h1>
        <h1 className="font-[Inter] text-2xl font-bold text-gray-900">{name}</h1>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default MslDashboardHeader;
