import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Notfound } from '../../common';
import SchedulesScreen from './SchedulesScreen';
import ScheduleScreen from './ScheduleScreen';
import AuthenticatedRoute from '../../navigation/AuthenticatedRoute';

/* =============================================================================
<SchedulesScreens />
============================================================================= */
function SchedulesScreens() {
  return (
    <Routes>
      <Route path="schedules">
        <Route element={<AuthenticatedRoute allowHcp={false} allowMsl />}>
          <Route path="msl" element={<SchedulesScreen />} />
          <Route path="msl/:scheduleId/:tab?" element={<ScheduleScreen />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Route>
    </Routes>
  );
}

/* Export
============================================================================= */
export default SchedulesScreens;
