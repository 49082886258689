/* eslint-disable react/jsx-filename-extension */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import SendBirdCall, { LoggerLevel, RoomType } from 'sendbird-calls';
import { connect } from 'react-redux';
import CallContext, { initialContext } from './context';
import { reducer } from './reducer';
import { initialState } from './state';
import { statefyDirectCall, statefyRoom } from './statefy';
import { SENDBIRD_APP_ID } from '../../../config/secrets';
import { getIsAuthenticated, getUser } from '../../../auth/redux/selectors';
import Spinner from '../../../common/Spinner';

/**
 * Provider
 * ```tsx
 * <SbCallsProvider>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the SbCallsProvider to its child components.
 */
const SbCallsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { calls } = state;
  const currentCall = useMemo(() => calls.find((call) => !call.isEnded), [calls]);
  const isBusy = useMemo(() => calls.some((call) => !call.isEnded), [calls]);

  const [isSendBirdAuthenticated, setIsSendBirdAuthenticated] = useState(false);

  // Available input devices
  const [availableVideoInputDevices, setAvailableVideoInputDevices] = useState([]);
  const [availableAudioInputDevices, setAvailableAudioInputDevices] = useState([]);
  const [availableAudioOutputDevices, setAvailableAudioOutputDevices] = useState([]);

  // Currently selected input devices
  const [selectedVideoInputDeviceId, setSelectedVideoInputDeviceId] = useState(null);
  const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] = useState(null);
  const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useState(null);

  const ringingListenerId = 'sb-call-listener';

  const initAndAuthSendbirdCalls = useCallback(async (userId, accessToken) => {
    SendBirdCall.init(SENDBIRD_APP_ID);

    try {
      SendBirdCall.removeListener(ringingListenerId);
    } catch (error) {
      // TODO
    }

    // TODO: keep logger line, or not?
    SendBirdCall.setLoggerLevel(LoggerLevel.WARNING);

    SendBirdCall.addListener(ringingListenerId, {
      onRinging: (call) => {
        dispatch({ type: 'RINGING', payload: statefyDirectCall(call, dispatch, true) });
      },
      onAudioInputDeviceChanged: (current, available) => {
        setAvailableAudioInputDevices(available);
        setSelectedAudioInputDeviceId(current?.deviceId);
      },
      onAudioOutputDeviceChanged: (current, available) => {
        setAvailableAudioOutputDevices(available);
        setSelectedAudioOutputDeviceId(current?.deviceId);
      },
      onVideoInputDeviceChanged: (current, available) => {
        setAvailableVideoInputDevices(available);
        setSelectedVideoInputDeviceId(current?.deviceId);
      },
    });

    const authenticatedSendBirdUser = await SendBirdCall.authenticate({ userId, accessToken })
      .then(() => {
        // authentication connection is established.
      }).catch((error) => {
        // authentication connection failed.
      });
    await SendBirdCall.connectWebSocket()
      .then(() => {
        // WebSocket connection is established.
      }).catch((error) => {
        // WebSocket connection failed.
      });

    dispatch({
      type: 'AUTH',
      payload: {
        user: authenticatedSendBirdUser,
      },
    });

    setIsSendBirdAuthenticated(true);

    return authenticatedSendBirdUser;
  }, []);

  const deauth = useCallback(() => {
    SendBirdCall.removeListener(ringingListenerId);
    SendBirdCall.deauthenticate();
    dispatch({ type: 'DEAUTH' });
  }, []);

  /*
    Media Device Control
   */

  const updateMediaDevices = useCallback((constraints) => {
    SendBirdCall.updateMediaDevices(constraints);
  }, []);

  const selectVideoInputDevice = useCallback(async (deviceId) => {
    SendBirdCall.selectVideoInputDevice(availableVideoInputDevices?.find((d) => d?.deviceId === deviceId));
    setSelectedVideoInputDeviceId(deviceId);
  }, [availableVideoInputDevices]);

  const selectAudioInputDevice = useCallback(async (deviceId) => {
    SendBirdCall.selectAudioInputDevice(availableAudioInputDevices?.find((d) => d?.deviceId === deviceId));
    setSelectedAudioInputDeviceId(deviceId);
  }, [availableAudioInputDevices]);

  const selectAudioOutputDevice = useCallback(async (deviceId) => {
    SendBirdCall.selectAudioOutputDevice(availableAudioOutputDevices?.find((d) => d?.deviceId === deviceId));
    setSelectedAudioOutputDeviceId(deviceId);
  }, [availableAudioOutputDevices]);

  /*
    Direct Calls
   */
  const dial = useCallback(
    (params) => new Promise((res, rej) => {
      SendBirdCall.dial(params, (call, error) => {
        if (error) {
          // TODO: show this error to the user
          rej(error);
          return;
        }
        // TODO: Test this - Moved the below line to here, instead of above (if (error)) to prevent
        const statefulCall = statefyDirectCall(call, dispatch, true);
        dispatch({ type: 'ADD_CALL', payload: statefulCall });
        res(statefulCall);
      });
    }),
    [],
  );

  const clearCalls = useCallback(() => {
    dispatch({ type: 'CLEAR_CALLS' });
  }, []);

  /*
    Rooms
   */
  const createRoom = useCallback(
    async (options) => {
      const room = await SendBirdCall.createRoom(options);
      const statefulRoom = statefyRoom(room, dispatch);
      dispatch({ type: 'ADD_ROOM', payload: statefulRoom });
      return statefulRoom;
    },
    [],
  );

  const getCachedRoomById = useCallback(
    (roomId) => state.rooms.find((x) => x.roomId === roomId),
    [state.rooms],
  );

  const fetchRoomById = useCallback(
    async (roomId) => {
      const room = await SendBirdCall.fetchRoomById(roomId);
      const statefulRoom = statefyRoom(room, dispatch);
      if (state.rooms.find((x) => x.roomId === room.roomId)) {
        dispatch({ type: 'UPDATE_ROOM', payload: statefulRoom });
      } else {
        dispatch({ type: 'ADD_ROOM', payload: statefulRoom });
      }
      return statefulRoom;
    },
    [state.rooms],
  );

  const callContext = useMemo(() => ({
    ...initialContext,
    ...state,
    deauth,
    isAuthenticated: !!state.user,
    initAndAuthSendbirdCalls,

    // Media Device Control
    updateMediaDevices,
    availableVideoInputDevices,
    availableAudioInputDevices,
    availableAudioOutputDevices,
    selectedVideoInputDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectVideoInputDevice,
    selectAudioInputDevice,
    selectAudioOutputDevice,

    // Direct Calls
    currentCall,
    isBusy,
    dial,
    addDirectCallSound: SendBirdCall.addDirectCallSound,
    removeDirectCallSound: SendBirdCall.removeDirectCallSound,
    clearCalls,

    // Rooms
    createRoom,
    getCachedRoomById,
    fetchRoomById,
    RoomType,
  }), [
    state,
    deauth,
    initAndAuthSendbirdCalls,

    // Media Device Control
    updateMediaDevices,
    availableVideoInputDevices,
    availableAudioInputDevices,
    availableAudioOutputDevices,
    selectedVideoInputDeviceId,
    selectedAudioInputDeviceId,
    selectedAudioOutputDeviceId,
    selectVideoInputDevice,
    selectAudioInputDevice,
    selectAudioOutputDevice,

    // Direct Calls
    currentCall,
    isBusy,
    dial,
    clearCalls,

    // Rooms
    createRoom,
    getCachedRoomById,
    fetchRoomById,
  ]);

  // TODO: if not sendbird authenticated, return children without context?

  return (
    <CallContext.Provider value={callContext}>
      {children}
    </CallContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
});

/* Export
============================================================================= */
export default connect(mapStateToProps)(SbCallsProvider);
