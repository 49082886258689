import React from 'react';
import { useParams } from 'react-router-dom';
import { EmojiSadIcon } from '@heroicons/react/outline';

import HcpProfileHeader from '../components/HcpProfileHeader';
import { EmptyState, Spinner } from '../../common';

import { useGetHcpProfile } from '../api/queries';

/* =============================================================================
<HcpProfileScreen />
============================================================================= */
function HcpProfileScreen() {
  const { hcpId } = useParams();
  const { data: hcp, isLoading } = useGetHcpProfile(hcpId);

  if (!hcp && isLoading) {
    return (
      <div className="text-center mt-8">
        <Spinner />
      </div>
    );
  }

  if (!hcp && !isLoading) {
    return (
      <div className="text-center mt-8">
        <EmptyState
          title="HCP not found"
          icon={<EmojiSadIcon className="w-9 h-9  text-gray-400" />}
          description="HCP not found"
        />
      </div>
    );
  }

  return (
    <div>
      <HcpProfileHeader hcpId={hcpId} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 md:py-8">
        <div className="bg-white overflow-hidden rounded-lg border">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">HCP information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Profile overview</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">FULL NAME</h1>
                <p className="mt-1 text-sm font-medium text-gray-700 leading-5">{hcp?.user?.name}</p>
              </div>
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider uppercase leading-4 text-gray-400">TYPE</h1>
                <p className="mt-1 text-sm font-medium text-gray-700 leading-5">{hcp?.hcp_type?.name}</p>
              </div>
              <div className="sm:col-span-1">
                <h1 className="text-xs font-semibold tracking-wider leading-4 text-gray-400">STATE</h1>
                <p className="mt-1 text-sm font-medium text-gray-700 leading-5">{hcp?.us_state?.name}</p>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

/* Export
============================================================================= */
export default HcpProfileScreen;
